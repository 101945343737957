import { useState } from 'react';
import './TradingSortingHeader.scss';
import { TradingSearchResult } from '../../../../replicant/features/offchainTrading/offchainTrading.properties';
import { TradingTokenListing } from '../../../../replicant/features/offchainTrading/types';

interface Props {
  totalLabel: string;
  totalValue: number;
  list: TradingSearchResult[] | TradingTokenListing[] | [];
}

type SortingMode = {
  id: string;
  caption: string;
  sort: () => void;
};

// todo: we need to pass a full list of items each with the folllowing data
// - list of items

// TradingSearchResult
// - item name -> profile.name / profile.ticker
// - item market cap -> missing -> there is lastTx.currencyAmount (?)
// - item creation date -> item.lastTx.createdAt
// - item number of holders -> missing

// TradingTokenListing
// - item name -> name / ticker
// - item market cap -> marketCap (as Big)
// - item creation date -> missing
// - item number of holders -> missing

const sortingModes = [
  { id: 'Name', caption: 'Name', sort: () => {} },
  { id: 'MarketCap', caption: 'Market Cap', sort: () => {} },
  { id: 'DateCreated', caption: 'Date Created', sort: () => {} },
  { id: 'Holders', caption: 'Number of Holders', sort: () => {} },
];

export const TradingSortingHeader = ({
  totalLabel,
  totalValue,
  list,
}: Props) => {
  const [sortingModeIndex, setSortingModeIndex] = useState<number>(0);

  const changeSortingMode = () => {
    let index = sortingModeIndex;

    index += 1;
    if (index > sortingModes.length - 1) index = 0;
    if (index < 0) index = sortingModes.length - 1;

    setSortingModeIndex(index);

    // console.warn(
    //   '>>> changeSortingMode',
    //   index,
    //   '/',
    //   sortingModes.length,
    //   sortingModes[index],
    // );
  };

  const currentSortingMode = sortingModes[sortingModeIndex] as SortingMode;

  // console.warn('>>> list', list);

  // todo: re-enable sorting button when sorting feature is completed

  return (
    <div className="sorting-header-container">
      <div className="sorting-header">
        <div className="total-assets">
          <div className="label">{`Total Assets ${totalLabel}`}</div>
          <div className="value">{totalValue}</div>
        </div>

        {/* <div className="sort-by" onClick={changeSortingMode}>
          <div className="label">Sort By</div>
          <div className="value">{currentSortingMode.caption}</div>
        </div> */}
      </div>

      <div className={`separator`} />
    </div>
  );
};
