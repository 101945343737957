import type { AnalyticsProperties } from '@play-co/gcinstant';
import { gcinstant } from '../Controllers/AppController';
import { env } from '../config';
import type {
  FirstEntryUserProperties,
  LastEntryUserProperties,
} from './properties';
import { State } from '../../replicant/schema';
import { getPowerUpBonusPerHour } from '../../replicant/features/powerups/getters';
import {
  getBalance,
  getEnergy,
  getLeague,
} from '../../replicant/features/game/game.getters';

export function sendEntryFinalAnalytics(
  state: State,
  entryDataPayload: Record<string, any>,
  now: number,
): Promise<void> {
  // Live reload will send EntryFinal more than once
  if (env === 'local' && (gcinstant as any).didCallSendEntryFinalAnalytics) {
    return Promise.resolve();
  }

  const entryData =
    gcinstant.entryData ?? ({} as AnalyticsProperties.EntryData);
  // EntryFinal event properties
  const entryFinalEventProps: { [key: string]: any } = {
    $subFeature: entryData.$subFeature,

    // selection count from inviteAsync
    sourceSelectionCount: entryData.selectionCount,
    sourceRealtimeEnergy: entryDataPayload?.sourceRealtimeEnergy,
    sourceRealtimeEarningsPerHour:
      entryDataPayload?.sourceRealtimeEarningsPerHour,
    sourceRealtimeEarningsPerSecond:
      entryDataPayload?.sourceRealtimeEarningsPerSecond,
    sourceRealtimeBalance: entryDataPayload?.sourceRealtimeBalance,
    sourceRealtimeScore: entryDataPayload?.sourceRealtimeScore,

    creativeVersion: entryDataPayload?.creativeVersion,
  };

  // First session user properties
  const firstEntryUserProps: FirstEntryUserProperties = {
    $firstEntrySourceRealtimeIsPurchaser: false,
    $firstEntrySourceRealtimeLTV: 0,
  };

  const earningsPerHour = getPowerUpBonusPerHour(state, now);

  // Last session user properties
  const lastEntryUserProps: LastEntryUserProperties = {
    $lastEntryIsPurchaser: false,
    $lastEntryLTV: 0,
    $lastEntrySourceRealtimeIsPurchaser: false,
    $lastEntrySourceRealtimeLTV: 0,

    '#lastEntryEnergy': getEnergy(state, now),
    '#lastEntryEarningsPerHour': Math.round(earningsPerHour),
    '#lastEntryEarningsPerSecond': Math.round(earningsPerHour / 3600),
    '#lastEntryBalance': getBalance(state, now),
    '#lastEntryScore': state.score,
    '#lastEntryLeague': getLeague(state),
  };

  // Send off all the props to GCInstant
  return gcinstant.sendEntryFinalAnalytics(
    entryFinalEventProps,
    firstEntryUserProps,
    lastEntryUserProps,
  );
}
