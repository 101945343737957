import { TradingToken } from '../../replicant/features/offchainTrading/offchainTrading.getters';
import { getPowerUps } from '../../replicant/features/powerups/getters';
import { gameApi } from '../api';
import {
  Team,
  TTeamPageShowOpts,
  LeagueLeaderboard,
  ModalComponent,
} from '../types';
import { View } from '../View';
import { AppController } from './AppController';

export const initViews = (app: AppController) => ({
  ProfilePage: new View('ProfilePage', app, async () => {}, {
    onOpen: () => {
      app.track('Profile_view', {
        feature: 'profile',
        $subFeature: 'profile',
        originFeature: 'home',
        username: app.profile.current?.name || 'unknown',
        self: Boolean(app.profile.current?.isSelf),
      });
    },
  }),
  Friends: new View('Friends', app, app.getFriends, {
    onOpen: () => {
      app.track('OpenFriendPage', {
        feature: 'friends',
        $subFeature: 'friends',
        originFeature: 'home',
      });
    },
  }),
  LeaderboardDrawer: new View('Leaderboard', app, gameApi.leaderboard, {
    onOpen: () => {
      app.track('OpenLeaderboard', {
        feature: 'leaderboard',
        $subFeature: 'leaderboard',
        originFeature: 'home',
      });
    },
  }),
  JoinTeam: new View('JoinTeam', app, gameApi.leaderboard, {
    onOpen: () => {
      app.track('JoinTeam', {
        feature: 'team',
        $subFeature: 'team_join',
        originFeature: 'home',
      });
    },
  }),
  TeamPage: new View<Team | undefined, TTeamPageShowOpts>(
    'TeamPage',
    app,
    gameApi.getTeam,
    {
      onOpen: (showOpts) => {
        app.track('OpenTeamPage', {
          ...showOpts?.eventProps,
          feature: 'team',
          $subFeature: 'team_view',
          originFeature: 'home',
        });
      },
      onClose: () => {
        // app prevent lingering data from another team page to display when visiting different teams
        app.views.TeamPage.setData(undefined);
      },
      onFetchComplete: () => {
        // If we refresh the team and it's the players team, update player team
        if (
          app.clicker.playerTeam &&
          app.clicker.playerTeam?.id === app.views.TeamPage.data?.id
        ) {
          app.clicker.setPlayerTeam(app.views.TeamPage.data);
        }

        // If we are viewing the page for a non migrated team, call migration
        if (app.views.TeamPage.data && !app.views.TeamPage.data.search) {
          app.invoke.migrateTeam({ teamId: app.views.TeamPage.data.id });
        }
      },
    },
  ),
  Shop: new View('Shop', app, () => gameApi.getShop(app.playerId)),
  Toast: new View('Toast', app, async () => undefined),
  Maintenance: new View('Maintenance', app, async () => {}, {
    onOpen: () => {
      app.track('OpenMaintenancePage', {
        feature: 'maintenance',
        $subFeature: 'maintenance',
        originFeature: 'home',
      });
    },
  }),
  EarnPage: new View('Earn', app, app.getEarnPageData, {
    onOpen: () => {
      app.track('OpenEarnPage', {
        feature: 'earn',
        $subFeature: 'earn_page',
        originFeature: 'home',
      });
    },
    onClose: () => {},
  }),
  LeaguePage: new View<LeagueLeaderboard>(
    'LeaguePage',
    app,
    app.clicker.fetchLeaguePageData,
  ),
  MinePage: new View(
    'MinePage',
    app,
    async () => {
      return getPowerUps(app.replicant.state, app.now());
    },
    {
      onOpen: () => {
        app.track('OpenMinePage', {
          feature: 'mine',
          $subFeature: 'mine_page',
          originFeature: 'home',
        });
      },
    },
  ),
  TradingPage: new View<{ isNew: true } | undefined>(
    'TradingPage',
    app,
    async () => {
      return undefined; // todo: fetch data if we need to
    },
    {
      onOpen: () => {
        app.track('OpenTradingPage', {});
      },
    },
  ),
  TradingTokenPage: new View(
    'TradingTokenPage',
    app,
    async () => {
      // don't use fetch. use SetData when opening the view
      return {} as unknown as TradingToken;
    },
    {
      onOpen: () => {
        app.track('OpenTradingTokenPage', {});
      },
    },
  ),
  TiktokPage: new View(
    'TiktokPage',
    app,
    async () => {
      // don't use fetch. use SetData when opening the view
      return {} as unknown as TradingToken;
    },
    {
      onOpen: () => {
        app.track('OpenTiktokPage', {});
      },
    },
  ),
  TiktokSearchPage: new View<{ isNew: true } | undefined>(
    'TiktokSearchPage',
    app,
    async () => {
      return undefined; // todo: fetch data if we need to
    },
    {
      onOpen: () => {
        app.track('OpenTiktokSearchPage', {});
      },
    },
  ),
  TradingCreatePage: new View(
    'TradingCreatePage',
    app,
    async () => {
      return undefined; // todo: fetch data if we need to
    },
    {
      onOpen: () => {
        app.track('OpenTradingCreatePage', {});
      },
    },
  ),
  TradingCreateLinksPage: new View(
    'TradingCreateLinksPage',
    app,
    async () => {
      return undefined; // todo: fetch data if we need to
    },
    {
      onOpen: () => {
        app.track('OpenTradingCreateLinksPage', {});
      },
    },
  ),
  TradingEditLinksPage: new View(
    'TradingEditLinksPage',
    app,
    async () => {
      return undefined; // todo: fetch data if we need to
    },
    {
      onOpen: () => {
        app.track('OpenTradingEditLinksPage', {});
      },
    },
  ),
  //
  ModalComponent: new View<ModalComponent>('ModalComponent', app, async () => ({
    queue: [],
  })),
  LoadingPage: new View('LoadingPage', app, async () => {}, {
    startVisible: true,
  }),
});

export const logVersion = () => {
  console.warn(`
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!! VERSION: ${process.env.REACT_APP_APP_VERSION} !!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    `);
};
