// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Daily-Codes"

import { Value } from './dailyCodes.types';

// prettier-ignore
const dailyCodes: Value[] = [
  {
    "code": "cai123",
    "starts_at": 1720594800000
  },
  {
    "code": "ian456",
    "starts_at": 1720681200000
  },
  {
    "code": "gemz",
    "starts_at": 1720767600000
  },
  {
    "code": "DeFi",
    "starts_at": 1720854000000
  },
  {
    "code": "NFT",
    "starts_at": 1720940400000
  },
  {
    "code": "Token",
    "starts_at": 1721026800000
  },
  {
    "code": "Altcoin",
    "starts_at": 1721113200000
  },
  {
    "code": "Wallet",
    "starts_at": 1721199600000
  },
  {
    "code": "LFG",
    "starts_at": 1721286000000
  },
  {
    "code": "Dapp",
    "starts_at": 1721372400000
  },
  {
    "code": "Mining",
    "starts_at": 1721458800000
  },
  {
    "code": "Halving",
    "starts_at": 1721545200000
  },
  {
    "code": "Hashrate",
    "starts_at": 1721631600000
  },
  {
    "code": "Staking",
    "starts_at": 1721718000000
  },
  {
    "code": "Gasfee",
    "starts_at": 1721804400000
  },
  {
    "code": "ICO",
    "starts_at": 1721890800000
  },
  {
    "code": "IEO",
    "starts_at": 1721977200000
  },
  {
    "code": "Airdrop",
    "starts_at": 1722063600000
  },
  {
    "code": "Fork",
    "starts_at": 1722150000000
  },
  {
    "code": "Node",
    "starts_at": 1722236400000
  },
  {
    "code": "DPOS",
    "starts_at": 1722322800000
  },
  {
    "code": "Sharding",
    "starts_at": 1722409200000
  },
  {
    "code": "Oracles",
    "starts_at": 1722495600000
  },
  {
    "code": "ERC20",
    "starts_at": 1722582000000
  },
  {
    "code": "ERC721",
    "starts_at": 1722668400000
  },
  {
    "code": "ERC1155",
    "starts_at": 1722754800000
  },
  {
    "code": "Address",
    "starts_at": 1722841200000
  },
  {
    "code": "Exchange",
    "starts_at": 1722927600000
  },
  {
    "code": "CEX",
    "starts_at": 1723014000000
  },
  {
    "code": "DEX",
    "starts_at": 1723100400000
  },
  {
    "code": "Pool",
    "starts_at": 1723186800000
  },
  {
    "code": "Burning",
    "starts_at": 1723273200000
  },
  {
    "code": "Satoshi",
    "starts_at": 1723359600000
  },
  {
    "code": "Moon",
    "starts_at": 1723446000000
  },
  {
    "code": "WAGMI",
    "starts_at": 1723532400000
  },
  {
    "code": "Regtech",
    "starts_at": 1723618800000
  },
  {
    "code": "AI",
    "starts_at": 1723705200000
  },
  {
    "code": "KYC",
    "starts_at": 1723791600000
  },
  {
    "code": "AML",
    "starts_at": 1723878000000
  },
  {
    "code": "CFT",
    "starts_at": 1723964400000
  },
  {
    "code": "Multisig",
    "starts_at": 1724050800000
  },
  {
    "code": "BIP39",
    "starts_at": 1724137200000
  },
  {
    "code": "BIP44",
    "starts_at": 1724223600000
  },
  {
    "code": "Electrum",
    "starts_at": 1724310000000
  },
  {
    "code": "HDWallet",
    "starts_at": 1724396400000
  },
  {
    "code": "QRcode",
    "starts_at": 1724482800000
  },
  {
    "code": "Fiat",
    "starts_at": 1724569200000
  },
  {
    "code": "Onchain",
    "starts_at": 1724655600000
  },
  {
    "code": "Offchain",
    "starts_at": 1724742000000
  },
  {
    "code": "zkSNARKs",
    "starts_at": 1724828400000
  },
  {
    "code": "zkSTARKs",
    "starts_at": 1724914800000
  },
  {
    "code": "Plasma",
    "starts_at": 1725001200000
  },
  {
    "code": "Rollup",
    "starts_at": 1725087600000
  },
  {
    "code": "DAO",
    "starts_at": 1725174000000
  },
  {
    "code": "DAC",
    "starts_at": 1725260400000
  },
  {
    "code": "Dapp",
    "starts_at": 1725346800000
  },
  {
    "code": "TVL",
    "starts_at": 1725433200000
  },
  {
    "code": "APY",
    "starts_at": 1725519600000
  },
  {
    "code": "APR",
    "starts_at": 1725606000000
  },
  {
    "code": "Leverage",
    "starts_at": 1725692400000
  },
  {
    "code": "Futures",
    "starts_at": 1725778800000
  },
  {
    "code": "Options",
    "starts_at": 1725865200000
  },
  {
    "code": "Margin",
    "starts_at": 1725951600000
  },
  {
    "code": "Spot",
    "starts_at": 1726038000000
  },
  {
    "code": "HODL",
    "starts_at": 1726124400000
  },
  {
    "code": "FOMO",
    "starts_at": 1726210800000
  },
  {
    "code": "FUD",
    "starts_at": 1726297200000
  },
  {
    "code": "Whale",
    "starts_at": 1726383600000
  },
  {
    "code": "Bear",
    "starts_at": 1726470000000
  },
  {
    "code": "Bull",
    "starts_at": 1726556400000
  },
  {
    "code": "Swing",
    "starts_at": 1726642800000
  },
  {
    "code": "Daytrade",
    "starts_at": 1726729200000
  },
  {
    "code": "Scalping",
    "starts_at": 1726815600000
  },
  {
    "code": "Squeeze",
    "starts_at": 1726902000000
  },
  {
    "code": "Rugpull",
    "starts_at": 1726988400000
  },
  {
    "code": "Pump",
    "starts_at": 1727074800000
  },
  {
    "code": "Dump",
    "starts_at": 1727161200000
  },
  {
    "code": "Mainnet",
    "starts_at": 1727247600000
  },
  {
    "code": "Testnet",
    "starts_at": 1727334000000
  },
  {
    "code": "Airdrop",
    "starts_at": 1727420400000
  },
  {
    "code": "Bounty",
    "starts_at": 1727506800000
  },
  {
    "code": "Reserve",
    "starts_at": 1727593200000
  },
  {
    "code": "CDP",
    "starts_at": 1727679600000
  },
  {
    "code": "Vault",
    "starts_at": 1727766000000
  },
  {
    "code": "LPToken",
    "starts_at": 1727852400000
  },
  {
    "code": "Reward",
    "starts_at": 1727938800000
  },
  {
    "code": "Stake",
    "starts_at": 1728025200000
  },
  {
    "code": "Farming",
    "starts_at": 1728111600000
  },
  {
    "code": "APR",
    "starts_at": 1728198000000
  },
  {
    "code": "APY",
    "starts_at": 1728284400000
  },
  {
    "code": "Vesting",
    "starts_at": 1728370800000
  },
  {
    "code": "Snapshot",
    "starts_at": 1728457200000
  },
  {
    "code": "Voting",
    "starts_at": 1728543600000
  },
  {
    "code": "Proposal",
    "starts_at": 1728630000000
  },
  {
    "code": "Quorum",
    "starts_at": 1728716400000
  },
  {
    "code": "Delegate",
    "starts_at": 1728802800000
  },
  {
    "code": "Node",
    "starts_at": 1728889200000
  },
  {
    "code": "Sideload",
    "starts_at": 1728975600000
  },
  {
    "code": "Sharding",
    "starts_at": 1729062000000
  },
  {
    "code": "Mixer",
    "starts_at": 1729148400000
  },
  {
    "code": "Tumbler",
    "starts_at": 1729234800000
  },
  {
    "code": "zkSNARKs",
    "starts_at": 1729321200000
  },
  {
    "code": "zkSTARKs",
    "starts_at": 1729407600000
  },
  {
    "code": "Blinded",
    "starts_at": 1729494000000
  },
  {
    "code": "Chaumian",
    "starts_at": 1729580400000
  },
  {
    "code": "Schnorr",
    "starts_at": 1729666800000
  },
  {
    "code": "Taproot",
    "starts_at": 1729753200000
  },
  {
    "code": "SegWit",
    "starts_at": 1729839600000
  },
  {
    "code": "Layer2",
    "starts_at": 1729926000000
  },
  {
    "code": "Layer1",
    "starts_at": 1730012400000
  },
  {
    "code": "Rollup",
    "starts_at": 1730098800000
  },
  {
    "code": "Plasma",
    "starts_at": 1730185200000
  },
  {
    "code": "Optimism",
    "starts_at": 1730271600000
  },
  {
    "code": "Arbitrum",
    "starts_at": 1730358000000
  },
  {
    "code": "zkRollup",
    "starts_at": 1730444400000
  },
  {
    "code": "Validium",
    "starts_at": 1730530800000
  },
  {
    "code": "Bridge",
    "starts_at": 1730617200000
  },
  {
    "code": "Polkadot",
    "starts_at": 1730703600000
  },
  {
    "code": "Cosmos",
    "starts_at": 1730790000000
  },
  {
    "code": "IBC",
    "starts_at": 1730876400000
  },
  {
    "code": "Hub",
    "starts_at": 1730962800000
  },
  {
    "code": "Zone",
    "starts_at": 1731049200000
  },
  {
    "code": "Oracle",
    "starts_at": 1731135600000
  },
  {
    "code": "Band",
    "starts_at": 1731222000000
  },
  {
    "code": "API",
    "starts_at": 1731308400000
  },
  {
    "code": "Web3",
    "starts_at": 1731394800000
  },
  {
    "code": "DID",
    "starts_at": 1731481200000
  },
  {
    "code": "SSI",
    "starts_at": 1731567600000
  },
  {
    "code": "IPFS",
    "starts_at": 1731654000000
  },
  {
    "code": "Swarm",
    "starts_at": 1731740400000
  },
  {
    "code": "Filecoin",
    "starts_at": 1731826800000
  },
  {
    "code": "Arweave",
    "starts_at": 1731913200000
  },
  {
    "code": "DHT",
    "starts_at": 1731999600000
  },
  {
    "code": "DLT",
    "starts_at": 1732086000000
  },
  {
    "code": "BFT",
    "starts_at": 1732172400000
  },
  {
    "code": "PBFT",
    "starts_at": 1732258800000
  },
  {
    "code": "Gossip",
    "starts_at": 1732345200000
  },
  {
    "code": "Raft",
    "starts_at": 1732431600000
  },
  {
    "code": "Paxos",
    "starts_at": 1732518000000
  },
  {
    "code": "DAG",
    "starts_at": 1732604400000
  },
  {
    "code": "Lattice",
    "starts_at": 1732690800000
  },
  {
    "code": "MinerFee",
    "starts_at": 1732777200000
  },
  {
    "code": "Slashing",
    "starts_at": 1732863600000
  },
  {
    "code": "CoinJoin",
    "starts_at": 1732950000000
  },
  {
    "code": "CoinSwap",
    "starts_at": 1733036400000
  },
  {
    "code": "Cosmos",
    "starts_at": 1733122800000
  },
  {
    "code": "Polkadot",
    "starts_at": 1733209200000
  },
  {
    "code": "Liquid",
    "starts_at": 1733295600000
  },
  {
    "code": "HTLC",
    "starts_at": 1733382000000
  },
  {
    "code": "Trie",
    "starts_at": 1733468400000
  },
  {
    "code": "AVLTree",
    "starts_at": 1733554800000
  },
  {
    "code": "DAG",
    "starts_at": 1733641200000
  },
  {
    "code": "BlockDAG",
    "starts_at": 1733727600000
  },
  {
    "code": "GhostDAG",
    "starts_at": 1733814000000
  },
  {
    "code": "SPECTRE",
    "starts_at": 1733900400000
  },
  {
    "code": "Prism",
    "starts_at": 1733986800000
  },
  {
    "code": "Snowman",
    "starts_at": 1734073200000
  },
  {
    "code": "Rollup",
    "starts_at": 1734159600000
  },
  {
    "code": "zkRollup",
    "starts_at": 1734246000000
  },
  {
    "code": "Validium",
    "starts_at": 1734332400000
  },
  {
    "code": "Sharding",
    "starts_at": 1734418800000
  },
  {
    "code": "Nakamoto",
    "starts_at": 1734505200000
  },
  {
    "code": "Secure",
    "starts_at": 1734591600000
  },
  {
    "code": "Public",
    "starts_at": 1734678000000
  },
  {
    "code": "Private",
    "starts_at": 1734764400000
  },
  {
    "code": "Hybrid",
    "starts_at": 1734850800000
  },
  {
    "code": "OffChain",
    "starts_at": 1734937200000
  },
  {
    "code": "OnChain",
    "starts_at": 1735023600000
  },
  {
    "code": "DAO",
    "starts_at": 1735110000000
  },
  {
    "code": "Dapp",
    "starts_at": 1735196400000
  },
  {
    "code": "ERC20",
    "starts_at": 1735282800000
  },
  {
    "code": "ERC721",
    "starts_at": 1735369200000
  },
  {
    "code": "ERC1155",
    "starts_at": 1735455600000
  },
  {
    "code": "BEP2",
    "starts_at": 1735542000000
  },
  {
    "code": "BEP20",
    "starts_at": 1735628400000
  },
  {
    "code": "TRC10",
    "starts_at": 1735714800000
  },
  {
    "code": "TRC20",
    "starts_at": 1735801200000
  },
  {
    "code": "Wallet",
    "starts_at": 1735887600000
  },
  {
    "code": "Exchange",
    "starts_at": 1735974000000
  }
];
export default dailyCodes;
