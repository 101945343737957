import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';

export class ClickerOnly22s extends TutorialModule {
  execute = async () => {
    this.app.ui.setTutorialUIState({
      league: '1',
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Mystery,
      btnMine: ElementUIState.Mystery,
      btnEarn: ElementUIState.Mystery,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Hidden,
    });

    // wait 22 seconds, until rocket time is over, then move to next step
    setTimeout(() => {
      this.onComplete();
    }, 1000 * 22); // 22s (20s boost time + delay)

    return super.execute().then(() => {});
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_boosts_rocket_preview',
    };
  }
}
