import './DrawerRewardsSummary.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getCombinedUnclaimedRewards } from '../../../replicant/features/game/game.getters';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import { waitFor } from '../../../data/utils';

export const DrawerRewardsSummary = () => {
  const { t } = useTranslation();
  const [earnings, setEarnings] = useState([]) as any;
  const [sumOfRewards, setSumOfRewards] = useState(0);

  // show confetti when opening the drawer
  useEffect(() => {
    app.ui.confetti.show(false);
  }, []);

  const onClaimClick = async () => {
    // when closing, instantly retrieve the reward and animate balance
    const sumOfRewards =
      (app.clicker.botEarnings ?? 0) + app.clicker.powerUpBonus;

    app.ui.animateBalance(sumOfRewards);

    // instantly close the drawer
    app.ui.drawer.close();

    // hide confetti slightly after closing
    await waitFor(500);
    app.ui.confetti.hide();
  };

  useEffect(() => {
    // fix the glitch when after the click daily rewards row disappears
    if (earnings.length && !app.state.unclaimed_rewards) {
      return;
    }
    const _earnings = [
      {
        id: 'top_bot_earned',
        amount: app.clicker.botEarnings,
        label: t('rewards_summary_tapbot_earned'),
      },
      {
        id: 'power_up_bonus',
        amount: app.clicker.powerUpBonus,
        label: t('rewards_summary_pu_earned'),
      },
      {
        id: 'referral_rewards',
        amount: app.clicker.unclaimedReferralRewards,
        label: t('rewards_summary_referrals_earned'),
      },
    ];
    setEarnings(_earnings);
    const _sumOfRewards = _earnings.reduce(
      (acc: number, e: any) => acc + e.amount,
      0,
    );
    setSumOfRewards(_sumOfRewards);
  }, [
    app.clicker.botEarnings,
    app.state.unclaimed_rewards,
    app.clicker.powerUpBonus,
  ]);

  return (
    <div className="drawer-reward-summary">
      <div className="drawer-layout">
        <div className="drawer-header">
          <div className="drawer-header-icon">
            <img src={assets.sloth_earning_summary} />
          </div>
          <div className="drawer-header-title">
            {t('rewards_summary_header')}
          </div>
          <div className="subtitle">{t('rewards_summary_copy')}</div>

          <div className="points-container">
            {earnings.map((e: any) => (
              <div
                className={`points-row ${!e.amount ? 'hide' : ''}`}
                key={e.id}
              >
                <div className="description">{e.label}</div>
                <span className="points">
                  +{largeIntegerToLetter(e.amount)}
                  <img src={assets.coin} className="coin-icon" />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onClaimClick}>
          {t('claim')}
          <img src={assets.coin} />
          {largeIntegerToLetter(sumOfRewards)}
        </div>
      </div>
    </div>
  );
};
