import './TiktokItemTransactionBubbles.scss';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TradingTx } from '../../../../replicant/features/offchainTrading/offchainTrading.schema';
import {
  displayTokenAmount,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import Big from 'big.js';
import { TradingToken } from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import { useEffect, useRef, useState } from 'react';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { t } from 'i18next';

// =================================================
// Tiktok Item Transactions

interface TiktokItemTransactionBubblesProps {
  tokenState: TradingToken | undefined;
  selected: boolean;
}

export const TiktokItemTransactionBubbles = ({
  tokenState,
  selected,
}: TiktokItemTransactionBubblesProps) => {
  // displayed transactions will act as if happening in realtime
  const txDisplayCount = 3;

  const minDelay = 1000;
  let maxDelay = 2000;

  const allTransactions = tokenState?.transactions || [];
  const lastTx = allTransactions[allTransactions.length - 1];
  const performedLastTx =
    allTransactions.length > 0 && lastTx.userId === app.state.id;
  const initialStartIndex = performedLastTx
    ? txDisplayCount
    : Math.min(13, allTransactions.length);

  const [startIndex, setStartIndex] = useState<number>(initialStartIndex);
  const timeoutRef = useRef<number | null>(null);

  // Reset startIndex when the component mounts or transactions change
  useEffect(() => {
    setStartIndex(initialStartIndex);
  }, [initialStartIndex]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    // Function to schedule the next update with a random delay
    const scheduleNextUpdate = () => {
      // Generate a random delay between minDelay and maxDelay
      const delay = minDelay + Math.random() * (maxDelay - minDelay);

      maxDelay += 1000;

      timeoutRef.current = window.setTimeout(() => {
        setStartIndex((prevIndex) => {
          const newIndex = prevIndex - 1;
          return Math.max(newIndex, txDisplayCount);
        });

        if (startIndex === txDisplayCount) {
          // reached the end of the list of transactions
          // note that we are not refreshing to fetch latest transactions as those do not happen frequently enough
          return;
        }

        // Schedule the next update recursively
        scheduleNextUpdate();
      }, delay);
    };

    // Start the initial update
    scheduleNextUpdate();

    // Cleanup function to clear the timeout on component unmount
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [initialStartIndex, lastTx?.createdAt, lastTx?.userId, selected]);

  const displayedTransactions = allTransactions.slice(
    -startIndex,
    startIndex === txDisplayCount ? undefined : txDisplayCount - startIndex,
  );

  const items = displayedTransactions.map((tx, i) => {
    let opacity = 1;
    if (displayedTransactions.length > 1 && i === 1) opacity = 0.6;
    if (displayedTransactions.length > 2 && i === 0) opacity = 0.3;

    return (
      <TiktokItemTransactionButton
        key={`tx_${tx.createdAt}_${i}`}
        index={i}
        opacity={opacity}
        tx={tx}
        ticker={tokenState?.ticker}
      />
    );
  });

  return (
    <div className="tiktok-item-transaction-bubbles">
      <div className={`tiktok-item-transaction-bubbles-buttons`}>{items}</div>
    </div>
  );
};

// =================================================
// Tiktok Transaction Item

interface TiktokItemTransactionButtonProps {
  index: number;
  opacity: number;
  tx: TradingTx;
  ticker: string | undefined;
}

export const TiktokItemTransactionButton = ({
  index,
  opacity,
  tx,
  ticker,
}: TiktokItemTransactionButtonProps) => {
  // console.warn('>>> tx', tx);

  const txType = tx.txType;
  const txCurrency = tx.currency;

  const onTapTransaction = () => {
    // open tiktok transactions drawer
    app.ui.drawer.show({
      id: 'drawerTiktokTransactions',
      hideClose: true,
      // noModal: true,
    });
  };

  const tokenAmount = displayTokenAmount(
    Big(tx.tokenAmount).toNumber(),
    4,
    true,
  );
  const tokenDisplay = `${tokenAmount} $${ticker}`;

  const txLabel =
    txCurrency === 'points' ? t('transaction_earned') : txType.toUpperCase();

  return (
    <div
      className={`btn tiktok-item-transaction-bubble-button ${txType}`}
      style={{ opacity: opacity.toString() }}
      onClick={onTapTransaction}
    >
      {/* debug index */}
      {/* <div className={`label-index`}>{index}</div> */}

      <div className="tiktok-item-transaction-bubble-box">
        {/* token icon */}
        <div className="icon">
          <img src={resolveProfilePicture(tx.userId, tx.userImage)} />
        </div>
        <div className="info">
          {/* user name */}
          <div className="label name">{tx.userName}</div>

          <div className="tiktok-item-transaction-bubble-box">
            {/* transaction type */}
            <div className={`label type ${txType}`}>{txLabel}</div>

            {/* transaction value */}
            {txCurrency === 'points' ? (
              <div
                className="label coins"
                dangerouslySetInnerHTML={{
                  __html: t('transaction_bubble_earned', {
                    amount: tokenAmount,
                    meme: ticker,
                  }),
                }}
              ></div>
            ) : (
              <div
                className="label coins"
                dangerouslySetInnerHTML={{ __html: tokenDisplay }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// =================================================
