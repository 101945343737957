import './EarnPage.scss';
import React from 'react';
import { Page } from '../Page';
import { useEffect } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { assets } from '../../../assets/assets';
import { EarnCard } from './EarnCard';
import { EarnAchievementType } from '../../../data/types';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';
import {
  getFriendReferralRewards,
  getTotalRewards,
} from '../../../replicant/features/game/game.getters';
import { useTranslation } from 'react-i18next';
import { League } from '../../../replicant/features/game/ruleset/league';
import { formatPrice } from '../../../replicant/utils/numbers';
import { ElementUIState } from '../../../data/Controllers/UIController/UITypes';
import { Tutorials } from '../../../data/tutorial/types';
import {
  getQuestComplete,
  getQuestIsPromo,
  getQuests,
  getQuestsByCategory,
  getQuestsReadyToClaim,
} from '../../../replicant/features/quests/getters';
import { Quest } from '../../../replicant/features/quests/types';
import { Address, beginCell, SenderArguments, toNano } from '@ton/core';
import { CHAIN } from '@tonconnect/sdk';
import {
  DAILY_CHECKIN_CONTRACT_ADDRESS,
  DAILY_CHECKIN_CONTRACT_LABEL,
  DAILY_CHECKIN_CONTRACT_REWARD,
  DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE,
  DAILY_CHECKIN_CONTRACT_TX_VALID,
  ONE_DAY_MS,
} from '../../../replicant/features/game/ruleset/contract';
import { waitFor } from '../../../data/utils';

export const EarnPage = () => {
  useAppUpdates({
    id: 'app',
    listener: app.views.EarnPage.attachEventListener(),
  });

  const { t } = useTranslation();

  const { state } = app;

  const { visible } = app.views.EarnPage;

  const rawReward = getTotalRewards();

  const currentReward = rawReward
    ? formatPrice(rawReward)
    : t('come_back_tomorrow');

  useEffect(() => {
    if (visible) {
      // fetch();
      // Whenever we open the view, check if we have any quests ready to claim
      if (getQuestsReadyToClaim(app.state, app.now())) {
        app.invoke.checkForQuestReady();
      }
    }
  }, [visible]);

  const onTutorialClick = () => {
    app.tutorial.step?.onAction?.('clear-tooltip');
    // note: tutorial_rocket_quest item will always be a simple check, without any actions etc
    app.ui.drawer.show({
      id: 'earnChecker',
      opts: { image: assets.boosters.Rocketman },
      props: {
        earnCheckerKey: 'tutorial_rocket_quest',
      },
    });
  };

  const onInviteClick = () => {
    // this is informative, and just brings you to the friends page so you can invite people
    app.nav.goTo('Friends');
  };

  const onDailyClick = () => {
    app.ui.drawer.show({ id: 'dailyReward' }, true);
  };

  const onDailyContractCheckinClick = () => {
    if (app.now() - app.state?.dailyContractCheckin < ONE_DAY_MS) {
      console.log('Already checked in today');
      return;
    }

    let args: SenderArguments = {
      to: Address.parse(DAILY_CHECKIN_CONTRACT_ADDRESS),
      value: toNano(DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE),
      body: beginCell()
        .storeUint(0, 32)
        .storeStringTail(DAILY_CHECKIN_CONTRACT_LABEL)
        .endCell(),
    };

    let connectionPromise = Promise.resolve();
    if (!app.tonConnectUI.connected) {
      connectionPromise = app.tonConnectUI.openModal();
    }

    connectionPromise.then(async () => {
      return await app.tonConnectUI
        .sendTransaction({
          validUntil: Date.now() + DAILY_CHECKIN_CONTRACT_TX_VALID,
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          network: CHAIN.MAINNET,
        })
        .then(async () => {
          console.log('Claiming reward for daily contract checkin');
          await app.invoke.claimDailyContractCheckin().then(async () => {
            app.views.EarnPage.rerender();
            app.ui.confetti
              .setData({
                gravity: { from: 0.5, to: 0.9 },
                duration: 1500,
              })
              .show(false);
            await waitFor(1000);
          });
        })
        .catch((e) => {
          console.error('Failed to send transaction', e);
        });
    });
  };

  const { questsByCategory, categories } = getQuestsByCategory(getQuests());

  const onQuestClick = (quest: Quest) => () => {
    if (quest.promo) {
      return app.ui.showPromoDrawer(quest);
    }

    app.ui.drawer.show(
      {
        id: 'questCheck',
        props: { quest },
      },
      true,
    );
  };

  const friendReward = getFriendReferralRewards(
    app.state,
    app.now(),
    League.league1,
  );

  const disabledQuests =
    app.ui.state.earn.questButtonsNonTutorial === ElementUIState.Disabled;

  const userHasTutorial = app.state.tutorials[Tutorials.InitialTutorial];

  const defaultIconUrl =
    'https://notgemz-game.s3.amazonaws.com/media/powerups/gemz-gold.png';

  // Helper function to render quests
  const renderQuests = ({
    filterFn,
    isPromo,
    showTitle,
  }: {
    filterFn: (q: Quest) => boolean;
    isPromo: boolean;
    showTitle: boolean;
  }) => {
    return categories.map((category) => {
      const quests = questsByCategory[category].filter(filterFn);
      if (quests.length === 0) return null;

      return (
        <React.Fragment key={category}>
          {showTitle && (
            <div className="earn-section-title">
              {t(`quest_category_${category}`)}
            </div>
          )}
          {quests.map((q, i) => {
            const image = q.iconUrl || assets.quests[q.icon] || defaultIconUrl;
            return (
              <EarnCard
                key={q.id + i}
                image={image}
                id={q.id}
                title={t(q.title)}
                score={formatPrice(q.reward)}
                disabled={disabledQuests}
                onClick={onQuestClick(q)}
                claimed={getQuestComplete(app.state, q.id)}
                isPromo={isPromo}
              />
            );
          })}
        </React.Fragment>
      );
    });
  };

  return (
    <Page id="earn" visible={visible}>
      {/* header */}
      <div className="modal-header earn">
        <GlowingImage
          noGlow={true}
          image={assets.sloth_earn}
          blur={70}
          intensity={1}
        />
        <div className="modal-header-title">{t('earn')}</div>
      </div>

      {/* Tutorial Card */}
      {userHasTutorial && (
        <EarnCard
          image={assets.boosters.Rocketman}
          id={EarnAchievementType.tutorialRocketQuest}
          title={t('earn_tutorial_rocket_quest')}
          score={`1,000`}
          disabled={state.earnings.tutorialRocketQuest}
          onClick={onTutorialClick}
          claimed={state.earnings.tutorialRocketQuest}
        />
      )}

      {/* Promo Cards */}
      {renderQuests({
        filterFn: (q) => getQuestIsPromo(q),
        isPromo: true,
        showTitle: false,
      })}

      {/* Invite Bonus Card */}
      <EarnCard
        image={assets.earn_inviteBonus}
        id={EarnAchievementType.InviteBonus}
        title={t('earn_invite_bonus')}
        score={t('earn_invite_bonus_amount', {
          amount: formatPrice(friendReward.premium),
        })}
        disabled={disabledQuests}
        onClick={onInviteClick}
        claimed={false}
      />

      {/* Daily Task Cards */}
      <div className="earn-section-title">{t('earn_daily_tasks')}</div>
      <EarnCard
        image={assets.earn_daily_reward}
        id={EarnAchievementType.DailyReward}
        title={t('earn_daily_reward')}
        score={`${currentReward}`}
        disabled={disabledQuests}
        onClick={onDailyClick}
        claimed={state.unclaimed_rewards <= 1}
      />

      <EarnCard
        image={assets.earn_wallet_connect100}
        id={EarnAchievementType.DailyContractCheckin}
        title={t('daily_contract_checkin_reward')}
        score={DAILY_CHECKIN_CONTRACT_REWARD.toString()}
        disabled={disabledQuests}
        onClick={onDailyContractCheckinClick}
        claimed={app.now() - app.state?.dailyContractCheckin < ONE_DAY_MS}
      />

      {/* CMS Cards */}
      {renderQuests({
        filterFn: (q) => !getQuestIsPromo(q),
        isPromo: false,
        showTitle: true,
      })}
    </Page>
  );
};
