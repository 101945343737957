import './ClickerPage.scss';
import { app } from '../../../data/Controllers/AppController';

import { Astronaut } from './Astronaut/Astronaut';
import { ClickerHeader } from './ClickerHeader/ClickerHeader';
import { DailyMenu } from './DailyMenu/DailyMenu';
import { ClickerScore } from './ClickerScore/ClickerScore';
import { DailyCode } from './DailyMenu/DailyCode';
import { ClickerDiamond } from './ClickerDiamond/ClickerDiamond';
import { ClickerFooter } from './ClickerFooter/ClickerFooter';
import { getUIStateClassName } from '../../../data/utils';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { useManyAppUpdates } from '../../../data/hooks';
import { NavEvents } from '../../../data/Controllers/NavController';
import { ClickerEvents } from '../../../data/Controllers/ClickerController';

export const ClickerPage = () => {
  useManyAppUpdates({
    id: 'ClickerPage',
    events: [
      { listener: app.nav.attachEventListener(NavEvents.OnNavigate) },
      {
        listener: app.clicker.attachEventListener(
          ClickerEvents.onRocketmanChange,
        ),
        dep: app.nav.isClicker,
      },
      {
        listener: app.clicker.attachEventListener(
          ClickerEvents.onGameStateUpdate,
        ),
        dep: app.nav.isClicker,
      },
      {
        listener: app.ui.attachEventListener(UIEvents.ClickerUpdate),
        dep: app.nav.isClicker,
      },
    ],
  });

  return (
    <div className="clicker-page">
      <div
        className={`home-disabled ${getUIStateClassName(
          app.ui.state.clicker.outOfViewOverlay,
        )}`}
      />
      <Astronaut />
      <ClickerHeader />
      <DailyMenu />
      <ClickerScore />
      <DailyCode />
      <ClickerDiamond />
      <ClickerFooter />
    </div>
  );
};
