import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class EnergyRecovery extends TutorialModule {
  id = 'EnergyRecovery' as 'EnergyRecovery';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Normal });
    this.app.ui.setClickerUIState({ energyGauge: ElementUIState.Highlight });
    this.app.ui.setTutorialUIState({ league: '1' });
    this.app.ui.setClickerUIState({ btnLeague: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ footer: ElementUIState.Hidden });
    this.app.ui.setClickerUIState({ btnBooster: ElementUIState.Hidden });
    this.app.ui.setClickerUIState({ btnTeam: ElementUIState.Hidden });

    this.app.tutorial.showTooltip({
      text: t('tut_popup_energy_title'),
      origin: { x: '135px', y: '100%' },
      offset: { x: '0px', y: '-40px' },
      align: 'top',
    });

    setTimeout(() => {
      this.onComplete();
    }, 100);

    return super.execute().then(() => {
      this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Normal });
      this.app.ui.setClickerUIState({ energyGauge: ElementUIState.Normal });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_Intro_energy_dialog',
    };
  }
}
