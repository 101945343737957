import { AppController } from '../../Controllers/AppController';
import { BADGE_VALUES } from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';

export class WaitForTapsOrTimeout extends TutorialModule {
  id = 'WaitForTapsOrTimeout' as 'WaitForTapsOrTimeout';

  private taps = 0;

  constructor(
    app: AppController,
    private tapLimit: number,
    private duration: number,
  ) {
    super(app);
  }

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    const timeout = setTimeout(() => {
      this.onComplete();
    }, this.duration);

    return super.execute().then(() => {
      clearInterval(timeout);
    });
  };

  override onClickerTap = () => {
    this.taps += 1;
    console.warn('>>> number of taps', this.taps, '/', this.tapLimit);
    if (this.taps >= this.tapLimit) {
      this.onComplete();
    }
  };
}
