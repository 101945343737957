import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { League, SEASON_LEAGUE_REWARDS } from '../features/game/ruleset/league';
import { tests } from '../ruleset';
import {
  RESET_SEASON_2_LABEL,
  season2StartTime,
} from '../features/offchainTrading/offchainTrading.ruleset';

async function startSeason2(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  // Season 1 league is only set during migration to season 2
  const season1League = state.seasonLeagues[0] as League;
  // if not set then the user has not yet migrated to season 2, skip;
  if (!season1League) {
    return;
  }

  const purchases = await api.purchases.getPurchaseHistory();
  // To all users that played season 2 beta give 1 credit; And give the amount of credits bought back too
  state.tokenCreationCredits =
    Math.max(purchases.length, state.tokenCreationCredits) + 1;

  state.trading.offchainTokens = {};

  // Apply same logic to reset use balance/score from season 2

  // reset score, taps and league
  state.score = 0;
  state.taps = 0;
  state.league = League.league1;

  // provide reward as starting balance
  state.balance = SEASON_LEAGUE_REWARDS[1][season1League];

  // add a label that we can use in the FE to show the modal once
  state.labels.push(RESET_SEASON_2_LABEL);
}
/**
 * Reason:
 * This migration is required, because we released season 2 (which is supposed to fix economy)
 * with a bug in the bonding curve that allowed users to sell the tokens for more than they bought.
 * Issues:
 * Users pay telegram coins to create tokens (real currency) so we need to credit that back to them somehow;
 * Users have made more money than the should have and it needs to be rolled back;
 * The curve has to be fixed (not in migration)
 * Tokens created have wrong investment in them and must be "deleted" (not in migration)
 * Solution:
 * We will give the users credit to create new tokens without using currency again; new state `tokenCreationCredits`
 * We will reset the user economy using the same logic applied on season 2 migration;
 * We will reset user trading state;
 */
export async function restartSeason2(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  await startSeason2(state, api);
}

// Reason:
// Because the meme card AB test rollout was rolled back to 0%, some players joined the game, ran the restartSeason2 migrator without trigger the reset
// It means another migrator is needed to reset them properly once they will be placed in the proper bucket
export async function restartSeason2Again(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  // determine if the player already played the season 2 reboot to avoid resetting the player twice
  const offchainTokenFromReboot = Object.values(
    state.trading.offchainTokens,
  ).find((offchainToken) => {
    return (
      offchainToken.purchasedAt !== undefined &&
      offchainToken.purchasedAt > season2StartTime
    );
  });

  const playedSeason2Rebooted = !!offchainTokenFromReboot;
  if (playedSeason2Rebooted) {
    return;
  }

  await startSeason2(state, api);
}
