import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class MinePageCard extends TutorialModule {
  id = 'MinePageCard' as 'MinePageCard';

  private canCloseBackTooltip = false;
  private firstAction = true;

  onAction = (id?: string) => {
    if (this.canCloseBackTooltip || this.firstAction) {
      this.app.ui.tooltip.hide();
    }
    this.firstAction = false;
    if (id === 'onTutorialMineCardBuy') {
      this.app.track('FTUE_mine_press_get_card');
      this.app.ui.setMineUIState({ btnTab: ElementUIState.Normal });
      this.app.ui.tooltip.hide();
      this.addBackTooltip();
      setTimeout(() => {
        this.canCloseBackTooltip = true;
      }, 2000);
      // 5s to show the back tooltip, which hides in 10s, or on any action after 2s displaying
      setTimeout(() => {
        this.app.ui.tooltip.hide();
      }, 10000);
    }
  };

  onBack = () => {
    this.onComplete();
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    // navigate to mine page
    this.app.nav.goTo('MinePage');

    this.app.ui.setMineUIState({ btnTab: ElementUIState.Inactive });
    this.app.ui.setMineUIState({ tutorialOverlay: ElementUIState.Highlight });

    this.app.ui.setCardState(
      'pu_specials_tutorial_1',
      ElementUIState.Highlight,
    );

    await waitFor(300);

    this.app.tutorial.showTooltip(
      {
        text: t('tut_tooltip_earn3hours'),
        origin: { x: '50%', y: '50%' },
        offset: { x: '0', y: '220px' },
        align: 'top',
      },
      0,
    );

    return super.execute().then(() => {
      this.app.nav.goToHomePage();
      this.app.ui.setCardState('pu_specials_tutorial_1', ElementUIState.Normal);
      this.app.ui.setMineUIState({ btnTab: ElementUIState.Normal });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_mine_press_card_preview',
    };
  }
}
