import './FarmingWidget.scss';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { ClickerEvents } from '../../../../data/Controllers/ClickerController';

const styleClass = {
  Idle: 'none',
  Farming: 'farming',
  'Ready to Claim': 'finished',
};

export const FarmingWidget = () => {
  const { farm } = app.tmg;

  useAppUpdates({
    id: `FarmingWidget/TMGEvents.OnFarmingUpdate`,
    listener: app.tmg.attachEventListener(TMGEvents.OnFarmingUpdate),
  });

  useAppUpdates({
    id: 'FarmingWidget/AppEvents.onGameStateUpdate',
    listener: app.clicker.attachEventListener(ClickerEvents.onGameStateUpdate),
    dep: Boolean(farm?.isFarming),
  });

  if (!farm) {
    return null;
  }

  const {
    status,
    onFarmAction,
    progress,
    isIdle,
    isFarming,
    isReadyToClaim,
    labels,
  } = farm;

  return (
    <div
      className={`btn farming-footer-button ${styleClass[status]}`}
      onClick={onFarmAction}
    >
      {isIdle && (
        <>
          <div className="farming-tappable" />
          <div className="farming-icon">
            <img src={assets.icon_farming_logo_small} />
          </div>
          <div className="farming-labels">
            <div className="farming-label">{labels.btn}</div>
          </div>
        </>
      )}

      {isFarming && (
        <>
          <div className="farming-icon">
            <img src={assets.icon_farming_logo_big} />
            <div className="farming-points">{labels.points}</div>
          </div>

          <div className="farming-labels">
            <div className="farming-label">{labels.btn}</div>
            <div className="farming-timer">{`${progress.hoursLeft}h ${progress.minutesLeft}m`}</div>
          </div>
        </>
      )}

      {isReadyToClaim && (
        <>
          <div className="farming-tappable" />
          <div className="farming-icon">
            <img src={assets.icon_farming_logo_big} />
            <div className="farming-points">{labels.points}</div>
          </div>

          <div className="farming-labels">
            <div className="farming-label">{labels.btn}</div>
          </div>
        </>
      )}
    </div>
  );
};
