import './DrawerTiktokTransactions.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TradingTokenTransactionItem } from '../../TradingTokenPage/TradingTokenTabs/TradingTokenTabsContent';

export const DrawerTiktokTransactions = () => {
  const onMinimize = () => {
    app.ui.drawer.close();
  };

  const token = app.memes.currentMeme.token;

  const transactions = token?.transactions.slice().reverse() || [];

  return (
    <div className="drawer-tiktok-transactions">
      {/* header - left title and minimize button */}
      <div className="drawer-tiktok-transactions-header">
        <div className="drawer-tiktok-transactions-header-title">
          ${token?.ticker} Transactions
        </div>
        <div
          className="btn drawer-tiktok-transactions-header-close"
          onClick={onMinimize}
        >
          <img src={assets.button_arrow_down} />
        </div>
      </div>

      {/* transaction list */}
      <div className="items-area transactions">
        <div className="items-list transactions">
          {transactions.map((tx, i) => (
            <TradingTokenTransactionItem
              key={'tx_' + tx.createdAt + `_${i}`}
              tx={tx}
              onClick={() => {
                app.ui.drawer.close();
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
