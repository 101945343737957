import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { Migrator } from './types';
// Migrators
import { restartSeason2, restartSeason2Again } from './restartSeason2';
import { moderatedTokensFromDeleteToReported } from './moderatedTokensFromDeleteToReported';
import { removeDuplicatedFriends } from './removeDuplicatedFriends';
import { fillTokenCurrencyInvestments } from './fillTokenCurrencyInvestments';
import { addBalanceToPortfolioTrends } from './addBalanceToPortfolioTrends';
import { clearOldFarming } from './clearOldFarming';
import { grantTappingTickets } from './grantTappingTickets';
import { migrateFriendIdsToFriendsSubstate } from './migrateFriendIdsToFriendsSubstate';
import { tutorialTracking } from './tutorialTracking';
import { addLastTokenCreatedTimestamp } from './addLastTokenCreatedTimestamp';
import { addModalLabel } from './addModalLabel';
import { ModalLabels } from '../ruleset';

// !!! :warning: NEVER CHANGE THIS ORDER :warning: !!!
enum Migration {
  restartSeason2 = 1, // no need to specify index after the defining one, it's linear
  restartSeason2Again,
  migrateDeletedTokensByModeration,
  migrateDeletedTokensByModerationAgain,
  removeDuplicatedFriends,
  fillTokenCurrencyInvestments,
  migrateFriendIdsToFriendsSubstate,
  tiktokTeaserModal,
  addBalanceToPortfolioTrends,
  clearOldFarming,
  grantTappingTokens,
  TutorialTrackingTiktok, // Prefix any other TutorialTracking
  addLastTokenCreatedTimestamp,
}

const migrations = {
  [Migration.restartSeason2]: restartSeason2,
  [Migration.restartSeason2Again]: restartSeason2Again,
  [Migration.migrateDeletedTokensByModeration]:
    moderatedTokensFromDeleteToReported,
  [Migration.migrateDeletedTokensByModerationAgain]:
    moderatedTokensFromDeleteToReported,
  [Migration.removeDuplicatedFriends]: removeDuplicatedFriends,
  [Migration.fillTokenCurrencyInvestments]: fillTokenCurrencyInvestments,
  [Migration.migrateFriendIdsToFriendsSubstate]:
    migrateFriendIdsToFriendsSubstate,
  [Migration.tiktokTeaserModal]: addModalLabel(
    ModalLabels.SHOW_TIKTOK_TEASER_MODAL,
  ),
  [Migration.addBalanceToPortfolioTrends]: addBalanceToPortfolioTrends,
  [Migration.clearOldFarming]: clearOldFarming,
  [Migration.grantTappingTokens]: grantTappingTickets,
  [Migration.TutorialTrackingTiktok]: tutorialTracking('tap_to_earn_tutorial'),
  [Migration.addLastTokenCreatedTimestamp]: addLastTokenCreatedTimestamp,
} satisfies Record<Migration, Migrator>;

// Update here when there's a new migration
export const CURRENT_SOFT_MIGRATION: Migration =
  Migration.addLastTokenCreatedTimestamp;

export async function runSoftMigrations(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const hasBeenMigrated =
    CURRENT_SOFT_MIGRATION === (state.softMigration as Migration);

  if (hasBeenMigrated) {
    return;
  }

  // Apply all missing migrations
  for (let i = state.softMigration + 1; i <= CURRENT_SOFT_MIGRATION; i++) {
    const migrator = migrations[i as Migration];
    // Migrator makes whatever state changes required for current migration
    await migrator(state, api);
    // Update user state with current migration to skip next time
    state.softMigration = i;
  }
}
