import { amplitudeTimeZone } from '../replicant/amplitude';

const queryParams = new URLSearchParams(document.location.search);
const payload = queryParams.get('payload');

export const qpConfig = {
  playerId: queryParams.get('uid') ?? 'ANON',
  useMock: queryParams.has('mock') ?? false,
  replicant: queryParams.get('replicant') ?? '',
  useLocal: queryParams.has('local') ?? false,
  simulateRocketman: queryParams.has('rocket') ?? false,
  skipQR: queryParams.has('noqr') ?? false,
  payload: payload ? JSON.parse(payload) : {},
  useCMS: queryParams.has('cms'),
  testTutorial: queryParams.has('tutorial'),
  simulateStartParam: queryParams.get('simstartparam') ?? '',
  skipInitModals: queryParams.has('noinitpopup') ?? false,
  dlRoute: queryParams.get('dlRoute'),
  dlOpts: queryParams.get('dlOpts'),
  skipTutorial: queryParams.has('noTutorial'),
};

type Env = 'dev' | 'prod' | 'local' | 'alpha' | 'bravo' | 'charlie';

if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
  // logging Storyverse app version
  const version = process.env.REACT_APP_APP_VERSION;
  const svsVersionLog = [
    '\n %c %c %c \u2730 Gemzcoin ' + version + ' \u2730  %c %c %c \n\n',
    'background: #9eacbe; padding:5px 0;',
    'background: #9eacbe; padding:5px 0;',
    'color: #9eacbe; background: #030307; padding:5px 0;',
    'background: #9eacbe; padding:5px 0;',
    'background: #beccde; padding:5px 0;',
    'color: #beccde; background: #fff; padding:5px 0;',
  ];

  const console = window.console;
  window.console.info.apply(console, svsVersionLog);
}

export const env: Env = qpConfig.useLocal
  ? 'local'
  : (process.env.REACT_APP_ENV as Env);

const sharedConfig = {
  amplitudeTimeZone,
};

export const configByEnv: Record<
  Env,
  {
    amplitudeTimeZone: string;
    adBlockId: string;
    amplitudeKey: string;
    replicant: {
      endpoint: string;
      offlineMode: boolean;
      oldGameApiUrl: string;
    };
    telegramAnalyticsToken?: string;
    telegramAnalyticsAppName?: string;
  }
> = {
  local: {
    ...sharedConfig,
    adBlockId: '51',
    amplitudeKey: '4a9568ca708d3d60c0df2d2f022ff98c',
    replicant: {
      endpoint: '',
      offlineMode: true,
      oldGameApiUrl: '',
    },
  },
  alpha: {
    ...sharedConfig,
    adBlockId: '51',
    amplitudeKey: '4a9568ca708d3d60c0df2d2f022ff98c',
    replicant: {
      endpoint:
        'https://gemzcoin-dev.us-east-1.replicant.gc-internal.net/gemzcoin-alpha',
      offlineMode: false,
      oldGameApiUrl: 'https://notgemz.game.dev.pnk.one',
    },
  },
  bravo: {
    ...sharedConfig,
    adBlockId: '51',
    amplitudeKey: '4a9568ca708d3d60c0df2d2f022ff98c',
    replicant: {
      endpoint:
        'https://gemzcoin-dev.us-east-1.replicant.gc-internal.net/gemzcoin-bravo',
      offlineMode: false,
      oldGameApiUrl: 'https://notgemz.game.dev.pnk.one',
    },
  },
  charlie: {
    ...sharedConfig,
    adBlockId: '51',
    amplitudeKey: '4a9568ca708d3d60c0df2d2f022ff98c',
    replicant: {
      endpoint:
        'https://gemzcoin-dev.us-east-1.replicant.gc-internal.net/gemzcoin-charlie',
      offlineMode: false,
      oldGameApiUrl: 'https://notgemz.game.dev.pnk.one',
    },
    telegramAnalyticsToken:
      'eyJhcHBfbmFtZSI6ImdlbXpfY2hhcmxpZSIsImFwcF91cmwiOiJodHRwczovL3QubWUvcG5rX2NoYXJsaWVfYm90IiwiYXBwX2RvbWFpbiI6Imh0dHBzOi8vbm90Z2Vtei5jaGFybGllLnBuay5vbmUifQ==!aVHrYviy9ruxDopu6bgBoIMqto3mCnZYTMpJHZwEyrE=',
    telegramAnalyticsAppName: 'gemz_charlie',
  },
  dev: {
    ...sharedConfig,
    adBlockId: '49',
    amplitudeKey: '4a9568ca708d3d60c0df2d2f022ff98c',
    replicant: {
      endpoint:
        process.env.REACT_APP_REPLICANT_ENDPOINT ||
        'https://gemzcoin.us-east-1.replicant.gc-internal.net/gemzcoin-dev',
      offlineMode: false,
      oldGameApiUrl: 'https://notgemz.game.dev.pnk.one',
    },
    telegramAnalyticsToken:
      'eyJhcHBfbmFtZSI6ImdlbXpfZGV2IiwiYXBwX3VybCI6Imh0dHBzOi8vdC5tZS9wbmtfZGV2ZWxvcF9ib3QiLCJhcHBfZG9tYWluIjoiaHR0cHM6Ly9ub3RnZW16LnBuay5vbmUifQ==!P83KXaHIb58qcyK/PJx3fcc4w0ikj1/+O/FbUBWAbV0=',
    telegramAnalyticsAppName: 'gemz_dev',
  },
  prod: {
    ...sharedConfig,
    adBlockId: '50',
    amplitudeKey: '9a2c9db91b4af221af54a57f6fd0f1b7',
    replicant: {
      endpoint: 'https://gemzcoin.us-east-1.replicant.gc-internal.net/gemzcoin',
      offlineMode: false,
      oldGameApiUrl: 'https://notgemz.game.prod2.pnk.one',
    },
    telegramAnalyticsToken:
      'eyJhcHBfbmFtZSI6ImdlbXoiLCJhcHBfdXJsIjoiaHR0cHM6Ly90Lm1lL2dlbXpjb2luX2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL2ZmLm5vdGdlbXouZ2Vtei5mdW4ifQ==!uD3wZAc7OU/1FqixC9ZXtB93pB35StEH55/rrze+3oI=',
    telegramAnalyticsAppName: 'gemz',
  },
};

export const config = configByEnv[env];
