import './DrawerPowerUpCardPromo.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useMemo } from 'react';
import { getPowerUps } from '../../../replicant/features/powerups/getters';
import { HIDDEN_CARD_REWARD } from '../../../replicant/features/powerups/ruleset';
import { formatPrice } from '../../../replicant/utils/numbers';
import { insertCoinIconIntoText } from '../../../data/utils';

export const DrawerPowerUpCardPromo = () => {
  const { t } = useTranslation(); //

  useEffect(() => {
    app.track('ReceivePromoCard', {
      card_name: app.clicker.cardPromo?.card || 'unknown',
      link_origin: app.clicker.cardPromo?.origin || 'unknown',
    });
    app.ui.confetti
      .setData({
        gravity: { from: 0.5, to: 0.9 },
        duration: 3000,
      })
      .show(false);
  }, [app.clicker.cardPromo?.card]);

  const { powerUp } = useMemo(() => {
    const powerUps = getPowerUps(app.state, app.now());
    const powerUpCard = powerUps.find(
      (pu) => pu.id === app.clicker.cardPromo?.card,
    );
    return {
      powerUp: powerUpCard,
    };
  }, [app.clicker.cardPromo?.card]);

  const rewardCoins = formatPrice(HIDDEN_CARD_REWARD);
  const headerTitle = insertCoinIconIntoText(
    t('promo_header_title', { amount: rewardCoins }),
  );

  const earnTitle = t('promo_earn_title', { card_name: powerUp?.details.name });

  const earnCoins = formatPrice(powerUp?.earn);
  const earnText = insertCoinIconIntoText(
    t('promo_ear_per_hour', { amount: earnCoins }),
  );

  if (!app.clicker.cardPromo) {
    return;
  }

  return (
    <div className="powerup-promo">
      <div className="drawer-layout">
        <div className="drawer-header">
          <div
            className="drawer-header-title"
            dangerouslySetInnerHTML={{ __html: headerTitle }}
          ></div>

          <div className="drawer-header-icon">
            <img src={powerUp?.details.image} />
          </div>

          <div className="drawer-header-subtitle">
            <div className={`puc-earn`}>
              <div className="puc-earn-title">{earnTitle}</div>
              <div
                className="puc-earn-coins"
                dangerouslySetInnerHTML={{ __html: earnText }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={app.ui.drawer.close}>
          {t(`claim_reward`)}
        </div>
      </div>
    </div>
  );
};
