// export const boosterPricePerLevel: Record<Booster, Record<Level, Price>> = {

import { Booster } from "./boosters";

export interface BoosterListing {
  price: number;
  level: number;
  maxedOut: boolean;
}

export interface ShopListing {
  buffs: {
    Rocketman: {
      available: number;
      maxUses: number;
      timeLeft: number;
    }
    FullEnergy: {
      timeLeft: number;
    }
  };
  boosters: Record<Booster, BoosterListing>;
}

export const boosterPricePerLevel = {
  AutoTap: [20000],
  MultiTap: [
    2000, 4000, 8000, 16000, 32000, 64000, 128000, 256000, 512000, 1024000,
  ],
  RechargeLimit: [
    2000, 4000, 8000, 16000, 32000, 64000, 128000, 256000, 512000, 1024000,
  ],
  RechargeSpeed: [2000, 4000, 8000],
};
