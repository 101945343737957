import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Chart.scss';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  LightweightChartComponent,
  GraphPoint,
} from '../../TradingPage/LightweightChartComponent/LightweightChartComponent';
import {
  portfolioPriceSliceConfigs,
  SliceConfig,
} from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';

interface TimeInterval {
  sliceConfig: SliceConfig | null;
  text: string;
  textP?: Record<string, any>;
}

const intervals: TimeInterval[] = [
  {
    sliceConfig: portfolioPriceSliceConfigs.hour24,
    text: 'trading_chart_1_day',
  },
  {
    sliceConfig: portfolioPriceSliceConfigs.day7,
    text: 'trading_chart_x_days',
    textP: { days: 7 },
  },
  {
    sliceConfig: portfolioPriceSliceConfigs.day30,
    text: 'trading_chart_x_days',
    textP: { days: 30 },
  },
  {
    sliceConfig: null,
    text: 'trading_chart_all_time',
  },
];

interface Props {
  portfolioValue: number;
  getPortfolioPricePoints: (
    sliceConfig: SliceConfig | null,
  ) => Promise<GraphPoint[]>;
  height: number;
  showPresets: boolean;
}

export const PortfolioChart = ({
  portfolioValue,
  getPortfolioPricePoints,
  height,
  showPresets,
}: Props) => {
  useAppUpdates({
    id: 'PortfolioChart',
    listener: app.memes.attachEventListener(
      MemesEvents.TradingOnPortfolioUpdate,
    ),
  });
  // If you are translating within the component use `i18n.t()` not the imported `t()`
  const { t } = useTranslation();

  const [priceData, setPriceData] = useState<GraphPoint[]>([]);

  const [selectedInterval, setSelectedInterval] = useState(intervals[3]);

  useEffect(() => {
    getPortfolioPricePoints(selectedInterval.sliceConfig).then((pricePoints) =>
      setPriceData(pricePoints),
    );
  }, [selectedInterval, portfolioValue]);

  // bottom gradient
  const colors = {
    backgroundColor: 'transparent',
    lineColor: 'rgba(0, 150, 0, 1)', // #2962FF',
    textColor: '#777', //'white',
    areaTopColor: 'rgba(0, 150, 0, 1)', // '#2962FF',
    areaBottomColor: 'rgba(0, 150, 0, 0.0)', // 'rgba(41, 98, 255, 0.28)',
  };

  return (
    <div className="portfolio-chart">
      {/* graph */}
      <LightweightChartComponent
        data={priceData}
        colors={colors}
        height={height}
        margin={0}
        lineWidth={4}
      />
      {/* time preset buttons */}
      {showPresets && (
        <div className="time-interval-buttons">
          {intervals.map((interval, index) => (
            <button
              key={`${interval.text}_${index}`}
              className={`interval-button ${
                selectedInterval.sliceConfig?.windowId ===
                interval.sliceConfig?.windowId
                  ? 'active'
                  : ''
              }`}
              onClick={() => setSelectedInterval(interval)}
            >
              {t(interval.text, interval.textP)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
