import { action, asyncAction } from '@play-co/replicant';
import { createActions } from '../../createActions';
import templates, { getNotificationMedia } from '../chatbot/chatbot.templates';
import { EarningKey } from '../game/ruleset/earnings';
import { League } from '../game/ruleset/league';
import {
  handleFreeTokenMemeGift,
  incrementBalance,
  incrementScoreAndTaps,
} from '../game/game.modifiers';
import { getFriendsSubStateId } from '../friends/friends.getters';
import { tmgRefillTicketsAndWipeSessionCache } from '../offchainTrading/offchainTrading.modifiers';
import {
  sendHighPriorityMessage,
  sendReengagementBroadcast,
  sendTelegramMessage,
} from '../chatbot/chatbot.modifiers';
import { NotificationFeature } from '../chatbot/chatbot.ruleset';
import { getChatbotPayload } from '../chatbot/chatbot.getters';
import { HOUR_IN_MS } from '../../utils/time';

const FAKE_FRIEND_PREFIX = 'cheat_fake_friend_';

export const cheatsActions = createActions({
  cheat_AddPoints: action((state, { points }: { points: number }) => {
    incrementScoreAndTaps(state, points);
  }),
  cheat_SetTaps: action((state, { taps }: { taps: number }) => {
    state.taps = taps;
  }),
  cheat_SetBalance: action((state, { balance }: { balance: number }) => {
    state.balance = 0;
    incrementBalance(state, balance);
  }),
  cheat_SetLeague: action((state, { league }: { league: League }) => {
    state.league = league;
  }),
  cheat_SendEnergyRechargedMessage: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('energyRecharged');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.energyRecharged({
        args: { userId: state.id, mediaUrl },
        payload: getChatbotPayload({
          feature: 'energy',
          $subFeature: 'energyRecharged',
          mediaUrl,
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendReengagementBroadcast: action((state, _, api) => {
    sendReengagementBroadcast(state, api);
  }),
  cheat_SendReengagementMessage: action((state, _, api) => {
    const iteration = Math.floor(Math.random() * 4) + 1;
    const templateIds = [
      'reengagement1',
      'reengagement2',
      'reengagement3',
    ] as const;
    const templateId = templateIds[iteration - 1] || 'reengagement1';
    const mediaUrl = getNotificationMedia(templateId);
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates[templateId]({
        args: { userId: state.id, mediaUrl },
        payload: getChatbotPayload({
          feature: 'reengagement',
          $subFeature: 'reengagement_24h',
          mediaUrl,
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendFriendIncrementalLeague: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('friendIncrementalLeague');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.friendIncrementalLeague({
        args: {
          userId: state.id,
          tokens: { friendName: state.username, league: '3', bonus: '1000' },
          mediaUrl,
        },
        payload: getChatbotPayload({
          feature: 'incremental_league',
          $subFeature: 'incremental_league',
          mediaUrl,
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendLevelUpMessage: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('friendJoined');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.friendJoined({
        args: {
          userId: state.id,
          tokens: {
            friendName: state.username,
            bonus: '100',
          },
          mediaUrl,
        },
        payload: getChatbotPayload({
          feature: 'friend',
          $subFeature: 'friend_joined',
          mediaUrl,
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendFriendsJoinedMessage: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('friendsJoined');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.friendsJoined({
        args: {
          userId: state.id,
          tokens: {
            friendName: state.username,
            friendCount: '7',
            bonus: '10000',
          },
          mediaUrl,
        },
        payload: getChatbotPayload({
          feature: 'friend',
          $subFeature: 'friend_joined',
          mediaUrl,
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendOffchainTokenPriceUpMessage: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('offchainTradingCardPriceUp');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.offchainTradingCardPriceUp({
        args: {
          userId: state.id,
          tokens: {
            tokenName: 'Rat Poison Square',
            priceChange: '50',
            price: '90000000',
          },
          mediaUrl,
        },
        payload: getChatbotPayload({
          feature: 'trading',
          $subFeature: 'offchainTrading_card_up',
          mediaUrl,
          payload: {
            dlRoute: 'TradingTokenPage',
            // alpha token id (replace if outdated)
            dlOpts: {
              offchainTokenId:
                'offchainTrading-7fc9fd0c-3963-405c-ab0e-96ef0f090344',
            },
          },
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_SendOffchainTokenPriceDownMessage: action((state, _, api) => {
    const mediaUrl = getNotificationMedia('offchainTradingCardPriceDown');
    sendTelegramMessage(state, api, {
      chatId: state.id,
      receiverId: state.id,
      message: templates.offchainTradingCardPriceDown({
        args: {
          userId: state.id,
          tokens: {
            tokenName: 'Rat Poison Square',
            priceChange: '75',
            price: '10',
          },
          mediaUrl,
        },
        payload: getChatbotPayload({
          feature: 'trading',
          $subFeature: 'offchainTrading_card_down',
          mediaUrl,
          payload: {
            dlRoute: 'TradingTokenPage',
            // alpha token id (replace if outdated)
            dlOpts: {
              offchainTokenId:
                'offchainTrading-7fc9fd0c-3963-405c-ab0e-96ef0f090344',
            },
          },
        }),
      }),
      maxDelayMs: 24 * HOUR_IN_MS,
    });
  }),
  cheat_AddFakeFreinds: asyncAction(
    async (state, payload: { friends: number; reset?: boolean }, api) => {
      const friendsSubState = await api.sharedStates.friends.fetch(
        getFriendsSubStateId(state),
      );
      const regularFriendCount = friendsSubState?.global.friendIds.length ?? 0;

      if (payload.reset) {
        state.friendCount = regularFriendCount;
        return;
      }

      state.friendCount += payload.friends;
    },
  ),
  cheat_CleanEarnPageQuests: action((state) => {
    const keys = Object.keys(state.earnings) as EarningKey[];
    keys.forEach((key) => {
      state.earnings[key] = false;
    });
    state.followOnYoutubeTimestamp = 0;
    state.watchedVideos = [];
  }),
  cheat_ClearWallet: action((state, _, _api) => {
    state.wallet = [];
  }),
  cheat_ResetEarning: action(
    (
      state,
      { earningKey }: { earningKey: keyof typeof state.earnings },
      _api,
    ) => {
      state.earnings[earningKey] = false;
    },
  ),
  cheat_firstInteraction: action((state, _, api) => {
    state.first_interaction = true;
    state.badgeControl.whitelisted = [];
    state.badgeControl.cards = [];
    state.first_interaction = true;
  }),
  cheat_refillTGMTickets: action((state, _, api) => {
    tmgRefillTicketsAndWipeSessionCache(state, api.date.now(), 'useCheat');
  }),
  cheat_TMGResetDaily: action((state, _, api) => {
    tmgRefillTicketsAndWipeSessionCache(
      state,
      api.date.now(),
      'cheatResetDaily',
    );
  }),
  cheat_SendReengamentMessage: asyncAction(async (state, _, api) => {
    await sendHighPriorityMessage(
      state,
      api,
      NotificationFeature.reengagement,
      'useCheat',
    );
  }),
  cheat_sendMemeTokenGiftMessage: asyncAction(
    async (state, { strict }: { strict?: boolean }, api) => {
      await handleFreeTokenMemeGift(
        state,
        api,
        strict ? undefined : 'useCheat',
      );
    },
  ),
});
