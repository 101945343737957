import { assets } from '../../../assets/assets';
import { boosterConfig } from '../../../data/consts';
import { Booster, BoosterListing } from '../../../data/types';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import { getUIStateClassName } from '../../../data/utils';
import { app } from '../../../data/Controllers/AppController';

interface Props extends BoosterListing {
  booster: Booster;
  disabled: boolean;
  onClick: (booster: Booster) => void;
}

export const BoosterCard = ({
  booster,
  price,
  level,
  maxedOut,
  onClick,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const { name, image } = boosterConfig[booster];

  const onCardClick = () => {
    if (disabled) {
      return;
    }
    onClick(booster);
  };

  const hideLevel = booster === Booster.AutoTap;

  return (
    <div
      className={`booster-card ${disabled && 'disabled'} ${getUIStateClassName(
        app.ui.state.shop.cards,
      )}`}
      onClick={onCardClick}
    >
      <div className="booster-card-left">
        <img className="booster-card-icon" src={image} />
        <div className="booster-card-info">
          <div className="booster-card-info-title">{t(name)}</div>
          <div className="booster-card-info-points">
            <img className="booster-card-info-points-icon" src={assets.coin} />
            {hideLevel ? (
              <div className="booster-card-info-points-value">
                {formatPrice(price)}
              </div>
            ) : (
              <div className="booster-card-info-points-value-ctn">
                <div className="booster-card-info-points-value">
                  {maxedOut ? t('booster_card_maxed_out') : formatPrice(price)}
                </div>

                <li></li>

                <div className="level">
                  {t('booster_card_level')} {level}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <img className="booster-card-arrow" src={assets.button_arrow_right} />
    </div>
  );
};
