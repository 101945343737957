import './LeaguePage.scss';
import { Page } from '../Page';
import { useState } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { assets } from '../../../assets/assets';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';
import { League } from '../../../replicant/features/game/ruleset/league';
import { useTranslation } from 'react-i18next';
import { LeagueTitle } from '../../shared/LeagueTitle/LeagueTitle';
import { PlayerCard } from '../../shared/LoadingScreen/PlayerCard';
import { formatPrice } from '../../../replicant/utils/numbers';
import { useEffect } from 'react';

const getProgress = (
  taps: number,
  min: number,
  max: number,
  isFinal = false,
) => {
  let text = `From ${formatPrice(min)}`;
  let progressBar = undefined;
  if (taps >= min && taps < max) {
    text = `${formatPrice(taps)} / ${isFinal ? '∞' : formatPrice(max)}`;
    if (!isFinal) {
      progressBar = (100 * taps) / max;
    }
  }
  return {
    text,
    progressBar,
  };
};

const leagueNavigation = {
  [League.league1]: {
    left: undefined,
    right: League.league2,
    progress: (taps: number) => getProgress(taps, 0, 20_000),
  },

  [League.league2]: {
    left: League.league1,
    right: League.league3,
    progress: (taps: number) => getProgress(taps, 20_000, 200_000),
  },

  [League.league3]: {
    left: League.league2,
    right: League.league4,
    progress: (taps: number) => getProgress(taps, 200_000, 2_000_000),
  },

  [League.league4]: {
    left: League.league3,
    right: League.league5,
    progress: (taps: number) => getProgress(taps, 2_000_000, 10_000_000),
  },

  [League.league5]: {
    left: League.league4,
    right: League.league6,
    progress: (taps: number) => getProgress(taps, 10_000_000, 50_000_000),
  },

  [League.league6]: {
    left: League.league5,
    right: League.league7,
    progress: (taps: number) => getProgress(taps, 50_000_000, 250_000_000),
  },

  [League.league7]: {
    left: League.league6,
    right: League.league8,
    progress: (taps: number) => getProgress(taps, 250_000_000, 500_000_000),
  },

  [League.league8]: {
    left: League.league7,
    right: League.league9,
    progress: (taps: number) => getProgress(taps, 500_000_000, 1_000_000_000),
  },

  [League.league9]: {
    left: League.league8,
    right: undefined,
    progress: (taps: number) =>
      getProgress(taps, 1_000_000_000, Infinity, true),
  },
};

export const LeaguePage = () => {
  useAppUpdates({
    id: 'LeaderboardPage',
    listener: app.views.LeaguePage.attachEventListener(),
  });

  const { t } = useTranslation();

  const { data, loading, visible, fetch } = app.views.LeaguePage;

  const [league, setLeague] = useState(app.clicker.league);
  const [telemetryIsSent, setTelemetryIsSent] = useState(false);

  useEffect(() => {
    if (visible && data?.userRank && !telemetryIsSent) {
      setTelemetryIsSent(true);
      app.track('OpenLeaguePage', { rank: data?.userRank });
    }
  }, [visible, data]);

  const navigation = leagueNavigation[league];

  const onLeft = () => {
    const { left } = navigation;
    if (left) {
      fetch(left);
      setLeague(left);
    }
  };

  const onRight = () => {
    const { right } = navigation;
    if (right) {
      fetch(right);
      setLeague(right);
    }
  };

  const progress = navigation.progress(app.state.taps);

  return (
    <Page id="modal-league" visible={visible}>
      <div className="modal-header leaguepage">
        {/* glowing gem with arrows */}
        <div className="header-nav">
          <div className="btn-left" onClick={onLeft}>
            {navigation.left && <img src={assets.button_arrow_right} />}
          </div>

          <GlowingImage
            image={assets.league[league]}
            blur={40}
            intensity={0.35}
            size={'200px'}
          ></GlowingImage>

          <div className="btn-right" onClick={onRight}>
            {navigation.right && <img src={assets.button_arrow_right} />}
          </div>
        </div>

        {/* league name */}
        <div className="modal-header-title">
          <div className="modal-header-title-name">
            <LeagueTitle league={league} />
          </div>
        </div>

        <div className="modal-header-subtitle">
          {/* progress label */}
          <div className="progress-label">
            <div className="progress-label-icon">
              <img src={assets.finger_up} />
            </div>
            <div className="progress-label-text">{`${progress.text}`}</div>
          </div>

          {/* progress bar */}
          {progress.progressBar && (
            <div className="progress-bar">
              <div className="progress-bar-bg"></div>
              <div
                className="progress-bar-value"
                style={{ width: `${progress.progressBar}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>

      <hr />

      {/* league leaderboard */}
      {loading ? (
        <p className="loading-label">{t('loading')}</p>
      ) : (
        <div
          className={`team-list leaguepage ${
            data?.outRank && data.ranked.length > 15 ? 'linked-user-card' : ''
          }`}
        >
          {(data?.ranked || []).map((p, i) => (
            <PlayerCard
              key={i}
              rank={p.rank}
              image={p.picture}
              name={p.username}
              score={p.score}
              isCurrentUser={p.isCurrentUser}
            />
          ))}
          {data?.outRank && (
            <PlayerCard
              key={data.outRank.rank}
              rank={data.outRank.rank}
              image={data.outRank.picture}
              name={data.outRank.username}
              score={data.outRank.score}
              isCurrentUser
              isOutrank
            />
          )}
        </div>
      )}
    </Page>
  );
};
