import { PowerUp } from '../../../../replicant/features/powerups/types';

interface Props {
  powerup: PowerUp;
}

export const DPUHeader = ({ powerup }: Props) => {
  return (
    <div className={`drawer-header-icon`}>
      <img src={powerup?.details.image} />
    </div>
  );
};
