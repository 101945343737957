import './TradingCreatePage.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { FormEvent, useMemo } from 'react';
import { TradingCreateInput } from './TradingCreateInput/TradingCreateInput';
import { Page } from '../Page';
import { FormEvents } from '../../../data/Form';

const targetFields = ['telegramChannelLink', 'telegramChatLink', 'twitterLink'];
export const TradingCreateLinksPage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingCreateLinksPage;

  useAppUpdates({
    id: 'TradingCreateLinksPage',
    listener: app.views.TradingCreateLinksPage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TradingEditLinksPage/FormEvents.OnUpdate',
    listener: app.memes.factory.newTokenForm.attachEventListener(
      FormEvents.OnUpdate,
    ),
  });

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    // inputRef.current?.blur();
  };

  const formConfig = useMemo(() => {
    return app.memes.factory.newTokenForm.fields.filter((f) =>
      targetFields.includes(f.key),
    );
  }, []);

  const onInputChange =
    (key: (typeof app.memes.factory.newTokenForm.fields)[0]['key']) =>
    (value: string) => {
      app.memes.factory.newTokenForm.setField(key, value);
    };

  const onContinue = async () => {
    if (!app.memes.factory.newTokenForm.isValid) {
      return;
    }
    app.memes.factory.submitFormCreateToken();
  };

  if (!visible) {
    return null;
  }

  return (
    <Page id="trading-create" visible={visible}>
      <div className="trading-create-layout">
        <div className="trading-create-header">
          {/* header title / question */}
          <div className="trading-create-title">
            <div className="label">{t('create_token_links_title')}</div>
          </div>
        </div>

        {/* form with several inputs */}
        <form onSubmit={onFormSubmit}>
          {formConfig.map((cfg, index) => {
            return (
              <TradingCreateInput
                key={index}
                title={cfg.title}
                placeholder={cfg.placeholder || ''}
                value={app.memes.factory.newTokenForm.data[cfg.key]}
                onChange={onInputChange(cfg.key)}
                isValid={app.memes.factory.newTokenForm.getIsFieldValid(
                  cfg.key,
                )}
                required={cfg.required}
              />
            );
          })}
        </form>

        <div
          className={`btn btn-normal button-continue ${
            !app.memes.factory.newTokenForm.isValid && 'disabled'
          }`}
          onClick={onContinue}
        >
          {t('trading_create_continue')}
        </div>
      </div>
    </Page>
  );
};
