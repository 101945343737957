import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import Big from 'big.js';
import { SliceTimeWindows } from '../features/offchainTrading/offchainTrading.ruleset';

/**
 * Adds the current player balance to all price points in the portfolio trends.
 * This migration is necessary to include the player's balance in the total portfolio value,
 * providing a more accurate representation of the player's assets over time.
 */
export async function addBalanceToPortfolioTrends(
  state: MutableState,
  _:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const currentBalance = Big(state.balance);
  const trends = state.trading.offchain.portfolioTrends;

  // Helper function to update price in a trend slice
  const updateTrendSlice = (slice: { price: string; time: number }) => {
    slice.price = Big(slice.price).add(currentBalance).toString();
  };

  // Update all trend periods
  Object.keys(trends).forEach((period) => {
    trends[period as SliceTimeWindows].forEach(updateTrendSlice);
  });
}
