import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class LeaguePage extends TutorialModule {
  id = 'LeaguePage' as 'LeaguePage';
  // @TODO: might require some extra logic because users can navigate to specific team
  onBack = () => {
    this.onComplete();
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Normal,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Normal,
      btnEarn: ElementUIState.Normal,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Normal,
    });

    // navigate to leagues page
    this.app.nav.goTo('LeaguePage');

    return super.execute().then(() => {
      this.app.nav.goToHomePage();
      this.app.ui.tooltip.hide();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_teams_page_prompt',
      data: {
        has_team: Boolean(this.app.state.team_id),
      },
    };
  }
}
