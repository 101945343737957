import { ReplicantAsyncActionAPI } from '@play-co/replicant';
import { MutableState } from '../../schema';
import { ReplicantServer } from '../../config';
import { tests } from '../../ruleset';

export function manuallyAssignAbTests(
  state: MutableState,
  api: ReplicantAsyncActionAPI<ReplicantServer>,
) {
  const isTiktokOnly =
    state.ruleset.abTests[tests.TEST_TIKTOK_ONLY]?.bucketId ===
    'tiktok_only_experience';
  // Make sure that tiktokOnly ab has its dependency turned on
  if (isTiktokOnly) {
    api.abTests.assign(tests.TEST_TIKTOK_FEED, 'enabled');
  }
}
