import { assets } from '../../../assets/assets';
import './Rank.scss';

interface Props {
  rank?: number;
}

const medals = {
  1: assets.medal_gold,
  2: assets.medal_silver,
  3: assets.medal_bronze,
};

export const Rank = ({ rank }: Props) => {
  return (
    <div className="rank">
      {rank ? (
        rank > 3 ? (
          <p className="rank-label">#{rank}</p>
        ) : (
          <img src={medals[rank as 1 | 2 | 3]} />
        )
      ) : null}
    </div>
  );
};
