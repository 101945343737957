import './UserBalance.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { getPowerUpBonusPerHour } from '../../../replicant/features/powerups/getters';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';

import { t } from 'i18next';

interface Props {
  showTitle?: boolean;
  showPerHour?: boolean;
}

export const UserBalance = ({ showTitle, showPerHour }: Props) => {
  const { now, replicant } = app;

  const replicantState = replicant?.state; // has hidden meta properties required by some getters

  const { balance } = app.ui;

  const prettyBalance =
    balance < 1_000_000_000
      ? formatPrice(balance)
      : largeIntegerToLetter(balance);

  return (
    <div className="user-balance">
      {/* title */}

      {/* points */}
      <div className="user-balance-points">
        {showTitle && <div className="user-balance-title">Your Balance</div>}
        <img className="user-balance-points-icon" src={assets.coin} />
        <div className="user-balance-points-label">{prettyBalance}</div>
      </div>

      <div className="user-balance-per-hour-container">
        {/* earning per hour */}
        {showPerHour && (
          <div className="user-balance-earn-per-hour">
            <div>{t('pup_earning_per_hour')}</div>
            <img src={assets.coin} />
            <div className="yellow">
              +{formatPrice(getPowerUpBonusPerHour(replicantState, now()))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
