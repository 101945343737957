import { assets } from '../../../assets/assets';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import { Rank } from '../../shared/Rank/Rank';

interface Props {
  rank: number;
  image?: string;
  name: string;
  score: number;
  contribution: number; // todo: is missing from our BE
}

export const TeamMemberCard = ({
  rank,
  image,
  name,
  score,
  contribution,
}: Props) => {
  return (
    <div className="card team-member">
      <div className="card-left">
        <Rank rank={rank} />
        <div className="image">
          <img src={image || assets.friends} />
        </div>
        <div className="info">
          <div className="name">{name}</div>
        </div>
      </div>

      {
        <div className="score">
          <div className="text">{largeIntegerToLetter(score)}</div>
          <div className="icon">
            <img src={assets.coin} />
          </div>
        </div>
      }
    </div>
  );
};
