import { teaHash } from '@play-co/replicant';
import ruleset, { ABTestBucket, ABTestID, ABTests, tests } from '../../ruleset';
import { State } from '../../schema';

export enum ReengageMsgType {
  EnergyRecharge = 'ReengageMsgType/EnergyRecharge',
  PeriodicReengagement = 'ReengageMsgType/PeriodicReengagement',
  TapBot = 'ReengageMsgType/TapBot',
  PointsReminder = 'ReengageMsgType/PointsReminder',
  FriendsJoined = 'ReengageMsgType/FriendsJoined',
}

export function getReceiverBucket<T extends ABTestID>(
  test: T,
  userId: string,
): ABTestBucket<T> {
  const testDef = ruleset.abTests[test];
  if (!testDef) {
    throw new Error(`Test missing for ${test}`);
  }

  const buckets = testDef.buckets;
  const roll = Math.min(
    buckets.length - 1,
    Math.floor(teaHash(test + userId) * buckets.length),
  );

  return buckets[roll].id;
}
