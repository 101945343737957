import './DrawerPowerUpGiftExpired.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { sendMineCardGift } from '../../../data/sharing';

export const DrawerPowerUpGiftExpired = () => {
  const { t } = useTranslation();

  const onSendGift = () => {
    const card = app.clicker.expiredGift;
    if (card) {
      app.track('SendGiftExpired', {
        card,
        gift_name: card,
      });
      sendMineCardGift({
        card,
        originFeature: 'daily',
        originSubFeature: 'daily_gifts',
      });
    }
    app.ui.drawer.close();
  };

  return (
    <div className="drawer-details powerup-gift-expired">
      <div className="drawer-layout powerup">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={assets.sloth_gift_invalid} />
          </div>
          {/* title and description */}
          <div className="drawer-header-title">
            {t(`pup_gift_expired_title`)}
          </div>
          <div className="drawer-header-subtitle powerup">
            <div className="text">{t(`pup_gift_expired_description`)}</div>
          </div>
        </div>
      </div>

      {/* buy button */}
      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onSendGift}>
          {t('pup_send_another_gift')}
        </div>
      </div>
    </div>
  );
};
