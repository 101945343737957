// From specs doc:
// Full Energy: Recharge your energy completely and keep earning! // (price)
// RocketMan: Get the RocketMan and supercharge your points! // Free
// Extra tap: Increase the amount of points earned per tap // +1 point per tap per level
// Recharge Speed: Recharge your energy in faster // +1 per sec for each level // 3 levels max limit
// Gemz ClickBot: I will earn points for you when you have not been tapping for at least one hour.//I can tap for 12 hours, then you need to claim these points so I can continue earning for you.

import { assets } from "../assets/assets";
import { Booster, Buff } from "./types";

export const boosterConfig = {
  // todo(Cai): What's up with the rest of boosters? rocket, full energy, etc...
  [Booster.AutoTap]: {
    image: assets.boosters.AutoTap,
    name: 'booster_autotap_name',
    desc: 'booster_autotap_desc',
    effect: 'booster_autotap_effect',
  },
  [Booster.MultiTap]: {
    image: assets.boosters.MultiTap,
    name: 'booster_multitap_name',
    desc: 'booster_multitap_desc',
    effect: 'booster_multitap_effect',
  },
  [Booster.RechargeLimit]: {
    image: assets.boosters.RechargeLimit,
    name: 'booster_rechargelimit_name',
    desc: 'booster_rechargelimit_desc',
    effect: 'booster_rechargelimit_effect',
  },
  [Booster.RechargeSpeed]: {
    image: assets.boosters.RechargeSpeed,
    name: 'booster_rechargespeed_name',
    desc: 'booster_rechargespeed_desc',
    effect: 'booster_rechargespeed_effect',
  },
  [Buff.Rocketman]: {
    image: assets.boosters.Rocketman,
    name: 'buff_rocketman_name',
    desc: 'buff_rocketman_desc',
    effect: 'buff_rocketman_effect',
  },
  [Buff.FullEnergy]: {
    image: assets.boosters.FullEnergy,
    name: 'buff_fullenergy_name',
    desc: 'buff_fullenergy_desc',
    effect: 'buff_fullenergy_effect',
  },
  [Buff.AutoBotReward]: {
    image: assets.boosters.AutoTap,
    name: 'buff_autobotreward_name',
    desc: 'buff_autobotreward_desc',
    effect: 'buff_autobotreward_effect',
  },
  [Buff.AutoBotFirstSession]: {
    image: assets.boosters.AutoTap,
    name: 'buff_autobotfirstsession_name',
    desc: 'buff_autobotfirstsession_desc',
    effect: 'buff_autobotfirstsession_effect',
  },
};