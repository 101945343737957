import './ClickerScore.scss';
import { assets } from '../../../../assets/assets';
import {
  useAppUpdates,
  app,
  AppEvents,
} from '../../../../data/Controllers/AppController';
import { getUIStateClassName, msToSeconds } from '../../../../data/utils';
import { tests } from '../../../../replicant/ruleset';
import { useTranslation } from 'react-i18next';
import { LeagueTitle } from '../../../shared/LeagueTitle/LeagueTitle';
import { formatPrice } from '../../../../replicant/utils/numbers';
import { UIEvents } from '../../../../data/Controllers/UIController/UITypes';

export const ClickerScore = () => {
  useAppUpdates({
    id: 'ClickerScore/balance',
    listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
  });

  const { bonus } = app.clicker;
  const { t } = useTranslation();

  const { balance } = app.ui;

  // rocket boosters are always less than a minute
  const prettyTime = bonus ? msToSeconds(bonus.timeLeft) : undefined;

  return (
    <div className="scoreboard">
      {/* timer */}
      {prettyTime && (
        <div className="timer">{`${t(
          'clicker_boost_time_left',
        )} ${prettyTime}`}</div>
      )}

      {/* points */}
      <div className="points">
        <img className="points-icon" src={assets.coin} alt="" />
        <div id="point" className="points-label">
          {formatPrice(balance)}
        </div>
      </div>

      {/* league */}
      <div
        className={`btn league ${getUIStateClassName(
          app.ui.state.clicker.btnLeague,
        )}`}
        onClick={() => {
          app.tutorial.step?.onAction && app.tutorial.step?.onAction('league');
          app.clicker.showLeagueLeaderboad();
        }}
      >
        <div className="league-icon">
          <img src={assets.league[app.clicker.league]} alt="" />
        </div>
        <div className="league-label">
          <LeagueTitle />
        </div>
      </div>
    </div>
  );
};
