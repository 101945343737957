import { assets } from '../../../assets/assets';
import { getPowerUpById } from '../../../replicant/features/powerups/getters';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class NextCard extends TutorialModule {
  id = 'NextCard' as 'NextCard';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });
    this.app.ui.setClickerUIState(
      Object.keys(this.app.ui.state.clicker).reduce(
        (res, cur) => ({
          ...res,
          [cur]: ElementUIState.Inactive,
        }),
        {},
      ),
    );

    await waitFor(100);

    // get 'next card' name and image from harcoded id 'pu_specials_new_horizons'
    const card = getPowerUpById(
      this.app.state,
      this.app.now(),
      'pu_specials_new_horizons',
    );

    const cardImage = card?.details.image;
    const cardName = t('pu_specials_new_horizons_name');
    console.warn('>>> card', cardName, cardImage, card);

    // show the drawer
    this.app.ui.drawer.show({
      id: 'generic',
      opts: {
        image: cardImage, // assets.tutorial_next_card,
        title: t('tut_drawer_nextcard_title'), // `Get your next card!`,
        subtitle: t('tut_drawer_nextcard_description', {
          cardName: cardName, // 'TBP Card Name',
        }), // 'Come back in three hours to claim and get {TBD Card name}',
        onClose: () => this.closeDrawerAndContinue(),
        buttons: [
          {
            cta: t('tut_drawer_nextcard_cta'), // `OK`,
            onClick: () => {
              this.closeDrawerAndContinue();
            },
          },
        ],
      },
    });

    return super.execute().then(() => {
      this.app.ui.setClickerUIState(
        Object.keys(this.app.ui.state.clicker).reduce(
          (res, cur) => ({
            ...res,
            [cur]: ElementUIState.Normal,
          }),
          {},
        ),
      );
      setTimeout(() => {
        this.app.ui.setTutorialUIState({
          badges: {
            mine: undefined,
            earn: undefined,
            friends: undefined,
            boosters: undefined,
            trading: undefined,
          },
        });
      }, 5000);
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_farewell_appointment_dialog',
    };
  }
}
