import './DrawerInviteFriend.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { invite } from '../../../data/sharing';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFriendReferralRewards } from '../../../replicant/features/game/game.getters';
import { League } from '../../../replicant/features/game/ruleset/league';
import { formatPrice } from '../../../replicant/utils/numbers';

export const DrawerInviteFriend = () => {
  const { t } = useTranslation();

  const getFriendInviteBonus = () => {
    return getFriendReferralRewards(app.state, app.now(), League.league1)
      .premium;
  };

  const onInviteClick = () => {
    // note: we probably dont want to close the drawer when tapping invite button
    // onClose();

    app.track('PressInvite', {
      screen_location: 'earn_page', // drawer_invite_friend
    });

    invite({
      screen_location: 'earn_page', // drawer_invite_friend
      data: {
        feature: 'friend',
        $subFeature: 'drawer_invite_friend',
        originFeature: 'home',
      },
    });
  };

  useEffect(() => {
    app.replicant?.invoke.updateInviteFriendNudge();
  }, []);

  const titleAndCopy = () => {
    return (
      <div>
        <div className="drawer-header-title">
          {t('drawer_invite_friend_title')}
        </div>
        <div className="drawer-header-subtitle">
          <div className="text">{t('drawer_invite_friend_subtitle_1')}</div>
          <div className="score">
            <div className="icon">
              <img src={assets.coin} />
            </div>
            <div className="text">{formatPrice(getFriendInviteBonus())}</div>
          </div>
          <div className="text">{t('drawer_invite_friend_subtitle_2')}</div>
        </div>
      </div>
    );
  };

  const titleAndCopyLRC = () => {
    return (
      <div>
        <div className="drawer-header-title">
          {t('drawer_invite_friend_title_lrc')}
        </div>
        <div className="drawer-header-subtitle">
          <div className="text">{t('drawer_invite_friend_title_copy_lrc')}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="drawer-layout friend">
        <div className="drawer-header">
          <div className="drawer-header-icon">
            <img src={assets.earn_inviteBonus} />
          </div>
          {titleAndCopy()}
        </div>
      </div>

      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={onInviteClick}>
          {t('drawer_invite_friend_button')}
        </div>
      </div>
    </>
  );
};
