import './DrawerTradingSlippage.scss';
import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/Controllers/AppController';
import { TradingInputNumeric } from '../../TradingPage/TradingInputNumeric/TradingInputNumeric';
import { useState } from 'react';
import { isTiktokEnabled } from '../../../../data/utils';

/**
 * This function returns a normalised percentage number
 * Raw percentage might be a weird float like: 10% = `(1.1 * 100) - 100` results in `10.00000014`
 * Normalise by only taking the last 2 decimals and ignoring if it's '00'
 */
const getPct = (slippage: number) => {
  const rawPct = slippage * 100 - 100;
  const pctStr = rawPct.toFixed(2);
  const [int, dec] = pctStr.split('.');
  return Number(dec === '00' ? int : pctStr);
};

export const DrawerTradingSlippage = () => {
  const { t } = useTranslation();

  const { trading } = app.memes;

  const [value, setValue] = useState(getPct(trading.tx?.maxSlippage ?? 0));

  const onConfirm = () => {
    const multiplier = 1 + value / 100;
    // Only save if the value was changed
    if (multiplier !== trading.tx?.maxSlippage) {
      trading.setMaxSlippage(multiplier);
    }

    // only if tiktok abtest is active,
    // re-open tiktok buy/sell drawer after setting the slippage
    if (isTiktokEnabled()) {
      app.ui.drawer.show({
        id: 'drawerTiktokBuySell',
      });
    } else {
      app.ui.drawer.close();
    }
  };

  return (
    <div className="drawer-trading-slippage">
      <div className="drawer-trading-slippage-header">
        <div className="drawer-trading-slippage-title">
          {t('trading_slippage_title')}
        </div>

        <div className="drawer-trading-slippage-form">
          <TradingInputNumeric
            type="numeric"
            value={value}
            autoFocus={true}
            pushContentUp={280}
            onAmountChange={setValue}
          />
          <div className="drawer-trading-slippage-percent">%</div>
        </div>

        <div className="drawer-trading-slippage-subtitle">
          {t('trading_slippage_subtitle')}
        </div>
      </div>

      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onConfirm}>
          {t('trading_slippage_button')}
        </div>
      </div>
    </div>
  );
};
