import { OwnedOffchainToken } from '../../../replicant/features/game/player.schema';
import {
  CanBuyOpts,
  CanSellOpts,
  computePortfolioValue,
  getBuyPointTxEstimate,
  getBuyTxEstimate,
  getCanUserSell,
  getCoinAmountForTokenSell,
  getIsBuyTxValid,
  getIsSellTxValid,
  getMyOffchainTokenIds,
  getTxWithinEstimate,
  TradingToken,
} from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingSearchResult } from '../../../replicant/features/offchainTrading/offchainTrading.properties';
import {
  offchainTradingNotifDelay,
  SliceConfig,
  txConfig,
} from '../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import {
  Currency,
  TradingTokenHolder,
  TxType,
} from '../../../replicant/features/offchainTrading/types';
import { ErrorCode } from '../../../replicant/response';
import { MIN_IN_MS, SEC_IN_MS } from '../../../replicant/utils/time';
import { AppController } from '../AppController';
import { captureGenericError } from '../../sentry';
import { Optional, WithProxy } from '../../types';
import { getPct } from '../../utils';
import Big from 'big.js';
import {
  getOffchainTokenHoldCount,
  getOffchainTokenHoldingStats,
  getOffchainTokenPricePoints,
  getPortfolioPricePoints,
  getPortfolioRoi,
} from '../../offchainTokenUtils';
import { MemesEvents } from './MemesController';
import { BusinessController } from '../BusinessController';

interface Tx {
  txType: TxType;
  receive: Big;
  send: Big;
  isValid: boolean;
}

const CARD_REFETCH_COOLDOWN_MS = 15;
const PORTFOLIO_REFETCH_COOLDOWN = MIN_IN_MS * 2; // @TODO: Check how often it should be
const HOT_CATEGORY_REFRESH_COOLDOWN = SEC_IN_MS * 2;
const NEW_CATEGORY_REFRESH_COOLDOWN = MIN_IN_MS * 1;
const TOP_CATEGORY_REFRESH_COOLDOWN = MIN_IN_MS * 5;

const LIST_FETCH_COOLDOWN = Math.min(
  HOT_CATEGORY_REFRESH_COOLDOWN,
  NEW_CATEGORY_REFRESH_COOLDOWN,
  TOP_CATEGORY_REFRESH_COOLDOWN,
);

export class TradingController extends BusinessController<
  '',
  WithProxy<MemesEvents>
> {
  // Check if repli
  // Record<UserId, ImageUrl | null>; null if we have attempted to find a phone but there's none

  private notifScheduler: NodeJS.Timeout | null = null;

  private memeFetchedAtLeastOnce: boolean = false;

  get notification() {
    return this.state.notification;
  }

  get isLoading() {
    return this.state.loading;
  }

  get purchaseMode() {
    return this.state.purchaseMode;
  }

  get isBuy() {
    return this.purchaseMode === 'buy';
  }

  get portfolioValue() {
    return this.state.portfolioValue;
  }

  get tx() {
    const token = this.app.memes.currentMeme.token;

    const formData = this.app.memes.factory.createNewTokenFormData;

    if ((!token && !formData) || !this.state.tx) {
      return undefined;
    }

    const canSell = token
      ? getCanUserSell(this.app.state, token.id, Big(0))
      : false;

    return {
      ...this.state.tx,
      maxSlippage: this.app.state.trading.maxSlippage,
      canSell,
    };
  }

  get txCurrency() {
    return this.state.txCurrency;
  }

  get txSummary() {
    const token = this.app.memes.currentMeme.token;

    if (!this.state.tx || !token) {
      return;
    }
    const {
      tx: { txType, receive, send },
    } = this.state;

    // default to 'sell'
    let tokenAmount = send;
    let finalSupply = token ? Big(token.supply) : undefined;

    if (txType === 'buy') {
      tokenAmount = receive;
      finalSupply = token ? Big(token.supply).add(tokenAmount) : undefined;
    }

    return {
      ...this.state.tx,
      shareOfTheSupply: finalSupply
        ? getPct(tokenAmount.toNumber(), finalSupply.toNumber())
        : '-',
      sending: send.toString(),
      receiving: receive.toString(),
    };
  }

  get timeUntilNextOffchainTokenRefresh() {
    return CARD_REFETCH_COOLDOWN_MS - this.offchainTokenRefreshCount;
  }

  private offchainTokenRefresher?: NodeJS.Timeout;
  private offchainTokenRefreshCount = 0;

  private listingFetcher?: NodeJS.Timeout;
  // @TODO: not public
  public state = {
    loading: false,
    txCurrency: 'coins' as Currency,
    purchaseMode: undefined as Optional<TxType>,
    tx: undefined as Optional<Tx>,
    notifCount: -1, // -1 count as inactive notification
    notification: undefined as Optional<TradingSearchResult>,
    pastNotificationIds: [] as string[],
    ownedOffchainTokensFetchTimestamp: 0,
    portfolioValue: -1,
  };

  private sendEvent!: (evt: MemesEvents) => void;

  // @TODO: Move the event listeners to MemesController
  public init = async ({ sendEvtProxy }: WithProxy<MemesEvents>) => {
    this.sendEvent = sendEvtProxy;
    /**
     * When we are showing the card page, start the refresh counter and whenever its up
     * refresh selected card data and restart counter
     */
    this.app.views.TradingTokenPage.onVisibilityChange((isShowing) => {
      this.onTokenChange(isShowing);
    });

    this.app.views.TradingPage.onVisibilityChange((isShowing) => {
      clearInterval(this.listingFetcher);
      if (isShowing) {
        this.app.memes.setCurrent({ tokenId: undefined });
        // this.state.selectedOffchainToken = undefined;

        let lastTopRefresh = Date.now();
        let lastNewRefresh = Date.now();
        let lastHotRefresh = Date.now();
        this.listingFetcher = setInterval(() => {
          const now = Date.now();

          if (this.app.memes.currentFilter === 'Hot') {
            if (now - lastTopRefresh > HOT_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastHotRefresh = now;
            }
          } else if (this.app.memes.currentFilter === 'Top') {
            if (now - lastTopRefresh > TOP_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastTopRefresh = now;
            }
          } else if (this.app.memes.currentFilter === 'New') {
            if (now - lastNewRefresh > NEW_CATEGORY_REFRESH_COOLDOWN) {
              this.app.memes.currentList.refresh();
              lastNewRefresh = now;
            }
          }
        }, LIST_FETCH_COOLDOWN);
      }
    });

    // todo: this causes issues with the tapping game
    // todo: disabling it for now until we can find a correct solution
    // todo: the goal is to refresh the token data, without re-rendering the tapping game

    if (getMyOffchainTokenIds(this.app.state).length > 0) {
      // avoid async call if no token
      // trigger a series of snapshots
      this.app.invoke.asyncTakePortfolioSnapshots();
    }

    // Reset amount when we change tokens
    this.app.memes.addEventListener(MemesEvents.OnTokenSelected, () => {
      this.updateAmount(Big(0));
    });

    this.onInitComplete();
  };

  /**
   * To be used by MemesController only!
   */
  public onTokenChange = (isShowing: boolean) => {
    clearInterval(this.offchainTokenRefresher);
    if (isShowing) {
      this.offchainTokenRefresher = setInterval(() => {
        if (this.offchainTokenRefreshCount === CARD_REFETCH_COOLDOWN_MS) {
          this.offchainTokenRefreshCount = 0;
          this.app.memes.getToken(undefined, 'fetchAndUpdate');
        } else {
          this.offchainTokenRefreshCount++;
        }
        // this.sendEvent(TradingEvents.OnRefreshTimeUpdate);
      }, SEC_IN_MS);
    } else {
      this.offchainTokenRefreshCount = 0;
    }
  };

  public goToTxConfirmation = async () => {
    if (!this.tx) {
      return;
    }

    await this.app.memes.getToken(undefined, 'fetch');

    this.app.ui.drawer.show({
      id: 'drawerTradingTransactionConfirm',
      hideClose: false,
    });
  };

  public onCurrentTokenChange = (
    offchainToken: TradingToken,
    isRefresh?: 'isRefresh',
  ) => {
    const amount = isRefresh ? this.state.tx?.send : Big(0);
    this.updateTx(offchainToken, amount);
    // this.sendEvent(TradingEvents.OnSelectedOffchainTokenUpdate);
  };

  public setPurchaseMode = (txType: TxType) => {
    if (this.state.purchaseMode === txType) {
      return;
    }
    this.state.purchaseMode = txType;
    const { token } = this.app.memes.currentMeme;
    if (token) {
      this.updateTx(token);
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
    }
  };

  public updateAmount = (value: Big) => {
    if (!this.state.tx) {
      return;
    }
    const { createNewTokenFormData } = this.app.memes.factory;
    if (createNewTokenFormData) {
      this.updateTx(
        {
          id: 'not_used_for_buy',
          supply: Big(0).toString(),
        },
        value,
      );
      this.sendEvent(MemesEvents.TradingOnTxUpdate);
    } else {
      const { token } = this.app.memes.currentMeme;
      if (token) {
        this.updateTx(token, value);
        this.sendEvent(MemesEvents.TradingOnTxUpdate);
      }
    }
  };

  public submitTransaction = () => {
    const { token } = this.app.memes.currentMeme;
    if (!token || !this.state.tx) {
      return;
    }

    const { send: amount, receive: price } = this.state.tx;
    let amountValue = amount;
    if (!(amount instanceof Big)) {
      amountValue = Big(amount);
    }

    if (this.isBuy) {
      return this.buyOffchainToken({
        currencyAmount: amountValue,
        tokenAmountEstimate: price,
        driftPct: txConfig.defaultMaxSlippage.toNumber(),
      });
    }

    return this.sellOffchainToken({
      tokenAmount: amountValue,
      currencyAmountEstimate: price,
      driftPct: txConfig.defaultMaxSlippage.toNumber(),
    });
  };

  private buyOffchainToken = async ({
    currencyAmount,
    tokenAmountEstimate,
  }: CanBuyOpts) => {
    if (this.app.playerBalance < currencyAmount.toNumber()) {
      return;
    }

    const { token } = this.app.memes.currentMeme;

    if (!token) {
      return;
    }

    const tokenId = token.id;

    this.app.ui.showSpinner();

    // Grab the current estimate and make sure we don't have some old ass estimate still

    let latestTokenAmountEstimate =
      await this.app.asyncGetters.getBuyOffchainTokenEstimate({
        offchainTokenId: tokenId,
        currencyAmount,
      });

    if (
      latestTokenAmountEstimate &&
      !(latestTokenAmountEstimate instanceof Big)
    ) {
      latestTokenAmountEstimate = Big(latestTokenAmountEstimate);
    }

    const withinEstimate =
      latestTokenAmountEstimate &&
      getTxWithinEstimate(
        latestTokenAmountEstimate,
        tokenAmountEstimate,
        txConfig.clientSlippage,
      );

    if (!withinEstimate) {
      // Update transaction to refetch latest estimate
      const updatedToken = await this.app.memes.getToken(tokenId, 'forceFetch');
      // Update transaction to refetch latest estimate
      if (this.tx?.send && updatedToken) {
        this.updateTx(updatedToken, this.tx.send);
      }

      return this.app.ui.showError({
        message: `The estimate has changed, new estimate ${latestTokenAmountEstimate}`,
      });
    }

    const currentOwnedTokenAmount = Big(
      this.app.state.trading.offchainTokens[tokenId]?.tokenAmount || 0,
    );

    try {
      const response = await this.app.invoke.buyOffchainToken({
        offchainTokenId: tokenId,
        currencyAmount: currencyAmount.toString(),
        tokenAmountEstimate: tokenAmountEstimate.toString(),
      });

      if (response.error) {
        // @TODO: Make this into a function
        if (response.code === ErrorCode.CUSTOM_SENTRY_TRACK) {
          captureGenericError(ErrorCode.CUSTOM_SENTRY_TRACK, response.data);
        }

        // If we are receiving a handled error most likely we have stale data, reload current item
        // await this.refreshSelectedOffchainToken();
        await this.app.memes.getToken(undefined, 'forceFetch');

        throw new Error(response.error);
      } else if (response.data) {
        // Update selected offchainToken

        await this.app.memes.getToken(tokenId, 'forceFetch');

        const { token } = this.app.memes.currentMeme;

        if (token) {
          const tokenAmount = Big(
            this.app.state.trading.offchainTokens[tokenId]!.tokenAmount,
          );
          const amount_bought = tokenAmount.minus(currentOwnedTokenAmount ?? 0);

          this.app.track('memeoffchainToken_buy', {
            offchainToken_name: token.name,
            offchainTokenID: tokenId,
            previous_owned: currentOwnedTokenAmount?.toString() ?? false,
            current_owned:
              this.app.state.trading.offchainTokens[tokenId]!.tokenAmount,
            amount_invested: currencyAmount.toString(),
            amount_bought: amount_bought.toString(),
            memeoffchainToken_price: currencyAmount
              .div(amount_bought)
              .toString(),
            total_holders: token.holderCount - 1, // exclude self
          });

          this.app.invoke.asyncTakePortfolioSnapshots();
          this.refetchPortfolio('forceUpdate');
          this.app.ui.onOffchainTokenTxSuccess({
            offchainTokenId: tokenId,
            offchainTokenImage: token.image,
            offchainTokenName: token.name,
            offchainTokenDescription: token.description.description,
            txAmount: amount_bought.toNumber(),
            txType: 'buy',
          });
          this.updateAmount(Big(0));
        }
      }

      return response;
    } catch (e: any) {
      this.app.ui.showError({
        message: e.message,
      });
    }
  };

  private sellOffchainToken = async ({
    tokenAmount,
    currencyAmountEstimate,
  }: CanSellOpts) => {
    const { token } = this.app.memes.currentMeme;

    if (!token) {
      return;
    }

    const tokenId = token.id;

    const currentOwnedTokenAmount = Big(
      this.app.state.trading.offchainTokens[tokenId]?.tokenAmount ?? 0,
    );

    if (currentOwnedTokenAmount.lt(tokenAmount)) {
      return this.app.ui.showError({
        message: `Not enough tokens to sell`,
      });
    }

    this.app.ui.showSpinner();

    let latestCurrencyAmountEstimate =
      await this.app.asyncGetters.getSellOffchainTokenEstimate({
        offchainTokenId: tokenId,
        tokenAmount,
      });

    if (!latestCurrencyAmountEstimate) {
      return this.app.ui.showError({
        message: `Could not estimate selling price`,
      });
    }

    if (typeof latestCurrencyAmountEstimate === 'string') {
      latestCurrencyAmountEstimate = Big(latestCurrencyAmountEstimate);
    }

    const withinEstimate = getTxWithinEstimate(
      latestCurrencyAmountEstimate,
      currencyAmountEstimate,
      txConfig.clientSlippage,
    );

    if (!withinEstimate) {
      const updatedToken = await this.app.memes.getToken(tokenId, 'forceFetch');
      // Update transaction to refetch latest estimate
      if (this.tx?.send && updatedToken) {
        this.updateTx(updatedToken, this.tx.send);
      }

      return this.app.ui.showError({
        message: `The estimate has changed, new estimate ${latestCurrencyAmountEstimate}`,
      });
    }

    try {
      const response = await this.app.invoke.sellOffchainToken({
        offchainTokenId: tokenId,
        tokenAmount: tokenAmount.toString(),
        currencyAmountEstimate: currencyAmountEstimate.toString(),
      });

      if (response.error) {
        // @TODO: Make this into a function
        if (response.code === ErrorCode.CUSTOM_SENTRY_TRACK) {
          captureGenericError(ErrorCode.CUSTOM_SENTRY_TRACK, response.data);
        }
        // If we are receiving a handled error most likely we have stale data, reload current item
        // await this.refreshSelectedOffchainToken();
        await this.app.memes.getToken(tokenId, 'forceFetch');

        throw new Error(response.error);
      } else if (response.data) {
        if (!response.data.token) {
          this.app.memes.setCurrent({ tokenId: undefined });
          return response;
        }
        const updatedToken = await this.app.memes.getToken(
          tokenId,
          'forceFetch',
        );
        if (updatedToken) {
          const amount_divested = response.data.amount_divested;

          this.app.track('memeoffchainToken_sell', {
            offchainToken_name: updatedToken.name,
            offchainTokenID: updatedToken.id,
            previous_owned: currentOwnedTokenAmount?.toString() ?? false,
            current_owned:
              this.app.state.trading.offchainTokens[updatedToken.id]
                ?.tokenAmount ?? 0,
            amount_divested,
            amount_sold: tokenAmount.toString(),
            memeoffchainToken_price: Big(amount_divested)
              .div(tokenAmount)
              .toString(),
            total_holders: updatedToken.holderCount - 1, // exclude self
          });
          this.app.invoke.asyncTakePortfolioSnapshots();
          this.refetchPortfolio('forceUpdate');
          this.app.ui.onOffchainTokenTxSuccess({
            offchainTokenId: updatedToken.id,
            offchainTokenImage: updatedToken.image,
            offchainTokenName: updatedToken.name,
            offchainTokenDescription: updatedToken.description.description,
            txAmount: amount_divested,
            txType: 'sell',
          });
        }
        this.updateAmount(Big(0));
      }
      return response;
    } catch (e: any) {
      this.app.ui.showError({
        message: e.message,
      });
    }
  };

  private updateTx = (
    selectedOffchainToken: Pick<TradingToken, 'supply' | 'id'>,
    amount?: Big,
  ) => {
    if (!this.purchaseMode) {
      return;
    }

    if (this.purchaseMode === 'buy') {
      const buyTx = {
        currencyAmount: amount ?? Big(0),
        currentSupply: Big(selectedOffchainToken.supply),
      };

      this.state.tx = {
        txType: this.purchaseMode,
        receive: getBuyTxEstimate(buyTx),
        send: buyTx.currencyAmount,
        isValid: getIsBuyTxValid(this.app.state, buyTx),
      };
    } else {
      const sellTx = {
        tokenAmount: amount ?? Big(0),
        currentSupply: Big(selectedOffchainToken.supply),
      };

      this.state.tx = {
        txType: this.purchaseMode,
        receive: getCoinAmountForTokenSell(
          sellTx.currentSupply,
          sellTx.tokenAmount,
        ),
        send: sellTx.tokenAmount,
        isValid: getIsSellTxValid(
          this.app.state,
          selectedOffchainToken.id,
          sellTx,
        ),
      };
    }
  };

  private getNotification = async () => {
    // alternating between new offchainTokens and last transaction
    const field =
      this.state.notifCount % 2 === 0 ? 'lastTx.createdAt' : 'availableAt';
    this.state.notifCount += 1;

    const notification = await this.app.asyncGetters.getHotOffchainToken({
      field,
      unwantedTokenIds: this.state.pastNotificationIds,
    });

    if (this.notifScheduler === null) {
      // was deactivated during the fetch
      return;
    }

    this.state.notification = notification;

    if (notification !== undefined) {
      this.state.pastNotificationIds.push(notification.id);

      // The notification will loop through at least 16 notifications
      // in theory updates to cards should happen so frequently that loops wont happen
      // if not enough items exists, then notifications do not even loop
      const minNotifLoop = 16;
      if (this.state.pastNotificationIds.length > minNotifLoop) {
        this.state.pastNotificationIds = this.state.pastNotificationIds.slice(
          this.state.pastNotificationIds.length - minNotifLoop,
        );
      }
    }

    this.sendEvent(MemesEvents.TradingOnTxUpdate);

    const timeVariation = 1.0;
    const delay =
      offchainTradingNotifDelay *
      (1 - timeVariation / 2 + timeVariation * Math.random());
    this.notifScheduler = setTimeout(() => this.getNotification(), delay);
  };

  private scheduleNotifications = () => {
    this.state.notifCount = 0;
    this.notifScheduler = setTimeout(
      () => this.getNotification(),
      offchainTradingNotifDelay,
    );
  };

  public setNotificationActivity = (active: boolean) => {
    if (active) {
      if (this.notifScheduler !== null) {
        // already active
        return;
      }

      this.scheduleNotifications();
      return;
    }

    if (this.notifScheduler !== null) {
      // active, deactivate
      this.state.notifCount = -1;
      clearTimeout(this.notifScheduler);
      this.notifScheduler = null;
    }
  };

  public setMaxSlippage = (value: number) => {
    this.app.invoke.setMaxSlippage({ value });
    this.sendEvent(MemesEvents.TradingOnTxUpdate);
  };

  public refetchPortfolio = async (
    forceUpdate: Optional<'forceUpdate'> = undefined,
  ) => {
    if (forceUpdate || !this.memeFetchedAtLeastOnce) {
      await this.app.memes.myMemes.refreshTargetList('Owned');

      this.memeFetchedAtLeastOnce = true;
    }

    const portfolioValueBefore = this.state.portfolioValue;
    const portfolioValue = computePortfolioValue(
      this.app.state,
      this.app.memes.myMemes.getList('Owned').getSearchResults(),
    ).toNumber();

    const hasChanged = portfolioValueBefore !== portfolioValue;

    this.state.portfolioValue = portfolioValue;

    if (hasChanged) {
      this.sendEvent(MemesEvents.TradingOnPortfolioUpdate);
    }
  };

  public onTokenCreated = () => {
    this.state.purchaseMode = 'buy';
    this.updateTx({
      id: 'not_used_for_buy',
      supply: Big(0).toString(),
    });
  };

  // ================================================
  // Public Getter Methods
  // ================================================

  public getHolders = async (): Promise<TradingTokenHolder[]> => {
    const currentOffchainToken = this.app.memes.currentMeme.token;
    if (!currentOffchainToken) {
      return [];
    }

    const holders = await this.app.asyncGetters.getOffchainTokenHolders({
      offchainTokenIds: [currentOffchainToken.id],
    });

    // using player state to display player's holder status in tab,
    // this fixes update
    const userHolder: TradingTokenHolder[] = [];
    const offchainTokens = this.app.state.trading.offchainTokens;
    const ownedToken = offchainTokens[currentOffchainToken.id];
    if (ownedToken && Big(ownedToken.tokenAmount).gt(0)) {
      userHolder.push({
        userId: currentOffchainToken.creatorId,
        offchainTokenCount: Big(ownedToken.tokenAmount),
        offchainTokenMarketShare: getPct(
          Big(ownedToken.tokenAmount).toNumber(),
          Big(currentOffchainToken.supply).toNumber(),
        ),
        image: this.app.state.profile.photo,
        name: this.app.state.profile.name,
        type: Boolean(ownedToken.productId) ? 'creator' : 'normal',
      });
    }

    const otherTokenHolders = holders
      .filter((holder) => {
        return holder.id !== this.app.state.id;
      })
      .map((holder) => {
        const offchainTokens = holder.offchainTokens;
        const offchainToken = offchainTokens.find(
          (token) => token?.id === currentOffchainToken.id,
        )!;

        const tokenAmount = offchainToken.tokenAmount ?? 0;

        return {
          userId: holder.id,
          offchainTokenCount: Big(tokenAmount),
          offchainTokenMarketShare: getPct(
            Big(tokenAmount).toNumber(),
            Big(currentOffchainToken.supply).toNumber(),
          ),
          image: holder.photo,
          name: holder.name,
          type: offchainToken.isCreator ? 'creator' : 'normal',
        };
      }) as TradingTokenHolder[];

    return userHolder.concat(otherTokenHolders);
  };

  public getTxFeePercentage = () => {
    if (!this.state.tx) {
      return -1;
    }
    return txConfig.fee;
  };

  /**
   *
   * @returns Integer
   */
  public getTxFee = () => {
    if (!this.state.tx) {
      return {
        feePercentage: 0,
        feeAmount: 0,
      };
    }

    const { txType, send, receive } = this.state.tx;
    const isBuy = txType === 'buy';

    const price = isBuy ? send : receive;

    const feePercentage = txConfig.fee;
    const basePrice = price.div(1 + feePercentage);
    const txFee = Math.ceil(price.minus(basePrice).toNumber());

    return {
      feePercentage: feePercentage,
      feeAmount: txFee,
    };
  };

  public getOffchainTokenPricePoints = (
    offchainToken: TradingToken,
    sliceConfig: SliceConfig | null,
  ) => {
    // console.error('offchainToken', offchainToken)
    // const listing = this.listingByCategory[this.listingCategory]?.listing || [];
    // const offchainTokenSearch = listing.find((token) => {
    //   return token.offchainTokenId === offchainToken.id;
    // })
    // console.error('offchainToken search res', offchainTokenSearch)
    return getOffchainTokenPricePoints(offchainToken, sliceConfig);
  };

  public getPortfolioPricePoints = async (sliceConfig: SliceConfig | null) => {
    await this.app.memes.myMemes.refreshTargetList('Owned');

    return getPortfolioPricePoints(
      this.app.state,
      sliceConfig,
      this.app.memes.myMemes.getList('Owned').getSearchResults(),
    );
  };

  public getOffchainTokenHoldCount = () => {
    return getOffchainTokenHoldCount(this.app.state);
  };

  public getPortfolioRoi = () => {
    return getPortfolioRoi(this.app.state, this.portfolioValue);
  };

  public getOffchainTokenHoldingStats = (
    offchainTokenId: string,
    tokenHolding: OwnedOffchainToken,
  ) => {
    return getOffchainTokenHoldingStats(
      offchainTokenId,
      this.app.memes.myMemes.getList('Owned').getSearchResults(),
      tokenHolding,
    );
  };
}
