import './TradingTokenItemProfileFarming.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName } from '../../../../data/utils';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { FarmingSearchResult } from '../../../../data/Controllers/ProfileController';
import { ClickerEvents } from '../../../../data/Controllers/ClickerController';

interface Props {
  offchainTokenData: FarmingSearchResult;
}

export const TradingTokenItemProfileFarming = ({
  offchainTokenData,
}: Props) => {
  const { t } = useTranslation();

  const { id: offchainTokenId, profile } = offchainTokenData;

  const active = Boolean(app.views.ProfilePage.visible && profile);

  useAppUpdates({
    id: 'TradingTokenItemProfileFarming',
    listener: app.clicker.attachEventListener(ClickerEvents.onGameStateUpdate),
    dep: active,
  });

  const farming = app.profile.getFarmingData(offchainTokenId);

  // console.log('TradingTokenItemProfileFarming', { farming, profile, offchainTokenId, offchainTokenData });

  if (!farming || !active) {
    return null;
  }

  const { state, progress, points } = farming;

  const { ticker, name, image } = profile;

  // retrieving data from profile controller
  const myToken = app.profile.getTokenHolding(offchainTokenId);

  const holdingStats = app.profile.getOffchainTokenHoldingStats(
    offchainTokenId,
    myToken,
  );

  const marketCap = holdingStats?.marketCap || 0;

  const isFarming = state === 'Farming';
  const isClaiming = state === 'Ready to Claim';

  const onTapClaimPoints = () => {
    if (!isClaiming) {
      return;
    }
    app.tmg.farm?.claimReward(offchainTokenId);
  };

  const onShowOffchainTokenDetails = async () => {
    app.nav.goToUserMemeToken('Farming', offchainTokenId, 'profile-list');
  };

  return (
    <div
      className={`trading-token-item-profile-farming ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="trading-token-item-profile-farming-container">
        <div className="trading-token-item-profile-farming-image">
          <img src={image} />
        </div>

        <div className="trading-token-item-profile-farming-content">
          <div className="left">
            <div className="name-container">
              <div className="tickername">{ticker}</div>
              <div className="tokenname">{name}</div>
            </div>

            {/* market cap */}
            <div className="trading-token-item-market-cap">
              <div className="trading-token-item-market-cap-label">
                {t('trading_token_item_market_cap')}
              </div>
              <div className="trading-token-item-market-cap-value">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="label">
                  {largeIntegerToLetter(marketCap, 3)}
                </div>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="price-container">
              <div className="farming-coin">
                <img src={image} />
              </div>
              {/* valuation */}
              <div className="value">
                {formatPrice(points)} {t('tiktok_points')}
              </div>
            </div>

            <div className="farming-container">
              <div
                className={`btn farming-label ${isClaiming && 'yellow'}`}
                onClick={onTapClaimPoints}
              >
                {isClaiming
                  ? t('tiktok_farming_claimnow')
                  : t('tiktok_farming')}
              </div>
              <div className="farming-icon">
                <img src={assets.icon_farming_logo_small} />
              </div>
              {isFarming && (
                <div className="farming-label">{`${progress?.hoursLeft}h ${progress?.minutesLeft}m`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
