import { QueryFilter } from '@play-co/replicant';
import { createAsyncGetters } from '../../createAsyncGetters';
import {
  getBuyEstimate,
  getBuyPointEstimate,
  getOffchainToken,
  getSellEstimate,
} from './offchainTrading.getters';
import {
  TradingIndexSchema,
  TradingSearchResult,
} from './offchainTrading.properties';
import {
  offchainTokenSearchCount,
  season2StartTime,
} from './offchainTrading.ruleset';
import { IAPConfig } from './types';
import Big from 'big.js';
import { OffchainTokenStatus } from './offchainTrading.schema';

export type TradingTokenSearchField = 'availableAt' | 'supply' | 'hotTxsCount';

export const offchainTradingAsyncGetters = createAsyncGetters({
  getOffchainToken: async (
    { offchainTokenId }: { offchainTokenId: string },
    api,
  ) => {
    const offchainTokenSharedState =
      await api.sharedStates.offchainTrading.fetch(offchainTokenId);
    return (
      offchainTokenSharedState &&
      getOffchainToken(offchainTokenSharedState.global, offchainTokenId)
    );
  },

  // IAP
  getInvoiceLink: async (payload: IAPConfig, api) => {
    return api.telegram.createInvoiceLink(payload);
  },
  getPurchaseConfirmed: async ({ productId }: { productId: string }, api) => {
    const unconsumedPurchases = await api.purchases.getUnconsumedPurchases();
    return unconsumedPurchases[productId];
  },

  getHotOffchainToken: async (
    {
      field,
      unwantedTokenIds,
    }: {
      field: 'availableAt' | 'lastTx.createdAt';
      unwantedTokenIds: string[];
    },
    api,
  ) => {
    const results = (
      await api.sharedStates.offchainTrading.search({
        where: {
          id: {
            isNotOneOf: unwantedTokenIds,
          },
          status: {
            isAnyOf: [
              OffchainTokenStatus.Moderated,
              OffchainTokenStatus.ModeratedOS,
            ],
          },
        },
        sort: [{ field, order: 'desc' }],
        limit: 1,
      })
    ).results;

    if (results.length === 0) {
      return;
    }

    return results[0] as TradingSearchResult;
  },

  getOffchainTokensFromOpenSearch: async (
    searchParams: {
      offchainTokenIds: string[];
      status?: string[];
      availableAt?: number;
    },
    api,
  ) => {
    let results: TradingSearchResult[] = [];
    results = (
      await api.sharedStates.offchainTrading.search({
        where: {
          id: {
            isOneOf: searchParams.offchainTokenIds,
          },
          status: {
            isAnyOf: searchParams.status
              ? searchParams.status
              : [
                  OffchainTokenStatus.Created,
                  OffchainTokenStatus.Moderated,
                  OffchainTokenStatus.ModeratedOS,
                  OffchainTokenStatus.Reported,
                ],
          },
          availableAt: {
            greaterThanOrEqual: searchParams.availableAt || 0,
          },
        },
        limit: searchParams.offchainTokenIds.length,
      })
    ).results;
    return results;
  },

  searchOffchainTokens: async (
    {
      searchString,
      field,
      lessThan,
      availableAfterIfEqual,
      limit = offchainTokenSearchCount,
      randomSort = false,
    }: {
      searchString: string | undefined;
      field: TradingTokenSearchField;
      lessThan?: number;
      availableAfterIfEqual?: number | undefined;
      limit?: number;
      randomSort?: boolean;
    },
    api,
  ) => {
    let results: TradingSearchResult[] = [];

    let conditions: QueryFilter<TradingIndexSchema>[] = [];

    const lessThanCondition: {
      supply?: { lessThan: number };
      availableAt: { lessThan?: number; greaterThanOrEqual: number };
      hotTxsCount?: { lessThan: number };
    } = {
      availableAt: {
        greaterThanOrEqual: season2StartTime,
      },
    };

    conditions.push(lessThanCondition);

    if (lessThan !== undefined) {
      if (field === 'availableAt' || field === 'hotTxsCount') {
        lessThan = Math.round(lessThan);
      }

      if (field === 'availableAt') {
        lessThanCondition[field].lessThan = lessThan;
      } else {
        lessThanCondition[field] = { lessThan: lessThan };
      }

      if (availableAfterIfEqual !== undefined && field !== 'availableAt') {
        const equalityCondition: {
          supply?: { between: [number, number] };
          availableAt: { greaterThan: number };
          hotTxsCount?: { between: [number, number] };
        } = {
          availableAt: {
            greaterThan: Math.max(season2StartTime, availableAfterIfEqual),
          },
        };

        equalityCondition[field] = { between: [lessThan, lessThan] };

        conditions.push(equalityCondition);
      }
    }

    // add the moderation constraint
    conditions.forEach((condition) => {
      condition.status = {
        isAnyOf: [
          OffchainTokenStatus.Moderated,
          OffchainTokenStatus.ModeratedOS,
          OffchainTokenStatus.Reported,
        ],
      };
    });

    if (searchString) {
      // add the search constraint
      conditions = conditions.reduce((conditionsWithSearch, condition) => {
        conditionsWithSearch.push({
          ...condition,
          profile: { name: { matchesAnyOf: [searchString] } },
        });

        conditionsWithSearch.push({
          ...condition,
          profile: { creatorName: { matchesAnyOf: [searchString] } },
        });

        return conditionsWithSearch;
      }, [] as QueryFilter<TradingIndexSchema>[]);
    }

    type SearchProps = Parameters<
      typeof api.sharedStates.offchainTrading.search
    >[0];

    const searchProps: SearchProps = {
      // @note: not sure why type checker complains
      // @ts-ignore
      where: conditions,
      sort: randomSort
        ? 'random'
        : [
            { field, order: 'desc' },
            { field: 'availableAt', order: 'asc' },
          ],
      limit,
    };

    results = (await api.sharedStates.offchainTrading.search(searchProps))
      .results;

    return results;
  },

  getBuyOffchainTokenEstimate: async (
    {
      offchainTokenId,
      currencyAmount,
    }: { offchainTokenId: string; currencyAmount: Big },
    api,
  ) => {
    const offchainTokenShareState =
      await api.sharedStates.offchainTrading.fetch(offchainTokenId);
    if (offchainTokenShareState) {
      return getBuyEstimate(offchainTokenShareState.global, currencyAmount);
    }
    return undefined;
  },

  getBuyOffchainTokenWithPointsEstimate: async (
    {
      offchainTokenId,
      currencyAmount,
      relativePointShare,
    }: {
      offchainTokenId: string;
      currencyAmount: Big;
      relativePointShare: number;
    },
    api,
  ) => {
    const offchainTokenSharedState =
      await api.sharedStates.offchainTrading.fetch(offchainTokenId);
    if (offchainTokenSharedState) {
      return getBuyPointEstimate(
        offchainTokenSharedState.global,
        currencyAmount,
        relativePointShare,
      );
    }
    return undefined;
  },
  getSellOffchainTokenEstimate: async (
    {
      offchainTokenId,
      tokenAmount,
    }: { offchainTokenId: string; tokenAmount: Big },
    api,
  ) => {
    const offchainTokenShareState =
      await api.sharedStates.offchainTrading.fetch(offchainTokenId);
    if (offchainTokenShareState) {
      return getSellEstimate(offchainTokenShareState.global, tokenAmount);
    }
    return undefined;
  },
  getMyOffchainTokens: async (
    { userId, ownedTokenIds }: { userId: string; ownedTokenIds: string[] },
    api,
  ) => {
    const createdByMe = api.sharedStates.offchainTrading.search({
      where: {
        profile: {
          creatorId: {
            isAnyOf: [userId],
          },
        },
        status: {
          isAnyOf: [
            OffchainTokenStatus.Moderated,
            OffchainTokenStatus.ModeratedOS,
          ],
        },
      },
      limit: 100,
    });

    const ownedByMe = api.sharedStates.offchainTrading.search({
      where: {
        id: {
          isOneOf: ownedTokenIds,
        },
        status: {
          isAnyOf: [
            OffchainTokenStatus.Moderated,
            OffchainTokenStatus.ModeratedOS,
          ],
        },
      },
      limit: ownedTokenIds.length,
    });

    return {
      created: (await createdByMe).results,
      owned: (await ownedByMe).results,
    };
  },
});
