import { assets } from '../../../assets/assets';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class FreeGiftRocketman extends TutorialModule {
  id = 'FreeGiftRocketman' as 'FreeGiftRocketman';
  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Normal,
      btnEarn: ElementUIState.Normal,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Normal,
    });

    await waitFor(300);

    this.app.ui.drawer.show({
      id: 'generic',
      opts: {
        image: assets.tutorial_free_gift,
        title: t('tut_drawer_giftrocket_title'),
        subtitle: t('tut_drawer_giftrocket_description'),
        onClose: () => this.closeDrawerAndContinue(),
        buttons: [
          {
            cta: t('tut_drawer_giftrocket_cta'),
            onClick: async () => {
              this.closeDrawerAndContinue();
            },
          },
        ],
      },
    });

    return super.execute().then(() => {});
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_gift_prompt_rocket_fuel',
    };
  }
}
