import { createAsyncGetters } from '../../createAsyncGetters';

export const playerAsyncGetters = createAsyncGetters({
  getOffchainTokenHolders: async (
    { offchainTokenIds, count }: { offchainTokenIds: string[]; count?: number },
    api,
  ) => {
    return (
      await api.searchPlayers({
        where: {
          offchainTokens: {
            id: {
              isAnyOf: offchainTokenIds,
            },
          },
        },
        limit: count ?? 100,
        sort: [{ field: 'offchainTokens.tokenAmount', order: 'desc' }],
      })
    ).results;
  },
  getPlayerComputedProps: async ({ userId }: { userId: string }, api) => {
    const players = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: [userId],
          },
        },
        limit: 1,
      })
    ).results;

    const player = players && players[0];
    if (!player) {
      return;
    }

    return player;
  },
  getPlayerTeamId: async ({ userId }: { userId: string }, api) => {
    const players = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: [userId],
          },
        },
        limit: 1,
      })
    ).results;

    const player = players && players[0];
    if (!player) {
      return;
    }

    return player.teamId;
  },
  getFriendUsernameById: async ({ userId }: { userId: string }, api) => {
    const players = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: [userId],
          },
        },
        limit: 1,
      })
    ).results;

    const player = players && players[0];
    if (!player) {
      return;
    }

    return player.name;
  },
  getUserState: async ({ userId }: { userId: string }, api) => {
    const fs = await api.fetchStates([userId]);
    return fs[userId]?.state;
  },
});
