import { getMyOffchainTokenIds } from '../features/offchainTrading/offchainTrading.getters';
import { updateStatus } from '../features/offchainTrading/offchainTrading.utils';
import {
  DEFAULT_FLAGGED_IMAGE,
  season2StartTime,
} from '../features/offchainTrading/offchainTrading.ruleset';
import { Migrator } from './types';

/*
  refund any tokens that were moderated as deleted, set their status to reported
  could be removed after all deleted tokens have been updated to reported.
*/
export const moderatedTokensFromDeleteToReported: Migrator = async (
  state,
  api,
) => {
  // get tokens with deleted status
  const offchainTokens = state.trading.offchainTokens;
  const myTokenIds = getMyOffchainTokenIds(state).filter((tokenId) => {
    const token = offchainTokens[tokenId];
    return !!token.productId;
  });

  if (myTokenIds.length === 0) {
    return;
  }

  const myTokens = await api.asyncGetters.getOffchainTokensFromOpenSearch({
    offchainTokenIds: myTokenIds,
    status: ['deleted'],
    availableAt: season2StartTime,
  });
  const deletedTokenIds = myTokens.map((token) => token.id);

  if (deletedTokenIds.length === 0) {
    return;
  }

  // update token status to reported
  await updateStatus(api, deletedTokenIds, 'reported', DEFAULT_FLAGGED_IMAGE);
};
