import { AppController } from '../../Controllers/AppController';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class IntroducingClicker extends TutorialModule {
  private energy = 0;

  private tapLimit;

  stateUpdateId = 'startEnergy';

  constructor(app: AppController, taps = 5) {
    super(app);
    this.tapLimit = taps;
  }

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
      league: '1',
    });

    this.app.ui.setClickerUIState({
      btnLeague: ElementUIState.Inactive,
      footer: ElementUIState.Hidden,
      energyGauge: ElementUIState.Hidden,
      btnBooster: ElementUIState.Hidden,
      btnTeam: ElementUIState.Hidden,
    });

    this.app.tutorial.showTooltip({
      text: t('tut_tooltip_tap'),
      origin: { x: '50%', y: '50%' },
      offset: { x: '0%', y: '30%' },
      align: 'top',
    });

    return super.execute().then(() => {
      this.app.ui.tooltip.hide();
    });
  };

  override onClickerTap = () => {
    this.energy += 1;
    console.warn('>>> number of taps', this.energy, '/', this.tapLimit);
    if (this.energy >= this.tapLimit) {
      this.onComplete();
    }
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_intro_tap_coin',
    };
  }
}
