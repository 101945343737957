import { TutorialModule } from './TutorialModule';

/**
 * This step just call state update via id and moves to the next
 */
export class FullEnergyState extends TutorialModule {
  stateUpdateId = 'rechargeEnergy';

  execute = async () => {
    setTimeout(() => {
      this.onComplete();
    });
    return super.execute();
  };
}
