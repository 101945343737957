import { useCallback } from 'react';
import { assets } from '../../../../assets/assets';
import { TxType } from '../../../../replicant/features/offchainTrading/types';
import { FarmingWidget } from '../components/FarmingWidget';
import { useTranslation } from 'react-i18next';

interface FeedMemeFooterProps {
  onTapPurchaseButton: (txType: TxType) => void;
}

export const FeedMemeFooter = ({
  onTapPurchaseButton,
}: FeedMemeFooterProps) => {
  const { t } = useTranslation();

  const onTap = useCallback(
    (txType: TxType) => () => {
      onTapPurchaseButton(txType);
    },
    [onTapPurchaseButton],
  );

  return (
    <div className="buysell-footer">
      <div className="buysell-footer-buttons">
        <div className="btn buysell-footer-button" onClick={onTap('buy')}>
          <img src={assets.icon_arrow_buy} />
          <div className="label">{t('tiktok_buy')}</div>
        </div>
        <div className="separator" />
        <div className="btn buysell-footer-button" onClick={onTap('sell')}>
          <img src={assets.icon_arrow_sell} />
          <div className="label">{t('tiktok_sell')}</div>
        </div>
      </div>
    </div>
  );
};
