import './TradingCreateInput.scss';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserScrolling } from '../../../../data/utils';
import { assets } from '../../../../assets/assets';

interface Props {
  title: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  isValid: boolean;
  required?: boolean;
}

export const TradingCreateInput = ({
  title,
  placeholder,
  value,
  onChange,
  required,
  isValid,
}: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<any>();
  const [isInputFocused, setInputFocused] = useState(false);

  const onInputFocus = (focused: boolean) => {
    setInputFocused(focused);

    // prevent scrolling while window open
    setUserScrolling(!focused);

    function setPageHeightStyle(heightStyle: string) {
      const page = document.querySelector(
        '.page.modal-team-list',
      ) as HTMLDivElement;

      if (page) page.style.minHeight = heightStyle;
    }

    setPageHeightStyle('unset');

    // reset scroll
    if (!focused) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }

    // =====================================================================================
  };

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className="trading-create-row">
      <div className="top">
        <div className="title">{title}</div>
        <div className="required">{required ? '*' : ''}</div>
      </div>

      <input
        className="trading-create-input"
        ref={inputRef}
        type="input"
        //
        inputMode="text"
        autoCapitalize="on"
        autoComplete="on"
        autoCorrect="on"
        enterKeyHint="done"
        //
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        autoFocus={false}
        onFocus={() => onInputFocus(true)}
        onBlur={() => onInputFocus(false)}
        onKeyDown={onKeyDown}
      />

      {isValid && inputRef?.current?.value.length > 0 && (
        <div className="icon-tick">
          <img src={assets.icon_green_tick} />
        </div>
      )}
    </div>
  );
};
