import { t } from 'i18next';
import { app } from '../../../../data/Controllers/AppController';
import { isIOS } from '../../../../data/device';
import { useEffect, useRef, useState } from 'react';
import { MemeFeedAdController } from '../../../../data/Controllers/Memes/MemeFeedAds';
import { assets } from '../../../../assets/assets';
import { generateInviteUrlWithPayload, invite } from '../../../../data/sharing';
import { copyToClipboard } from '../../../../data/utils';
import {
  REFERRAL_BONUS_PREMIUM,
  REFERRAL_BONUS_REGULAR,
} from '../../../../replicant/features/game/ruleset/referrals';
import { formatPrice } from '../../../../replicant/utils/numbers';

interface FeedAdProps {
  index: number;
  currentSlideIndex: number;
  adController: MemeFeedAdController;
}

export const FeedAd = ({
  index,
  currentSlideIndex,
  adController,
}: FeedAdProps) => {
  // ----------------------------------------------
  // if this item is out of rendering range,
  // avoid filling it with content in order to increase performance
  // note that the root container still needs to be rendered for carousel to work correctly
  const selected = index === currentSlideIndex;
  const distanceToSelection = index - currentSlideIndex;
  const renderZone = app.memes.currentList.getItemRenderZone(index);
  const shouldRenderLight = !selected && renderZone === 'Cold';

  const [progress, setProgress] = useState(100);
  const [muted, setMuted] = useState(true);
  // const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!selected) {
      setProgress(0);
      return;
    }

    if (adController.timeLeftUntilViewed === 0) {
      setProgress(0);
      return;
    }

    setProgress(100);

    const timer = setInterval(() => {
      const newProgress = Math.max(
        (100 * adController.timeLeftUntilViewed) / adController.lockInDuration,
        0,
      );
      setProgress(newProgress);
      if (newProgress <= 0) {
        clearInterval(timer);
      }
    }, 100);
    return () => clearInterval(timer);
  }, [adController, selected]);

  useEffect(() => {
    if (Math.abs(distanceToSelection) <= 4) {
      // preload the ad content
      adController.load();
    }
  }, [distanceToSelection]);

  const [showSuccess, setShowSuccess] = useState(false);

  if (shouldRenderLight) {
    return <div className={`feed-item ${isIOS() ? 'telegram-ios' : ''}`} />;
  }

  const adConfig = adController.memeAdConfig;
  const coverContent = adConfig.contentUrl;

  const handleLoadedData = async (
    e: React.SyntheticEvent<HTMLVideoElement>,
  ) => {
    if (selected) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 150));
        await (e.target as HTMLVideoElement).play();
        setMuted(false);
      } catch (error) {
        console.error('Failed to autoplay:', error);
      }
    }
  };

  const handleVideoPlay = () => {
    if (selected) setMuted(false);
  };

  const handleVideoPause = () => {
    setMuted(true);
  };

  const onClick = () => {
    if (adConfig.feature === 'invite') {
      app.track('PressInvite', { screen_location: 'mfa' });

      invite({
        screen_location: 'feed_ad', // feed_ad
        data: {
          feature: 'friend',
          $subFeature: 'mfa_invite',
        },
      });
    }
  };

  const onCopyLink = () => {
    app.track('CopyInviteLink', { screen_location: 'mfa' });

    const url = generateInviteUrlWithPayload({
      screen_location: 'mfa',
      data: {
        feature: 'friend',
        $subFeature: 'mfa_invite',
      },
    });

    copyToClipboard(url);

    // diplay 'copied' tooltip for a second
    setTimeout(() => {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 900);
    }, 250);
  };

  let description;

  const descriptionKey = adConfig.description;
  switch (descriptionKey) {
    case 'mfa_invite_description':
      description = t(descriptionKey, {
        regularReward: formatPrice(REFERRAL_BONUS_REGULAR),
        premiumReward: formatPrice(REFERRAL_BONUS_PREMIUM),
      });
      break;
    default:
      description = t(descriptionKey);
  }

  // ----------------------------------------------

  // full item if is meant to be rendered

  return (
    <div className={`feed-item ${isIOS() ? 'telegram-ios' : ''}`}>
      <div className="feed-item-media-container">
        {/* full screen image */}
        {adConfig.format === 'video' ? (
          // @todo - fixme on safari:
          // - the video plays very inconsistently
          // - the unmute never works the first time
          // - video caching does not work well and would get loaded multiple times
          <video
            // ref={videoRef}
            className="feed-item-video"
            autoPlay
            preload="auto"
            playsInline
            muted={!selected || muted}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onLoadedData={handleLoadedData}
          >
            <source src={coverContent} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="feed-item-image">
            <img src={coverContent} />
          </div>
        )}

        <div className="feed-ad-title-description">
          <div className="feed-ad-sponsored">
            <img
              src={assets.icon_megaphone}
              alt="icon"
              className="feed-ad-icon"
            />
            {t('mfa_sponsored')}
          </div>

          <div className="feed-ad-title">{t(adConfig.title)}</div>
          <div className="feed-ad-description">{description}</div>

          <div className="feed-buttons">
            <div className="btn btn-normal feed-ad-button" onClick={onClick}>
              {t(adConfig.ctaLabel)}
            </div>

            {adConfig.feature === 'invite' && (
              <div className="btn feed-ad-btn-copy" onClick={onCopyLink}>
                <img src={assets.copy_white} />

                {showSuccess && (
                  <div className="feed-ad-copy-success">
                    <div className="feed-ad-copy-success-icon">
                      <img src={assets.copy_success} />
                    </div>
                    <div className="copy-success-text">{t('mfa_copied')}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="feed-ad-countdown">
          <div
            className="feed-ad-progress-bar"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {/* bottom gradient */}
      {/* <div className="feed-item-bottom-gradient" /> */}
    </div>
  );
};
