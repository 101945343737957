import { Team } from '../../../data/types';
import { createAsyncGetters } from '../../createAsyncGetters';
import { TeamComputedProperties } from './teams.properties';
import { MAX_TEAM_MEMBERS_DISPLAY, MAX_TOP_TEAMS } from './teams.ruleset';

export const teamAsyncGetters = createAsyncGetters({
  getRecommendedTeams: async (_, api) => {
    // TODO: Logic for recommending teams.
    const query = await api.sharedStates.teams.search({
      where: {
        membersCount: {
          greaterThan: 0,
        },
      },
      limit: 100,
      sort: [{ field: 'membersCount', order: 'desc' }],
    });

    return query.results.map((team) => getTeamResponse(team));
  },

  searchTeams: async ({ searchString }: { searchString: string }, api) => {
    const query = await api.sharedStates.teams.search({
      where: {
        profile: {
          search: {
            matchesAnyOf: [searchString],
          },
        },
      },
      limit: 20,
    });

    return query.results.map((team) => getTeamResponse(team));
  },

  getTeamsLeaderboard: async (
    { limit }: { limit?: number },
    api,
  ): Promise<Team[]> => {
    const query = await api.sharedStates.teams.search({
      where: {
        membersCount: {
          greaterThan: 0,
        },
      },
      limit: Math.min(limit ?? MAX_TOP_TEAMS, MAX_TOP_TEAMS),
      sort: [{ field: 'score', order: 'desc' }],
    });

    return query.results.map((team, index) => {
      // Teams with the same score should have the same rank.
      while (query.results[index - 1]?.score === team.score) {
        index--;
      }
      return getTeamResponse(team, index);
    });
  },

  /**
   * Get team with members.
   */
  getTeamWithMembers: async (
    { teamId }: { teamId: string },
    api,
  ): Promise<Team> => {
    const { state, rank } = await teamAsyncGetters.getTeamWithRank(
      { teamId },
      api,
    );

    if (!state) {
      throw new Error('Team not found');
    }

    // Fetch members.
    const query = await api.searchPlayers({
      where: {
        teamId: {
          isAnyOf: [teamId],
        },
        banned: {
          is: false,
        },
      },
      limit: MAX_TEAM_MEMBERS_DISPLAY,
      sort: [{ field: 'score', order: 'desc' }],
    });

    const players = query.results.map((player, index) => ({
      name: player.name,
      photo: player.photo,
      score: player.score,
      rank: index + 1,
    }));

    return {
      id: teamId,
      ...state.profile,
      members: state.membersCount,
      score: state.score,
      players,
      rank: rank,
    };
  },

  getTeamWithRank: async ({ teamId }: { teamId: string }, api) => {
    const state = await api.sharedStates.teams.fetch(teamId);

    if (!state) {
      throw new Error('Team not found');
    }

    const teamsWithHigherScore = await api.sharedStates.teams.count({
      where: {
        score: {
          greaterThan: state.global.score,
        },
      },
      sort: [{ field: 'score', order: 'desc' }],
    });

    return {
      counters: state.counters,
      state: {
        ...state.global,
        score: state.global.score + state.counters.score.value, // `score` was migrated to a counter
      },
      rank: teamsWithHigherScore + 1,
    };
  },
});

/**
 * Generate a TeamResponse from a TeamComputedProperties object.
 */
type TeamResponse<T> = T extends undefined ? Omit<Team, 'rank'> : Team;
function getTeamResponse<T extends number | undefined>(
  computedProperties: TeamComputedProperties,
  index?: T,
): TeamResponse<T> {
  return {
    id: computedProperties.id,
    ...computedProperties.profile,
    members: computedProperties.membersCount,
    score: computedProperties.score,
    rank: Number.isNaN(Number(index)) ? undefined : (index || 0) + 1,
  } as TeamResponse<T>;
}
