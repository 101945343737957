import './ClickerDiamond.scss';
import { useState } from 'react';
import { assets } from '../../../../assets/assets';
import { useAppUpdates, app } from '../../../../data/Controllers/AppController';
import { isDesktop } from '../../../../data/device';
import { getUIStateClassName } from '../../../../data/utils';
import { addClickerRipple } from './addPointUp';
import { morseTimes } from '../../../../data/morseCode';
import { GlowingImage } from '../../../shared/GlowingImage/GlowingImage';
import { ClickerEvents } from '../../../../data/Controllers/ClickerController';

export const ClickerDiamond = () => {
  useAppUpdates({
    id: 'ClickerDiamond/appMode',
    listener: app.clicker.attachEventListener(ClickerEvents.onAppModeChange),
  });

  const [buttonPressed, setButtonPressed] = useState(false);
  const [tapStartTime, setTapStartTime] = useState(-1);

  const onTap = () => {
    if (app.clicker.morseCodeMode) {
      onTapStart();
      return;
    }
    addClickerRipple();
    app.clicker.onTap();

    try {
      Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
    } catch (error) {
      // sometimes a Java error makes the haptic feedback fail but it's no biggy
    }

    setButtonPressed(true);
    setTimeout(() => setButtonPressed(false), 100);
  };

  const onTapStart = () => {
    setTapStartTime(Date.now());
  };

  const onTouchEnd = () => {
    if (!app.clicker.morseCodeMode) {
      return;
    }

    const elapsedTime = Date.now() - tapStartTime;
    if (elapsedTime >= morseTimes.codeEndDelay) {
      return;
    }
    app.clicker.onMorseInput(elapsedTime > morseTimes.dash ? '-' : '.');
  };

  return (
    <div className="clicker-diamond">
      <div
        id="coin-container"
        className={`coin-container ${getUIStateClassName(
          app.ui.state.clicker.btnClicker,
        )}`}
      >
        <div className={`bg-radial ${app.ui.appColor}`} />
        <div
          id="coin"
          className={`coin ${buttonPressed && 'btn-animate'}`}
          onTouchStart={onTap}
          onTouchEnd={onTouchEnd}
          {...(isDesktop() ? { onClick: onTap } : {})}
        >
          <div
            className={`hitbox ${getUIStateClassName(
              app.ui.state.clicker.btnClicker,
            )}`}
          />
          <img
            className={`gem-circle ${
              app.clicker.morseCodeMode ? 'morse-code' : ''
            }`}
            src={assets.btn_clicker_circle}
            alt=""
          />

          <div className="gem-icon">
            <GlowingImage
              image={assets.league[app.clicker.league]}
              // image={assets.league[League.league7]}
              blur={30}
              intensity={0.25}
              size={'100%'}
              whiteGlow={true}
            ></GlowingImage>
          </div>

          {/* <img className="gem-icon" src={assets.league[app.league]} alt="" /> */}
        </div>
      </div>

      {/* This element is hidden, only used to copy the ripple within programatically */}
      {/* Note: limiting this to one ripple circle per tap. Feels cleaner and for performance reasons */}
      <div id="ripple-templates">
        <div className="ripple-circles">
          <div className="ripple-circle-1"></div>
        </div>
      </div>
    </div>
  );
};
