import { assets } from '../../../assets/assets';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

/**
 * Don't forget to call `Grant15kState` module as next step
 */
export class YourEnergyIsLow extends TutorialModule {
  id = 'YourEnergyIsLow' as 'YourEnergyIsLow';

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
      league: '1',
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,
      energyGauge: ElementUIState.Normal,

      footer: ElementUIState.Hidden,
      btnBooster: ElementUIState.Hidden,
      btnTeam: ElementUIState.Hidden,
    });

    this.app.ui.drawer.show({
      id: 'generic',
      hideClose: true,
      opts: {
        image: assets.sloth_teams,
        title: t('tut_drawer_energylow_title'),
        subtitle: t('tut_drawer_energylow_description'),
        buttons: [
          {
            cta: t('tut_drawer_energylow_cta'),
            onClick: () => {
              this.closeDrawerAndContinue();
            },
          },
        ],
      },
    });

    await waitFor(1000);

    return super.execute();
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_mine_coins_gift_dialog',
    };
  }
}
