import { useEffect } from 'react';
import { PowerUp } from '../../../../replicant/features/powerups/types';
import { PowerUpCard } from '../PowerUpCard/PowerUpCard';
import { app } from '../../../../data/Controllers/AppController';

interface Props {
  items: PowerUp[];
  onClick: (powerup: PowerUp) => void;
  track: string;
}

export const PowerUpList = ({ items, onClick, track }: Props) => {
  const { visible } = app.views.MinePage;

  /*
  MinePage component is always rendered, regardless of visibility,
  so any active PowerUpList tab will also be rendered.
  Also, in the current hierarchy, changing tabs only changes the content
  of PowerUpList and not create a whole new instance.
  */

  useEffect(() => {
    if (visible) {
      app.track('Mine_page_view', {
        page: track,
      });
    }
  }, [visible, track]);

  return (
    <div className="items-list">
      {items.map((item, index) => (
        <PowerUpCard
          key={index + '_' + item.id}
          powerUp={item}
          onClick={(powerup: PowerUp) => {
            onClick(powerup);
          }}
        />
      ))}
    </div>
  );
};
