import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { MutableState, State } from '../../schema';
import { TradingSearchResult } from './offchainTrading.properties';
import {
  curveConfig,
  pointTxConfig,
  tmgRuleset,
  txConfig,
} from './offchainTrading.ruleset';
import {
  PriceSlice,
  StatsSlice,
  TradingState,
  TradingTx,
} from './offchainTrading.schema';
import {
  TMGFarmingStatus,
  TradingOverview,
  TradingTokenListing,
} from './types';
import Big from 'big.js';
import { ReplicantServer } from '../../config';
import { OTTG } from '../game/player.schema';
import { getTimeLeft } from '../game/game.getters';
import { HOUR_IN_MS, MIN_IN_MS } from '../../utils/time';
import { tests } from '../../ruleset';

export function getCurvePrice(supply: Big) {
  return curveConfig.startPrice.mul(
    Math.exp(supply.mul(curveConfig.exponent).toNumber()),
  );
}

export function getSupplyFromCurvePrice(price: Big) {
  return Big(Math.log(price.div(curveConfig.startPrice).toNumber())).div(
    curveConfig.exponent,
  );
}

function getTokenAmountForCoinBuy(
  currentSupply: Big,
  coinsToSpend: Big,
  priceModifier = 1 + txConfig.fee,
) {
  let coinsToSpendValue = coinsToSpend;
  if (!(coinsToSpend instanceof Big)) {
    coinsToSpendValue = Big(coinsToSpend);
  }

  const adjustedCoinsToSpend = coinsToSpendValue
    .mul(curveConfig.exponent)
    // .div(curveConfig.startPrice.mul(txConfig.buyModifier).mul(priceModifier));
    .div(curveConfig.startPrice.mul(priceModifier));
  const term = adjustedCoinsToSpend.add(
    Math.exp(currentSupply.mul(curveConfig.exponent).toNumber()),
  );
  const tokenAmount = Big(1)
    .div(curveConfig.exponent)
    .mul(
      Big(Math.log(term.toNumber())).minus(
        currentSupply.mul(curveConfig.exponent),
      ),
    );
  return tokenAmount;
}

export function getGrossCoinAmountForTokenSell(
  currentSupply: Big,
  tokenAmount: Big,
) {
  let tokenAmountDiff = currentSupply.minus(tokenAmount);
  if (tokenAmountDiff.lt(0)) {
    tokenAmountDiff = currentSupply;
  }

  const pricing = curveConfig.startPrice.div(curveConfig.exponent);

  const curve = Big(
    Math.exp(curveConfig.exponent.mul(currentSupply).toNumber()),
  );

  const cadence = Big(
    Math.exp(
      curveConfig.exponent.mul(currentSupply.minus(tokenAmount)).toNumber(),
    ),
  );

  return pricing.mul(curve.minus(cadence));
}

// (1 - spread) * (1 - fee) * (constant / exponent) * (EXP(exponent * (total supply + amount to sell)) - EXP(exponent * total supply))
export function getCoinAmountForTokenSell(
  currentSupply: Big,
  tokensToSell: Big,
) {
  const grossCurrencyAmount = getGrossCoinAmountForTokenSell(
    currentSupply,
    tokensToSell,
  );
  // const netCurrencyAmount = grossCurrencyAmount.mul(
  //   txConfig.sellModifier.minus(txConfig.fee),
  // );
  const netCurrencyAmount = grossCurrencyAmount.mul(Big(1 - txConfig.fee));

  return netCurrencyAmount;
}

export function getMarketCap(supply: Big) {
  return getCurvePrice(supply).mul(supply);
}

export function getMyOffchainTokenIds(state: State) {
  return Object.keys(state.trading.offchainTokens);
}

export function getMyOffchainTokenTokenAmount(
  state: State,
  offchainTokenId: string,
) {
  return state.trading.offchainTokens[offchainTokenId]?.tokenAmount || '0';
}

export function getMyOffchainTokenPointsAccumulated(
  state: State,
  offchainTokenId: string,
) {
  return (
    state.trading.offchainTokens[offchainTokenId]?.pointsAccumulated || '0'
  );
}

function getOverview({
  details: { description },
  supply,
  holderCount,
}: TradingState): TradingOverview {
  let numericValueSupply = Big(supply);
  // @TODO: Put this in a const
  const shortDescription =
    description.length > 180
      ? `${description.substring(0, 180)}...`
      : description;
  return {
    shortDescription,
    marketCap: getMarketCap(numericValueSupply),
    numOfHolders: holderCount,
  };
}

function getDescription({
  details: { description, telegramChannelLink, telegramChatLink, twitterLink },
}: TradingState) {
  return {
    description,
    telegramChannelLink,
    telegramChatLink,
    twitterLink,
  };
}

export function getOffchainTokenListing(
  searchableOffchainToken: TradingSearchResult,
): TradingTokenListing {
  return {
    offchainTokenId: searchableOffchainToken.id,
    image: searchableOffchainToken.profile.image,
    name: searchableOffchainToken.profile.name,
    creatorName: searchableOffchainToken.profile.creatorName,
    creatorImage: searchableOffchainToken.profile.creatorImage,
    creatorId: searchableOffchainToken.profile.creatorId,
    ticker: searchableOffchainToken.profile.ticker,
    marketCap: getMarketCap(Big(searchableOffchainToken.supply)),
    priceChange: searchableOffchainToken.priceChange,
    lastTx: searchableOffchainToken.lastTx,
    shares: searchableOffchainToken.shares,
  };
}

export function getOffchainToken(
  state: TradingState & { createdAt: number },
  offchainTokenId: string,
) {
  const { image, name, creatorId, creatorName, ticker, creatorImage } =
    state.details;

  const curvePrice = getCurvePrice(Big(state.supply));

  return {
    id: offchainTokenId,
    image,
    name,
    creatorId,
    creatorName,
    creatorImage,
    ticker,
    // overview
    overview: getOverview(state),
    // description
    description: getDescription(state),
    // holders
    holderCount: state.holderCount,
    // transactions
    transactions: state.txs,
    // prices
    buyPrice: curvePrice,
    sellPrice: undefined,
    // supply
    supply: state.supply,
    // trends
    trends: state.trends,
    // stats
    stats: state.stats,
    // metrics
    changePerHour: -1, // is this applied for both sell and buy or would be one for each?,
    status: state.status,
    createdAt: state.createdAt,
    shares: state.shares,
  };
}

export type TradingToken = ReturnType<typeof getOffchainToken>;

export interface CanBuyOpts {
  currencyAmount: Big;
  tokenAmountEstimate: Big;
  driftPct: number;
}

export function getCanBuy(state: TradingState, opts: CanBuyOpts) {
  const { currencyAmount, tokenAmountEstimate, driftPct } = opts;
  const buyTokenAmount = getBuyEstimate(state, currencyAmount);
  const minBuyTokenAmount = tokenAmountEstimate.minus(
    tokenAmountEstimate.mul(driftPct - 1),
  );

  return buyTokenAmount.gte(minBuyTokenAmount);
}

export interface CanSellOpts {
  tokenAmount: Big;
  currencyAmountEstimate: Big;
  driftPct: number;
}

export function getCanSell(state: TradingState, opts: CanSellOpts) {
  const { tokenAmount, currencyAmountEstimate, driftPct } = opts;
  const sellPrice = getSellEstimate(state, tokenAmount);

  const minSellPrice = currencyAmountEstimate.minus(
    currencyAmountEstimate.mul(driftPct - 1),
  );

  return sellPrice.gte(minSellPrice);
}

/**
 * Use this function to get the estimate token amount the user can purchase given the
 * currencyAmount. The estimate deducts the transactions fee. Use optional opts to modify the behaviour
 * @param state
 * @param currencyAmount
 * @returns the token amount that can be bought with the currencyAmount
 */
export const getBuyEstimate = (state: TradingState, currencyAmount: Big) => {
  return getBuyTxEstimate({
    currencyAmount,
    currentSupply: Big(state.supply),
  });
};

export const getBuyPointEstimate = (
  state: TradingState,
  currencyAmount: Big,
  relativePointShare: number,
) => {
  return getBuyPointTxEstimate(
    {
      currencyAmount,
      currentSupply: Big(state.supply),
    },
    relativePointShare,
  );
};

/**
 * Use this function to get the estimate amount of currencyAmount the user can get given the
 * tokenAmount it wants to sell
 * @param state
 * @param tokenAmount
 * @returns
 */
export const getSellEstimate = (state: TradingState, tokenAmount: Big) => {
  return getCoinAmountForTokenSell(Big(state.supply), tokenAmount);
};

export const getCreateEstimate = (currencyAmount: Big) => {
  return getTokenAmountForCoinBuy(Big(0), currencyAmount);
};

interface SellTx {
  tokenAmount: Big;
  currentSupply: Big;
}

interface BuyTx {
  currencyAmount: Big;
  currentSupply: Big;
}

export const getBuyTxEstimate = (opts: BuyTx) => {
  return getTokenAmountForCoinBuy(opts.currentSupply, opts.currencyAmount);
};

export const getCoinToPointConversionRate = (relativePointShare: number) => {
  return (
    1 +
    pointTxConfig.maxModifier *
      Math.exp(-pointTxConfig.modifierSlope * relativePointShare)
  );
};

export const getBuyPointTxEstimate = (
  opts: BuyTx,
  relativePointShare: number,
) => {
  return getTokenAmountForCoinBuy(
    opts.currentSupply,
    opts.currencyAmount,
    getCoinToPointConversionRate(relativePointShare),
  );
};

export const getIsBuyTxValid = (userState: State, tx: BuyTx) => {
  return tx.currencyAmount.lte(userState.balance) && tx.currencyAmount.gt(0);
};

export const getIsSellTxValid = (
  userState: State,
  offchainTokenId: string,
  tx: SellTx,
) => {
  const offchainToken = userState.trading.offchainTokens[offchainTokenId];

  if (!offchainToken || !tx) {
    return false;
  }

  const hasSufficientTokens = Big(offchainToken.tokenAmount).gte(
    tx.tokenAmount,
  );

  const hasValidCoinAmount = getCoinAmountForTokenSell(
    tx.currentSupply,
    tx.tokenAmount,
  ).gte(1);

  return hasSufficientTokens && hasValidCoinAmount;
};

export const getTxWithinEstimate = (value: Big, estimate: Big, pct: number) => {
  const diff = value.minus(estimate).abs();
  const acceptableError = value.mul(pct - 1);
  return acceptableError.gte(diff);
};

export function getCanUserSell(
  state: State,
  cardId: string,
  tokenAmount: Big = Big(0),
) {
  const myTokenAmount = state.trading.offchainTokens[cardId]?.tokenAmount;
  if (!myTokenAmount) {
    return false;
  }
  return Big(myTokenAmount).gte(tokenAmount);
}

export async function confirmTransaction(
  api: ReplicantAsyncActionAPI<ReplicantServer>,
  userId: string,
  offchainTokenId: string,
  timestamp: number,
): Promise<
  | {
      transaction: TradingTx;
      offchainToken: TradingState & { createdAt: number };
    }
  | undefined
> {
  await api.flushMessages();

  const offchainTokenState = await api.sharedStates.offchainTrading.fetch(
    offchainTokenId,
  );

  const offchainToken = offchainTokenState?.global;
  // This should be impossible
  if (!offchainToken) {
    throw new Error(
      `Cannot find offchainToken ${offchainTokenId} for transaction confirmation at time ${timestamp}`,
    );
  }

  const failedTx = offchainToken.failedTxs.find((failedTx) => {
    return failedTx.createdAt === timestamp && failedTx.userId === userId;
  });

  if (failedTx) {
    api.sendAnalyticsEvents([
      {
        eventType: 'OffchainTxFailed',
        eventProperties: {
          offchainTokenId,
          reason: failedTx.reason ?? 'unknown',
          createdAt: failedTx.createdAt,
        },
      },
    ]);
    return;
  }

  const transaction = offchainToken.txs.find((tx) => {
    return tx.createdAt === timestamp && tx.userId === userId;
  });

  if (transaction) {
    return {
      transaction,
      offchainToken,
    };
  }

  api.sendAnalyticsEvents([
    {
      eventType: 'OffchainTxFailed',
      eventProperties: {
        offchainTokenId,
        reason: 'transaction missing',
        txCount: offchainToken.txs.length,
        failedTxCount: offchainToken.failedTxs.length,
        timestamp,
      },
    },
  ]);
}

export function getCurrencyInvested(state: State) {
  const ownedTokens = Object.values(state.trading.offchainTokens);
  return ownedTokens.reduce((totalInvested, ownedToken) => {
    return totalInvested.add(ownedToken.currencyInvested);
  }, Big(0));
}

export function computeHoldingsValue(
  state: State,
  tokenStatuses: TradingSearchResult[],
) {
  const ownedTokens = state.trading.offchainTokens;
  const holdingsValue = tokenStatuses.reduce((totalValue, tokenStatus) => {
    const ownedToken = ownedTokens[tokenStatus.id];
    if (!ownedToken) {
      return totalValue;
    }

    const coinValue = getGrossCoinAmountForTokenSell(
      Big(tokenStatus.supply),
      Big(ownedToken.tokenAmount),
    );

    return totalValue.add(coinValue);
  }, Big(0));

  return holdingsValue;
}

export function computePortfolioValue(
  state: State,
  tokenStatuses: TradingSearchResult[],
) {
  const holdingsValue = computeHoldingsValue(state, tokenStatuses);
  return holdingsValue.add(state.balance);
}

export async function getPortfolioValue(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const tokenIds = getMyOffchainTokenIds(state);
  const tokenStatuses = await api.asyncGetters.getOffchainTokensFromOpenSearch({
    offchainTokenIds: tokenIds,
  });

  return computePortfolioValue(state, tokenStatuses);
}

export function getTradingVolume(state: State) {
  const { currencySpent, currencyRecovered } = state.trading.offchain;
  return Big(currencyRecovered).add(currencySpent);
}

export function getProfitLoss(
  state: State,
  tokenStatuses: TradingSearchResult[],
) {
  const { currencySpent, currencyRecovered } = state.trading.offchain;
  const portfolioValue = computeHoldingsValue(state, tokenStatuses);
  return Big(currencyRecovered).add(portfolioValue).minus(currencySpent);
}

export function getPriceBackThen(pricePoints: PriceSlice[], then: number) {
  if (pricePoints.length === 0) {
    return curveConfig.startPrice;
  }

  let priceIdx = pricePoints.findIndex((pricePoint) => {
    return pricePoint.time > then;
  });

  if (priceIdx !== 0) {
    if (priceIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      priceIdx -= 1;
    } else {
      priceIdx = pricePoints.length - 1;
    }
  }

  return Big(pricePoints[priceIdx].price);
}

export function getStatsBackThen(
  stats: StatsSlice[],
  then: number,
): StatsSlice {
  if (stats.length === 0) {
    return {
      time: then,
      volume: '0',
      holderCount: 0,
    };
  }

  let statsIdx = stats.findIndex((statsSlice) => {
    return statsSlice.time > then;
  });

  if (statsIdx !== 0) {
    if (statsIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      statsIdx -= 1;
    } else {
      statsIdx = stats.length - 1;
    }
  }

  return stats[statsIdx];
}

export function getValueChange(priceNow: Big, priceThen: Big) {
  if (priceThen.eq(0)) {
    return 0;
  }
  return priceNow.div(priceThen).minus(1).toNumber();
}

export function getMeanHoldings(state: TradingState) {
  return Big(state.supply).div(state.holderCount);
}

export function getVariance(state: TradingState) {
  if (state.holderCount <= 0) {
    return Big(0);
  }

  const meanHoldings = getMeanHoldings(state);
  const sumOfHoldingsSquare = Big(state.sumOfHoldingsSquare);
  const variance = sumOfHoldingsSquare
    .div(state.holderCount)
    .minus(meanHoldings.pow(2));

  // Handle potential floating-point errors
  if (variance.lt(0)) {
    return Big(0);
  }

  return variance;
}

// "ceofficient of variation" is a measure of inequality
export function getCoefficientOfVariation(state: TradingState) {
  if (state.holderCount === 1) {
    // everything held by a single person
    return 1;
  }

  if (state.holderCount <= 0) {
    // no one holds the token = fairest distribution
    return 0;
  }

  const meanHoldings = getMeanHoldings(state);
  if (meanHoldings.lte(0)) {
    // note that it should not happen
    // in this case we default to 1 to avoid promoting "broken" tokens
    return 1;
  }

  const holdingsSdToMean = getVariance(state).sqrt();
  const c = holdingsSdToMean.div(meanHoldings).toNumber();
  // normalize, constant chosen arbitrarily to make variation coefficient feel good
  return c / (c + 10);
}

// ====================================================
// ==================== TAP GAME ======================
// ====================================================

function getTMG(state: State) {
  return state.trading.miniGames;
}

function getTMGTapping(state: State) {
  return state.trading.miniGames.tapping;
}

export function getTMGState(state: State) {
  return { ...getTMG(state).state };
}

function getTMGToken(state: State, tokenId: string) {
  return getTMG(state).state[tokenId];
}

function getTTGFarmingSpotsInUse(state: State) {
  const ttg = getTMGState(state);
  return Object.keys(ttg).reduce((res, key) => {
    if (ttg[key]?.miningStart !== undefined) {
      res.push({
        id: key,
        ...ttg[key],
      } as OTTG & { id: string });
    }
    return res;
  }, [] as (OTTG & { id: string })[]);
}

export function getTTGFarmingSpotsAvailable(state: State) {
  return tmgRuleset.farmingLimit - getTTGFarmingSpotsInUse(state).length;
}

export function getTTGIsFarming(state: State, tokenId: string) {
  return getTMGToken(state, tokenId)?.miningStart !== undefined;
}

export function getTTGFarmingTimeLeft(
  state: State,
  tokenId: string,
  now: number,
) {
  if (!getTTGIsFarming(state, tokenId)) {
    return -1;
  }
  const farmStart = getTMGToken(state, tokenId).miningStart ?? 0;
  const timeLeft = getTimeLeft(
    farmStart, // should never be undefined we check above
    tmgRuleset.farmingDuration,
    now,
  );
  return Math.max(0, timeLeft); // clamp negative time to 0
}

export function getTTGFarmingStatus(
  state: State,
  tokenId: string,
  now: number,
): TMGFarmingStatus {
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);
  if (timeLeft < 0) {
    return 'Idle';
  }
  return timeLeft > 0 ? 'Farming' : 'Ready to Claim';
}

export function getTTGFarmingPoints(
  state: State,
  tokenId: string,
  now: number,
) {
  if (!getTTGIsFarming(state, tokenId)) {
    return 0;
  }
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);

  const timeDiff = tmgRuleset.farmingDuration - timeLeft;

  return Math.ceil(timeDiff * tmgRuleset.farmingPointsPerMs);
}

export function getTTGCanFarm(state: State, tokenId: string, now: number) {
  const status = getTTGFarmingStatus(state, tokenId, now);
  return status === 'Idle' && getTTGFarmingSpotsAvailable(state) > 0;
}

export function getTTGFarmProgress(state: State, tokenId: string, now: number) {
  if (!getTTGIsFarming(state, tokenId)) {
    return {
      hoursLeft: 0,
      minutesLeft: 0,
    };
  }
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);
  const hoursLeft = Math.floor(timeLeft / HOUR_IN_MS);
  const timeDiff = timeLeft - hoursLeft * HOUR_IN_MS;
  const minutesLeft = Math.floor(timeDiff / MIN_IN_MS);
  return {
    hoursLeft,
    minutesLeft,
  };
}

export function getTTGKickback(state: State, tokenId: string) {
  const allTimeReferralKickBack =
    getTMGToken(state, tokenId)?.allTimeReferralKickBack ?? '0';
  return Big(allTimeReferralKickBack).toNumber();
}

export function getTTGCanClaim(state: State, tokenId: string, now: number) {
  return getTTGFarmingStatus(state, tokenId, now) === 'Ready to Claim';
}

export function getTMGTappingTickets(state: State, tokenId: string) {
  const tickets = getTMGToken(state, tokenId)?.tickets;
  if (tickets === undefined) {
    return getTMGTappingMaxTickets(state);
  }
  return tickets;
}

export function getTMGTappingMaxTickets(state: State) {
  const bucketId =
    state.ruleset.abTests[tests.TEST_LIMITED_MEME_TICKETS]?.bucketId;
  switch (bucketId) {
    case 'one_max_tickets':
      return 1;
    case 'two_max_tickets':
      return 2;
    default:
      return tmgRuleset.tappingMaxTickets;
  }
}

export function getTMGTappingSessionTaps(state: State) {
  return getTMG(state).tapping.sessionTaps;
}

export function getMyToken(state: State, tokenId: string) {
  return state.trading.offchainTokens[tokenId];
}

export function getTMGFarmingListing(state: State, now: number) {
  const farming = getTTGFarmingSpotsInUse(state);
  return farming.map(({ id }) => ({
    tokenId: id,
    state: getTTGFarmingStatus(state, id, now),
    progress: getTTGFarmProgress(state, id, now),
    points: getTTGFarmingPoints(state, id, now),
  }));
}

export function getTMGFarmingIsShowing(state: State) {
  const bucketId = state.ruleset.abTests[tests.TEST_HIDE_FARMING]?.bucketId;
  return bucketId != 'hide_farming';
}

export function getRandomTickr() {
  const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = Math.floor(Math.random() * 3) + 3; // Random length between 3 and 5
  let uniqueWord = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumeric.length);
    uniqueWord += alphanumeric[randomIndex];
  }
  return uniqueWord;
}

export function getRandomMemeImage() {
  // get random number between 1 and 22
  const randomImageNumber = Math.floor(Math.random() * 3900) + 1;
  // "https://ddw8nafke4m1l.cloudfront.net/user-assets/gemzcoin-bravo/trading/offchainTrading-ILLUM/65a4c1ab29bff9f87cfad360f027adde.png"
  return `https://notgemz.cms.gemz.fun/media/memes/${randomImageNumber}.png`;
}

export function hasSeenTokenDisplayChange(state: State) {
  return state.trading.hasSeenTokenDisplayChange ?? false;
}

export function hasTradedBefore(state: State) {
  return Big(state.trading.offchain.currencySpent).gt(0);
}

function getTappedMemeCount(state: State) {
  const memesWithPoints = Object.values(state.trading.miniGames.state);
  const tappedMemeCount = memesWithPoints.reduce(
    (tappedMemeCount, memeWithPoints) => {
      if (memeWithPoints.dailyScore > 0) {
        return tappedMemeCount + 1;
      }

      return tappedMemeCount;
    },
    0,
  );

  return tappedMemeCount;
}

export function hasReachedMemeTapLimit(state: State, tokenId: string) {
  if (state.trading.miniGames.state[tokenId]?.dailyScore === 0) {
    return false;
  }

  return getTappedMemeCount(state) >= tmgRuleset.tappingLimit;
}

export function hasReachedMemeCreationLimit(state: State) {
  const memeHoldings = Object.values(state.trading.offchainTokens);
  const createdMemeCount = memeHoldings.reduce(
    (createdMemeCount, memeHolding) => {
      if (memeHolding.productId !== undefined) {
        return createdMemeCount + 1;
      }

      return createdMemeCount;
    },
    0,
  );

  return createdMemeCount >= tmgRuleset.creationLimit;
}

export function hasReachedMemeHoldingLimit(state: State, tokenId: string) {
  if (state.trading.offchainTokens[tokenId] !== undefined) {
    return false;
  }

  const memeHoldingCount = Object.keys(state.trading.offchainTokens).length;

  return memeHoldingCount >= tmgRuleset.holdingLimit;
}

export function getTmgTicketTimestamp(state: State) {
  return state.trading.miniGames.tapping.ticketTimestamp;
}

/**
  Points received = getTokenAmountForCoinBuy(supply, Session Score / Price Modifier)
  Price Modifier = 1+(quantity*exp(-decay * share of points earned to date))
 */
export function scoreToPoints(
  currentSupply: Big,
  pointsAccumulated: string,
  pointsDistributed: string,
  score: number,
) {
  const shareOfDistribution = Big(pointsDistributed).eq(0)
    ? 1
    : Big(pointsAccumulated).div(pointsDistributed).toNumber();

  // @WARNING: DO NOT CHANGE THOSE CONSTANTS WITHOUT VERSIONING MESSAGE!
  const quantity = 0.25;
  const decay = 0.5;

  const priceModifier = 1 + quantity * Math.exp(-decay * shareOfDistribution);
  const equivalentCurrencyAmount = Big(score).div(priceModifier);
  const points = getTokenAmountForCoinBuy(
    currentSupply,
    equivalentCurrencyAmount,
    1,
  );

  return {
    points,
    equivalentCurrencyAmount,
  };
}

export function getActualTime(
  api: ReplicantAsyncActionAPI<any> | ReplicantEventHandlerAPI<any>,
) {
  return api.date.now() - api.getClockOffset();
}
