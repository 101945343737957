import { action, asyncAction } from '@play-co/replicant';
import { createActions } from '../../createActions';
import { CREATE_TEAM_COST } from './teams.ruleset';
import { TeamProfile } from './teams.schema';
import {
  addSearchToProfile,
  fixNegativeTeamScore,
  joinTeam,
  leaveTeam,
} from './teams.modifiers';
import { spendCoins } from '../game/game.modifiers';
import { getBalance } from '../game/game.getters';
import { ExpectedError } from '../../types';

export const teamActions = createActions({
  asyncCreateTeam: asyncAction(
    async (
      state,
      args: TeamProfile & { teamId: string },
      api,
    ): Promise<void | ExpectedError> => {
      // Check if player has enough balance to create a team.
      const now = api.date.now();
      if (getBalance(state, now) < CREATE_TEAM_COST) {
        return {
          expectedError: true,
          errorMessage: 'Insufficient balance to create a team.',
        };
      }

      // Deduct balance from player's account.
      spendCoins(state, CREATE_TEAM_COST, now);

      // Create shared state for team.
      const { teamId, ...profile } = args;
      api.sharedStates.teams.create(teamId);

      const profileWithSearch = {
        ...profile,
        search: profile.name,
      };

      // update profile.
      api.sharedStates.teams.postMessage.updateTeamProfile(teamId, {
        profile: profileWithSearch,
        timestamp: now,
      });

      // join the team.
      joinTeam(state, { teamId }, api);
    },
  ),

  joinTeam: action((state, args: { teamId: string }, api) => {
    // Leave the current team if the player is already in one.
    leaveTeam(state, api);

    // join the team.
    joinTeam(state, args, api);
  }),

  leaveTeam: action((state, _: void, api) => {
    leaveTeam(state, api);
  }),

  updateTeamProfile: action((state, profile: Partial<TeamProfile>, api) => {
    if (!state.team_id) return;

    api.sharedStates.teams.postMessage.updateTeamProfile(state.team_id, {
      profile: {
        ...profile,
        search: profile.name,
      },
      timestamp: api.date.now(),
    });
  }),

  getTeamWithMembersWithScoreFix: asyncAction(
    async (state, args: { teamId: string }, api) => {
      const team = await api.asyncGetters.getTeamWithMembers(args);
      if (!team) {
        return undefined;
      }

      fixNegativeTeamScore(state, { team }, api);

      return team;
    },
  ),

  migrateTeam: asyncAction(async (_state, args: { teamId: string }, api) => {
    const team = await api.asyncGetters.getTeamWithMembers(args);
    if (!team) {
      return;
    }
    await addSearchToProfile(api, { team });
  }),
});
