import './DrawerPowerUp/DrawerPowerUp.scss';
import { useState } from 'react';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { getEarnDiff } from '../../../replicant/features/powerups/getters';
import { sendMineCardGift } from '../../../data/sharing';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { PowerUp } from '../../../replicant/features/powerups/types';
import { formatPrice } from '../../../replicant/utils/numbers';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const DrawerMultipleGifts = () => {
  const { t } = useTranslation();
  const powerups: PowerUp[] = app.clicker.getMultipleGifts(5);

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderSettings = {
    ...settings,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index: number) => {
      setCurrentIndex(index);
    },
  };

  const onSendGift = () => {
    const powerup = powerups[currentIndex];
    if (powerup) {
      app.track('multigift_prompt', {
        card: powerup.id,
        gift_name: powerup.id,
      });
      sendMineCardGift({
        card: powerup.id,
        originFeature: 'daily',
        originSubFeature: 'daily_gifts',
      });
    }
    app.ui.drawer.close();
  };

  return (
    <div className="multiple-gifts-container">
      <DrawerContent powerups={powerups} sliderSettings={sliderSettings} />

      {/* buy button */}
      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={onSendGift}>
          {t(`send_gift`)}
        </div>
      </div>
    </div>
  );
};

interface DrawerContentProps {
  powerups: PowerUp[];
  sliderSettings: object;
}

const DrawerContent: React.FC<DrawerContentProps> = ({
  powerups,
  sliderSettings,
}) => {
  if (powerups.length === 1) {
    return (
      <div className="slider-card single-card">
        <SingleGiftCard powerup={powerups[0]} />
      </div>
    );
  }
  return (
    <Slider {...sliderSettings}>
      {powerups.map((powerup) => (
        <div className="slider-card-container" key={powerup.id}>
          <div className="slider-card">
            <SingleGiftCard powerup={powerup} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

interface SingleGiftCardProps {
  powerup: PowerUp;
}

const SingleGiftCard: React.FC<SingleGiftCardProps> = ({ powerup }) => {
  const { t } = useTranslation();
  return (
    <div className="drawer-layout powerup">
      <div className="drawer-header">
        {/* image */}
        <div className="drawer-header-icon">
          <img src={powerup?.details.image} alt={t(`free_gift_modal_name`)} />
        </div>
        {/* title and description */}
        <div className="drawer-header-title">{t(`${powerup.id}_name`)}</div>
        <div className="drawer-header-subtitle powerup">
          <div className="text">{t(`${powerup.id}_description`)}</div>
        </div>

        {/* earn */}
        <div className="puc-earn">
          <div className="puc-earn-coins">
            <div className="puc-earn-title">{t('earn_per_hr')}</div>
            <img src={assets.coin} />
            <div className="puc-earn-title golden">
              +
              {formatPrice(
                getEarnDiff(powerup?.id || '', app.state, app.now()),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={assets.arrow_left} />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={assets.arrow_right} />
    </div>
  );
};
