import { assets } from '../../../assets/assets';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

/**
 * Don't forget to call `FullEnergyState` module as next step
 */
export class FullEnergy extends TutorialModule {
  id = 'FullEnergy' as 'FullEnergy';
  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });
    this.app.ui.setTutorialUIState({ league: '1' });
    this.app.ui.setClickerUIState({ btnLeague: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnBooster: ElementUIState.Hidden });
    this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnFriends: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnMine: ElementUIState.Inactive });
    this.app.ui.setClickerUIState({ btnEarn: ElementUIState.Mystery });

    // this.app.tutorial.enableOverlay(true);

    this.app.nav.goToHomePage();
    setTimeout(() => {
      this.app.ui.popup.show({
        image: assets.boosters.FullEnergy,
        title: t('tut_popup_fullenergy_title'), // 'Play longer',
        description: t('tut_popup_fullenergy_description'), // 'Let’s increase your energy limit by 500 so you can play longer.',
        cta: t('tut_popup_fullenergy_cta'), // 'Get',
        onClick: () => {
          this.onComplete();
        },
      });
    }, 300);

    return super.execute().then(() => {
      this.app.ui.popup.close();
      // this.app.tutorial.enableOverlay(false);
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_boosts_energy_gift_dialog',
    };
  }
}
