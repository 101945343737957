import './DrawerPowerUp.scss';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { getEarnDiff } from '../../../../replicant/features/powerups/getters';
import { useEffect } from 'react';
import { formatPrice } from '../../../../replicant/utils/numbers';
import { PowerUpCardType } from '../../../../replicant/features/powerups/types';
import { DPUFooter } from './DPUFooter';
import { DPUHeader } from './DPUHeader';

export const DrawerPowerUp = () => {
  const { t } = useTranslation();

  const { view, close } = app.ui.drawer;

  const powerup = view.data?.props?.mine?.powerup;

  useEffect(() => {
    // Makes it so the back button closes the modal
    app.nav.showingBlockingModal();
  }, []);

  if (!powerup) {
    return null;
  }

  const hideScore =
    (powerup?.isGift && powerup?.level === 0) ||
    powerup?.type === PowerUpCardType.GIFT_ONLY;

  const description = t(`${powerup?.id}_description`);

  return (
    <>
      <div className="drawer-layout powerup">
        <div className="drawer-header">
          {/* image */}
          <DPUHeader powerup={powerup} />

          {/* title and description */}
          <div className="drawer-header-title">{t(`${powerup?.id}_name`)}</div>
          <div className="drawer-header-subtitle powerup">
            <div className="text">{description}</div>
          </div>

          {/* earn */}
          <div className="puc-earn">
            <div className="puc-earn-title">{t('pup_earn_hr')}</div>
            <div className="puc-earn-coins">
              <img src={assets.coin} />+
              {getEarnDiff(powerup?.id || '', app.state, app.now())}
            </div>
          </div>

          {/* price */}
          {!hideScore && (
            <div className="score">
              <div className="icon">
                <img src={assets.coin} />
              </div>
              <div className="text">{formatPrice(powerup?.cost)}</div>
            </div>
          )}
        </div>
      </div>

      {/* buy button */}
      <DPUFooter
        powerup={powerup}
        onDailyFound={view.data?.props?.mine?.onDailyFound}
      />
    </>
  );
};
