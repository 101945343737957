import './DrawerOutOfEnergy.scss';
import { assets } from '../../../assets/assets';
import {
  AppEvents,
  app,
  useAppUpdates,
} from '../../../data/Controllers/AppController';
import { ButtonInvite } from '../../shared/ButtonInvite/ButtonInvite';
import { useTranslation } from 'react-i18next';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { insertCoinIconIntoText } from '../../../data/utils';
import { getFriendReferralRewards } from '../../../replicant/features/game/game.getters';
import { League } from '../../../replicant/features/game/ruleset/league';

export const DrawerOutOfEnergy = () => {
  useAppUpdates({
    id: 'DrawerOutOfEnergy:AppEvents.onAdEnergyRewardChange',
    listener: app.attachEventListener(AppEvents.onAdEnergyRewardChange),
  });
  useAppUpdates({
    id: 'DrawerOutOfEnergy:UIEvents.TutorialUpdate',
    listener: app.ui.attachEventListener(UIEvents.TutorialUpdate),
  });

  app.track('out_of_energy', {
    energy_limit: app.clicker.energyLimit,
  });

  const { t } = useTranslation();

  const imageAsset = assets.sloth_earning_summary;

  const title = t('drawer_out_energy_invite_title');

  const amount = getFriendReferralRewards(
    app.state,
    app.now(),
    League.league1,
  ).premium;

  const subtitleTranslation = t('drawer_out_energy_invite_subtitle', {
    amount: amount,
  });
  const subtitle = insertCoinIconIntoText(subtitleTranslation);

  return (
    <div className="drawer-outofenergy">
      <div className="drawer-outofenergy-header">
        <div className="drawer-outofenergy-icon">
          <img src={imageAsset} />
        </div>
        <div className="drawer-outofenergy-title">{title}</div>
        <div
          className="drawer-outofenergy-subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></div>
      </div>

      <div className="footer-buttons">
        <ButtonInvite
          caption={t('friends_invite_button')}
          feature="friend"
          subFeature="drawer_out_of_energy_coin"
          showCopyBtn={true}
        />
      </div>
    </div>
  );
};
