import { analytics, AnalyticsProperties } from '@play-co/gcinstant';
import { PayloadProperties } from './analytics/properties';
import {
  app,
  gcinstant,
  payloadEncoder,
  Route,
} from './Controllers/AppController';
import {
  getMiniAppName,
  getReferralUrl,
} from '../replicant/features/chatbot/chatbot.private';
import { getSourceRealtimeStats } from '../replicant/features/game/game.getters';
import { tests } from '../replicant/ruleset';
import config from '../replicant/features/game/game.config';
import { getDayMidnightInUTC } from '../replicant/utils/time';
import { isIOS } from './device';
import { t } from 'i18next';
import { formatPrice } from '../replicant/utils/numbers';
import {
  REFERRAL_BONUS_PREMIUM,
  REFERRAL_BONUS_REGULAR,
} from '../replicant/features/game/ruleset/referrals';

function getInviteMessage() {
  return t('friendstab_invite_text', {
    regularReward: formatPrice(REFERRAL_BONUS_REGULAR),
    premiumReward: formatPrice(REFERRAL_BONUS_PREMIUM),
  });
}

function telegramShare(
  encodedPayloadKey: string,
  url?: string,
  inviteText?: string,
  inviteTitle?: string,
) {
  // TG share api
  if (inviteTitle) {
    // if there is a title, bake it into the text
    inviteText = `${inviteTitle}\n\n${inviteText}`;
  }

  const shareUrl = url
    ? app.getShareUrl(url, inviteText)
    : app.getReferralShareUrl(encodedPayloadKey, inviteText);
  // We can't tell if the invite was a success or not
  // GCInstant natively uses `postEvent` instead of `openTelegramLink`
  Telegram.WebApp.openTelegramLink(shareUrl);
}

async function share(
  encodedPayloadKey: string,
  url?: string,
  inviteText?: string,
  inviteTitle?: string,
) {
  // Note:
  // Android has navigator.share api natively, however, android WEBVIEW does not.
  // Therefore, android TG app will alwyas default to telegram api for sharing and inviting.

  const ruleset = app.replicant?.state.ruleset;
  const bucketId = ruleset.abTests[tests.TEST_SHARE_API]?.bucketId;

  const isSharingToTelegram =
    bucketId === 'telegram_share' || (bucketId === 'control' && !isIOS());

  if (isSharingToTelegram) {
    return telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
  }

  // use native navigator.share feature
  const referralUrl = url || getReferralUrl(encodedPayloadKey, app.state);
  try {
    await navigator.share({
      url: referralUrl,
      title: inviteTitle || t('friendstab_invite_title'),
      text: inviteText,
    });

    // here we can identify that a share was performed
  } catch (error) {
    if ((error as DOMException).name === 'AbortError') {
      // here we can identify that no share was performed due to user cancellation
      if (bucketId === 'navigator_share_fallback_to_telegram_share') {
        telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
      }
      return;
    }

    console.log({ referralUrl });
    console.error(
      '>>> invite - cannot use navigator.share',
      (error as DOMException).message,
    );

    return telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
  }
}

function makePayload(payload: {
  [key: string]: unknown;
  $channel: AnalyticsProperties.Channel;
  feature: string;
  $subFeature?: string;
  payload?: Record<string, any>;
}): PayloadProperties {
  const sourceRealtimeData = getSourceRealtimeStats(app.state, app.now());
  return {
    $realtimeIsPurchaser: false,
    $realtimeLTV: 0,
    ...payload,
    payload: {
      ...sourceRealtimeData,
      ...payload?.payload,
    },
    ...gcinstant.getPlatformPayloadData(),
  };
}

// TODO move into GCInstant
export async function invite(args: {
  screen_location: string;
  url?: string;
  data: {
    feature: string;
    $subFeature: string;
    originFeature?: string;
    originSubFeature?: string;
  };
}) {
  const appName = getMiniAppName(app.replicant.state);
  const payload = makePayload({
    ...args.data,
    $channel: 'INVITE',
    url: appName,
  });

  analytics.pushEvent('TrackInviteAsync', payload);

  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  const inviteText = args.url ? '' : getInviteMessage();

  await share(encodedPayloadKey, args.url, inviteText);
}

export function generateInviteUrlWithPayload(args: {
  screen_location: string;
  data: {
    feature: string;
    $subFeature: string;
  };
}) {
  const appName = getMiniAppName(app.state);
  const payload = makePayload({
    screen_location: args.screen_location,
    ...args.data,
    $channel: 'LINK',
    url: appName,
  });
  analytics.pushEvent('TrackInviteCopyAsync', payload);
  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);
  const url = getReferralUrl(encodedPayloadKey, app.state);

  // return `${url}\n\n${getInviteMessage()}`;

  return url;
}

export async function sendMineCardGift({
  card,
  originFeature,
  originSubFeature,
}: {
  card: string;
  originFeature?: string;
  originSubFeature?: string;
}) {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'gift',
    $channel: 'INVITE',
    card,
  });

  analytics.pushEvent('TrackMineGiftAsync', {
    ...payload,
    card,
    gift_name: card,
    originFeature,
    originSubFeature,
  });

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: {
      ...(payload as any).payload,
      card,
      createdAt: getDayMidnightInUTC(app.now()),
    },
  });

  // console.log({encodedPayloadKey});

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  const shareUrl = app.clicker.getMineGiftUrl(encodedPayloadKey, card);

  // At this point the web app closes and we can't tell if the invite was a success or not
  // GCInstant natively uses `postEvent` instead of `openTelegramLink`
  Telegram.WebApp.openTelegramLink(shareUrl);
}

export function generatePromoLink(card: string, origin: string = 'default') {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'promo',
    $channel: 'LINK',
    card: card,
    origin: origin,
  });

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: { card },
  });

  return `https://t.me/${config.botName}/tap?startapp=${encodedPayloadKey}`;
}

// Returns share url
export async function shareDeeplink(
  route: Route,
  {
    messageOpts,
    deeplinkOpts,
    skipShare,
  }: {
    messageOpts?: { title: string; text: string };
    deeplinkOpts?: Record<string, any>;
    skipShare?: boolean;
  },
) {
  const payload = makePayload({
    feature: 'deeplink',
    $subFeature: route,
    $channel: 'LINK',
  });

  const customPayload = {
    dlRoute: route,
    dlOpts: deeplinkOpts,
  };

  const { $key: encodedPayloadKey } = payloadEncoder.encode({
    ...payload,
    payload: {
      ...(payload as any).payload,
      ...customPayload,
    },
  });

  if (skipShare) {
    return getReferralUrl(encodedPayloadKey);
  }

  await share(
    encodedPayloadKey,
    undefined,
    messageOpts?.text,
    messageOpts?.title,
  );
}
