import './Shop.scss';
import { Page } from '../Page';
import { useEffect, useMemo } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { Booster, Buff } from '../../../data/types';
import { BoosterCard } from './BoosterCard';
import { assets } from '../../../assets/assets';
import { getUIStateClassName, pluralizeWordOnly } from '../../../data/utils';
import { useTranslation } from 'react-i18next';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { isExpectedError } from '../../../replicant/types';
import { UserBalance } from '../../shared/UserBalance/UserBalance';
import { Booster as BoosterType } from '../../../replicant/features/game/ruleset/boosters';

export const Shop = () => {
  const { data, loading, visible, fetch } = app.views.Shop;

  useAppUpdates({
    id: 'shop',
    listener: app.views.Shop.attachEventListener(),
  });
  useAppUpdates({
    id: 'shop/balance',
    listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
  });

  useAppUpdates({
    id: 'shop-tutorial-update',
    listener: app.ui.attachEventListener(UIEvents.ShopUpdate),
  });

  const { t } = useTranslation();

  const { balance } = app.ui;

  useEffect(() => {
    if (visible) fetch();
  }, [visible]);

  const boosterList = useMemo(() => {
    if (!data) {
      return [];
    }

    let availableBoosters = Object.keys(data.boosters) as Booster[];
    availableBoosters = availableBoosters.filter((booster) => {
      return booster !== Booster.AutoTap;
    });

    return availableBoosters.map((booster) => ({
      booster,
      ...data.boosters[booster],
    }));
  }, [data]);

  const onOpenDetails = (booster: Booster) => {
    if (data) {
      app.ui.drawer.show({
        id: 'boosterDetails',
        onClose: () => app.ui.drawer.close(),
        props: {
          booster: {
            boosterId: booster,
            listing: data.boosters[booster],
            onBuyBooster: buyBooster,
          },
        },
      });
    }
  };

  const onOpenBufff = (buff: Buff) => () => {
    app.ui.drawer.show({
      id: 'boosterDetails',
      onClose: () => app.ui.drawer.close(),
      props: {
        booster: {
          boosterId: buff,
          listing: {
            price: 0,
            level: 0,
            maxedOut: false,
          },
        },
      },
    });
  };

  const buyBooster = async (booster: Booster | BoosterType) => {
    const currentBalance = balance;

    const newBalance = await app.clicker.buyBooster(booster as Booster);
    if (isExpectedError(newBalance)) {
      console.log('Shop buyBooster expected error:', newBalance);
      return;
    }

    await app.ui.animateBalance(newBalance - currentBalance);

    fetch();
  };

  const rocketman = {
    available: data?.buffs[Buff.Rocketman].available || 0,
    max: data?.buffs[Buff.Rocketman].maxUses || 3,
  };

  const fullEnergyTimeLeft = data?.buffs[Buff.FullEnergy].timeLeft || 1;
  const fullEnergyTimeLeftInHours = Math.ceil(
    fullEnergyTimeLeft / 1000 / 60 / 60,
  );

  const rocketmanTimeLeft = data?.buffs[Buff.Rocketman].timeLeft || 1;
  const rocketmanTimeLeftInHours = Math.ceil(
    rocketmanTimeLeft / 1000 / 60 / 60,
  );

  const prettyBalance =
    balance < 1000000000 ? formatPrice(balance) : largeIntegerToLetter(balance);

  return (
    <Page id="modal-shop" visible={visible}>
      {/* points */}
      <div className="shop-header">
        <UserBalance showPerHour={false} />
      </div>

      {/* daily boosts */}
      {
        <div className="shop-daily-boosts-container">
          <div className="shop-subtitle">{t('shop_free_daily_boosts')}</div>
          <div className="shop-daily-boosts">
            <div className="daily-items">
              {/* button rocket */}
              <div
                className={`daily-item ${
                  rocketman.available <= 0 && 'disabled'
                } ${getUIStateClassName(app.ui.state.shop.btnRocket)}`}
                onClick={onOpenBufff(Buff.Rocketman)}
              >
                <img className="daily-icon" src={assets.boosters.Rocketman} />
                <div className="daily-info">
                  <div className="daily-info-title">{t('shop_rocket')}</div>
                  <div className="daily-info-subtitle">
                    {rocketman.available}/{rocketman.max}
                    {rocketmanTimeLeftInHours > 0
                      ? ` in ${rocketmanTimeLeftInHours} ${pluralizeWordOnly(
                          rocketmanTimeLeftInHours,
                          t('hour'),
                          t('hours'),
                        )}`
                      : ''}
                  </div>
                </div>
              </div>
              {/* button energy */}
              <div
                className={`daily-item ${
                  fullEnergyTimeLeft > 0 && 'disabled'
                } ${getUIStateClassName(app.ui.state.shop.btnEnergy)}`}
                onClick={onOpenBufff(Buff.FullEnergy)}
              >
                <img className="daily-icon" src={assets.boosters.FullEnergy} />
                <div className="daily-info">
                  <div className="daily-info-title">
                    {t('shop_full_energy')}
                  </div>
                  <div className="daily-info-subtitle">
                    {fullEnergyTimeLeftInHours > 0
                      ? `in ${fullEnergyTimeLeftInHours} ${pluralizeWordOnly(
                          fullEnergyTimeLeftInHours,
                          t('hour'),
                          t('hours'),
                        )}`
                      : t('shop_available')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {
        <div className="shop-list-container">
          <div className="shop-subtitle">{t('shop_boosters')}</div>
          <div className="shop-list">
            {boosterList.map((listing, i) => (
              <BoosterCard
                onClick={onOpenDetails}
                key={listing.booster + i}
                disabled={listing.price > balance || listing.maxedOut}
                {...listing}
              />
            ))}
          </div>
        </div>
      }
    </Page>
  );
};
