import './MineHeader.scss';
import { UserBalance } from '../../../shared/UserBalance/UserBalance';

export const MineHeader = () => {
  return (
    <div className="modal-header mine">
      <UserBalance showPerHour={true} />
    </div>
  );
};
