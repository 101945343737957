import { extendReplicantConfigForDebugPanel } from '@play-co/debug-panel/replicant';
import { extendReplicantConfigForAnalytics } from '@play-co/gcinstant/replicantExtensions';
import { createConfig, ReplicantFromConfig } from '@play-co/replicant';
import actions from './actions';
import { amplitudeTimeZone } from './amplitude';
import asyncGetters from './asyncGetters';
import { chatbot } from './chatbot';
import { computedProperties } from './computedProperties';
import { eventHandlers } from './eventHandlers';
import messages from './messages';
import migrator from './migrations';
import { ruleset } from './ruleset';
import * as adminTool from './adminTool';
import scheduledActions from './scheduledActions';
import stateSchema from './schema';
import { sharedStates } from './sharedStates';

const appName = 'gemzcoin';
const version = process.env.APP_VERSION || process.env.REACT_APP_APP_VERSION;

if (!version) {
  throw Error(
    'define either APP_VERSION or REACT_APP_APP_VERSION environment variable',
  );
}

const config = createConfig({
  eventHandlers,
  stateSchema,
  actions,
  adminTool,
  messages,
  computedProperties,
  ruleset,
  scheduledActions,
  asyncGetters,
  chatbot,
  migrator,
  appName,
  version,
  onLoginAction: 'onLogin', // note: disable this to test locally while migration feature has not been completed
  // onAdminCreateUserAction: 'onAdminCreateUser',
  sharedStates,
});

export type ReplicantServer = ReplicantFromConfig<typeof config>;

const extendedConfig = extendReplicantConfigForDebugPanel(
  extendReplicantConfigForAnalytics(config, {
    amplitudeTimeZone,
  }),
);

export default extendedConfig;
