import './TradingTokenPage.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { TradingTokenTabs } from './TradingTokenTabs/TradingTokenTabs';
import { TradingTokenFooter } from './TradingTokenFooter/TradingTokenFooter';
import { useEffect, useState } from 'react';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { Page } from '../Page';
import { getMyOffchainTokenTokenAmount } from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { shareDeeplink } from '../../../data/sharing';
import Big from 'big.js';
import { resolveProfilePicture } from '../../../replicant/features/game/game.getters';
import { MemesEvents } from '../../../data/Controllers/Memes/MemesController';

export const TradingTokenPage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingTokenPage;
  const { memes } = app;

  useAppUpdates({
    id: 'TradingTokenPage',
    listener: app.views.TradingTokenPage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TradingTokenPage/UIEvents.OnBalanceUpdate',
    listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
  });
  useAppUpdates({
    id: 'TradingTokenPage/MemesEvents.OnCurrentTokenUpdate',
    listener: app.memes.attachEventListener(MemesEvents.OnCurrentTokenUpdate),
  });

  const [tabUpdateCounter, setTabUpdateCounter] = useState(0);

  useEffect(() => {
    if (visible) {
      // If for some reason there's no data set, close itself
      if (!memes.currentMeme.token) {
        app.ui.drawer.close();
        return;
      }

      app.track('memeoffchainToken_detail_view', {
        memeoffchainToken_name: memes.currentMeme.token.name,
        memeoffchainTokenID: memes.currentMeme.token.id,
        current_price: memes.currentMeme.token.buyPrice.toString(),
        current_owned: memes.currentMeme.token.creatorId === app.state.id,
        total_holders: memes.currentMeme.token.holderCount,
        source: memes.market.filter,
      });
    }
  }, [visible, memes.currentMeme.token?.id]);

  const goToProfile = () => {
    console.warn('>>> trading.currentMeme.token');
    if (!memes.currentMeme.token) return;
    app.nav.goToProfile(memes.currentMeme.token.creatorId);
  };

  const onShare = () => {
    shareDeeplink('TradingTokenPage', {
      messageOpts: {
        title: memes.currentMeme.token?.name as unknown as string,
        text: memes.currentMeme.token?.description
          .description as unknown as string,
      },
      deeplinkOpts: app.nav.getDeeplinkOpts('TradingTokenPage'),
    });
  };

  const onEditInfo = () => {
    app.nav.goTo('TradingEditLinksPage');
  };

  if (!memes.currentMeme.token) {
    return null;
  }

  const owned = getMyOffchainTokenTokenAmount(
    app.state,
    memes.currentMeme.token.id,
  );

  const onTabChange = () => {
    setTabUpdateCounter(tabUpdateCounter + 1);
  };

  const tickerName = memes.currentMeme.token?.ticker ?? '#';

  return (
    <Page id="trading-token" visible={visible}>
      <div className="trading-token-layout trading-details">
        <div className="trading-token-header">
          {/* <div className="trading-token-header-bg" /> */}

          {/* offchainToken image */}

          <div className="trading-token-header-image">
            <img src={memes.currentMeme.token.image} />
          </div>

          <div className="trading-token-header-info">
            <div className="trading-token-header-title">
              {memes.currentMeme.token.name}
            </div>

            {/* author */}
            <div className="trading-token-author" onClick={goToProfile}>
              <div className="trading-token-author-by">{tickerName}</div>
              <div className="trading-token-author-by">{'•'}</div>
              <div className="trading-token-author-by">{t('tiktok_by')}</div>
              <div className="trading-token-author-image">
                <img
                  src={resolveProfilePicture(
                    memes.currentMeme.token.creatorId,
                    memes.currentMeme.token.creatorImage,
                  )}
                />
              </div>
              <div className="trading-token-author-name">
                {memes.currentMeme.token.creatorName}
              </div>
            </div>

            {/* buttons */}
            <div className="trading-token-buttons">
              {memes.currentMeme.isOwner && (
                <div className="trading-token-button-edit" onClick={onEditInfo}>
                  {t('trading_edit_info_cta')}
                </div>
              )}

              <div className="trading-token-button-owned">
                {t('trading_token_x_owned', {
                  amount: Big(owned).toFixed(4),
                })}{' '}
              </div>
              <div className="trading-token-button-share" onClick={onShare}>
                <img src={assets.share_white} />
              </div>
            </div>
          </div>
        </div>

        {/* tabs */}
        <TradingTokenTabs onTabChange={onTabChange} />

        {/* footer */}
        <TradingTokenFooter footerMode={'normal'} collapse={tabUpdateCounter} />
      </div>
    </Page>
  );
};
