import { AppController } from '../Controllers/AppController';
import { EventListener } from '../EventListener';
import { qpConfig } from '../config';
import { waitFor } from '../utils';
import { TutorialModule } from './modules/TutorialModule';
import { TutorialCfg, Tutorials } from './types';
import { getFTUE, tutorials } from './tutorials';
import { Tooltip } from '../Controllers/UIController/UITypes';
import { SlideshowItem } from '../../components/Tutorial/Slideshows/Slideshow';
import {
  Slideshow,
  slideshows,
} from '../../components/Tutorial/Slideshows/config';
import { ModalLabels } from '../../replicant/ruleset';
import { BusinessController } from '../Controllers/BusinessController';

export enum TutorialEvents {
  onUpdate = 'onUpdate',
}

interface StepTrack {
  taps: number;
  balance: number;
  firstTime?: boolean;
}

export interface TutorialOpts {
  substitutes?: Record<string, string>;
}

export class TutorialController extends BusinessController<TutorialEvents> {
  private currentTutorial?: TutorialCfg;

  private stepIndex = -1;

  private _steps?: TutorialModule[];
  private get steps() {
    return this._steps || [];
  }

  get step(): TutorialModule | undefined {
    return this.steps[this.stepIndex];
  }

  // Tutorial are active if any tutorial has been set
  get active() {
    return Boolean(this.currentTutorial);
  }

  private stepInProgress = false;

  private _overlayEnabled = false;
  get overlayEnabled() {
    return this._overlayEnabled;
  }

  private _transparentBg = false;
  get transparentBg() {
    return this._transparentBg;
  }

  private _tutorialOpts: TutorialOpts | undefined = undefined;
  get tutorialOpts() {
    return this._tutorialOpts;
  }

  private firstStep = false;

  private tooltipTimer?: NodeJS.Timeout;

  private firstShow = false;

  private stepTapTrack: StepTrack = {
    taps: 0,
    balance: 0,
  };

  get analyticsTracking() {
    return this.stepTapTrack;
  }

  public init = async () => {
    // We must be in the A/B and it must be the firstSession ever or if we have started before
    const tutorial = getFTUE(this.app);

    const showTutorial =
      qpConfig.testTutorial ||
      (tutorial &&
        (this.app.realFirstEverSession ||
          this.app.state.tutorials[tutorial] !== undefined));

    if (showTutorial && tutorial) {
      // start ftue
      this.startTutorial(tutorial);
    }

    this.onInitComplete();
  };

  getIsActive = (id: Tutorials) => {
    return this.currentTutorial?.id === id;
  };

  private preloadFTUEImages = async (images: string[]) => {
    await new Promise(function (myResolve, myReject) {
      function preload(imageArray: string[], index: number = 0): any {
        index = index || 0;
        if (imageArray && imageArray.length > index) {
          var img = new Image();
          img.onload = function () {
            console.warn('>>> loaded', images[index]);
            preload(imageArray, index + 1);
          };
          img.onerror = function () {
            console.error(
              '>>> error when preloading FTUE image',
              images[index],
            );
          };
          img.src = images[index];
        } else {
          console.warn('>>> finished preloading');
          myResolve(true);
        }
      }

      console.warn('>>> start preloading images', images);
      preload(images);
    });
  };

  private preloadSlideshow = async (tutorial: Tutorials) => {
    const slideshowsCfg: Partial<
      Record<
        Tutorials,
        {
          items: SlideshowItem[];
          preload: (images: string[]) => Promise<void>;
        }
      >
    > = {
      [Tutorials.SlideshowTutorial]: {
        items: slideshows[Tutorials.SlideshowTutorial].items,
        preload: this.preloadFTUEImages,
      },
      [Tutorials.SlideshowTradingCreateToken]: {
        items: slideshows[Tutorials.SlideshowTradingCreateToken].items,
        preload: this.preloadFTUEImages,
      },
      [Tutorials.SlideshowSeasonKickOff]: {
        items: slideshows[Tutorials.SlideshowSeasonKickOff].items,
        preload: this.preloadFTUEImages,
      },
      [Tutorials.SlideshowTiktok]: {
        items: slideshows[Tutorials.SlideshowTiktok].items,
        preload: this.preloadFTUEImages,
      },
      [Tutorials.SlideshowTiktok2]: {
        items: slideshows[Tutorials.SlideshowTiktok2].items,
        preload: this.preloadFTUEImages,
      },
      [Tutorials.SlideshowTiktokOnlyExperience]: {
        items: slideshows[Tutorials.SlideshowTiktok].items,
        preload: this.preloadFTUEImages,
      },
    };

    const cfg = slideshowsCfg[tutorial];
    if (!cfg) {
      return;
    }

    const images = cfg.items.map((item) => item.image);
    await cfg.preload(images);

    this.app.ui.startSlideshow(tutorial as Slideshow);
  };

  private getIsFirstTime = () => {
    for (const label of this.app.state.labels) {
      if (label.includes(ModalLabels.TUTORIAL_TRACKING)) {
        // const [,,event] = label.split('_');
        this.app.invoke.removeLabels({ labels: [label] });
        return true;
      }
    }
  };

  public startTutorial = async (
    tutorial: Tutorials,
    tutorialOpts?: TutorialOpts,
  ) => {
    if (qpConfig.skipTutorial) {
      return;
    }
    await this.preloadSlideshow(tutorial);

    if (this.currentTutorial) {
      console.error(
        `Trying to start a tutorial '${tutorial}' while one is in progress '${this.currentTutorial.id}'.`,
      );
      return;
    }
    this.currentTutorial = tutorials[tutorial](this.app);
    this._transparentBg = Boolean(this.currentTutorial.transparentBg);
    this._tutorialOpts = tutorialOpts;

    if (this.getIsFirstTime()) {
      this.stepTapTrack.firstTime = true;
    }

    if (qpConfig.testTutorial) {
      this.stepIndex = 0; // todo carles: set this to a different step number in order to test
    } else {
      this.stepIndex = this.app.state.tutorials[this.currentTutorial.id] || 0;
    }

    // If we already finished the tutorial, dont start it, unless it is repeateable
    if (this.stepIndex >= this.currentTutorial.lastStep) {
      if (this.currentTutorial.repeateable) {
        this.stepIndex = 0;
      } else {
        this.currentTutorial = undefined;
        return;
      }
    }

    // Initialise steps for tutorial;
    this._steps = this.currentTutorial.steps;

    this.firstStep = true;

    await this.executeStep();
  };

  resetStepTapTrack = () => {
    this.stepTapTrack = {
      taps: 0,
      balance: 0,
    };
  };

  updateStepTapTrack = (balance: number) => {
    this.stepTapTrack.taps += 1;
    this.stepTapTrack.balance += balance;
  };

  enableOverlay = (enable: boolean) => {
    this._overlayEnabled = enable;
    this.sendEvents(TutorialEvents.onUpdate);
  };

  onOverlayClick = () => {
    if (this._overlayEnabled) {
      this.step?.onComplete();
    }
  };

  showTooltip = (data: Tooltip, duration: number = 5000) => {
    clearInterval(this.tooltipTimer);
    this.app.ui.tooltip.setData(data).show(false);

    if (duration > 0) {
      this.tooltipTimer = setTimeout(() => {
        this.app.ui.tooltip.hide();
      }, duration);
    }
  };

  private executeStep = async () => {
    if (this.stepInProgress || !this.currentTutorial || !this.step) {
      return;
    }

    // Don't save state for repeateable tutorials
    // console.log('cai', {r: this.currentTutorial.repeateable, f: !this.currentTutorial.repeateable});
    if (!this.currentTutorial.repeateable) {
      // First execute any state mutation
      await this.app.invoke.updateStateFromTutorial({
        tutorialId: this.currentTutorial.id,
        stepId: this.step.stateUpdateId,
        stepIndex: this.stepIndex,
        test: qpConfig.testTutorial,
      });
    }

    // On the first step of the tutorial always wait a bit for locale to kick in
    if (this.firstStep) {
      await waitFor(200);
      this.firstStep = false;
    }

    // Then execute the step
    this.stepInProgress = true;
    const awaitStepComplete = this.step.execute();
    this.sendEvents(TutorialEvents.onUpdate);
    await awaitStepComplete;
    // Once step is complete, reset inProgress state and call next
    this.stepInProgress = false;
    this.nextStep();
  };

  private nextStep = async () => {
    this.stepIndex += 1;
    if (this.stepIndex === this._steps?.length) {
      this.onTutorialEnd();
      return;
    }
    if (!this.step) {
      return;
    }
    this.executeStep();
  };

  private onTutorialEnd = async () => {
    if (this.currentTutorial) {
      if (!this.currentTutorial.repeateable) {
        await this.app.invoke.updateStateFromTutorial({
          tutorialId: this.currentTutorial.id,
          stepId: this.step?.stateUpdateId,
          stepIndex: this.stepIndex,
          test: qpConfig.testTutorial,
        });
      }
      this.currentTutorial.onComplete(this.app);
      this.currentTutorial = undefined;
      this.stepInProgress = false;
      this.sendEvents(TutorialEvents.onUpdate);
    }
  };
}
