import './OverlayPowerUp.scss';
import { assets } from '../../../assets/assets';
import { Overlay } from '../Overlay';
import { PowerUp } from '../../../replicant/features/powerups/types';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';
import { useTranslation } from 'react-i18next';
import ConfettiEffect from '../../shared/Confetti/ConfettiEffect';

interface Props {
  index: number;
  powerup?: PowerUp;
  visible: boolean;
  onClose: () => void;
  lastFind: boolean;
}

const textKeys = [
  'pup_daily_item_first',
  'pup_daily_item_second',
  'pup_daily_item_third',
];

export const OverlayPowerUp = ({
  index,
  powerup,
  visible,
  onClose,
  lastFind,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Overlay
      className="modal-overlay overlay-details"
      visible={visible}
      onClose={onClose}
    >
      <div className="overlay-layout">
        <div className="overlay-header">
          {/* item checkmarks */}
          <div className="overlay-checkmarks-area">
            <div className="overlay-checkmarks-title">{`Daily Combo`}</div>
            <div className="overlay-checkmarks-container">
              {[0, 1, 2].map((i) => (
                <img
                  key={i}
                  className={`checkmark ${index >= i && 'active'}`}
                  src={
                    index >= i
                      ? assets.powerup_check_on
                      : assets.powerup_check_off
                  }
                />
              ))}
            </div>
          </div>

          {/* item image */}
          <GlowingImage
            image={powerup?.details?.image || ''}
            size={'200px'}
            borderRadius={24}
            blur={60}
            intensity={0.8}
          />

          {/* item title and subtitle*/}
          <div>
            <div className="overlay-header-title">{powerup?.details.name}</div>
            <div className="overlay-header-subtitle">{t(textKeys[index])}</div>
          </div>

          <ConfettiEffect show={lastFind} gravity={{ from: 0.3, to: 0.5 }} />
        </div>
      </div>
    </Overlay>
  );
};

export default OverlayPowerUp;
