import './Overlay.scss';
import { PropsWithChildren, useEffect, useState } from 'react';
import { isTelegramWebview } from '../../data/device';

interface Props extends PropsWithChildren {
  className: string;
  visible: boolean;
  onClose?: () => void;
}

export const Overlay = ({ children, className, visible, onClose }: Props) => {
  const [showedOnce, setShowedOnce] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowedOnce(true);
    }
  }, [visible]);

  return (
    <div
      className={`modal-overlay ${className} ${
        visible ? 'fade-in' : 'fade-out'
      } ${!showedOnce && 'hide'}`}
      onClick={onClose}
    >
      <div
        className="modal-overlay-box"
        style={{ paddingBottom: isTelegramWebview() ? '36px' : '24px' }}
      >
        {children}
      </div>
    </div>
  );
};
