import './DrawerPowerUpCardGiftReceived.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useMemo } from 'react';
import { getPowerUps } from '../../../replicant/features/powerups/getters';
import { sendMineCardGift } from '../../../data/sharing';
import {
  PowerUp,
  PowerUpCardType,
} from '../../../replicant/features/powerups/types';
import { insertCoinIconIntoText } from '../../../data/utils';

const FriendsDescription = ({ powerUp }: { powerUp: PowerUp | undefined }) => {
  const { t } = useTranslation(); //

  if (!app.clicker.cardGift) {
    return;
  }

  const isNew = app.clicker.cardGift.isNew;

  // Top text parsing
  const sender = app.clicker.cardGift.sender || '';
  const cardName = t(`${app.clicker.cardGift.card}_name`);

  const topTextKey = isNew
    ? 'pu_specials_received_new'
    : 'pu_specials_received_multiple';
  const topText = t(topTextKey, {
    sender: sender,
    cardName: cardName,
  });

  // Middle text parsing
  const earnPerHour = powerUp?.earn || 0;
  const level = app.clicker.cardGift.level
    ? String(app.clicker.cardGift.level + 1)
    : '0';

  const middleTextKey = isNew
    ? 'pu_specials_received_coins_per'
    : 'pu_specials_received_level';
  const middleText = isNew
    ? insertCoinIconIntoText(t(middleTextKey, { amount: earnPerHour }))
    : t(middleTextKey, { amount: level });

  // Bottom text parsing
  const bottomText = t('pu_specials_received_bottom');

  return (
    <>
      <div className="drawer-header-subtitle">
        <div className={`puc-earn`}>
          <div className="puc-earn-title">{topText}</div>
          <div
            className="puc-earn-coins"
            dangerouslySetInnerHTML={{ __html: middleText }}
          ></div>
        </div>
      </div>
      <div className="drawer-header-morecards">{bottomText}</div>
    </>
  );
};

export const DrawerPowerUpCardGiftReceived = () => {
  const { t } = useTranslation(); //

  if (!app.clicker.cardGift) {
    return;
  }

  useEffect(() => {
    app.track('ReceiveGift', {
      gift_name: app.clicker.cardGift?.card || 'unknown',
    });
  }, [app.clicker.cardGift?.card]);

  const giftBack = () => {
    if (app.clicker.cardGift) {
      app.track('Regift', {
        card: app.clicker.cardGift.card,
        gift_name: app.clicker.cardGift.card,
      });
      sendMineCardGift({
        card: app.clicker.cardGift.card,
        originFeature: 'daily',
        originSubFeature: 'daily_gifts',
      });
    }
    app.ui.drawer.close();
  };

  const { powerUp, originalPowerUp } = useMemo(() => {
    const powerUps = getPowerUps(app.state, app.now());
    const p = powerUps.find((pu) => pu.id === app.clicker.cardGift?.card);
    let originalP = p;
    if (
      app.clicker.cardGift &&
      app.clicker.cardGift.card !== app.clicker.cardGift.originalCard
    ) {
      originalP = powerUps.find(
        (pu) => pu.id === app.clicker.cardGift?.originalCard,
      );
    }
    return {
      powerUp: p,
      originalPowerUp: originalP,
    };
  }, [app.clicker.cardGift.card]);

  const cardName = t(`${app.clicker.cardGift.card}_name`);
  const title = t('pu_specials_received_title', { giftName: cardName });

  return (
    <div className="powerup-gift-received">
      <div className="drawer-layout">
        <div className="drawer-header">
          <div className="drawer-header-title">{title}</div>

          <div className="drawer-header-icon">
            <img src={powerUp?.details.image} />
          </div>

          <FriendsDescription powerUp={powerUp} />

          {/* --- */}
        </div>
      </div>

      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={giftBack}>
          {t(`pup_gift_received_button`)}
        </div>
      </div>
    </div>
  );
};
