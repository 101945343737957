import './RoiSummary.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { RoiBox } from './RoiBox';

import { PortfolioChart } from '../../TradingTokenPage/Charts/PortfolioChart';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { useEffect } from 'react';
import { SliceConfig } from '../../../../replicant/features/offchainTrading/offchainTrading.ruleset';

export const RoiSummary = () => {
  useAppUpdates({
    id: 'RoiSummary',
    listener: app.memes.attachEventListener(
      MemesEvents.TradingOnPortfolioUpdate,
    ),
  });

  useEffect(() => {
    app.memes.trading.refetchPortfolio();
  }, []);

  const { t } = useTranslation();

  return (
    <div className={`roi-summary`}>
      <RoiBox
        id={'portfolio_value'}
        name={t('trade_roi_portfolio_value')}
        value={app.memes.trading.portfolioValue}
        percent={app.memes.trading.getPortfolioRoi()}
        timeIntervalMode="All"
      />

      {/* insert chart here */}
      {Boolean(app.memes.trading.portfolioValue) && (
        <PortfolioChart
          portfolioValue={app.memes.trading.portfolioValue}
          getPortfolioPricePoints={async (sliceConfig: SliceConfig | null) =>
            app.memes.trading.getPortfolioPricePoints(sliceConfig)
          }
          height={250} // todo carles
          showPresets={true}
        />
      )}
    </div>
  );
};
