import './TiktokPage.scss';
import { Page } from '../Page';
import { useEffect, useRef, useState } from 'react';
import { app } from '../../../data/Controllers/AppController';
import { Carousel } from 'react-responsive-carousel';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { MemesEvents } from '../../../data/Controllers/Memes/MemesController';
import { TMGEvents } from '../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../data/hooks';
import { offchainTokenSearchCount } from '../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { FeedMeme } from './TiktokFeedItem/FeedMeme';
import { FeedAd } from './TiktokFeedItem/FeedAd';
import { TiktokHeader } from './TiktokHeader/TiktokHeader';
import { useTranslation } from 'react-i18next';

// ===================================================
// #region TiktokPage

export const TiktokPage = () => {
  const { visible } = app.views.TiktokPage;

  useManyAppUpdates({
    id: 'TiktokPage',
    events: [
      { listener: app.views.TiktokPage.attachEventListener() },
      {
        listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
        dep: visible,
      },
      {
        listener: app.memes.attachEventListener(
          MemesEvents.OnCurrentTokenUpdate,
        ),
        dep: visible,
      },
      {
        listener: app.memes.attachEventListener(MemesEvents.OnFilterChange),
        dep: visible,
      },
      {
        listener: app.memes.attachEventListener(MemesEvents.OnListingUpdate),
        dep: visible,
      },
      // tapgame updates
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingPlayingUpdate),
        dep: visible,
      },
      {
        // necessary to receive tapgame countdown updates
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingGameTick),
      },
    ],
  });

  // ------------------------------------------
  // initialization

  return (
    <Page id="tiktok" visible={visible}>
      {/* fixed top ui */}
      <TiktokHeader />

      {/* full screen feed carousel */}
      <FeedCarousel visible={visible} />
    </Page>
  );
};

// #endregion
// ===================================================
// #region FeedCarousel

const swipeOverrideDistanceThreshold = window.innerHeight / 2 - 100;
const swipeOverrideTimeThreshold = 300; // in milliseconds, adjust as needed
const defaultSwipeTolerance = 10;
const preventSwipeTolerance = 1_000_000;

interface FeedCarouselProps {
  visible: boolean;
}

export const FeedCarousel = ({ visible }: FeedCarouselProps) => {
  const { t } = useTranslation();

  // ------------------------------------------
  // initialization

  const { currentMeme } = app.memes;

  // ------------------------------------------
  // change slide

  const carouselRef = useRef<Carousel>(null);

  const [transitionTime, setTransitionTime] = useState(0);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const [canSwipe, setCanSwipe] = useState(true);

  const [adLocked, setAdLocked] = useState(false);

  const [canShowTapGame, setCanShowTapGame] = useState(true);

  const [uiAlpha, setUiAlpha] = useState(1);

  const [swipeStartY, setSwipeStartY] = useState<number | null>(null);
  const [swipeDistance, setSwipeDistance] = useState(0);
  const [swipeStartIndex, setSwipeStartIndex] = useState<number | null>(null);
  const postSwipeOverrideIndexRef = useRef<number | null>(null);
  const swipeStartTimeRef = useRef<number | null>(null);

  const memeItems = app.memes.currentList.listing;

  const carouselItems = app.memes.curateFeedWithAds(memeItems);

  const currentElement = carouselItems[currentSlideIndex];

  useEffect(() => {
    // find the index of the slide that matches current token id
    const carouselIndex = Math.max(
      carouselItems.findIndex((carouselItem) => {
        return (
          carouselItem.type === 'meme' &&
          carouselItem.item.offchainTokenId === currentMeme.token?.id
        );
      }),
      0,
    );

    const carouselItem = carouselItems[carouselIndex];
    if (carouselItem?.type === 'meme') {
      app.memes.setCurrent({ tokenId: carouselItem.item.offchainTokenId });
    }

    app.memes.setCurrentSlideIndex(carouselIndex);
    setCurrentSlideIndex(carouselIndex);
  }, [visible, currentMeme.token?.id]);

  const onCarouselChange = async (carouselIndex: number) => {
    setUiAlpha(1);

    if (
      postSwipeOverrideIndexRef.current !== null &&
      carouselIndex != postSwipeOverrideIndexRef.current
    ) {
      carouselRef.current?.selectItem({
        selectedItem: postSwipeOverrideIndexRef.current,
      });
      postSwipeOverrideIndexRef.current = null;
      return;
    }

    let listing = app.memes.currentList.listing;
    const halfOfTheList = carouselIndex + offchainTokenSearchCount / 2;
    if (listing.length <= carouselIndex) {
      await app.memes.currentList.paginate();

      listing = app.memes.currentList.listing;
    } else if (listing.length <= halfOfTheList) {
      // initiate pagination without waiting
      app.memes.currentList.paginate();
    }

    // update the current offchain token in trading controller
    const carouselItem = carouselItems[carouselIndex];
    if (carouselItem?.type === 'meme') {
      const direction = carouselIndex - currentSlideIndex;

      const trackingOpts =
        direction === 0
          ? undefined
          : {
              sourceCategory: 'navigation' as const,
              sourceName: 'swipe' as const,
              swipe: direction > 0 ? ('down' as const) : ('up' as const),
              positionInFeed: carouselIndex,
            };

      app.memes.setCurrent(
        { tokenId: carouselItem.item.offchainTokenId },
        trackingOpts,
      );
    }

    app.memes.setCurrentSlideIndex(carouselIndex);

    // update slide index
    setCurrentSlideIndex(carouselIndex);

    // reset transition time to zero once we reached next slide after transition
    setTimeout(() => {
      setTransitionTime(0);
    }, 200);

    // make sure we dont show tap game for a bit to avoid flashes
    setCanShowTapGame(false);
    setTimeout(() => {
      setCanShowTapGame(true);
    }, 500);
  };

  // ------------------------------------------
  // swipe state checks

  const isCountdownEnabled = app.tmg.tap?.gameStartCountdown !== undefined;
  const hasTapGameStarted = app.tmg.isTapping() && !isCountdownEnabled;
  const swipeTolerance = hasTapGameStarted
    ? preventSwipeTolerance
    : defaultSwipeTolerance;

  // ------------------------------------------
  // swipe handlers

  const onSwipeStart = (event: React.TouchEvent) => {
    // escape if we are in tapping game mode
    if (hasTapGameStarted) return false;

    if (event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      setSwipeStartY(touch.clientY);
      setSwipeStartIndex(currentSlideIndex);
      swipeStartTimeRef.current = Date.now();
    } else {
      resetSwipeState();
    }
    setSwipeDistance(0);
  };

  const onSwipeMove = (event: React.TouchEvent) => {
    // escape if we are in tapping game mode
    if (hasTapGameStarted) return false;

    // if we are in countdown mode, reset game and back to feed
    if (!hasTapGameStarted && isCountdownEnabled) {
      console.warn('>>> onSwipeMove during countdown -> resetGame');
      app.tmg.tap?.resetGame();
      return false;
    }

    setTransitionTime(200);
    setUiAlpha(0.35);
    setCanSwipe(false);

    // Calculate swipe distance
    if (swipeStartY !== null && event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      const newSwipeDistance = Math.abs(touch.clientY - swipeStartY);
      setSwipeDistance(newSwipeDistance);
    }

    app.ui.drawer.close();
    return true;
  };

  const onSwipeEnd = (event: React.TouchEvent) => {
    // escape if we are in tapping game mode
    if (hasTapGameStarted) return false;

    setUiAlpha(1);

    setTimeout(() => {
      setCanSwipe(true);
    }, 300);

    const swipeTime = swipeStartTimeRef.current
      ? Date.now() - swipeStartTimeRef.current
      : 0;
    const isSwipeSlow = swipeTime > swipeOverrideTimeThreshold;
    const isSwipeDistanceShort = swipeDistance < swipeOverrideDistanceThreshold;
    const isMovingBackToStartIndex =
      isSwipeSlow && isSwipeDistanceShort && swipeStartIndex !== null;
    if (isMovingBackToStartIndex) {
      postSwipeOverrideIndexRef.current = swipeStartIndex;
    } else {
      postSwipeOverrideIndexRef.current = null;
    }

    resetSwipeState();
  };

  const resetSwipeState = () => {
    setSwipeStartY(null);
    setSwipeDistance(0);
    setSwipeStartIndex(null);
    swipeStartTimeRef.current = null;
  };

  // ------------------------------------------

  useEffect(() => {
    if (currentElement?.type === 'ad') {
      setAdLocked(true);
      currentElement.item.view().then(() => {
        setAdLocked(false);
      });
    } else {
      setAdLocked(false);
    }
  }, [currentSlideIndex, currentElement?.item]);

  return (
    <div
      className={`carousel-container ${
        canSwipe && !adLocked ? 'swipe-enabled' : 'swipe-disabled'
      }`}
    >
      {carouselItems.length === 0 && (
        <div className="empty-carousel">{t('tiktok_noitems')}</div>
      )}

      <Carousel
        //
        ref={carouselRef}
        autoFocus={true}
        axis={'vertical'}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        // renderArrowPrev={scrollPrevButton}
        // renderArrowNext={scrollNextButton}
        infiniteLoop={false} // note: infiniteLoop seems to be working fine in this latest carousel version
        dynamicHeight={false}
        autoPlay={false}
        interval={4000}
        stopOnHover={false}
        swipeable={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={swipeTolerance}
        verticalSwipe={'standard'}
        emulateTouch={true}
        transitionTime={transitionTime} // note: resetting this to 0 to achieve instant behaviour when initializing page
        animationHandler={'slide'}
        useKeyboardArrows={true}
        onChange={onCarouselChange}
        onSwipeMove={onSwipeMove}
        onSwipeStart={onSwipeStart}
        onSwipeEnd={onSwipeEnd}
        //
        selectedItem={currentSlideIndex}
      >
        {/* generate item list */}
        {carouselItems.map((itemConfig, index) =>
          itemConfig.type === 'meme' ? (
            <FeedMeme
              index={index}
              key={`feedItem_${index}`}
              currentSlideIndex={currentSlideIndex}
              token={itemConfig.item}
              uiAlpha={uiAlpha}
              canSwipe={canSwipe}
              canShowTapGame={canShowTapGame}
            />
          ) : (
            <FeedAd
              index={index}
              key={`feedItem_${index}`}
              currentSlideIndex={currentSlideIndex}
              adController={itemConfig.item}
            />
          ),
        )}
      </Carousel>
    </div>
  );
};
