import './TeamPage.scss';
import { Page } from '../Page';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { Rank } from '../../shared/Rank/Rank';
import { TeamMemberCard } from './TeamMemberCard';

import { invite } from '../../../data/sharing';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';
import { getFriendReferralRewards } from '../../../replicant/features/game/game.getters';
import { ButtonInvite } from '../../shared/ButtonInvite/ButtonInvite';
import { useTranslation } from 'react-i18next';
import { League } from '../../../replicant/features/game/ruleset/league';
import { formatPrice } from '../../../replicant/utils/numbers';
import { ClickerEvents } from '../../../data/Controllers/ClickerController';

export const TeamPage = () => {
  useAppUpdates({
    id: 'TeamPage',
    listener: app.views.TeamPage.attachEventListener(),
  });

  useAppUpdates({
    id: 'TeamPage',
    listener: app.clicker.attachEventListener(ClickerEvents.onMyTeamUpdate),
  });

  const { t } = useTranslation();

  const { data, visible } = app.views.TeamPage;

  const { playerTeam, joinTeam, leaveTeam } = app.clicker;

  const showJoinBtn = !Boolean(playerTeam);

  const displayData = data;

  const onJoinClick = async () => {
    if (data) {
      await joinTeam(data.id);
      app.nav.goToHomePage();
      app.tutorial.step?.onAction && app.tutorial.step?.onAction();
    }
  };

  const onLeaveClick = () => {
    if (playerTeam) {
      leaveTeam();
    }
  };

  const onLeaderboardClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('reset-timeout');
    setTimeout(() => {
      app.ui.drawer.show({ id: 'leaderboard' });
    }, 250);
  };

  const openTeamGroup = () => {
    // This errors (cross origin)
    invite({
      screen_location: 'team_page',
      url: displayData?.inviteLink || '',
      data: {
        feature: 'team',
        $subFeature: 'team_share',
      },
    });
    // Telegram.WebApp.openTelegramLink(displayData?.inviteLink || '');
    // This does not error, but opens next page in normal browser, even from inside telegram app
    // Telegram.WebApp.openLink(displayData?.inviteLink || '');
  };

  const myTeam = playerTeam && playerTeam.id === data?.id;

  return (
    <Page id="modal-team-page" visible={visible}>
      <div className="team-page">
        {
          <>
            {/* header with glowing user avatar image */}

            <div className="modal-header teampage">
              <GlowingImage
                image={displayData?.photo || assets.friends}
                size={'100px'}
                blur={60}
                intensity={3}
              />

              <div className="modal-header-title">
                <div className="modal-header-title-name">
                  {displayData?.name}
                </div>
                <div
                  className="modal-header-title-share"
                  onClick={openTeamGroup}
                >
                  <img src={assets.share} />
                </div>
              </div>
              <div className="modal-header-subtitle">
                <Rank rank={displayData?.rank} />
                <div className="modal-header-subtitle-rank">{`Rank #${displayData?.rank}`}</div>
                <div> •</div>
                <div className="btn leaderboard" onClick={onLeaderboardClick}>
                  <img src={assets.leaderboard} />
                  <div>{t('team_view_leaderboard')}</div>
                </div>
              </div>
            </div>

            {/* team info */}
            <div className="team-page-info">
              <div className="team-page-info-item">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="value">
                  {formatPrice(displayData?.score)}
                  <div className="label">{t('team_total_earned')}</div>
                </div>
              </div>

              <div className="team-page-info-separator" />

              <div className="team-page-info-item">
                <div className="icon">
                  <img src={assets.friends} />
                </div>
                <div className="value">
                  {displayData?.members}
                  <div className="label">{t('team_members')}</div>
                </div>
              </div>
            </div>

            {/* invite info */}
            {playerTeam && (
              <div className="tp-invite-info">
                <div className="tp-invite-text">
                  <div className="title">{t('team_invite_title')}</div>
                  <img src={assets.coin} />
                  <div className="subtitle">
                    {t('team_invite_subtitle', {
                      amount: formatPrice(
                        getFriendReferralRewards(
                          app.state,
                          app.now(),
                          League.league1,
                        ).regular,
                      ),
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* footer buttons */}
            <div className="footer-buttons vertical">
              {showJoinBtn && (
                <>
                  <ButtonInvite
                    caption={t('team_invite_button')}
                    feature={'team'}
                    subFeature="team_invite"
                  />
                  <div
                    className="btn btn-inverse"
                    id="tp-join"
                    onClick={onJoinClick}
                  >
                    {t('team_join_button')}
                  </div>
                </>
              )}
              {myTeam && (
                <>
                  <ButtonInvite
                    caption={t('team_invite_button')}
                    feature={'team'}
                    subFeature="team_invite"
                  />
                  <div
                    className="btn btn-inverse"
                    id="tp-leave"
                    onClick={onLeaveClick}
                  >
                    {t('team_leave_button')}
                  </div>
                </>
              )}
            </div>

            {/* team member list */}
            <div className="team-list teampage">
              {(displayData?.players || []).map((player, i) => (
                <TeamMemberCard
                  key={player.name + i}
                  rank={player.rank}
                  image={player.photo || ''}
                  name={player.name}
                  score={player.score}
                  contribution={0} // todo: is missing from our BE
                />
              ))}
            </div>
          </>
        }
      </div>
    </Page>
  );
};

export default TeamPage;
