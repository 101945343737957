import { configureDebugPanel } from '@play-co/debug-panel';
import {
  analytics,
  type AnalyticsProperties,
  PlatformMock,
  PlatformTelegram,
} from '@play-co/gcinstant';
import {
  configureExtensions,
  createPayloadEncoder,
} from '@play-co/gcinstant/replicantExtensions';
import {
  ClientReplicant,
  createOfflineReplicant,
  createOnlineReplicant,
  ReplicantFromConfig,
} from '@play-co/replicant';
import { useEffect, useState } from 'react';
import replicantConfig from '../../replicant/config';
import { getReferralUrl } from '../../replicant/features/chatbot/chatbot.private';
import {
  generateUserPayloadKey,
  getBalance,
  getEnergy,
  getLeague,
} from '../../replicant/features/game/game.getters';
import { getDayMidnightInUTC } from '../../replicant/utils/time';
import { FriendList } from '../../replicant/features/game/ruleset/friends';
import { sendEntryFinalAnalytics } from '../analytics/entryFinal';
import { gameApi, socialApi } from '../api';
import cheats from '../cheats';
import { config, configByEnv, env, qpConfig } from '../config';
import { View } from '../View';
import { CriticalErrors, onError, onReplicationError } from '../errors';
import { EventListener } from '../EventListener';
import { captureGenericError, initSentry, setSentryUser } from '../sentry';
import i18n from '../../i18n';
import { extractPayloadUserId } from '../../replicant/chatbot';
import {
  GiftCardInitInfo,
  PromoCardInitInfo,
  LeagueLeaderboard,
  ModalComponent,
  PlayerGame,
  ShopListing,
  Team,
  TTeamPageShowOpts,
} from '../types';
import {
  PowerUp,
  PowerUpCardType,
} from '../../replicant/features/powerups/types';
import {
  getActivePowerUpById,
  getOwnedPowerUpsStats,
  getPowerUps,
  hasReceivedFromUserToday,
} from '../../replicant/features/powerups/getters';
import { ConnectedWallet, TonConnectError, TonConnectUI } from '@tonconnect/ui';
import { config as gameConfig } from '../../replicant/features/game/game.config';
import { waitFor } from '../utils';
import { UIController } from './UIController/UIController';
import { TutorialController } from '../tutorial/TutorialController';
import { DAY_IN_MS } from '../../replicant/utils/time';
import { tests } from '../../replicant/ruleset';
import { EarnPageData, isExpectedError } from '../../replicant/types';
import { InternetController, InternetEvents } from '../InternetController';
import { SessionController } from './SessionController';
import { NavController } from './NavController';
import { IAPConfig } from '../../replicant/features/offchainTrading/types';
import { TradingToken } from '../../replicant/features/offchainTrading/offchainTrading.getters';
import { ProfileController } from './ProfileController';
import { Buffer } from 'buffer';
import { TokenMiniGamesController } from './TokenMiniGames/TMGController';
import { ReplicantCollector } from '../ReplicantCollector';
import { MemesController } from './Memes/MemesController';
import { AutomationController } from './AutomationController';
import { ClickerController, ClickerEvents } from './ClickerController';
import { initViews, logVersion } from './initViews';
import { TGController } from './TGController';

window.Buffer = window.Buffer || Buffer;

type Listener = (value: () => void) => () => void;

interface ReRenderOpts {
  id: string;
  listener?: Listener;
  dep?: boolean;
  debug?: boolean;
}

export const useAppUpdates = ({ id, listener, dep, debug }: ReRenderOpts) => {
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (!listener || dep === false) {
      return;
    }
    const callback = () => {
      if (debug) {
        // console.log(`Re-render ${id}`);
      }
      setRenderCount(renderCount + 1);
    };
    return listener(callback);
  }, [renderCount, listener, dep]);
};

export enum AppEvents {
  onAppReady = 'onAppReady',
  onAdEnergyRewardChange = 'onAdEnergyRewardUpdate',
  onCriticalError = 'onCriticalError',
}

let doOnce = qpConfig.simulateRocketman;

export const isLocal = env === 'local' && !qpConfig.replicant;

export const gcinstant = isLocal ? new PlatformMock() : new PlatformTelegram();

export type ReplicantClient = ClientReplicant<
  ReplicantFromConfig<typeof replicantConfig>
>;

export class AppController extends EventListener {
  // Listen to all events within AppEvents
  events = Object.keys(AppEvents).reduce(
    (res, cur) => ({
      ...res,
      [cur]: [],
    }),
    {},
  );
  /**
   * Takes care of checking if we have access to the internet and notify
   * when the status change
   */
  public internet = new InternetController();

  // Business Controllers
  private resolveReplicantClient!: (replicantClient: ReplicantClient) => void;

  public replicantClientPromise = new Promise<ReplicantClient>((resolve) => {
    this.resolveReplicantClient = resolve;
  });

  public replicant!: ReplicantClient;
  public tonConnectUI!: TonConnectUI;

  public telegram: TGController = new TGController(this);
  public session = new SessionController(this);
  public nav = new NavController(this);
  public memes = new MemesController(this);
  public tmg = new TokenMiniGamesController(this);
  public automation = new AutomationController(this);
  public clicker = new ClickerController(this);
  public ui = new UIController(this);
  public tutorial = new TutorialController(this);
  public profile = new ProfileController(this);
  public replCollector = new ReplicantCollector(this);

  public views: {
    LeaderboardDrawer: View<Team[]>;
    JoinTeam: View<Team[]>;
    TeamPage: View<Team | undefined, TTeamPageShowOpts>;
    Friends: View<FriendList>;
    Shop: View<ShopListing>;
    Toast: View<{ text?: string; hidePurchaseText?: true } | undefined>;
    Maintenance: View<void>;
    EarnPage: View<EarnPageData>;
    LeaguePage: View<LeagueLeaderboard>;
    MinePage: View<PowerUp[]>;
    TradingPage: View<{ isNew: true } | undefined>;
    TradingCreatePage: View<undefined>;
    TradingCreateLinksPage: View<undefined>;
    TradingEditLinksPage: View<undefined>;
    TradingTokenPage: View<TradingToken>;
    TiktokPage: View<TradingToken>;
    TiktokSearchPage: View<{ isNew: true } | undefined>;
    //
    ModalComponent: View<ModalComponent>;
    ProfilePage: View<void>;
    LoadingPage: View<void>;
  };

  private player?: PlayerGame;

  // used to trigger critical error drawers
  private _criticalError: CriticalErrors | null = null;
  public set criticalError(value: CriticalErrors | null) {
    this._criticalError = value;
    this.nav.goToHomePage();
    this.ui.drawer.show({ id: 'criticalError', hideClose: true });
    this.sendEvents(AppEvents.onCriticalError);
  }
  public get criticalError() {
    return this._criticalError;
  }

  public get isFirstSession() {
    // only show the message for players joining organically (no invite)
    return Boolean(this.player?.isFirstSession);
  }

  public get isFirstSessionOfTheDay() {
    return analytics.getUserProperties().lastEntryIsFirstOfDay || false;
  }

  public get playerBalance() {
    return getBalance(this.replicant.state, this.now());
  }

  private _isReady = false;
  private _isUserBanned = false;

  private firstEverSession = false;

  get isReady() {
    return this._isReady;
  }

  get isUserBanned() {
    return this._isUserBanned;
  }

  get state() {
    return this.replicant?.state;
  }

  get playerId() {
    return this.state?.id;
  }

  constructor(private _playerId: string) {
    super();
    logVersion();
    // @TODO2: Look into this
    /**
      @note: forces the game to refresh after 24h
      the goal is to avoid exploiting an old client
      (better solution would be to invalidate old backend to force a refresh)
     */
    setTimeout(() => {
      window.location.reload();
    }, DAY_IN_MS);

    initSentry();

    this.internet.addEventListener(InternetEvents.OnChange, () => {
      if (this.internet.online) {
        analytics.pushEvent('wifi_reconnect', {
          offline_duration: this.internet.offlineDuration,
        });
      } else {
        analytics.pushEvent('wifi_disconnect');
      }
    });

    /** 
      initialize ton connect ui
      (santosh) note that this needs to be done right away
      to avoid conflicts with avoid closing telegram hack in App class
     */
    this.initTonConnect();

    // initialise TON analytics
    if (config.telegramAnalyticsToken) {
      try {
        //@ts-ignore
        window.telegramAnalytics.init({
          token: config.telegramAnalyticsToken,
          appName: config.telegramAnalyticsAppName,
        });
      } catch {
        console.error(`Could not init telegram analytics`);
      }
    }

    this.initGame()
      .then(() => this.setUserPhoto())
      .catch(async (error) => {
        analytics.pushError('AppInitFailed', error);
        captureGenericError('AppInitFailed', error);

        if (Telegram?.WebApp?.initDataUnsafe?.user) {
          onReplicationError();
        }
      });

    this.views = initViews(this);
  }

  get invoke() {
    return this.replicant.invoke;
  }

  get asyncGetters() {
    return this.replicant.asyncGetters;
  }

  public now = () => {
    return this.replicant.now();
  };

  onBack = () => {
    if (this.tutorial.step?.onBack) {
      return this.tutorial.step?.onBack();
    }
    this.nav.back();
  };

  private setUserPhoto = async () => {
    if (env === 'local' || this.isUserBanned) {
      return;
    }

    const profile = this.replicant.state.profile;
    if (profile.photo !== gcinstant.playerPhoto) {
      await this.replicant.invoke.setProfilePicture({
        profilePictureUrl: gcinstant.playerPhoto,
      });
    }
  };

  private async initTonConnect() {
    let stage = process.env.REACT_APP_STAGE || 'dev';
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${stage}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.replicantClientPromise;

        if (status) {
          this.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          analytics.setUserProperties({
            walletAddress: status.account.address,
          });
        }
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.maybeFixBrokenWalletConnectQuest();
  }

  private initReplicant = async () => {
    let telegramAuthorizationData: Record<string, string> = {};

    if (gcinstant instanceof PlatformTelegram && Telegram.WebApp.initData) {
      telegramAuthorizationData = gcinstant.getTelegramAuthorizationData();
      this._playerId = gcinstant.playerID;
    }

    let replicantEndpoint: string | undefined;
    const offlineMode = config.replicant.offlineMode;
    const replicantEnv = qpConfig.replicant;
    if (!offlineMode || replicantEnv) {
      replicantEndpoint = offlineMode
        ? configByEnv[replicantEnv as keyof typeof configByEnv]?.replicant
            .endpoint
        : config.replicant.endpoint;
    }

    if (replicantEndpoint) {
      this.replicant = await createOnlineReplicant(
        replicantConfig,
        gcinstant.playerID,
        {
          endpoint: replicantEndpoint,
          platform: 'web',
          telegramAuthorizationData,
          batchingMaxTimeOverrides: { tap: 6_000 }, // Use a longer batching window for `tap` actions to reduce request rate
        },
      );
    } else {
      this.replicant = await createOfflineReplicant(
        replicantConfig,
        qpConfig.playerId,
        {
          platform: 'mock',
        },
      );
    }

    this.replicant.setOnError((error) => onError(error, this.replicant));

    this.resolveReplicantClient(this.replicant);

    await this.replicantClientPromise; // Avoid `Replicant client is not initialized` on `gcinstant.loadStorage`

    return telegramAuthorizationData;
  };

  private getEntryData = async (startParam: string) => {
    const entryPayloadKey =
      (process.env.REACT_APP_ENV === 'local' && qpConfig.simulateStartParam) ||
      startParam;

    let referrerId: string | undefined = undefined;
    if (this.replicant.state.first_interaction) {
      try {
        referrerId = extractPayloadUserId(entryPayloadKey);
      } catch (e: any) {
        // allow continuance, but allow log to analytics and sentry
        this.track('FirstInteractPayloadUserIdError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('FirstInteractPayloadUserIdError', e);
      }
    }

    const entryPayload = await payloadEncoder.decode({
      ['$key']: entryPayloadKey,
    });

    const qpPayload = qpConfig.payload;

    const deeplinkRoute =
      qpPayload.payload?.dlRoute ||
      qpConfig.dlRoute ||
      entryPayload?.payload?.dlRoute;
    const deeplinkOpts =
      qpPayload.payload?.dlOpts ||
      qpConfig.dlOpts ||
      entryPayload?.payload?.dlOpts;

    const tokenId = deeplinkOpts?.offchainTokenId;

    return {
      referrerId: this.replicant.state.first_interaction
        ? extractPayloadUserId(entryPayloadKey)
        : undefined,
      entryPayload,
      entryTokenId: tokenId,
      deeplink: {
        route: deeplinkRoute,
        opts: deeplinkOpts,
      },
      entryFinalProps: {
        ...entryPayload?.payload,
        ...qpPayload,
      },
    };
  };

  private initGame = async () => {
    const startTime = Date.now();

    await gcinstant.initializeAsync({
      // These are for local development and will get overwritten in dev/prod
      amplitudeKey: config.amplitudeKey,
      amplitudeTimeZone: config.amplitudeTimeZone,
      appID: 'gemz-coin',
      disableAutomaticTosPopup: true,
      shortName: 'gemz-coin-telegram',
      version: process.env.REACT_APP_APP_VERSION!,
      revenueCurrency: 'USD',
    });

    setSentryUser({ id: gcinstant.playerID, username: gcinstant.playerName });

    this.telegram.init();

    const telegramAuthorizationData = await this.initReplicant();

    await gcinstant.loadStorage();

    this.replCollector.init();
    // async on purpose
    this.memes.init();

    const {
      entryPayload,
      entryTokenId,
      referrerId,
      deeplink,
      entryFinalProps,
    } = await this.getEntryData(telegramAuthorizationData.start_param);

    const telegramUser = this.telegram.user;
    const entryUserProps: { [key: string]: unknown } = {};

    if (this.replicant.state.first_interaction) {
      await this.replicant.invoke.handleFirstEntry({
        referrer: referrerId,
        tokenId: entryTokenId,
        telegramUser,
      });

      // do not await for this as it might hang forever
      this.joinReferrerTeam();

      entryUserProps.username = this.replicant.state.username;
      this.firstEverSession = true;
    } else {
      this.replicant.invoke.handleReentry();
    }

    if (telegramUser) {
      entryUserProps.isPremium = Boolean(telegramUser.is_premium);
    }

    if (process.env.REACT_APP_IS_DEVELOPMENT) {
      configureDebugPanel({
        replicant: this.replicant,
        ui: cheats,
      });
    }

    if (this._playerId === 'ANON') {
      throw new Error(`Trying to initialise game without playerId`);
    }

    gcinstant.locale = this.initLanguage(gcinstant.platformLocale);

    analytics.setUserProperties({
      friendCount: this.replicant.state.friendCount ?? 0,
      league: getLeague(this.replicant.state),
      score: this.replicant.state.score,
      balance: this.replicant.state.balance,
      teamId: this.replicant.state.team_id,
      ...entryUserProps,
    });

    // Starts the game once assets are loaded and the backend is ready.
    const startSessionResponse = await gameApi.startSession();

    // In case of an existing user's first entry with GCInstant, bump entry count to 2 to avoid tracking entry as `first: true` in Amplitude:
    const isMigratingUsersFirstEntry =
      gcinstant.storage.entry.count === 1 &&
      !startSessionResponse.player.isFirstSession;

    if (isMigratingUsersFirstEntry) {
      gcinstant.storage.assign((storage) => (storage.entry.count = 2));
    }

    await gcinstant.startGameAsync();

    if (env === 'local') {
      gcinstant.playerID = this._playerId;
      gcinstant.playerName = `Player ${this._playerId}`;
    }

    await this.clicker.init(startSessionResponse);

    this.clicker.setPlayer(startSessionResponse.player, true);
    this.clicker.setPlayerTeam(startSessionResponse.team);

    if (entryPayload) {
      await this.handleMineEntry(entryPayload);
    }

    this.track('SessionStart', {
      deeplinkRoute: deeplink.route,
      deeplinkOpts: JSON.stringify(deeplink.opts),
    });

    const elapsedTime = Date.now() - startTime;
    const minLoadScreenTime = this.ui.minLoadScreenTime;
    const remainingTime = minLoadScreenTime - elapsedTime;

    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }

    // Nav wont work until init is called
    this.nav.init();
    this.profile.init();
    this.tmg.init();

    this.sendEvents([ClickerEvents.onGameStateUpdate, AppEvents.onAppReady]);

    analytics.setUserProperties({
      banned: !!this.replicant.state.banned,
    });

    await sendEntryFinalAnalytics(
      this.replicant.state,
      entryFinalProps,
      this.replicant.now(),
    );

    if (this.replicant.state.banned) {
      this._isUserBanned = true;
    }

    await this.tutorial.init();

    await this.ui.init();

    this._isReady = true;
    this.sendEvents([ClickerEvents.onGameStateUpdate, AppEvents.onAppReady]);

    await this.initialNavigation(deeplink);
  };

  private initialNavigation = async (deeplink: {
    route: string;
    opts: string;
  }) => {
    // For some reason not giving some time here makes the app init with "-40px margin-top"
    await waitFor(100);
    const didDeeplink = await this.nav.deepLink(deeplink.route, deeplink.opts);
    if (didDeeplink) {
      return;
    }
    await this.nav.goToHomePage();
  };

  public fetchPlayerState = async (playerId: string) => {
    try {
      const playerStateMap = await this.replicant.fetchStates([playerId]);
      const playerState = playerStateMap?.[playerId]?.state;
      return playerState;
    } catch (error) {
      analytics.pushError('FetchStateFailed', {
        playerId,
        message: typeof error === 'string' ? error : (error as Error).message,
      });
    }
  };

  private joinReferrerTeam = async () => {
    const referrerId = this.state.referrer_id;
    if (!referrerId) {
      return;
    }

    const teamId = await this.replicant.asyncGetters.getPlayerTeamId({
      userId: referrerId,
    });
    if (!teamId) {
      return;
    }

    this.replicant.invoke.joinTeam({ teamId });
  };

  private handleMineEntry = async (payload: AnalyticsProperties.EntryData) => {
    const subfeature = payload.$subFeature;
    if (subfeature === 'gift') {
      try {
        await this.handleMineGiftEntry(payload);
      } catch (e: any) {
        this.track('InitGameHandleMineGiftEntryError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('InitGameHandleMineGiftEntryError', e);
      }
    } else if (subfeature === 'promo') {
      try {
        await this.handleMinePromoEntry(payload);
      } catch (e: any) {
        this.track('InitGameHandleMinePromoEntryError', {
          error_message: e?.message || 'unknown',
        });
        captureGenericError('InitGameHandleMinePromoEntryError', e);
      }
    }
  };

  private handleMineGiftEntry = async (
    payload: AnalyticsProperties.EntryData,
  ) => {
    const today = getDayMidnightInUTC(this.now());
    const card = payload.payload.card;
    const powerUpCard = getActivePowerUpById(card);
    if (!powerUpCard) {
      console.error(`Invalid card: ${card}`);
      return;
    }

    if (today === payload.payload.createdAt) {
      const senderId = payload.playerID;
      // ignore if the player has clicked its own gift link
      if (senderId && senderId !== this._playerId) {
        const canAcceptGift = !hasReceivedFromUserToday(
          this.replicant.state,
          powerUpCard,
          senderId,
        );
        if (canAcceptGift) {
          try {
            const cardGiftOrError = await this.invoke.handleMineGiftEntry({
              card,
              senderId,
            });
            if (isExpectedError(cardGiftOrError)) {
              throw new Error(cardGiftOrError.errorMessage);
            }
            this.clicker.setCardGift(cardGiftOrError as GiftCardInitInfo);
          } catch (e) {
            console.error(e);
            app.track('JoinGiftExpired', {
              gift_name: card || 'unknown',
            });
            this.clicker.setExpiredGift(card);
          }
        }
      }
    } else {
      app.track('JoinGiftExpired', {
        gift_name: card || 'unknown',
      });
      this.clicker.setExpiredGift(card);
    }
  };

  private handleMinePromoEntry = async (
    payload: AnalyticsProperties.EntryData,
  ) => {
    const today = getDayMidnightInUTC(this.now());
    const card = payload.payload.card;
    const powerUpCard = getActivePowerUpById(card);

    if (!powerUpCard) {
      console.error(`Invalid card: ${card}`);
      return;
    }

    const availablePowerup = getPowerUps(this.replicant.state, this.now()).find(
      (item) =>
        item.id === card &&
        item.specialState === 'Available' &&
        item.type === PowerUpCardType.HIDDEN,
    );

    const isPromoAvailable = availablePowerup !== undefined;
    if (isPromoAvailable) {
      try {
        const cardPromoOrError = await this.invoke.handleMinePromoEntry({
          card,
        });
        if (isExpectedError(cardPromoOrError)) {
          throw new Error(cardPromoOrError.errorMessage);
        }
        this.clicker.setCardPromo(cardPromoOrError as PromoCardInitInfo);
      } catch (e) {
        console.error(e);
        app.track('PromoCardExpired', {
          card_name: card || 'unknown',
        });
      }
    } else {
      app.track('PromoCardExpired', {
        card_name: card || 'unknown',
      });
    }
  };

  public getABTest = (key: keyof typeof tests) => {
    return this.replicant.abTests.getBucketID(tests[key]) as string | undefined;
  };

  public getIsInAB = (
    key: keyof typeof tests,
    ab: string | string[],
  ): boolean => {
    const abs = typeof ab === 'string' ? [ab] : ab;
    const test = this.getABTest(key);
    if (!test) {
      return false;
    }
    return abs.includes(test);
  };

  initLanguage(languageCode: string) {
    const supportedLanguages = ['en', 'es', 'fa', 'id', 'pt', 'ru', 'tr', 'uz'];
    const locale = supportedLanguages.includes(languageCode)
      ? languageCode
      : 'en';

    i18n.changeLanguage(locale);

    const rtlLanguages = ['ar', 'fa', 'he'];
    const isRtl = rtlLanguages.includes(locale);
    if (isRtl) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }

    return locale;
  }

  track = (
    eventName: string,
    eventProps: Record<string, string | number | boolean | undefined> = {},
    userProps: Record<string, string | number | boolean | undefined> = {},
  ): void => {
    let realtimeUserProps: Record<string, string | number | boolean> = {};
    if (this.replicant?.state) {
      const state = this.replicant.state;
      const now = this.replicant.now();
      const powerUpStats = getOwnedPowerUpsStats(state, now);
      realtimeUserProps = {
        '#realtimeScore': state.score,
        '#realtimeBalance': getBalance(state, now),
        '#realtimeLeague': getLeague(state),
        '#realtimeEnergy': getEnergy(state, now),
        '#realtimeEarningsPerHour': Math.round(powerUpStats.bonusPerHour),
        '#realtimeEarningsPerSecond': Math.round(
          powerUpStats.bonusPerHour / 3600,
        ),
        '#realtimeCardsUnique': powerUpStats.uniqueCount,
        '#realtimeCardsTotal': powerUpStats.totalCount,
        '#realtimeCardsGear': powerUpStats.gearUniqueCount,
        '#realtimeCardsWorkers': powerUpStats.companionUniqueCount, // companion == worker
        '#realtimeCardsServices': powerUpStats.serviceUniqueCount,
        '#realtimeCardsSpecials': powerUpStats.specialUniqueCount,
        '#realtimeDailyReward': state.streak_days - state.unclaimed_rewards,
        '#realtimeOffchainTokenCount': Object.keys(state.trading.offchainTokens)
          .length,
        '#realtimeFollowerCount': state.followersCount,
        '#realtimeFollowingCount': state.followingsCount,
      };
    }
    analytics.pushEvent(eventName, eventProps, undefined, {
      ...realtimeUserProps,
      ...userProps,
    });
  };

  setUserProperties = (userProps: Record<string, number | string>): void => {
    analytics.setUserProperties(userProps);
  };

  getFriends = async () => {
    // const start = Date.now();
    const friends = await socialApi.getFriends();
    // console.error('Friends in ', Date.now() - start)
    return friends;
  };

  getEarnPageData = async () => {
    return {
      friendCount: this.state.friendCount,
    };
  };

  getShareUrl(url: string, inviteText?: string): string {
    const urlEncoded = encodeURIComponent(url);
    if (!inviteText) {
      return `https://t.me/share/url?url=${urlEncoded}`;
    }

    const text = encodeURIComponent(inviteText);
    return `https://t.me/share/url?url=${urlEncoded}&text=${text}`;
  }

  getReferralShareUrl(payloadKey: string, inviteText?: string): string {
    const referralUrl = getReferralUrl(payloadKey, this.replicant.state);
    return this.getShareUrl(referralUrl, inviteText);
  }

  get realFirstEverSession() {
    return this.firstEverSession;
  }

  maybeFixBrokenWalletConnectQuest = async () => {
    // we check the earnings flag against the existence of a wallet here
    await this.replicantClientPromise;

    const walletConnectQuestDone = this.replicant.state.earnings.walletConnect;
    const stateWalletConnected = this.replicant.state.wallet.length > 0;
    if (!stateWalletConnected && walletConnectQuestDone) {
      // wallet is not connected but quest was completed, so reset
      await this.replicant.invoke.resetEarningWalletConnect();
    }
  };

  getIAPId = (cfg: IAPConfig) => {
    return `${cfg.productId}_${this._playerId}`;
  };
}

export const app = new AppController(qpConfig.playerId);
export type Route = keyof typeof app.views;

configureExtensions({
  analytics,
  gcinstant,
  replicantClientPromise: app.replicantClientPromise,
});

// Configure a payload encoder with shorter payload keys to comply with Telegram bot link limits: https://core.telegram.org/api/links#bot-links
const generatePayloadKey = (): string => {
  return generateUserPayloadKey(app.replicant.userId);
};

export const payloadEncoder = createPayloadEncoder(
  () => app.replicant,
  analytics,
  { generatePayloadKey },
);

gcinstant.setDataCodec(payloadEncoder); // Must be called after configureExtensions!

// ngrok http --domain=cai-privy-server.ngrok.dev 8080

if (process.env.REACT_APP_ENV !== 'prod') {
  (window as any).app = app;
  (window as any).gcinstant = gcinstant;
}
