import { assets } from '../../assets/assets';
import { constructFollowingSubStateId } from '../../replicant/features/followings/followings.getters';
import { maxFollowingsCount } from '../../replicant/features/followings/followings.ruleset';
import { resolveProfilePicture } from '../../replicant/features/game/game.getters';
import { OwnedOffchainToken } from '../../replicant/features/game/player.schema';
import {
  computePortfolioValue,
  getProfitLoss,
  getTMGFarmingListing,
  getTradingVolume,
} from '../../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingSearchResult } from '../../replicant/features/offchainTrading/offchainTrading.properties';
import { TMGFarmingStatus } from '../../replicant/features/offchainTrading/types';
import { SliceConfig } from '../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { State } from '../../replicant/schema';
import {
  getOffchainTokenHoldCount,
  getOffchainTokenHoldingStats,
  getPortfolioPricePoints,
  getPortfolioRoi,
} from '../offchainTokenUtils';
import { shareDeeplink } from '../sharing';
import { Fn } from '../types';
import { waitFor } from '../utils';
import { BusinessController } from './BusinessController';

export type FarmingSearchResult = TradingSearchResult & {
  state?: TMGFarmingStatus;
  progress?: {
    hoursLeft: number;
    minutesLeft: number;
  };
  points?: number;
};
type ProfileData = {
  state: State;
  ownedTokens: {
    created: TradingSearchResult[];
    owned: TradingSearchResult[];
    all: TradingSearchResult[];
  };
};

export enum ProfileEvents {
  OnUpdate = 'OnUpdate',
}

export class ProfileController extends BusinessController<ProfileEvents> {
  // @TODO: Do we want to cache, if yes for how long, any of this data we want to update more often?
  private profileCaches: Record<string, ProfileData> = {};

  private currentUserId?: string;

  private tokenFarmCache: Record<string, TradingSearchResult> = {};

  // Internal use
  private get currentProfile() {
    if (!this.currentUserId) {
      return undefined;
    }
    return this.profileCaches[this.currentUserId];
  }

  private initialFollowing = false;
  private optmisticFollowing = false;

  private followLoading = false;
  get updatingFollow() {
    return this.followLoading;
  }

  get current() {
    if (!this.currentProfile) {
      return undefined;
    }

    if (!this.currentProfile.state) {
      return undefined;
    }

    const { profile, followingsCount, followersCount, balance } =
      this.currentProfile.state;

    const { ownedTokens } = this.currentProfile;

    return {
      id: this.currentUserId,
      name: profile.name,
      picture: this.currentUserId
        ? resolveProfilePicture(this.currentUserId, profile.photo)
        : assets.default_profile,
      followings: this.followings,
      following: followingsCount,
      followers: followersCount,
      tokensCreated: ownedTokens.created || [],
      tokensOwned: ownedTokens.owned || [],
      balance,
      portfolio: {
        value: this.getPortfolioValue() || 0,
        diff: this.getPortfolioRoi() || 0,
        profitLoss: this.getProfitLoss() || 0,
        tradingVolume: this.getTradingvolume() || 0,
      },
      isSelf: this.currentUserId === this.app.state.id,
      isFollowing: this.optmisticFollowing,
      tokensFarming: this.getTokensFarming(),
    };
  }

  private followDebounce?: NodeJS.Timeout;

  private initialProfileResolver!: Fn<void>;
  private initialProfilePromise = new Promise((resolve) => {
    this.initialProfileResolver = resolve;
  });

  private followings: string[] = [];

  public init = async () => {
    this.profileCaches = {} as Record<string, ProfileData>;

    await this.setProfile(this.app.state.id);
    this.initialProfileResolver();

    this.onInitComplete();
  };

  private fetchProfileData = async (
    refresh = false,
    userId = this.currentUserId,
  ) => {
    if (!userId) {
      return;
    }

    if (!refresh && this.profileCaches[userId]) {
      return;
    }

    const playerStateRequest = this.app.fetchPlayerState(userId);

    const followingsSubStateId = constructFollowingSubStateId(userId);
    const followingsRequest = this.app.asyncGetters.getFollowings({
      followingsSubStateId,
    });

    const playerState = await playerStateRequest;
    if (!playerState) {
      return;
    }

    const ownedTokens = await this.app.asyncGetters.getMyOffchainTokens({
      userId: userId,
      ownedTokenIds: Object.keys(playerState.trading.offchainTokens),
    });

    this.followings = (await followingsRequest) ?? [];

    this.profileCaches[userId] = {
      state: playerState,
      ownedTokens: {
        created: ownedTokens.created,
        owned: ownedTokens.owned,
        // remove duplicates after concatenating
        all: ownedTokens.created.concat(
          ownedTokens.owned.filter(
            ({ id }) => !ownedTokens.created.find((f) => f.id == id),
          ),
        ),
      },
    };

    // @todo: fixme
    this.initialFollowing = this.followings.includes(userId);
    this.optmisticFollowing = this.initialFollowing;

    await waitFor(10);
  };

  getPortfolioPricePoints = (sliceConfig: SliceConfig | null) => {
    if (!this.currentUserId) {
      return [];
    }

    const playerData = this.profileCaches[this.currentUserId];
    return getPortfolioPricePoints(
      playerData.state,
      sliceConfig,
      playerData.ownedTokens.all,
    );
  };

  getOffchainTokenHoldCount = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    return getOffchainTokenHoldCount(playerData.state);
  };

  getPortfolioRoi = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const portfolioValue = computePortfolioValue(
      playerData.state,
      playerData.ownedTokens.all,
    ).toNumber();
    return getPortfolioRoi(playerData.state, portfolioValue);
  };

  getPortfolioValue = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const portfolioValue = computePortfolioValue(
      playerData.state,
      playerData.ownedTokens.all,
    ).toNumber();
    return portfolioValue;
  };

  getProfitLoss = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const tradingVolume = getProfitLoss(
      playerData.state,
      playerData.ownedTokens.all,
    );
    return tradingVolume.toNumber();
  };

  getTradingvolume = () => {
    if (!this.currentUserId) {
      return;
    }

    const playerData = this.profileCaches[this.currentUserId];
    const tradingVolume = getTradingVolume(playerData.state);
    return tradingVolume.toNumber();
  };

  getTokenHolding = (offchainTokenId: string) => {
    if (!this.currentUserId) {
      return;
    }

    return this.profileCaches[this.currentUserId].state.trading.offchainTokens[
      offchainTokenId
    ];
  };

  getOffchainTokenHoldingStats = (
    offchainTokenId: string,
    tokenHolding: OwnedOffchainToken | undefined,
  ) => {
    if (!this.currentUserId) {
      return;
    }

    // console.warn('>>> tokenHolding', tokenHolding);

    const playerData = this.profileCaches[this.currentUserId];
    return getOffchainTokenHoldingStats(
      offchainTokenId,
      playerData.ownedTokens.all,
      tokenHolding,
    );
  };

  refresh = async () => {
    this.prefetchFarmingData();
    await this.fetchProfileData(true);
    this.sendEvents(ProfileEvents.OnUpdate);
  };

  // To be used only for navigation from NavController or privately
  setProfile = async (userId: string) => {
    // if (this.currentUserId === userId) {
    //   return Promise.resolve();
    // }
    this.currentUserId = userId;
    this.prefetchFarmingData();
    await this.fetchProfileData(true);
    this.sendEvents(ProfileEvents.OnUpdate);
  };

  toggleFollow = () => {
    clearInterval(this.followDebounce);
    // There's no follow for self
    if (this.current?.isSelf) {
      return;
    }

    if (this.app.state.followingsCount >= maxFollowingsCount) {
      // @todo: notify user that max followings count has been reached
      this.app.ui.drawer.show({
        id: 'generic',
        opts: {
          title: 'Follows limit reached',
          subtitle:
            'You have reached the maximum limit of follows. Please unfollow someone and try again.',
          buttons: [
            {
              cta: 'Okay',
              onClick: () => this.app.ui.drawer.close(),
            },
          ],
        },
      });
      return;
    }

    this.optmisticFollowing = !this.optmisticFollowing;
    this.followLoading = true;
    this.sendEvents(ProfileEvents.OnUpdate);

    // Debounce for a second to prevent unnecessary requests
    this.followDebounce = setTimeout(async () => {
      if (
        this.initialFollowing !== this.optmisticFollowing &&
        this.currentUserId
      ) {
        if (this.optmisticFollowing) {
          this.followUser(this.currentUserId);
        } else {
          this.unfollowUser(this.currentUserId);
        }
      }
    }, 1000);
  };

  share = () => {
    if (!this.current) {
      return;
    }
    this.app.track('Profile_share', {
      name: this.current.name,
    });
    shareDeeplink('ProfilePage', {
      messageOpts: {
        title: `Check out this profile!`,
        text: `${this.current.name} has created ${this.current.tokensCreated.length} tokens!`,
      },
      deeplinkOpts: this.app.nav.getDeeplinkOpts('ProfilePage'),
    });
  };

  getFollows = async () => {
    if (!this.currentProfile) {
      return {
        following: [],
        followers: [],
      };
    }
    const followingsSubStateId = this.currentProfile.state.followingsSubStateId;
    const response = await Promise.all([
      followingsSubStateId
        ? this.app.asyncGetters.getFollowingProfiles({
            followingsSubStateId,
          })
        : [],
      this.app.asyncGetters.getFollowerProfiles({}),
    ]);

    const [following, followers] = response;

    // Also fetch and pass along all possible token information because we need to know their supply for stats
    const allTokenIds = [...following, ...followers].reduce((res, cur) => {
      for (const token of cur.offchainTokens) {
        if (!res.includes(token.id)) {
          res.push(token.id);
        }
      }
      return res;
    }, [] as string[]);

    const tokens = await this.app.asyncGetters.getOffchainTokensFromOpenSearch({
      offchainTokenIds: allTokenIds,
    });

    return {
      following,
      followers,
      tokens,
    };
  };

  private updateFollow = async (key: 'follow' | 'unfollow', userId: string) => {
    const error = await this.app.invoke.asyncUpdateFollow({
      [key]: userId,
    });

    if (error) {
      this.app.ui.drawer.show({
        id: 'generic',
        opts: {
          title: 'Something went wrong!',
          subtitle: error.errorMessage,
        },
      });
      this.followLoading = false;
      this.sendEvents(ProfileEvents.OnUpdate);
      return;
    }

    setTimeout(async () => {
      // update own profile
      this.fetchProfileData(true, this.app.state.id);
      // update current viewing profile
      await this.fetchProfileData(true);
      this.followLoading = false;
      this.sendEvents(ProfileEvents.OnUpdate);
    }, 500);
  };

  followUser = (userId: string) => {
    if (userId === this.app.state.id) {
      return;
    }
    return this.updateFollow('follow', userId);
  };

  unfollowUser = (userId: string) => {
    if (userId === this.app.state.id) {
      return;
    }
    return this.updateFollow('unfollow', userId);
  };

  private prefetchFarmingData = async () => {
    const farming = getTMGFarmingListing(this.app.state, this.app.now());

    const farmIds = farming.map((f) => f.tokenId);

    // If it's cached, skip
    const idsToFetch = farmIds.filter((id) => !this.tokenFarmCache[id]);

    // All data already in cache
    if (idsToFetch.length <= 0) {
      return;
    }

    const tokens = await this.app.asyncGetters.getOffchainTokensFromOpenSearch({
      offchainTokenIds: idsToFetch,
    });

    // Cache response
    tokens.forEach((t) => {
      this.tokenFarmCache[t.id] = t;
    });

    if (tokens.length > 0) {
      this.sendEvents(ProfileEvents.OnUpdate);
    }
  };

  /**
   * @TODO: This should probably come from the farming list
   */
  private getTokensFarming = () => {
    const farming = getTMGFarmingListing(this.app.state, this.app.now());

    return farming.map((farm) => ({
      ...farm,
      ...this.tokenFarmCache[farm.tokenId],
    })) as FarmingSearchResult[];
  };

  getFarmingData = (tokenId: string) => {
    this.prefetchFarmingData();
    return this.getTokensFarming().find((f) => f.id === tokenId);
  };
}
