import { AppController } from '../../Controllers/AppController';
import { ElementUIState } from '../../Controllers/UIController/UITypes';
import { TutorialModule } from './TutorialModule';

export class WaitForCoinTaps extends TutorialModule {
  id = 'WaitForCoinTaps' as 'WaitForCoinTaps';

  private taps = 0;

  private tapLimit;

  constructor(app: AppController, taps = 5) {
    super(app);
    this.tapLimit = taps;
  }

  execute = async () => {
    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
    });

    return super.execute().then(() => {
      this.app.ui.tooltip.hide();
    });
  };

  override onClickerTap = () => {
    this.taps += 1;
    console.warn('>>> number of taps', this.taps, '/', this.tapLimit);
    if (this.taps >= this.tapLimit) {
      this.onComplete();
    }
  };
}
