import { Replicant } from '@play-co/replicant';
import { State } from './schema';
import { FriendList } from './features/game/ruleset/friends';
import { QuestVideoAirtableItem } from './features/powerups/types';

export type ReplicantFromState<
  TPartialReplicant extends Omit<Partial<Replicant<{}>>, 'state'> = {},
> = Replicant<
  TPartialReplicant & {
    state: State;
  }
>;

export type EarnPageData = {
  friendCount: number;
};

export type TradingPageData = {};

/**
 * A non-Error interface for actions to return in case of expected errors.
 */
export interface ExpectedError {
  expectedError: true;
  errorMessage: string;
  errorCode?: string;
}

export const isExpectedError = (obj: any): obj is ExpectedError => {
  return (
    typeof obj === 'object' &&
    obj?.expectedError &&
    typeof obj.errorMessage === 'string' &&
    (!obj.errorCode || typeof obj.errorCode === 'string')
  );
};
