// DO NOT EDIT MANUALLY! This file is generated from Airtable: "PowerUp-Daily"

import { Value } from './powerUpDaily.types';

// prettier-ignore
const powerUpDaily: Value[] = [
  {
    "date": "2024-11-15",
    "power_up_1": "pu_gear_hand_radio",
    "power_up_2": "pu_services_rig_optimization_service",
    "power_up_3": "pu_gear_thermal_paste",
    "starts_at": 1731628800000
  },
  {
    "date": "2024-11-16",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_companions_skeptical_partner",
    "power_up_3": "pu_services_crypto_cloud_boost",
    "starts_at": 1731715200000
  },
  {
    "date": "2024-11-17",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_services_crypto_compliance_check",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1731801600000
  },
  {
    "date": "2024-11-18",
    "power_up_1": "pu_services_crypto_cloud_boost",
    "power_up_2": "pu_services_hash_hackers",
    "power_up_3": "pu_services_mule_rentals",
    "starts_at": 1731888000000
  },
  {
    "date": "2024-11-19",
    "power_up_1": "pu_companions_wired_pm",
    "power_up_2": "pu_companions_awkward_ceo",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1731974400000
  },
  {
    "date": "2024-11-20",
    "power_up_1": "pu_gear_water_cooled_pc",
    "power_up_2": "pu_services_rig_optimization_service",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1732060800000
  },
  {
    "date": "2024-11-21",
    "power_up_1": "pu_companions_agile_coach",
    "power_up_2": "pu_companions_cryptoconference_junkie",
    "power_up_3": "pu_companions_hardware_hacker",
    "starts_at": 1732147200000
  },
  {
    "date": "2024-11-22",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_services_mine_masters",
    "power_up_3": "pu_companions_blockchain_evangelist",
    "starts_at": 1732233600000
  },
  {
    "date": "2024-11-23",
    "power_up_1": "pu_specials_first_eth_validator",
    "power_up_2": "pu_gear_hardware_wallet",
    "power_up_3": "pu_gear_cold_weather_gear",
    "starts_at": 1732320000000
  },
  {
    "date": "2024-11-24",
    "power_up_1": "pu_gear_harness",
    "power_up_2": "pu_services_rig_optimization_service",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1732406400000
  },
  {
    "date": "2024-11-25",
    "power_up_1": "pu_services_hash_haven",
    "power_up_2": "pu_services_crypto_cloud_boost",
    "power_up_3": "pu_services_rig_rentals",
    "starts_at": 1732492800000
  },
  {
    "date": "2024-11-26",
    "power_up_1": "pu_companions_skeptical_partner",
    "power_up_2": "pu_gear_o2_tank",
    "power_up_3": "pu_companions_serial_entrepreneur",
    "starts_at": 1732579200000
  },
  {
    "date": "2024-11-27",
    "power_up_1": "pu_services_hash_haven",
    "power_up_2": "pu_gear_hardware_wallet",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1732665600000
  },
  {
    "date": "2024-11-28",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_specials_eth_etf",
    "power_up_3": "pu_services_transaction_fee_negotiator",
    "starts_at": 1732752000000
  },
  {
    "date": "2024-11-29",
    "power_up_1": "pu_companions_reclusive_backend_dev",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_services_rig_rentals",
    "starts_at": 1732838400000
  },
  {
    "date": "2024-11-30",
    "power_up_1": "pu_gear_thermal_paste",
    "power_up_2": "pu_gear_tool_bag",
    "power_up_3": "pu_gear_o2_tank",
    "starts_at": 1732924800000
  },
  {
    "date": "2024-12-01",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_companions_curious_inlaws",
    "power_up_3": "pu_companions_serial_entrepreneur",
    "starts_at": 1733011200000
  },
  {
    "date": "2024-12-02",
    "power_up_1": "pu_gear_thermal_paste",
    "power_up_2": "pu_gear_ups_backup",
    "power_up_3": "pu_gear_ear_protection",
    "starts_at": 1733097600000
  },
  {
    "date": "2024-12-03",
    "power_up_1": "pu_services_smart_contract_builder",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_gear_knee_pads",
    "starts_at": 1733184000000
  },
  {
    "date": "2024-12-04",
    "power_up_1": "pu_gear_cold_weather_gear",
    "power_up_2": "pu_companions_blockchain_evangelist",
    "power_up_3": "pu_gear_climbing_rope",
    "starts_at": 1733270400000
  },
  {
    "date": "2024-12-05",
    "power_up_1": "pu_companions_reclusive_backend_dev",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_companions_hardware_hacker",
    "starts_at": 1733356800000
  },
  {
    "date": "2024-12-06",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_services_crypto_concierge",
    "starts_at": 1733443200000
  },
  {
    "date": "2024-12-07",
    "power_up_1": "pu_gear_hardhat",
    "power_up_2": "pu_gear_water_cooled_pc",
    "power_up_3": "pu_specials_eth_etf",
    "starts_at": 1733529600000
  },
  {
    "date": "2024-12-08",
    "power_up_1": "pu_gear_harness",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_services_blockchain_security_audit",
    "starts_at": 1733616000000
  },
  {
    "date": "2024-12-09",
    "power_up_1": "pu_gear_pickaxe",
    "power_up_2": "pu_companions_agile_coach",
    "power_up_3": "pu_services_mine_masters",
    "starts_at": 1733702400000
  },
  {
    "date": "2024-12-10",
    "power_up_1": "pu_gear_harness",
    "power_up_2": "pu_specials_inhouse_physical_therapist",
    "power_up_3": "pu_companions_curious_inlaws",
    "starts_at": 1733788800000
  },
  {
    "date": "2024-12-11",
    "power_up_1": "pu_companions_crypto_daytrader",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_companions_tech_journalist",
    "starts_at": 1733875200000
  },
  {
    "date": "2024-12-12",
    "power_up_1": "pu_companions_serial_entrepreneur",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_services_virtual_mining_coach",
    "starts_at": 1733961600000
  },
  {
    "date": "2024-12-13",
    "power_up_1": "pu_services_rig_rentals",
    "power_up_2": "pu_companions_early_adopter_sibling",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1734048000000
  },
  {
    "date": "2024-12-14",
    "power_up_1": "pu_services_crypto_concierge",
    "power_up_2": "pu_companions_reclusive_backend_dev",
    "power_up_3": "pu_services_crypto_compliance_check",
    "starts_at": 1734134400000
  },
  {
    "date": "2024-12-15",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1734220800000
  },
  {
    "date": "2024-12-16",
    "power_up_1": "pu_services_nft_gallery",
    "power_up_2": "pu_services_blockbuster_excavation",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1734307200000
  },
  {
    "date": "2024-12-17",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_companions_wired_pm",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1734393600000
  },
  {
    "date": "2024-12-18",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_gear_water_bottle",
    "power_up_3": "pu_services_hash_haven",
    "starts_at": 1734480000000
  },
  {
    "date": "2024-12-19",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_gear_hardware_wallet",
    "power_up_3": "pu_services_hash_hackers",
    "starts_at": 1734566400000
  },
  {
    "date": "2024-12-20",
    "power_up_1": "pu_companions_skeptical_partner",
    "power_up_2": "pu_gear_cold_weather_gear",
    "power_up_3": "pu_services_smart_contract_builder",
    "starts_at": 1734652800000
  },
  {
    "date": "2024-12-21",
    "power_up_1": "pu_specials_first_eth_validator",
    "power_up_2": "pu_services_mule_rentals",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1734739200000
  },
  {
    "date": "2024-12-22",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_companions_venture_capitalist",
    "power_up_3": "pu_gear_knee_pads",
    "starts_at": 1734825600000
  },
  {
    "date": "2024-12-23",
    "power_up_1": "pu_gear_protective_gloves",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_services_smart_contract_builder",
    "starts_at": 1734912000000
  },
  {
    "date": "2024-12-24",
    "power_up_1": "pu_specials_bitcoin_etf",
    "power_up_2": "pu_gear_first_aid_kit",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1734998400000
  },
  {
    "date": "2024-12-25",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_services_hash_hounds",
    "power_up_3": "pu_companions_agile_coach",
    "starts_at": 1735084800000
  },
  {
    "date": "2024-12-26",
    "power_up_1": "pu_gear_pickaxe",
    "power_up_2": "pu_gear_o2_tank",
    "power_up_3": "pu_gear_sturdy_boots",
    "starts_at": 1735171200000
  },
  {
    "date": "2024-12-27",
    "power_up_1": "pu_gear_lamp",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1735257600000
  },
  {
    "date": "2024-12-28",
    "power_up_1": "pu_gear_climbing_rope",
    "power_up_2": "pu_services_rig_menders",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1735344000000
  },
  {
    "date": "2024-12-29",
    "power_up_1": "pu_gear_thermal_paste",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_services_hash_hounds",
    "starts_at": 1735430400000
  },
  {
    "date": "2024-12-30",
    "power_up_1": "pu_gear_o2_tank",
    "power_up_2": "pu_services_decentralized_data_storage",
    "power_up_3": "pu_specials_inhouse_physical_therapist",
    "starts_at": 1735516800000
  },
  {
    "date": "2024-12-31",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_thermal_paste",
    "power_up_3": "pu_companions_agile_coach",
    "starts_at": 1735603200000
  },
  {
    "date": "2025-01-01",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_services_block_bouncers",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1735689600000
  },
  {
    "date": "2025-01-02",
    "power_up_1": "pu_services_mine_canaries",
    "power_up_2": "pu_companions_serial_entrepreneur",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1735776000000
  },
  {
    "date": "2025-01-03",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_companions_serial_entrepreneur",
    "power_up_3": "pu_services_hodl_strategy_consultant",
    "starts_at": 1735862400000
  },
  {
    "date": "2025-01-04",
    "power_up_1": "pu_companions_reclusive_backend_dev",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_services_crypto_cloud_boost",
    "starts_at": 1735948800000
  },
  {
    "date": "2025-01-05",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_services_crypto_concierge",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1736035200000
  },
  {
    "date": "2025-01-06",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_gear_pickaxe",
    "power_up_3": "pu_companions_reclusive_backend_dev",
    "starts_at": 1736121600000
  },
  {
    "date": "2025-01-07",
    "power_up_1": "pu_services_crypto_concierge",
    "power_up_2": "pu_specials_dao",
    "power_up_3": "pu_services_hash_hackers",
    "starts_at": 1736208000000
  },
  {
    "date": "2025-01-08",
    "power_up_1": "pu_specials_inhouse_physical_therapist",
    "power_up_2": "pu_companions_tech_journalist",
    "power_up_3": "pu_gear_protective_gloves",
    "starts_at": 1736294400000
  },
  {
    "date": "2025-01-09",
    "power_up_1": "pu_gear_tool_bag",
    "power_up_2": "pu_companions_nft_influencer",
    "power_up_3": "pu_gear_satellite_modem",
    "starts_at": 1736380800000
  },
  {
    "date": "2025-01-10",
    "power_up_1": "pu_gear_ups_backup",
    "power_up_2": "pu_specials_denver_eth_conference",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1736467200000
  },
  {
    "date": "2025-01-11",
    "power_up_1": "pu_services_blockbuster_excavation",
    "power_up_2": "pu_services_hash_haven",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1736553600000
  },
  {
    "date": "2025-01-12",
    "power_up_1": "pu_specials_bitcoin_etf",
    "power_up_2": "pu_companions_hardware_hacker",
    "power_up_3": "pu_companions_reclusive_backend_dev",
    "starts_at": 1736640000000
  },
  {
    "date": "2025-01-13",
    "power_up_1": "pu_specials_dao",
    "power_up_2": "pu_services_mine_masters",
    "power_up_3": "pu_companions_concerned_enviromentalist",
    "starts_at": 1736726400000
  },
  {
    "date": "2025-01-14",
    "power_up_1": "pu_companions_awkward_ceo",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_services_crypto_concierge",
    "starts_at": 1736812800000
  },
  {
    "date": "2025-01-15",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_companions_skeptical_partner",
    "power_up_3": "pu_services_hash_hackers",
    "starts_at": 1736899200000
  },
  {
    "date": "2025-01-16",
    "power_up_1": "pu_companions_awkward_ceo",
    "power_up_2": "pu_companions_ux_designer",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1736985600000
  },
  {
    "date": "2025-01-17",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_companions_skeptical_partner",
    "power_up_3": "pu_companions_hardware_hacker",
    "starts_at": 1737072000000
  },
  {
    "date": "2025-01-18",
    "power_up_1": "pu_services_crypto_cloud_boost",
    "power_up_2": "pu_gear_hardware_wallet",
    "power_up_3": "pu_specials_mined_first_bitcoin",
    "starts_at": 1737158400000
  },
  {
    "date": "2025-01-19",
    "power_up_1": "pu_companions_blockchain_evangelist",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_gear_ups_backup",
    "starts_at": 1737244800000
  },
  {
    "date": "2025-01-20",
    "power_up_1": "pu_services_swap_services",
    "power_up_2": "pu_specials_denver_eth_conference",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1737331200000
  },
  {
    "date": "2025-01-21",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_services_crypto_concierge",
    "power_up_3": "pu_companions_reclusive_backend_dev",
    "starts_at": 1737417600000
  },
  {
    "date": "2025-01-22",
    "power_up_1": "pu_specials_gemz_on_x",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_services_swap_services",
    "starts_at": 1737504000000
  },
  {
    "date": "2025-01-23",
    "power_up_1": "pu_companions_digital_nomad",
    "power_up_2": "pu_gear_satellite_modem",
    "power_up_3": "pu_services_mine_masters",
    "starts_at": 1737590400000
  },
  {
    "date": "2025-01-24",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_services_token_tutors",
    "power_up_3": "pu_companions_nft_influencer",
    "starts_at": 1737676800000
  },
  {
    "date": "2025-01-25",
    "power_up_1": "pu_services_rig_menders",
    "power_up_2": "pu_gear_lamp",
    "power_up_3": "pu_specials_dao",
    "starts_at": 1737763200000
  },
  {
    "date": "2025-01-26",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_gear_knee_pads",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1737849600000
  },
  {
    "date": "2025-01-27",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_services_block_bouncers",
    "power_up_3": "pu_companions_reclusive_backend_dev",
    "starts_at": 1737936000000
  },
  {
    "date": "2025-01-28",
    "power_up_1": "pu_services_crypto_cloud_boost",
    "power_up_2": "pu_services_crypto_crane",
    "power_up_3": "pu_gear_sturdy_boots",
    "starts_at": 1738022400000
  },
  {
    "date": "2025-01-29",
    "power_up_1": "pu_companions_venture_capitalist",
    "power_up_2": "pu_services_mine_masters",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1738108800000
  },
  {
    "date": "2025-01-30",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_services_blockbuster_excavation",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1738195200000
  },
  {
    "date": "2025-01-31",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1738281600000
  },
  {
    "date": "2025-02-01",
    "power_up_1": "pu_companions_nft_influencer",
    "power_up_2": "pu_services_rig_menders",
    "power_up_3": "pu_services_mine_masters",
    "starts_at": 1738368000000
  },
  {
    "date": "2025-02-02",
    "power_up_1": "pu_services_mule_rentals",
    "power_up_2": "pu_services_hash_haven",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1738454400000
  },
  {
    "date": "2025-02-03",
    "power_up_1": "pu_gear_thermal_paste",
    "power_up_2": "pu_companions_early_adopter_sibling",
    "power_up_3": "pu_specials_inhouse_physical_therapist",
    "starts_at": 1738540800000
  },
  {
    "date": "2025-02-04",
    "power_up_1": "pu_services_blockchain_security_audit",
    "power_up_2": "pu_companions_hardware_hacker",
    "power_up_3": "pu_companions_crypto_daytrader",
    "starts_at": 1738627200000
  },
  {
    "date": "2025-02-05",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_services_rig_menders",
    "power_up_3": "pu_gear_lamp",
    "starts_at": 1738713600000
  },
  {
    "date": "2025-02-06",
    "power_up_1": "pu_gear_harness",
    "power_up_2": "pu_companions_reclusive_backend_dev",
    "power_up_3": "pu_specials_dao",
    "starts_at": 1738800000000
  },
  {
    "date": "2025-02-07",
    "power_up_1": "pu_specials_inhouse_physical_therapist",
    "power_up_2": "pu_services_token_tutors",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1738886400000
  },
  {
    "date": "2025-02-08",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_services_token_tutors",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1738972800000
  },
  {
    "date": "2025-02-09",
    "power_up_1": "pu_gear_backpack",
    "power_up_2": "pu_services_crypto_compliance_check",
    "power_up_3": "pu_gear_o2_tank",
    "starts_at": 1739059200000
  },
  {
    "date": "2025-02-10",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_gear_lamp",
    "starts_at": 1739145600000
  },
  {
    "date": "2025-02-11",
    "power_up_1": "pu_services_hash_hackers",
    "power_up_2": "pu_companions_nft_influencer",
    "power_up_3": "pu_specials_consensus_conference",
    "starts_at": 1739232000000
  },
  {
    "date": "2025-02-12",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_gear_hand_radio",
    "power_up_3": "pu_gear_oil_immersed_rigs",
    "starts_at": 1739318400000
  },
  {
    "date": "2025-02-13",
    "power_up_1": "pu_gear_knee_pads",
    "power_up_2": "pu_services_block_bouncers",
    "power_up_3": "pu_gear_climbing_rope",
    "starts_at": 1739404800000
  },
  {
    "date": "2025-02-14",
    "power_up_1": "pu_companions_tech_journalist",
    "power_up_2": "pu_companions_digital_nomad",
    "power_up_3": "pu_specials_gemz_on_x",
    "starts_at": 1739491200000
  },
  {
    "date": "2025-02-15",
    "power_up_1": "pu_gear_hand_radio",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_companions_ux_designer",
    "starts_at": 1739577600000
  },
  {
    "date": "2025-02-16",
    "power_up_1": "pu_gear_hardhat",
    "power_up_2": "pu_gear_protective_gloves",
    "power_up_3": "pu_gear_satellite_modem",
    "starts_at": 1739664000000
  },
  {
    "date": "2025-02-17",
    "power_up_1": "pu_gear_gas_mask",
    "power_up_2": "pu_gear_satellite_modem",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1739750400000
  },
  {
    "date": "2025-02-18",
    "power_up_1": "pu_companions_wired_pm",
    "power_up_2": "pu_companions_awkward_ceo",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1739836800000
  },
  {
    "date": "2025-02-19",
    "power_up_1": "pu_services_rig_menders",
    "power_up_2": "pu_gear_lamp",
    "power_up_3": "pu_companions_blockchain_evangelist",
    "starts_at": 1739923200000
  },
  {
    "date": "2025-02-20",
    "power_up_1": "pu_companions_cryptoconference_junkie",
    "power_up_2": "pu_companions_early_adopter_sibling",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1740009600000
  },
  {
    "date": "2025-02-21",
    "power_up_1": "pu_companions_concerned_enviromentalist",
    "power_up_2": "pu_companions_crypto_daytrader",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1740096000000
  },
  {
    "date": "2025-02-22",
    "power_up_1": "pu_companions_serial_entrepreneur",
    "power_up_2": "pu_services_crypto_cloud_boost",
    "power_up_3": "pu_companions_curious_inlaws",
    "starts_at": 1740182400000
  },
  {
    "date": "2025-02-23",
    "power_up_1": "pu_companions_cryptoconference_junkie",
    "power_up_2": "pu_companions_data_scientist",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1740268800000
  },
  {
    "date": "2025-02-24",
    "power_up_1": "pu_services_crypto_concierge",
    "power_up_2": "pu_services_blockchain_security_audit",
    "power_up_3": "pu_companions_techbro",
    "starts_at": 1740355200000
  },
  {
    "date": "2025-02-25",
    "power_up_1": "pu_services_mine_canaries",
    "power_up_2": "pu_companions_awkward_ceo",
    "power_up_3": "pu_companions_skeptical_partner",
    "starts_at": 1740441600000
  },
  {
    "date": "2025-02-26",
    "power_up_1": "pu_gear_sturdy_boots",
    "power_up_2": "pu_services_hash_hounds",
    "power_up_3": "pu_companions_ux_designer",
    "starts_at": 1740528000000
  },
  {
    "date": "2025-02-27",
    "power_up_1": "pu_companions_hardware_hacker",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_specials_eth_etf",
    "starts_at": 1740614400000
  },
  {
    "date": "2025-02-28",
    "power_up_1": "pu_gear_hand_radio",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_specials_mined_first_bitcoin",
    "starts_at": 1740700800000
  },
  {
    "date": "2025-03-01",
    "power_up_1": "pu_services_rig_optimization_service",
    "power_up_2": "pu_specials_bitcoin_etf",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1740787200000
  },
  {
    "date": "2025-03-02",
    "power_up_1": "pu_gear_harness",
    "power_up_2": "pu_companions_intern",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1740873600000
  },
  {
    "date": "2025-03-03",
    "power_up_1": "pu_specials_mined_first_bitcoin",
    "power_up_2": "pu_services_crypto_crane",
    "power_up_3": "pu_gear_water_bottle",
    "starts_at": 1740960000000
  },
  {
    "date": "2025-03-04",
    "power_up_1": "pu_services_crypto_compliance_check",
    "power_up_2": "pu_gear_pickaxe",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1741046400000
  },
  {
    "date": "2025-03-05",
    "power_up_1": "pu_specials_denver_eth_conference",
    "power_up_2": "pu_services_mine_canaries",
    "power_up_3": "pu_companions_cryptoconference_junkie",
    "starts_at": 1741132800000
  },
  {
    "date": "2025-03-06",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_companions_data_scientist",
    "starts_at": 1741219200000
  },
  {
    "date": "2025-03-07",
    "power_up_1": "pu_services_swap_services",
    "power_up_2": "pu_gear_protective_gloves",
    "power_up_3": "pu_services_hash_haven",
    "starts_at": 1741305600000
  },
  {
    "date": "2025-03-08",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_services_swap_services",
    "starts_at": 1741392000000
  },
  {
    "date": "2025-03-09",
    "power_up_1": "pu_gear_lamp",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_gear_protective_gloves",
    "starts_at": 1741478400000
  },
  {
    "date": "2025-03-10",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_gear_sturdy_boots",
    "power_up_3": "pu_companions_nft_influencer",
    "starts_at": 1741564800000
  },
  {
    "date": "2025-03-11",
    "power_up_1": "pu_gear_hardhat",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_companions_skeptical_partner",
    "starts_at": 1741651200000
  },
  {
    "date": "2025-03-12",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_services_rig_rentals",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1741737600000
  },
  {
    "date": "2025-03-13",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_companions_skeptical_partner",
    "power_up_3": "pu_gear_thermal_paste",
    "starts_at": 1741824000000
  },
  {
    "date": "2025-03-14",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_companions_wired_pm",
    "power_up_3": "pu_companions_concerned_enviromentalist",
    "starts_at": 1741910400000
  },
  {
    "date": "2025-03-15",
    "power_up_1": "pu_gear_ear_protection",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_services_hash_haven",
    "starts_at": 1741996800000
  },
  {
    "date": "2025-03-16",
    "power_up_1": "pu_services_blockbuster_excavation",
    "power_up_2": "pu_gear_cold_weather_gear",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1742083200000
  },
  {
    "date": "2025-03-17",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1742169600000
  },
  {
    "date": "2025-03-18",
    "power_up_1": "pu_services_block_bouncers",
    "power_up_2": "pu_gear_sturdy_boots",
    "power_up_3": "pu_gear_protective_gloves",
    "starts_at": 1742256000000
  },
  {
    "date": "2025-03-19",
    "power_up_1": "pu_companions_awkward_ceo",
    "power_up_2": "pu_gear_cold_weather_gear",
    "power_up_3": "pu_specials_inhouse_physical_therapist",
    "starts_at": 1742342400000
  },
  {
    "date": "2025-03-20",
    "power_up_1": "pu_gear_lamp",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_companions_tech_journalist",
    "starts_at": 1742428800000
  },
  {
    "date": "2025-03-21",
    "power_up_1": "pu_specials_denver_eth_conference",
    "power_up_2": "pu_specials_inhouse_physical_therapist",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1742515200000
  },
  {
    "date": "2025-03-22",
    "power_up_1": "pu_companions_tech_journalist",
    "power_up_2": "pu_services_block_bouncers",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1742601600000
  },
  {
    "date": "2025-03-23",
    "power_up_1": "pu_companions_wired_pm",
    "power_up_2": "pu_specials_dao",
    "power_up_3": "pu_services_transaction_fee_negotiator",
    "starts_at": 1742688000000
  },
  {
    "date": "2025-03-24",
    "power_up_1": "pu_companions_curious_inlaws",
    "power_up_2": "pu_gear_lamp",
    "power_up_3": "pu_specials_consensus_conference",
    "starts_at": 1742774400000
  },
  {
    "date": "2025-03-25",
    "power_up_1": "pu_services_swap_services",
    "power_up_2": "pu_companions_venture_capitalist",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1742860800000
  },
  {
    "date": "2025-03-26",
    "power_up_1": "pu_companions_curious_inlaws",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_companions_blockchain_evangelist",
    "starts_at": 1742947200000
  },
  {
    "date": "2025-03-27",
    "power_up_1": "pu_companions_ux_designer",
    "power_up_2": "pu_services_block_bouncers",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1743033600000
  },
  {
    "date": "2025-03-28",
    "power_up_1": "pu_companions_skeptical_partner",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_services_block_bouncers",
    "starts_at": 1743120000000
  },
  {
    "date": "2025-03-29",
    "power_up_1": "pu_services_hash_hounds",
    "power_up_2": "pu_companions_digital_nomad",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1743206400000
  },
  {
    "date": "2025-03-30",
    "power_up_1": "pu_companions_cryptoconference_junkie",
    "power_up_2": "pu_gear_lamp",
    "power_up_3": "pu_gear_gas_mask",
    "starts_at": 1743292800000
  },
  {
    "date": "2025-03-31",
    "power_up_1": "pu_services_rig_optimization_service",
    "power_up_2": "pu_services_nft_gallery",
    "power_up_3": "pu_companions_concerned_enviromentalist",
    "starts_at": 1743379200000
  },
  {
    "date": "2025-04-01",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_specials_consensus_conference",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1743465600000
  },
  {
    "date": "2025-04-02",
    "power_up_1": "pu_companions_concerned_enviromentalist",
    "power_up_2": "pu_specials_eth_etf",
    "power_up_3": "pu_specials_consensus_conference",
    "starts_at": 1743552000000
  },
  {
    "date": "2025-04-03",
    "power_up_1": "pu_services_nft_gallery",
    "power_up_2": "pu_services_rig_rentals",
    "power_up_3": "pu_gear_oil_immersed_rigs",
    "starts_at": 1743638400000
  },
  {
    "date": "2025-04-04",
    "power_up_1": "pu_gear_ups_backup",
    "power_up_2": "pu_services_crypto_compliance_check",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1743724800000
  },
  {
    "date": "2025-04-05",
    "power_up_1": "pu_gear_reflective_vest",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_gear_o2_tank",
    "starts_at": 1743811200000
  },
  {
    "date": "2025-04-06",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_o2_tank",
    "power_up_3": "pu_services_swap_services",
    "starts_at": 1743897600000
  },
  {
    "date": "2025-04-07",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_services_crypto_concierge",
    "power_up_3": "pu_gear_climbing_rope",
    "starts_at": 1743984000000
  },
  {
    "date": "2025-04-08",
    "power_up_1": "pu_companions_cryptoconference_junkie",
    "power_up_2": "pu_companions_agile_coach",
    "power_up_3": "pu_companions_techbro",
    "starts_at": 1744070400000
  },
  {
    "date": "2025-04-09",
    "power_up_1": "pu_specials_dao",
    "power_up_2": "pu_companions_crypto_daytrader",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1744156800000
  },
  {
    "date": "2025-04-10",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_gear_water_bottle",
    "power_up_3": "pu_services_blockbuster_excavation",
    "starts_at": 1744243200000
  },
  {
    "date": "2025-04-11",
    "power_up_1": "pu_services_hash_haven",
    "power_up_2": "pu_specials_bitcoin_etf",
    "power_up_3": "pu_services_hash_hackers",
    "starts_at": 1744329600000
  },
  {
    "date": "2025-04-12",
    "power_up_1": "pu_gear_sturdy_boots",
    "power_up_2": "pu_services_mule_rentals",
    "power_up_3": "pu_companions_techbro",
    "starts_at": 1744416000000
  },
  {
    "date": "2025-04-13",
    "power_up_1": "pu_gear_climbing_rope",
    "power_up_2": "pu_specials_mined_first_bitcoin",
    "power_up_3": "pu_specials_inhouse_physical_therapist",
    "starts_at": 1744502400000
  },
  {
    "date": "2025-04-14",
    "power_up_1": "pu_gear_pickaxe",
    "power_up_2": "pu_services_mule_rentals",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1744588800000
  },
  {
    "date": "2025-04-15",
    "power_up_1": "pu_companions_serial_entrepreneur",
    "power_up_2": "pu_gear_ups_backup",
    "power_up_3": "pu_services_blockbuster_excavation",
    "starts_at": 1744675200000
  },
  {
    "date": "2025-04-16",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_gear_hardhat",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1744761600000
  },
  {
    "date": "2025-04-17",
    "power_up_1": "pu_services_rig_menders",
    "power_up_2": "pu_gear_lamp",
    "power_up_3": "pu_gear_water_cooled_pc",
    "starts_at": 1744848000000
  },
  {
    "date": "2025-04-18",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_hand_radio",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1744934400000
  },
  {
    "date": "2025-04-19",
    "power_up_1": "pu_companions_agile_coach",
    "power_up_2": "pu_services_virtual_mining_coach",
    "power_up_3": "pu_services_hash_hounds",
    "starts_at": 1745020800000
  },
  {
    "date": "2025-04-20",
    "power_up_1": "pu_companions_skeptical_partner",
    "power_up_2": "pu_companions_intern",
    "power_up_3": "pu_companions_awkward_ceo",
    "starts_at": 1745107200000
  },
  {
    "date": "2025-04-21",
    "power_up_1": "pu_services_hash_hackers",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1745193600000
  },
  {
    "date": "2025-04-22",
    "power_up_1": "pu_services_rig_optimization_service",
    "power_up_2": "pu_gear_backpack",
    "power_up_3": "pu_gear_o2_tank",
    "starts_at": 1745280000000
  },
  {
    "date": "2025-04-23",
    "power_up_1": "pu_gear_cold_weather_gear",
    "power_up_2": "pu_companions_venture_capitalist",
    "power_up_3": "pu_services_hodl_strategy_consultant",
    "starts_at": 1745366400000
  },
  {
    "date": "2025-04-24",
    "power_up_1": "pu_services_blockbuster_excavation",
    "power_up_2": "pu_services_mine_canaries",
    "power_up_3": "pu_services_mule_rentals",
    "starts_at": 1745452800000
  },
  {
    "date": "2025-04-25",
    "power_up_1": "pu_gear_oil_immersed_rigs",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1745539200000
  },
  {
    "date": "2025-04-26",
    "power_up_1": "pu_companions_cryptoconference_junkie",
    "power_up_2": "pu_services_rig_menders",
    "power_up_3": "pu_gear_protective_gloves",
    "starts_at": 1745625600000
  },
  {
    "date": "2025-04-27",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_specials_first_eth_validator",
    "power_up_3": "pu_gear_lamp",
    "starts_at": 1745712000000
  },
  {
    "date": "2025-04-28",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_gear_knee_pads",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1745798400000
  },
  {
    "date": "2025-04-29",
    "power_up_1": "pu_companions_techbro",
    "power_up_2": "pu_services_hash_hackers",
    "power_up_3": "pu_companions_curious_inlaws",
    "starts_at": 1745884800000
  },
  {
    "date": "2025-04-30",
    "power_up_1": "pu_gear_satellite_modem",
    "power_up_2": "pu_gear_knee_pads",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1745971200000
  },
  {
    "date": "2025-05-01",
    "power_up_1": "pu_services_hash_hounds",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_companions_skeptical_partner",
    "starts_at": 1746057600000
  },
  {
    "date": "2025-05-02",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_specials_mined_first_bitcoin",
    "power_up_3": "pu_specials_gemz_on_x",
    "starts_at": 1746144000000
  },
  {
    "date": "2025-05-03",
    "power_up_1": "pu_companions_techbro",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1746230400000
  },
  {
    "date": "2025-05-04",
    "power_up_1": "pu_services_virtual_mining_coach",
    "power_up_2": "pu_companions_early_adopter_sibling",
    "power_up_3": "pu_specials_denver_eth_conference",
    "starts_at": 1746316800000
  },
  {
    "date": "2025-05-05",
    "power_up_1": "pu_services_mule_rentals",
    "power_up_2": "pu_gear_water_bottle",
    "power_up_3": "pu_gear_oil_immersed_rigs",
    "starts_at": 1746403200000
  },
  {
    "date": "2025-05-06",
    "power_up_1": "pu_services_nft_gallery",
    "power_up_2": "pu_companions_crypto_daytrader",
    "power_up_3": "pu_services_blockbuster_excavation",
    "starts_at": 1746489600000
  },
  {
    "date": "2025-05-07",
    "power_up_1": "pu_gear_satellite_modem",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1746576000000
  },
  {
    "date": "2025-05-08",
    "power_up_1": "pu_services_crypto_concierge",
    "power_up_2": "pu_services_rig_menders",
    "power_up_3": "pu_services_crypto_compliance_check",
    "starts_at": 1746662400000
  },
  {
    "date": "2025-05-09",
    "power_up_1": "pu_services_hash_hackers",
    "power_up_2": "pu_services_blockchain_security_audit",
    "power_up_3": "pu_specials_consensus_conference",
    "starts_at": 1746748800000
  },
  {
    "date": "2025-05-10",
    "power_up_1": "pu_companions_nft_influencer",
    "power_up_2": "pu_services_mine_masters",
    "power_up_3": "pu_services_blockbuster_excavation",
    "starts_at": 1746835200000
  },
  {
    "date": "2025-05-11",
    "power_up_1": "pu_companions_techbro",
    "power_up_2": "pu_gear_knee_pads",
    "power_up_3": "pu_gear_first_aid_kit",
    "starts_at": 1746921600000
  },
  {
    "date": "2025-05-12",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_services_mine_canaries",
    "power_up_3": "pu_companions_ux_designer",
    "starts_at": 1747008000000
  },
  {
    "date": "2025-05-13",
    "power_up_1": "pu_companions_wired_pm",
    "power_up_2": "pu_services_nft_gallery",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1747094400000
  },
  {
    "date": "2025-05-14",
    "power_up_1": "pu_gear_ups_backup",
    "power_up_2": "pu_services_hash_hackers",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1747180800000
  },
  {
    "date": "2025-05-15",
    "power_up_1": "pu_companions_reclusive_backend_dev",
    "power_up_2": "pu_companions_curious_inlaws",
    "power_up_3": "pu_gear_water_bottle",
    "starts_at": 1747267200000
  },
  {
    "date": "2025-05-16",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_gear_oil_immersed_rigs",
    "power_up_3": "pu_services_token_tutors",
    "starts_at": 1747353600000
  },
  {
    "date": "2025-05-17",
    "power_up_1": "pu_gear_lamp",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_companions_crypto_daytrader",
    "starts_at": 1747440000000
  },
  {
    "date": "2025-05-18",
    "power_up_1": "pu_gear_hardhat",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_gear_water_bottle",
    "starts_at": 1747526400000
  },
  {
    "date": "2025-05-19",
    "power_up_1": "pu_gear_protective_gloves",
    "power_up_2": "pu_gear_sturdy_boots",
    "power_up_3": "pu_gear_pickaxe",
    "starts_at": 1747612800000
  },
  {
    "date": "2025-05-20",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_services_hodl_strategy_consultant",
    "power_up_3": "pu_services_hash_hounds",
    "starts_at": 1747699200000
  },
  {
    "date": "2025-05-21",
    "power_up_1": "pu_specials_mined_first_bitcoin",
    "power_up_2": "pu_companions_serial_entrepreneur",
    "power_up_3": "pu_services_crypto_concierge",
    "starts_at": 1747785600000
  },
  {
    "date": "2025-05-22",
    "power_up_1": "pu_specials_dao",
    "power_up_2": "pu_services_smart_contract_builder",
    "power_up_3": "pu_services_transaction_fee_negotiator",
    "starts_at": 1747872000000
  },
  {
    "date": "2025-05-23",
    "power_up_1": "pu_specials_eth_etf",
    "power_up_2": "pu_specials_inhouse_physical_therapist",
    "power_up_3": "pu_gear_climbing_rope",
    "starts_at": 1747958400000
  },
  {
    "date": "2025-05-24",
    "power_up_1": "pu_companions_agile_coach",
    "power_up_2": "pu_specials_eth_etf",
    "power_up_3": "pu_specials_first_eth_validator",
    "starts_at": 1748044800000
  },
  {
    "date": "2025-05-25",
    "power_up_1": "pu_services_nft_gallery",
    "power_up_2": "pu_services_hash_haven",
    "power_up_3": "pu_gear_satellite_modem",
    "starts_at": 1748131200000
  },
  {
    "date": "2025-05-26",
    "power_up_1": "pu_specials_consensus_conference",
    "power_up_2": "pu_companions_hardware_hacker",
    "power_up_3": "pu_gear_sturdy_boots",
    "starts_at": 1748217600000
  },
  {
    "date": "2025-05-27",
    "power_up_1": "pu_gear_ear_protection",
    "power_up_2": "pu_services_virtual_mining_coach",
    "power_up_3": "pu_companions_cryptoconference_junkie",
    "starts_at": 1748304000000
  },
  {
    "date": "2025-05-28",
    "power_up_1": "pu_companions_hardware_hacker",
    "power_up_2": "pu_gear_satellite_modem",
    "power_up_3": "pu_gear_water_cooled_pc",
    "starts_at": 1748390400000
  },
  {
    "date": "2025-05-29",
    "power_up_1": "pu_companions_skeptical_partner",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_services_decentralized_data_storage",
    "starts_at": 1748476800000
  },
  {
    "date": "2025-05-30",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_gear_water_cooled_pc",
    "power_up_3": "pu_companions_concerned_enviromentalist",
    "starts_at": 1748563200000
  },
  {
    "date": "2025-05-31",
    "power_up_1": "pu_services_blockchain_security_audit",
    "power_up_2": "pu_companions_crypto_daytrader",
    "power_up_3": "pu_gear_first_aid_kit",
    "starts_at": 1748649600000
  },
  {
    "date": "2025-06-01",
    "power_up_1": "pu_gear_gas_mask",
    "power_up_2": "pu_companions_wired_pm",
    "power_up_3": "pu_gear_oil_immersed_rigs",
    "starts_at": 1748736000000
  },
  {
    "date": "2025-06-02",
    "power_up_1": "pu_services_rig_optimization_service",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_services_rig_rentals",
    "starts_at": 1748822400000
  },
  {
    "date": "2025-06-03",
    "power_up_1": "pu_companions_concerned_enviromentalist",
    "power_up_2": "pu_services_nft_gallery",
    "power_up_3": "pu_services_transaction_fee_negotiator",
    "starts_at": 1748908800000
  },
  {
    "date": "2025-06-04",
    "power_up_1": "pu_gear_sturdy_boots",
    "power_up_2": "pu_gear_cold_weather_gear",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1748995200000
  },
  {
    "date": "2025-06-05",
    "power_up_1": "pu_services_nft_gallery",
    "power_up_2": "pu_companions_blockchain_evangelist",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1749081600000
  },
  {
    "date": "2025-06-06",
    "power_up_1": "pu_gear_oil_immersed_rigs",
    "power_up_2": "pu_gear_water_bottle",
    "power_up_3": "pu_gear_ear_protection",
    "starts_at": 1749168000000
  },
  {
    "date": "2025-06-07",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_sturdy_boots",
    "power_up_3": "pu_services_hodl_strategy_consultant",
    "starts_at": 1749254400000
  },
  {
    "date": "2025-06-08",
    "power_up_1": "pu_companions_intern",
    "power_up_2": "pu_services_hash_haven",
    "power_up_3": "pu_services_rig_menders",
    "starts_at": 1749340800000
  },
  {
    "date": "2025-06-09",
    "power_up_1": "pu_companions_concerned_enviromentalist",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_companions_serial_entrepreneur",
    "starts_at": 1749427200000
  },
  {
    "date": "2025-06-10",
    "power_up_1": "pu_services_crypto_compliance_check",
    "power_up_2": "pu_services_crypto_crane",
    "power_up_3": "pu_companions_digital_nomad",
    "starts_at": 1749513600000
  },
  {
    "date": "2025-06-11",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_gear_hardhat",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1749600000000
  },
  {
    "date": "2025-06-12",
    "power_up_1": "pu_companions_nft_influencer",
    "power_up_2": "pu_companions_data_scientist",
    "power_up_3": "pu_services_hodl_strategy_consultant",
    "starts_at": 1749686400000
  },
  {
    "date": "2025-06-13",
    "power_up_1": "pu_gear_sturdy_boots",
    "power_up_2": "pu_services_hash_hackers",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1749772800000
  },
  {
    "date": "2025-06-14",
    "power_up_1": "pu_specials_dao",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1749859200000
  },
  {
    "date": "2025-06-15",
    "power_up_1": "pu_services_hash_haven",
    "power_up_2": "pu_services_blockchain_security_audit",
    "power_up_3": "pu_companions_agile_coach",
    "starts_at": 1749945600000
  },
  {
    "date": "2025-06-16",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_specials_denver_eth_conference",
    "power_up_3": "pu_companions_agile_coach",
    "starts_at": 1750032000000
  },
  {
    "date": "2025-06-17",
    "power_up_1": "pu_services_hash_haven",
    "power_up_2": "pu_companions_techbro",
    "power_up_3": "pu_services_hash_hounds",
    "starts_at": 1750118400000
  },
  {
    "date": "2025-06-18",
    "power_up_1": "pu_services_hash_hounds",
    "power_up_2": "pu_services_swap_services",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1750204800000
  },
  {
    "date": "2025-06-19",
    "power_up_1": "pu_gear_gas_mask",
    "power_up_2": "pu_specials_mined_first_bitcoin",
    "power_up_3": "pu_companions_early_adopter_sibling",
    "starts_at": 1750291200000
  },
  {
    "date": "2025-06-20",
    "power_up_1": "pu_gear_water_bottle",
    "power_up_2": "pu_companions_skeptical_partner",
    "power_up_3": "pu_gear_thermal_paste",
    "starts_at": 1750377600000
  },
  {
    "date": "2025-06-21",
    "power_up_1": "pu_specials_denver_eth_conference",
    "power_up_2": "pu_specials_eth_etf",
    "power_up_3": "pu_gear_lamp",
    "starts_at": 1750464000000
  },
  {
    "date": "2025-06-22",
    "power_up_1": "pu_services_mine_canaries",
    "power_up_2": "pu_specials_dao",
    "power_up_3": "pu_companions_early_adopter_sibling",
    "starts_at": 1750550400000
  },
  {
    "date": "2025-06-23",
    "power_up_1": "pu_companions_early_adopter_sibling",
    "power_up_2": "pu_companions_digital_nomad",
    "power_up_3": "pu_specials_inhouse_physical_therapist",
    "starts_at": 1750636800000
  },
  {
    "date": "2025-06-24",
    "power_up_1": "pu_gear_oil_immersed_rigs",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_companions_crypto_daytrader",
    "starts_at": 1750723200000
  },
  {
    "date": "2025-06-25",
    "power_up_1": "pu_companions_data_scientist",
    "power_up_2": "pu_gear_water_cooled_pc",
    "power_up_3": "pu_companions_early_adopter_sibling",
    "starts_at": 1750809600000
  },
  {
    "date": "2025-06-26",
    "power_up_1": "pu_services_decentralized_data_storage",
    "power_up_2": "pu_companions_serial_entrepreneur",
    "power_up_3": "pu_companions_early_adopter_sibling",
    "starts_at": 1750896000000
  },
  {
    "date": "2025-06-27",
    "power_up_1": "pu_gear_climbing_rope",
    "power_up_2": "pu_gear_tool_bag",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1750982400000
  },
  {
    "date": "2025-06-28",
    "power_up_1": "pu_gear_protective_gloves",
    "power_up_2": "pu_gear_climbing_rope",
    "power_up_3": "pu_gear_hardware_wallet",
    "starts_at": 1751068800000
  },
  {
    "date": "2025-06-29",
    "power_up_1": "pu_companions_reclusive_backend_dev",
    "power_up_2": "pu_gear_harness",
    "power_up_3": "pu_services_crypto_cloud_boost",
    "starts_at": 1751155200000
  },
  {
    "date": "2025-06-30",
    "power_up_1": "pu_companions_agile_coach",
    "power_up_2": "pu_gear_knee_pads",
    "power_up_3": "pu_gear_backpack",
    "starts_at": 1751241600000
  },
  {
    "date": "2025-07-01",
    "power_up_1": "pu_services_smart_contract_builder",
    "power_up_2": "pu_services_crypto_crane",
    "power_up_3": "pu_gear_lamp",
    "starts_at": 1751328000000
  },
  {
    "date": "2025-07-02",
    "power_up_1": "pu_services_token_tutors",
    "power_up_2": "pu_services_mule_rentals",
    "power_up_3": "pu_gear_water_bottle",
    "starts_at": 1751414400000
  },
  {
    "date": "2025-07-03",
    "power_up_1": "pu_companions_awkward_ceo",
    "power_up_2": "pu_companions_hardware_hacker",
    "power_up_3": "pu_specials_dao",
    "starts_at": 1751500800000
  },
  {
    "date": "2025-07-04",
    "power_up_1": "pu_specials_inhouse_physical_therapist",
    "power_up_2": "pu_gear_ear_protection",
    "power_up_3": "pu_companions_venture_capitalist",
    "starts_at": 1751587200000
  },
  {
    "date": "2025-07-05",
    "power_up_1": "pu_gear_thermal_paste",
    "power_up_2": "pu_specials_gemz_on_x",
    "power_up_3": "pu_gear_ear_protection",
    "starts_at": 1751673600000
  },
  {
    "date": "2025-07-06",
    "power_up_1": "pu_companions_nft_influencer",
    "power_up_2": "pu_gear_water_bottle",
    "power_up_3": "pu_specials_bitcoin_etf",
    "starts_at": 1751760000000
  },
  {
    "date": "2025-07-07",
    "power_up_1": "pu_specials_bitcoin_etf",
    "power_up_2": "pu_companions_agile_coach",
    "power_up_3": "pu_services_smart_contract_builder",
    "starts_at": 1751846400000
  },
  {
    "date": "2025-07-08",
    "power_up_1": "pu_gear_first_aid_kit",
    "power_up_2": "pu_services_swap_services",
    "power_up_3": "pu_gear_hand_radio",
    "starts_at": 1751932800000
  },
  {
    "date": "2025-07-09",
    "power_up_1": "pu_gear_sturdy_boots",
    "power_up_2": "pu_services_mine_masters",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1752019200000
  },
  {
    "date": "2025-07-10",
    "power_up_1": "pu_services_token_tutors",
    "power_up_2": "pu_gear_first_aid_kit",
    "power_up_3": "pu_gear_satellite_modem",
    "starts_at": 1752105600000
  },
  {
    "date": "2025-07-11",
    "power_up_1": "pu_companions_agile_coach",
    "power_up_2": "pu_companions_wired_pm",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1752192000000
  },
  {
    "date": "2025-07-12",
    "power_up_1": "pu_gear_hand_radio",
    "power_up_2": "pu_gear_first_aid_kit",
    "power_up_3": "pu_gear_satellite_modem",
    "starts_at": 1752278400000
  },
  {
    "date": "2025-07-13",
    "power_up_1": "pu_services_mine_masters",
    "power_up_2": "pu_companions_blockchain_evangelist",
    "power_up_3": "pu_gear_hardhat",
    "starts_at": 1752364800000
  },
  {
    "date": "2025-07-14",
    "power_up_1": "pu_services_rig_rentals",
    "power_up_2": "pu_companions_concerned_enviromentalist",
    "power_up_3": "pu_services_mine_canaries",
    "starts_at": 1752451200000
  },
  {
    "date": "2025-07-15",
    "power_up_1": "pu_services_crypto_compliance_check",
    "power_up_2": "pu_companions_agile_coach",
    "power_up_3": "pu_gear_ear_protection",
    "starts_at": 1752537600000
  },
  {
    "date": "2025-07-16",
    "power_up_1": "pu_specials_first_eth_validator",
    "power_up_2": "pu_gear_pickaxe",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1752624000000
  },
  {
    "date": "2025-07-17",
    "power_up_1": "pu_gear_hardware_wallet",
    "power_up_2": "pu_gear_reflective_vest",
    "power_up_3": "pu_gear_ear_protection",
    "starts_at": 1752710400000
  },
  {
    "date": "2025-07-18",
    "power_up_1": "pu_companions_tech_journalist",
    "power_up_2": "pu_services_rig_optimization_service",
    "power_up_3": "pu_services_rig_menders",
    "starts_at": 1752796800000
  },
  {
    "date": "2025-07-19",
    "power_up_1": "pu_services_hodl_strategy_consultant",
    "power_up_2": "pu_services_crypto_crane",
    "power_up_3": "pu_services_rig_optimization_service",
    "starts_at": 1752883200000
  },
  {
    "date": "2025-07-20",
    "power_up_1": "pu_specials_first_eth_validator",
    "power_up_2": "pu_gear_gas_mask",
    "power_up_3": "pu_companions_wired_pm",
    "starts_at": 1752969600000
  },
  {
    "date": "2025-07-21",
    "power_up_1": "pu_companions_awkward_ceo",
    "power_up_2": "pu_services_decentralized_data_storage",
    "power_up_3": "pu_companions_digital_nomad",
    "starts_at": 1753056000000
  },
  {
    "date": "2025-07-22",
    "power_up_1": "pu_specials_bitcoin_etf",
    "power_up_2": "pu_services_token_tutors",
    "power_up_3": "pu_gear_gas_mask",
    "starts_at": 1753142400000
  },
  {
    "date": "2025-07-23",
    "power_up_1": "pu_companions_techbro",
    "power_up_2": "pu_companions_cryptoconference_junkie",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1753228800000
  },
  {
    "date": "2025-07-24",
    "power_up_1": "pu_services_mine_masters",
    "power_up_2": "pu_specials_dao",
    "power_up_3": "pu_gear_harness",
    "starts_at": 1753315200000
  },
  {
    "date": "2025-07-25",
    "power_up_1": "pu_services_mine_masters",
    "power_up_2": "pu_companions_concerned_enviromentalist",
    "power_up_3": "pu_companions_cryptoconference_junkie",
    "starts_at": 1753401600000
  },
  {
    "date": "2025-07-26",
    "power_up_1": "pu_companions_serial_entrepreneur",
    "power_up_2": "pu_services_crypto_cloud_boost",
    "power_up_3": "pu_gear_tool_bag",
    "starts_at": 1753488000000
  }
];
export default powerUpDaily;
