import {
  SB,
  payloadComputedProperty,
  searchableComputedProperty,
} from '@play-co/replicant';
import { createComputedProperties } from '../../createComputedProperties';
import { getLeague } from './game.getters';
import { floorToSignificantDigits } from '../../utils/numbers';
import Big from 'big.js';

export const gameComputedProperties = createComputedProperties({
  score: searchableComputedProperty(SB.int(), (state) => {
    return floorToSignificantDigits(state.score, 3);
  }),
  scoreSeason1: searchableComputedProperty(SB.int(), (state) => {
    return state.seasonScores[0] ?? 0;
  }),
  referrerContribution: payloadComputedProperty(SB.int(), (state) =>
    Math.round(state.referrerContribution),
  ),
  balance: searchableComputedProperty(SB.int(), (state) => {
    return floorToSignificantDigits(state.balance, 3);
  }),
  name: payloadComputedProperty(SB.string(), (state) => state.profile.name),
  photo: payloadComputedProperty(SB.string(), (state) => state.profile.photo),
  league: searchableComputedProperty(SB.string(), (state) => getLeague(state)),
  notSeenSinceLegacy: searchableComputedProperty(SB.boolean(), (state) => {
    const legacyTerminationDate = 1717113600500;
    const lastSessionTime = state.last_session_time ?? 0;
    return lastSessionTime < legacyTerminationDate;
  }),
  lastBotNotifTime: searchableComputedProperty(SB.int(), (state) => {
    return state.lastNotifTime;
  }),
  lastSession: searchableComputedProperty(
    SB.int(),
    (state) => state.last_session_time,
  ),
  season: searchableComputedProperty(SB.int(), (state) => {
    return state.season;
  }),
  offchainTokens: searchableComputedProperty(
    SB.array(
      SB.object({
        id: SB.string(),
        isCreator: SB.boolean(),
        tokenAmount: SB.number(),
      }),
    ),
    (state) => {
      const offchainTokens = state.trading.offchainTokens;
      const offchainTokenList = Object.keys(offchainTokens);
      return offchainTokenList.map((tokenId) => {
        const ownedToken = offchainTokens[tokenId];
        return {
          id: tokenId,
          isCreator: Boolean(ownedToken.productId),
          tokenAmount: Big(ownedToken.tokenAmount).toNumber(),
        };
      });
    },
  ),
  teamId: searchableComputedProperty(
    SB.string().optional(),
    (state) => state.team_id,
  ),
  banned: searchableComputedProperty(
    SB.boolean(),
    (state) => state.banned !== undefined,
  ),
  updatedAt: searchableComputedProperty(SB.int(), (state) => state.updatedAt),
  followersCount: searchableComputedProperty(
    SB.int(),
    (state) => state.followersCount,
  ),
  referrer: searchableComputedProperty(
    SB.object({
      userId: SB.string().optional(),
      tokenId: SB.string().optional(),
    }),
    (state) => ({
      userId: state.referrer_id,
      tokenId: state.trading.referrerTokenId,
    }),
  ),
});
