import { createScheduledActions } from './createScheduledActions';
import { chatbotScheduledActions } from './features/chatbot/chatbot.scheduledActions';
import { gameScheduledActions } from './features/game/game.scheduledActions';
import { offchainScheduledActions } from './features/offchainTrading/offchainTrading.scheduledActions';
import { friendsScheduledActions } from './features/friends/friends.scheduledActions';

export default createScheduledActions({
  ...gameScheduledActions.schema,
  ...chatbotScheduledActions.schema,
  ...offchainScheduledActions.schema,
  ...friendsScheduledActions.schema,
})({
  ...gameScheduledActions.actions,
  ...chatbotScheduledActions.actions,
  ...offchainScheduledActions.actions,
  ...friendsScheduledActions.actions,
});
