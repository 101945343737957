import { useCallback, useEffect, useRef } from 'react';
import { TradingTokenItem } from '../TradingTokenItem/TradingTokenItem';
import { app } from '../../../../data/Controllers/AppController';
import { TradingTokenListing } from '../../../../replicant/features/offchainTrading/types';
import { TradingTokenPlaceholder } from '../TradingTokenItem/TradingTokenPlaceholder';
import { TradingTokenItemHolder } from '../TradingTokenItemHolder/TradingTokenItemHolder';

interface Props {
  items: TradingTokenListing[];
  track: string;
  placeholderCount: number;
}

export const TradingTokenList = ({ items, track, placeholderCount }: Props) => {
  const { visible } = app.views.MinePage || app.views.ProfilePage;

  const firstPlaceHolderRef = useRef<HTMLDivElement | null>(null);
  const placeholders = new Array(placeholderCount).fill('');

  // Intersection Observer setup
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // Trigger any actions or functions here
        // app.trading.setExtendSearch(true);
        // Paginate
        app.memes.currentList.paginate();
      }
    },
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Observes visibility relative to the viewport
      rootMargin: '0px',
      threshold: 0.1, // Adjust if we want the entire element to be visible
    });

    if (firstPlaceHolderRef.current) {
      observer.observe(firstPlaceHolderRef.current);
    }

    return () => {
      if (firstPlaceHolderRef.current) {
        observer.unobserve(firstPlaceHolderRef.current);
      }
    };
  }, [handleIntersection, items]);

  useEffect(() => {
    if (visible) {
      app.track('Trading_page_view', {
        page: track,
      });
    }
  }, [visible, track]);

  return (
    <div className="trading-items-list">
      {items.map((item, index) => {
        return track === 'myCards' ? (
          <TradingTokenItemHolder
            key={index + '_' + item.offchainTokenId}
            offchainTokenData={item}
          />
        ) : (
          <TradingTokenItem
            key={index + '_' + item.offchainTokenId}
            offchainTokenData={item}
          />
        );
      })}
      {placeholders.map((_, index) => (
        <TradingTokenPlaceholder
          key={index + '_' + _.offchainTokenId}
          ref={index === 0 ? firstPlaceHolderRef : null}
        />
      ))}
    </div>
  );
};
