export const stage = process.env.REACT_APP_STAGE ?? process.env.STAGE; // undefined in local dev

const prodPlayUrl = 'https://ff.notgemz.gemz.fun';

export const config = {
  botName: stage
    ? {
        alpha: 'pnk_alpha_bot',
        bravo: 'pnk_bravo_bot',
        charlie: 'pnk_charlie_bot',
        dev: 'pnk_develop_bot',
        prod: 'gemzcoin_bot',
      }[stage]
    : // todo: ideally this should be a 'local' prop, but there is no local in stage
      'GemzcoinLocal', // undefined

  playUrl: stage
    ? {
        alpha: 'https://notgemz.alpha.pnk.one',
        bravo: 'https://notgemz.bravo.pnk.one',
        charlie: 'https://notgemz.charlie.pnk.one',
        dev: 'https://notgemz.pnk.one',
        prod: prodPlayUrl,
        // use prod play url as fallback to keep things safe
      }[stage] || prodPlayUrl
    : prodPlayUrl,

  migrationUrl:
    stage === 'prod'
      ? 'https://notgemz.game.prod2.pnk.one'
      : 'https://notgemz.game.dev.pnk.one',

  sentryDNS:
    'https://b30872fe6a5951ca2dd8d9dd82c928f1@o4505230490861568.ingest.us.sentry.io/4507338261659648',
};

export default config;
