import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';

import { Friend } from '../../../replicant/features/game/ruleset/friends';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';

export const FriendsCard = ({
  id,
  username,
  contribution,
  picture,
  team,
  score,
  league,
}: Friend) => {
  const onClick = () => {
    app.nav.goToProfile(id);
  };

  return (
    <div className="card friend" onClick={onClick}>
      <div className="card-left">
        <div className="image">
          <img src={picture || assets.mock_portrait} />
        </div>
        <div className="info">
          <div className="name">{username}</div>
          {/* --- */}
          <div className="friend-card-special-info">
            <div className="friend-rank">
              <div className="friend-rank-icon">
                <img src={assets.league[league]} />
              </div>
              <div className="friend-rank-label">{league}</div>
            </div>

            <div className="separator" />

            <div className="friend-bonus">
              {contribution ? `+${formatPrice(contribution)}` : 0}
            </div>
          </div>
          {/* --- */}
        </div>
      </div>

      <div className="score">
        <div className="text">{largeIntegerToLetter(score)}</div>
        <div className="icon">
          <img src={assets.coin} />
        </div>
      </div>
    </div>
  );
};
