import { useState } from 'react';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { generateInviteUrlWithPayload, invite } from '../../../data/sharing';
import { copyToClipboard } from '../../../data/utils';
import './ButtonInvite.scss';

interface Props {
  caption: string;
  feature: string;
  subFeature: string;
  showCopyBtn?: boolean;
  onInvite?: () => void;
}

export const ButtonInvite = ({
  caption,
  feature,
  subFeature,
  showCopyBtn = true,
  onInvite = undefined,
}: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const onInviteFriend = () => {
    app.track('PressInvite', { screen_location: feature });

    invite({
      screen_location: feature, // invite_button
      data: {
        feature,
        $subFeature: subFeature,
      },
    });

    onInvite?.();
  };

  const onCopyLink = () => {
    app.track('CopyInviteLink', { screen_location: feature });

    const url = generateInviteUrlWithPayload({
      screen_location: feature,
      data: {
        feature,
        $subFeature: subFeature,
      },
    });

    copyToClipboard(url);

    // diplay 'copied' tooltip for a second
    setTimeout(() => {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 900);
    }, 250);

    onInvite?.();
  };

  return (
    <div className="buttons-invite">
      <div className="btn btn-normal btn-invite" onClick={onInviteFriend}>
        {caption}
      </div>

      {showCopyBtn && (
        <div className="btn btn-normal btn-copy" onClick={onCopyLink}>
          <img src={assets.copy} />

          {showSuccess && (
            <div className="copy-success">
              <div className="copy-success-icon">
                <img src={assets.copy_success} />
              </div>
              <div className="copy-success-text">Copied!</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
