import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../../assets/assets';
import './TeamButton.scss';
import {
  useAppUpdates,
  app,
} from '../../../../../data/Controllers/AppController';
import { TutorialEvents } from '../../../../../data/tutorial/TutorialController';
import { getUIStateClassName } from '../../../../../data/utils';
import { largeIntegerToLetter } from '../../../../../replicant/utils/numbers';
import { ClickerEvents } from '../../../../../data/Controllers/ClickerController';

interface Props {
  fullWidth: boolean;
}

export const TeamButton = ({ fullWidth }: Props) => {
  useAppUpdates({
    id: 'TeamPage',
    listener: app.clicker.attachEventListener(ClickerEvents.onMyTeamUpdate),
  });

  useAppUpdates({
    id: 'TeamPage/Tutorial',
    listener: app.tutorial.attachEventListener(TutorialEvents.onUpdate),
  });

  const { t } = useTranslation();

  const { state } = app;
  const { playerTeam } = app.clicker;

  const onJoinTeamClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('team');
    app.nav.goTo('JoinTeam');
  };

  const onMyTeamClick = () => {
    app.tutorial.step?.onAction && app.tutorial.step?.onAction('team');
    if (playerTeam) {
      app.views.TeamPage.fetch(playerTeam.id);
      app.nav.goTo('TeamPage');
    }
  };

  // Fetch initial team state.
  const [ready, setReady] = useState(false);
  useEffect(() => {
    // Player not in a team.
    if (!state.team_id) {
      setReady(true);
      return;
    }

    app.replicant.asyncGetters
      .getTeamWithMembers({
        teamId: state.team_id!,
      })
      .then((team) => {
        // if team score is negative, invoke score fix
        // this should already be the user's own team, so no need to check
        if (team && team.score < 0) {
          return app.replicant.invoke.getTeamWithMembersWithScoreFix({
            teamId: team.id,
          });
        }
        return team;
      })
      .then((team) => {
        app.clicker.setPlayerTeam(team);
        setReady(true);
      });
  }, []);

  const uiClassName = getUIStateClassName(app.ui.state.clicker.btnTeam);

  // Show loading while fetching team state.
  if (!ready) {
    return (
      <div className={`team-button ${fullWidth ? 'full-width' : ''}`}>
        <div
          id="join-team"
          className={`btn btn-clicker join-team ${uiClassName}`}
        >
          <div className="join-team-loading">{t('loading')}</div>
        </div>
      </div>
    );
  }

  // Show join team button if player is not in a team.
  if (!playerTeam) {
    return (
      <div className={`team-button ${fullWidth ? 'full-width' : ''}`}>
        <div
          id="join-team"
          className={`btn btn-clicker join-team ${uiClassName}`}
          onClick={onJoinTeamClick}
        >
          {t('team_join_button')}
        </div>
      </div>
    );
  }

  // Show player team info.
  return (
    <div className={`team-button ${fullWidth ? 'full-width' : ''}`}>
      <div
        id="my-team"
        className={`btn btn-clicker my-team ${uiClassName}`}
        onClick={onMyTeamClick}
      >
        <div className="img">
          <img src={playerTeam.photo || assets.friends} alt={playerTeam.name} />
        </div>

        <div className="text">
          <div className="name-label">{playerTeam.name}</div>
          <div className="score">
            <img src={assets.coin} alt="Team Points" />
            <div className="points">
              {largeIntegerToLetter(playerTeam.score)} • Rank #
              {playerTeam?.rank || 3}
            </div>
          </div>
        </div>

        {/* note: commented out so we can use S1/Settings button layout */}
        {/* <div className="rank">
          <Rank rank={playerTeam?.rank || 3} />
        </div> */}
      </div>
    </div>
  );
};
