import { ErrorCode, errorResponse, successResponse } from '../../response';
import { State } from '../../schema';
import { HOUR_IN_MS, MIN_IN_MS } from '../../utils/time';
import {
  AirtableQuestItem,
  Quest,
  QuestActionCTA,
  QuestCategory,
} from './types';
import airtableQuests from '../../../replicant/features/powerups/airtable/earnQuests';
import { t } from 'i18next';

const getQuestFromAirtable = (item: AirtableQuestItem): Quest => {
  const translationKey = (key: string) =>
    item.category === 'video'
      ? `gemz_video_${item.id}`
      : `quest_${item.id}_${key}`;

  const descriptionTranslationKey =
    item.category === 'video'
      ? `gemz_video_description_${item.id}`
      : translationKey('description');

  const hasDescription =
    t(descriptionTranslationKey) !== descriptionTranslationKey;

  const description = hasDescription ? descriptionTranslationKey : null;

  return {
    id: item.id,
    icon: item.icon,
    title: translationKey('title'),
    description,
    category: item.category,
    reward: item.reward,
    checkCTA: item.checkCTA ?? 'check_word',
    actionCTA: item.actionCTA ?? 'follow_word',
    url: item.url ?? null,
    startTime: item.startTime ? new Date(item.startTime).getTime() : null,
    endTime: item.endTime ? new Date(item.endTime).getTime() : null,
    analytics: item.analytics ?? item.id,
    order: item.order ?? 0,
    waitDuration: item.waitDuration ?? null,
    iconUrl: item.iconUrl ?? null,
    creativeUrl: item.creativeUrl ?? null,
    promo: item.promo ?? null,
  };
};

interface QuestOpts {
  id?: string;
  friends?: number;
  waitDuration?: number;
  now?: number;
}

const questCheckers = {
  friendInvite: (state: State, { friends }: QuestOpts) => {
    if (!friends) {
      return errorResponse(`Missing 'friends' opt for quest.`);
    }
    const diff = friends - state.friendCount;
    if (diff > 0) {
      return errorResponse('earn_error_friend', {
        code: ErrorCode.QUEST_FRIEND_INVITE,
        data: {
          translation: true,
          translationOpts: { friends: diff },
        },
      });
    }
    return successResponse('success');
  },
  fakeCheckWithWait: (state: State, { id }: QuestOpts) => {
    if (!id) {
      return errorResponse(`Missing 'id' opt for quest.`);
    }

    const isReadyToClaim = getQuestReadyToClaim(state, id);
    if (!isReadyToClaim) {
      return errorResponse('earn_error_need_waiting', {
        code: ErrorCode.START_WAITING_FOR_QUEST,
        data: { translation: true },
      });
    }
    return successResponse('success');
  },
  fakeCheck: (_s: State, _: QuestOpts) => {
    return successResponse('success');
  },
  walletConnect: (state: State, _: QuestOpts) => {
    if (state.wallet.length) {
      return successResponse('success');
    }
    return errorResponse('earn_connect_wallet_error', {
      code: ErrorCode.WALLET_NOT_CONNECTED,
      data: { translation: true },
    });
  },
  buyMeme: (state: State, _: QuestOpts) => {
    const heldCount = Object.values(
      state?.trading?.offchainTokens ?? {},
    ).length;
    const hasBought = heldCount > 0;
    if (hasBought) {
      return successResponse('success');
    }
    return errorResponse('earn_buy_meme_error', {
      code: ErrorCode.NO_OFFCHAIN_TOKEN_OWNED,
      data: { translation: true },
    });
  },
  createMeme: (state: State, _: QuestOpts) => {
    const createdCount = Object.values(
      state?.trading?.offchainTokens ?? {},
    ).filter((t) => t.productId).length;
    const hasCreated = createdCount > 0;
    if (hasCreated) {
      return successResponse('success');
    }
    return errorResponse('earn_create_meme_error', {
      code: ErrorCode.NO_OFFCHAIN_TOKEN_CREATED,
      data: { translation: true },
    });
  },
};

type QuestCheckerKey = keyof typeof questCheckers;

const customCheckersCfg: Record<
  string,
  { key: QuestCheckerKey; opts?: QuestOpts }
> = {
  buy_meme: {
    key: 'buyMeme',
  },
  create_meme: {
    key: 'createMeme',
  },
  invite_2: {
    key: 'friendInvite',
    opts: { friends: 2 },
  },
  invite_5: {
    key: 'friendInvite',
    opts: { friends: 5 },
  },
  invite_10: {
    key: 'friendInvite',
    opts: { friends: 10 },
  },
  invite_100: {
    key: 'friendInvite',
    opts: { friends: 100 },
  },
  video: {
    key: 'fakeCheckWithWait',
    opts: {
      waitDuration: HOUR_IN_MS,
    },
  },
};

interface CheckQuestOpts {
  id: string;
  category: QuestCategory;
  actionCTA: QuestActionCTA;
}
export const checkQuest = (
  state: State,
  now: number,
  { id, category, actionCTA }: CheckQuestOpts,
) => {
  const customCheckerKey =
    category === 'video'
      ? category
      : actionCTA == 'buy_meme' || actionCTA == 'create_meme'
      ? actionCTA
      : id;

  const custom = customCheckersCfg[customCheckerKey];
  let checkerKey: QuestCheckerKey = 'fakeCheck';
  let opts: QuestOpts = {};
  if (custom) {
    checkerKey = custom.key;
    opts = custom.opts || opts;
  }

  opts.id = id;
  // If the quest has a waitDuration set in CMS, use that
  const quest = getQuest(id);
  if (quest?.waitDuration) {
    opts.waitDuration = quest?.waitDuration;
  }

  const checker = questCheckers[checkerKey];
  return checker(state, opts);
};

export const getQuestCategories = (): QuestCategory[] => [
  'video',
  'special',
  'bonus',
];

export const getQuests = (): Quest[] => {
  return airtableQuests.map(getQuestFromAirtable);
};

export const getQuest = (questId: string) => {
  return getQuests().find((q) => q.id === questId);
};

export const getQuestInitial = (state: State, questId: string) => {
  return state.quests[questId]?.state === 'default';
};

export const getQuestComplete = (state: State, questId: string) => {
  return state.quests[questId]?.state === 'complete';
};

export const getQuestOnWait = (state: State, questId: string) => {
  return state.quests[questId]?.state === 'waiting';
};

export const getQuestReadyToClaim = (state: State, questId: string) => {
  return state.quests[questId]?.state === 'ready_to_claim';
};

export const getQuestIsPromo = (quest: AirtableQuestItem | Quest) => {
  return Boolean(quest.endTime) && Boolean(quest.promo);
};

export const getQuestsByCategory = (quests: Quest[]) => {
  /**
   * also sort the quests within categories
   */
  const questsByCategory = getQuestCategories().reduce((res, cur) => {
    return {
      ...res,
      [cur]: quests
        .filter((q) => q.category === cur)
        .sort((a, b) => a.order - b.order),
    };
  }, {} as Record<QuestCategory, Quest[]>);

  // Remove any categories which are empty
  const categories = getQuestCategories().filter((c) =>
    Boolean(questsByCategory[c].length),
  );

  return {
    questsByCategory,
    categories,
  };
};

export const getQuestsReadyToClaim = (state: State, now: number) => {
  const quests = Object.keys(state.quests);

  const questIdsReadyToClaim = quests.reduce((res, cur) => {
    const questState = state.quests[cur];
    if (questState.state !== 'waiting') {
      return res;
    }
    if (questState.firstCheckAt === undefined) {
      return res;
    }
    const quest = airtableQuests.find((q) => q.id === cur);
    if (!quest) {
      return res;
    }
    const hasWaited =
      now - questState.firstCheckAt > (quest.waitDuration || HOUR_IN_MS);
    if (!hasWaited) {
      return res;
    }
    return [...res, cur];
  }, [] as string[]);

  return questIdsReadyToClaim;
};

export function getActivePromoQuests(now: number) {
  const quests = airtableQuests as AirtableQuestItem[];
  return (
    quests
      .filter((quest) => {
        if (!getQuestIsPromo(quest)) {
          return false;
        }

        return quest.endTime! > now + 5 * MIN_IN_MS;
      })
      // sort by end time in increasing order
      .sort((a, b) => {
        return a.endTime! - b.endTime!;
      })
  );
}

export function getUnpromotedQuests(state: State, now: number) {
  const questsStatuses = state.quests;

  // uncomment to test promo quest drawer
  // return [airtableQuests[7]];

  return getActivePromoQuests(now).filter((quest) => {
    return !questsStatuses[quest.id]?.promoted;
  });
}
