import './MineTabs.scss';
import { t } from 'i18next';
import { app } from '../../../../data/Controllers/AppController';
import { useEffect, useMemo, useRef, useState } from 'react';
import { tests } from '../../../../replicant/ruleset';
import { Tabs, TabStyle } from '../../../shared/Tabs/Tabs';
import { PowerUpList } from './PowerUpList';
import {
  PowerUp,
  PowerUpCardType,
  PowerUpCategory,
} from '../../../../replicant/features/powerups/types';

type PowerUpSpecials = 'Owned' | 'Available' | 'Missed';

interface Props {
  onTapItem: (powerup: PowerUp, hideClose?: boolean) => void;
}

export const MineTabs = ({ onTapItem }: Props) => {
  const { data, visible } = app.views.MinePage;
  const unseenCards = app.replicant.state.badgeControl.cards;
  const visibleRef = useRef(visible);

  // ---------------------------
  // configure data

  let { gear, services, companions, specials, badges, initialTab } =
    useMemo(() => {
      const specials = (data || []).filter((pu) => pu.category === 'Specials');

      const available = specials.filter(
        (pu) =>
          pu.specialState === 'Available' && pu.type !== PowerUpCardType.HIDDEN,
      );

      let availableFinal = available.sort(
        (a, b) => (a.sort ?? Infinity) - (b.sort ?? Infinity),
      );

      if (app.tutorial.step?.stepActive('MinePageCard')) {
        availableFinal = availableFinal.map((pu) => {
          if (pu.type === 'tutorial_1') {
            return pu;
          }
          return {
            ...pu,
            locked: 'Require end tutorial',
          };
        });
      }

      const _data = data || [];
      // badges per tab
      const unseenCategories = unseenCards.map((card) =>
        _data.find((pu) => pu.id === card.id),
      );

      const unseenGear = unseenCategories.filter(
        (pu) => pu?.category === 'Gear',
      );
      const unseenCompanions = unseenCategories.filter(
        (pu) => pu?.category === 'Companions',
      );
      const unseenServices = unseenCategories.filter(
        (pu) => pu?.category === 'Services',
      );
      const unseenSpecials = unseenCategories.filter(
        (pu) => pu?.category === 'Specials',
      );
      const unseenSpecialsAvailable = unseenSpecials.filter(
        (pu) => pu?.specialState === 'Available',
      );

      let initialTab = 'Gear';
      if (unseenGear.length) {
        initialTab = 'Gear';
      } else if (unseenCompanions.length) {
        initialTab = 'Companions';
      } else if (unseenServices.length) {
        initialTab = 'Services';
      } else if (unseenSpecials.length) {
        initialTab = 'Specials';
      }

      const notShowBadges = app.realFirstEverSession || app.tutorial.active;

      return {
        gear: _data.filter((pu) => pu.category === 'Gear'),
        companions: _data.filter((pu) => pu.category === 'Companions'),
        services: _data.filter((pu) => pu.category === 'Services'),
        specials: {
          owned: specials.filter((pu) => pu.specialState === 'Owned'),
          // sort list with values that have sorted prop set first, then unset values
          available: availableFinal,
          missed: specials.filter((pu) => pu.specialState === 'Missed'),
        },
        initialTab,
        badges: {
          gear: notShowBadges ? [] : unseenGear,
          companions: notShowBadges ? [] : unseenCompanions,
          services: notShowBadges ? [] : unseenServices,
          specials: {
            total: notShowBadges ? [] : unseenSpecials,
            available: notShowBadges ? [] : unseenSpecialsAvailable,
          },
        },
      };
    }, [data]);

  let specialsIntialTab = 'Owned' as PowerUpSpecials;
  if (badges.specials.available.length) {
    specialsIntialTab = 'Available';
  }

  // ---------------------------
  // specials related unique inits

  const [specialsChecked, setSpecialsChecked] = useState(false);
  const [currentTab, setCurrentTab] = useState(initialTab);

  const onTabClick = (
    tab: PowerUpCategory | PowerUpSpecials,
    previousTab: PowerUpCategory | PowerUpSpecials,
  ) => {
    if (tab === 'Specials') {
      if (!specialsChecked) {
        app.clicker.initPowerUpSpecials().then(() => {
          app.views.MinePage.fetch();
        });
      }
      setSpecialsChecked(true);
    }

    setCurrentTab(tab);
    cleanBadge(previousTab);

    app.tutorial.step?.onAction?.();
  };

  // ---------------------------
  // configure badges

  // If the component becomes invisible after being visible,
  // clean the badges from current tab assuming user already saw them
  useEffect(() => {
    if (!visible && visibleRef.current) {
      cleanBadge(currentTab as PowerUpCategory | PowerUpSpecials, true);
    }
    visibleRef.current = visible;
  }, [visible, currentTab]);

  const cleanBadge = (
    tab: PowerUpCategory | PowerUpSpecials,
    force = false,
  ) => {
    if (!visible && !force) {
      return;
    }
    let ids = [] as any[];
    if (tab === 'Gear') {
      ids = badges.gear.map((pu) => pu?.id);
    } else if (tab === 'Companions') {
      ids = badges.companions.map((pu) => pu?.id);
    } else if (tab === 'Services') {
      ids = badges.services.map((pu) => pu?.id);
    } else if (tab === 'Specials' || tab === 'Available') {
      ids = badges.specials.available.map((pu) => pu?.id);
      setSpecialsChecked(true);
      tab = 'Specials';
    }
    app.ui.setPowerupCardsBadgesStateChecked(tab, ids);
    app.invoke.cleanBadge({
      ids: ids.filter((id) => !!id),
      type: 'cards',
    });
  };

  // ---------------------------
  // render

  return (
    <div className="mine-tabs-container">
      <Tabs<PowerUpCategory>
        tabStyle={TabStyle.Squared}
        initial={initialTab as PowerUpCategory}
        onTabClick={onTabClick}
        autoSelected={
          app.tutorial.step?.stepActive('MinePageCard') ? 'Specials' : undefined
        }
        uiState={app.ui.state.mine.btnTab}
        tabs={[
          {
            id: 'Gear',
            name: t('pup_tab_gear'),
            badge: {
              visible,
              amount: badges.gear.length,
              checked: app.ui.powerupCardsBadgesState.Gear,
            },
            component: (
              <PowerUpList
                items={gear}
                onClick={(powerup) => onTapItem(powerup)}
                track="gear"
              />
            ),
          },
          {
            id: 'Companions',
            name: t('pup_tab_companions'),
            badge: {
              visible,
              amount: badges.companions.length,
              checked: app.ui.powerupCardsBadgesState.Companions,
            },
            component: (
              <PowerUpList
                items={companions}
                onClick={(powerup) => onTapItem(powerup)}
                track="companions"
              />
            ),
          },
          {
            id: 'Services',
            name: t('pup_tab_services'),
            badge: {
              visible,
              amount: badges.services.length,
              checked: app.ui.powerupCardsBadgesState.Services,
            },
            component: (
              <PowerUpList
                items={services}
                onClick={(powerup) => onTapItem(powerup)}
                track="services"
              />
            ),
          },
          {
            id: 'Specials',
            name: t('pup_tab_specials'),
            badge: {
              visible,
              amount: badges.specials.total.length,
              checked: app.ui.powerupCardsBadgesState.Specials,
            },
            component: (
              <Tabs<PowerUpSpecials>
                tabStyle={TabStyle.Underline}
                onTabClick={onTabClick}
                initial={specialsIntialTab}
                autoSelected={
                  app.tutorial.step?.stepActive('MinePageCard')
                    ? 'Available'
                    : undefined
                }
                uiState={app.ui.state.mine.btnTab}
                tabs={[
                  {
                    id: 'Owned',
                    name: t('pup_tab_owned'),
                    component: (
                      <PowerUpList
                        items={specials.owned}
                        onClick={(powerup) => onTapItem(powerup)}
                        track="specials/owned"
                      />
                    ),
                  },
                  {
                    id: 'Available',
                    name: t('pup_tab_available'),
                    badge: {
                      visible,
                      amount: badges.specials.available.length,
                      checked: app.ui.powerupCardsBadgesState.Specials,
                    },
                    component: (
                      <PowerUpList
                        items={specials.available}
                        onClick={(powerup) => onTapItem(powerup)}
                        track="specials/available"
                      />
                    ),
                  },
                  {
                    id: 'Missed',
                    name: t('pup_tab_missed'),
                    component: (
                      <PowerUpList
                        items={specials.missed}
                        onClick={(powerup) => onTapItem(powerup)}
                        track="specials/missed"
                      />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
