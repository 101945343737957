import './PowerUpCard.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import {
  PowerUpUI,
  PowerUpCardType,
} from '../../../../replicant/features/powerups/types';
import { getUIStateClassName, msToSeconds } from '../../../../data/utils';
import { msToFullTimeWithDays } from '../../../../data/utils';
import { app } from '../../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';
import { Badge } from '../../../shared/Badge/Badge';
import { tests } from '../../../../replicant/ruleset';
import {
  getGiftOnlyCardsReceived,
  getNoOfGiftsAtLevel,
  getPowerUpAvailableTime,
} from '../../../../replicant/features/powerups/getters';

interface Props {
  powerUp: PowerUpUI;
  onClick: (powerup: PowerUpUI) => void;
}

export const PowerUpCard = ({ powerUp, onClick }: Props) => {
  const { t } = useTranslation();

  const {
    id,
    details,
    earn,
    cost,
    level,
    locked,
    endTime,
    isGift,
    isFriendGated,
    expireTime,
    specialState,
    maxLevel,
    type,
    hasBadge,
  } = powerUp;

  let noOfGiftsAtLevel = 0;
  const isGiftOnlyCard = type === PowerUpCardType.GIFT_ONLY;
  if (isGiftOnlyCard) {
    noOfGiftsAtLevel =
      getNoOfGiftsAtLevel(level + 1, id) -
      getGiftOnlyCardsReceived(app.state, id);
  }
  const { playerBalance } = app;
  const isOwned = specialState === 'Owned';

  const [timeLeft, setTimeLeft] = useState(
    (expireTime || endTime || 0) - app.now(),
  );

  const availableTime = getPowerUpAvailableTime(app.state, powerUp);
  const [timeUntilAvailable, setTimeUntilAvailable] = useState(
    availableTime - app.now(),
  );

  const ended = Boolean(endTime && app.now() > endTime);
  const expired = Boolean(expireTime && app.now() > expireTime);

  const isShowingLevel = (!expired && !ended) || isOwned;
  const isShowingEnded = endTime && app.now() > endTime && !isOwned;

  const canBuy = playerBalance >= cost || isGift;

  const isMaxLevel = maxLevel === level;

  const prettyTimeUntilAvailable =
    timeUntilAvailable > 0
      ? msToFullTimeWithDays(timeUntilAvailable, {
          includeSeconds: false,
          includeMinutes: false,
        })
      : undefined;

  const lock =
    (locked && !isGift) ||
    prettyTimeUntilAvailable ||
    (endTime && app.now() > endTime && isOwned === false) ||
    (expireTime && app.now() > expireTime);

  const prettyTime =
    timeLeft > 0
      ? msToFullTimeWithDays(timeLeft, {
          includeSeconds: false,
          includeMinutes: false,
        })
      : undefined;

  const onBuyClick = async () => {
    // todo: not sure how to trigger this from modules.ts for this specific case
    app.tutorial.step?.onAction?.();

    if (isFriendGated) {
      onClick(powerUp);
      return;
    }
    if (!canBuy || lock || isMaxLevel) return;
    onClick(powerUp);
  };

  useEffect(() => {
    if (!endTime && !expireTime) {
      return;
    }

    if (endTime && ended) {
      return;
    }

    if (expireTime && expired) {
      return;
    }

    if (!app.views.MinePage.visible) {
      return;
    }

    const interval = setInterval(() => {
      if (expireTime) {
        setTimeLeft(expireTime - app.now());
      } else if (endTime) {
        setTimeLeft(endTime - app.now());
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime, expireTime, ended, expired, app.views.MinePage.visible]);

  useEffect(() => {
    if (!availableTime) {
      return;
    }

    const interval = setInterval(() => {
      if (availableTime) {
        setTimeUntilAvailable(availableTime - app.now());
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [availableTime, app.views.MinePage.visible]);

  if (!app.views.MinePage.visible) {
    return null;
  }

  const lockedText = locked;

  return (
    <div
      className={`btn powerup-card ${
        powerUp.isGift && !ended ? 'gift-card' : ''
      } ${getUIStateClassName(app.ui.getCardState(id))}`}
      onClick={onBuyClick}
    >
      <Badge
        visible={hasBadge}
        hasNoText
        checked={app.ui.powerupCardsBadgesState[id]}
      />
      <div className="puc-img-container">
        <div className="puc-img">
          <img
            className={lock || isMaxLevel ? `disabled` : ''}
            src={details.image}
          ></img>
          {lock && <img className="locked" src={assets.powerup_locked} />}
          {prettyTimeUntilAvailable && (
            <div className={`timer ${endTime || expireTime ? 'above' : ''}`}>
              <img className="icon icon-tinted" src={assets.powerup_timer} />
              <div className="label">{prettyTimeUntilAvailable}</div>
            </div>
          )}
          {(endTime || expireTime) && (
            <div className="timer">
              <img className="icon" src={assets.powerup_timer} />
              <div className="label">{prettyTime}</div>
            </div>
          )}
        </div>
      </div>
      <div className="puc-name">
        <div>{t(`${id}_name`)}</div>
      </div>
      <div className="puc-earn">
        <div className="puc-earn-title">{t('pup_earn_hr')}</div>

        <div className="puc-earn-coins">
          <img src={assets.coin} />+{largeIntegerToLetter(earn)}
        </div>
      </div>

      {powerUp.isGift && locked && !ended ? (
        <div className="puc-footer gift-card">
          <div className="">{t('pu_specials_send_free_gift')}</div>
        </div>
      ) : (
        <div className="puc-footer">
          {isShowingLevel && (
            <div
              className={`puc-btn puc-level ${
                (lock || isMaxLevel) && 'locked'
              }`}
            >
              {isMaxLevel ? `Max level` : `${t('pup_lvl')}  ${level}`}
            </div>
          )}
          {!isMaxLevel && (
            <div
              className={`puc-btn puc-cost ${lock && 'locked'} ${
                !canBuy && !lock && 'disabled'
              }`}
            >
              {lockedText ? (
                <div>{t(lockedText)}</div>
              ) : isShowingEnded ? (
                <div>Ended</div>
              ) : expired ? (
                <div>Expired</div>
              ) : (
                <>
                  {isGiftOnlyCard ? (
                    <div>&#127873; </div>
                  ) : (
                    <img src={assets.coin} />
                  )}
                  <div>
                    {largeIntegerToLetter(
                      isGiftOnlyCard ? noOfGiftsAtLevel : cost,
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
