import { MIN_IN_MS, SEC_IN_MS } from '../../../utils/time';

export const specialCardWaitTimes = [
  0,
  15 * SEC_IN_MS,
  30 * SEC_IN_MS,
  1 * MIN_IN_MS,
  5 * MIN_IN_MS,
  10 * MIN_IN_MS,
  30 * MIN_IN_MS,
  60 * MIN_IN_MS,
  120 * MIN_IN_MS,
  120 * MIN_IN_MS,
  240 * MIN_IN_MS,
  480 * MIN_IN_MS,
  960 * MIN_IN_MS,
  1440 * MIN_IN_MS,
  2480 * MIN_IN_MS,
  4960 * MIN_IN_MS,
  9920 * MIN_IN_MS,
];
