import { ReplicantMigrator } from '@play-co/replicant';

// IMPORTANT: Do *NOT* import anything.

const migrator = new ReplicantMigrator();

// Do *NOT* modify existing migrations.

/**
 * Remove the `friends` property replaced by `friendIds`
 */
migrator.addMigration(1, (state) => {
  delete state.friends;
});

// Add migrations right above this line. Do *NOT* modify existing migrations.

export default migrator;
