import { createAsyncGetters } from '../../createAsyncGetters';
import { dedupArray } from '../../utils/objects';
import { League } from './ruleset/league';

export const gameAsyncGetters = createAsyncGetters({
  getPlayersByLeague: async (
    {
      season,
      league,
      sortField,
      currentUserId,
    }: {
      season: number;
      league: League;
      sortField: 'score' | 'balance';
      currentUserId: string;
    },
    api,
  ) => {
    const limit = 100;
    // look for current player in current league
    const response = await api.searchPlayers({
      where: {
        league: {
          isAnyOf: [league],
        },
        season: {
          between: [season, season],
        },
        id: {
          isOneOf: [currentUserId],
        },
      },
      limit: 1,
    });

    const currentPlayerData = response.results[0];
    let rank = -1;
    let outRank = undefined;

    // if he is in this league, see how many guys are in front of him
    if (currentPlayerData) {
      rank = await api.countPlayers({
        where: {
          league: {
            isAnyOf: [league],
          },
          season: {
            between: [season, season],
          },
          banned: {
            is: false,
          },
          [sortField]: {
            greaterThan: currentPlayerData[sortField],
          },
        },
      });

      // if rank < 100, player is in top 100 and will be visible anyway
      if (rank > limit) {
        outRank = {
          rank: rank + 1,
          picture: currentPlayerData.photo,
          username: currentPlayerData.name,
          score: currentPlayerData.score,
          league,
        };
      }
    }

    const players = await api.searchPlayers({
      where: {
        league: {
          isAnyOf: [league],
        },
        season: {
          between: [season, season],
        },
        banned: {
          is: false,
        },
      },
      limit,
      sort: [{ field: sortField, order: 'desc' }],
    });

    const ranked = players.results.map((p, i) => ({
      rank: i + 1,
      picture: p.photo,
      username: p.name,
      score: p.score,
      league,
      isCurrentUser: p.id === currentUserId,
    }));

    return {
      ranked,
      outRank,
      userRank: rank === -1 ? undefined : rank + 1,
    };
  },
  getMyReferrals: async ({ userId }: { userId: string }, api) => {
    const referralsResponse = await api.searchPlayers({
      where: {
        referrer: {
          userId: {
            isAnyOf: [userId],
          },
        },
      },
      limit: 1000,
    });

    // All the players that joined the game under my referral (get 10% from these)
    const referrals = referralsResponse.results;

    const myReferralsIds = referrals.map((r) => r.id);
    const tokenIds = dedupArray(
      referrals.map((r) => r.referrer?.tokenId).filter(Boolean) as string[],
    );

    // All the referrals made by my referrals on the same token (get 2.5% from there)
    const referralsOfMyReferralsResponse = await api.searchPlayers({
      where: {
        referrer: {
          userId: {
            isAnyOf: myReferralsIds,
          },
          tokenId: {
            isAnyOf: tokenIds,
          },
        },
      },
      limit: 1000,
    });

    // All
  },
});
