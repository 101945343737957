import { useCallback } from 'react';
import { MemeFilters } from '../../../../data/Controllers/Memes/types';

interface FeedItemfilterButtonProps {
  filter: MemeFilters;
  selectedFilter: MemeFilters;
  onTap: (cat: MemeFilters) => Promise<void>;
}

export const FeedItemFilterButton = ({
  filter,
  selectedFilter,
  onTap,
}: FeedItemfilterButtonProps) => {
  const onClick = useCallback(() => {
    onTap(filter);
  }, [filter, onTap]);

  return (
    <div
      onClick={onClick}
      className={`btn feed-item-ui-category ${
        filter === selectedFilter ? 'selected' : ''
      }`}
    >
      <div className="feed-item-ui-category-label">{filter}</div>
    </div>
  );
};
