import { getEarliestNotifiableTime } from '../game/game.getters';
import { MutableState } from '../../schema';
import {
  AnalyticsPayload,
  ChatbotTelegramMessageSend,
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import templates, {
  getMediaUrl,
  getNotificationMedia,
} from './chatbot.templates';
import { booleanRoll, displayPrice, formatPrice } from '../../utils/numbers';
import {
  DAY_IN_MS,
  HOUR_IN_MS,
  MIN_IN_MS,
  MONTH_IN_MS,
  WEEK_IN_MS,
} from '../../utils/time';
import {
  NotificationFeature,
  notifFeaturePriorities,
  maxNotifDelays,
  highPriorityNotifs,
  cmdReplyCaptions,
  notificationFolderUrl,
} from './chatbot.ruleset';
import { BotNotifBacklog } from '../game/player.schema';
import { getActivePromoQuests } from '../quests/getters';
import {
  getCoinAmountForTokenSell,
  getCurvePrice,
  getValueChange,
} from '../offchainTrading/offchainTrading.getters';
import Big from 'big.js';
import { durationAwayBeforeQuestNotifTrigger } from '../game/ruleset/quests';
import { TradingSearchResult } from '../offchainTrading/offchainTrading.properties';
import { tests } from '../../ruleset';
import { getReceiverBucket } from '../game/abtest.getters';
import { getChatbotPayload } from './chatbot.getters';
import { OffchainTokenStatus } from '../offchainTrading/offchainTrading.schema';

export type ChatbotTelegramMessageSendOpts =
  Parameters<ChatbotTelegramMessageSend>[0] & {
    maxDelayMs: number;
  };

export function sendTelegramMessage(
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantSyncActionAPI<ReplicantServer>,
  opts: ChatbotTelegramMessageSendOpts,
) {
  api.chatbot.sendTelegramMessage(opts);

  state.lastNotifTime = api.date.now();
}

export const notifyAboutNewQuests = (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  const now = api.date.now();
  const lastSessionTime = state.last_session_end_time;
  if (now - lastSessionTime <= durationAwayBeforeQuestNotifTrigger) {
    // played too recently to be notified about new quests
    return false;
  }

  const hotQuests = getActivePromoQuests(now);
  const unnotifiedQuests = hotQuests.filter((questConfig) => {
    const quest = state.quests[questConfig.id];
    if (!quest) {
      return true;
    }

    return !quest.notified && quest.state === 'default';
  });

  if (unnotifiedQuests.length === 0) {
    return false;
  }

  const hottestQuest = unnotifiedQuests[0];
  const mediaUrl = getMediaUrl('newQuest', {
    customUrl: hottestQuest.creativeUrl,
    odds: 0.25,
  });

  sendTelegramMessage(state, api, {
    chatId: state.id,
    message: templates.newQuest({
      args: {
        userId: state.id,
        mediaUrl,
      },
      payload: getChatbotPayload({
        feature: 'quest',
        $subFeature: 'quest_awareness',
        mediaUrl,
        nonCuratedCreativeId: hottestQuest.creativeUrl,
        // payload: {
        //   dlRoute: 'EarnPage',
        // },
      }),
    }),
    receiverId: state.id,
    maxDelayMs: maxNotifDelays.newQuest,
    priority: highPriorityNotifs.newQuest,
  });

  if (state.quests[hottestQuest.id]) {
    state.quests[hottestQuest.id].notified = true;
  } else {
    state.quests[hottestQuest.id] = {
      state: 'default',
      notified: true,
    };
  }

  return true;
};

export const notifyAboutOffchainTokens = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  const ownedOffchainTokenIds = Object.keys(state.trading.offchainTokens);
  if (ownedOffchainTokenIds.length === 0) {
    return false;
  }

  const ownedCards: TradingSearchResult[] = (
    await api.sharedStates.offchainTrading.search({
      where: {
        id: {
          isOneOf: ownedOffchainTokenIds,
        },
      },
      sort: [
        {
          field: 'lastTx.createdAt',
          order: 'desc',
        },
      ],
      limit: 25,
    })
  ).results;

  if (ownedCards?.length === 0) {
    return false;
  }

  let cardOfInterest: TradingSearchResult = ownedCards[0];
  let largestPriceChange = 0;

  const significantChange = 0.25;

  const cardStatuses = state.trading.offchainTokens;
  ownedCards.forEach((card: TradingSearchResult) => {
    const cardStatus = cardStatuses[card.id];
    const priceNow = getCurvePrice(Big(card.supply));

    if (!cardStatus.lastNotifPrice) {
      // make sure a comparison point exists for the token price
      cardStatus.lastNotifPrice = priceNow.toString();
      return;
    }

    const lastNotifPrice = Big(cardStatus.lastNotifPrice);

    const changeSinceLastNotif = getValueChange(priceNow, lastNotifPrice);

    if (Math.abs(largestPriceChange) < Math.abs(changeSinceLastNotif)) {
      cardOfInterest = card;
      largestPriceChange = changeSinceLastNotif;
    }
  });

  if (Math.abs(largestPriceChange) < significantChange) {
    return false;
  }

  const templateId =
    largestPriceChange > 0
      ? 'offchainTradingCardPriceUp'
      : 'offchainTradingCardPriceDown';

  const mediaUrl = getMediaUrl(templateId, {
    customUrl: cardOfInterest.profile.image,
    odds: 0.25,
  });

  sendTelegramMessage(state, api, {
    chatId: state.id,
    message: templates[templateId]({
      args: {
        userId: state.id,
        mediaUrl,
        tokens: {
          tokenName: cardOfInterest.profile.name,
          priceChange: formatPrice(
            Math.round(Math.abs(largestPriceChange) * 100),
          ),
          price: displayPrice(
            getCurvePrice(Big(cardOfInterest.supply)).round().toNumber(),
          ),
        },
      },
      payload: getChatbotPayload({
        feature: 'trading',
        $subFeature:
          largestPriceChange > 0
            ? 'offchainTrading_card_up'
            : 'offchainTrading_card_down',
        mediaUrl,
        nonCuratedCreativeId: 'meme_image',
        payload: {
          dlRoute: 'TradingTokenPage',
          dlOpts: { offchainTokenId: cardOfInterest.id },
        },
      }),
    }),
    receiverId: state.id,
    maxDelayMs: maxNotifDelays.offchainTradingCardPriceUp,
    priority: highPriorityNotifs.offchainTradingCardPriceUp,
  });

  const cardPrice = getCurvePrice(Big(cardOfInterest.supply));
  state.trading.offchainTokens[cardOfInterest.id].lastNotifPrice =
    cardPrice.toString();
  return true;
};

const notifyWithTokenFomo = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  const now = api.date.now();

  const runawayTokens = (
    await api.sharedStates.offchainTrading.search({
      where: {
        status: {
          isAnyOf: [
            OffchainTokenStatus.Created,
            OffchainTokenStatus.Moderated,
            OffchainTokenStatus.ModeratedOS,
            OffchainTokenStatus.Reported,
          ],
        },
        availableAt: {
          // min age
          lessThanOrEqual: now - 4 * HOUR_IN_MS,
          // max age
          greaterThanOrEqual: now - 24 * HOUR_IN_MS,
        },
        holderCount: {
          greaterThanOrEqual: 50,
        },
        priceChange: {
          last1hour: {
            greaterThanOrEqual: 1,
          },
        },
      },
      sort: [
        {
          field: 'distribution',
          order: 'asc',
        },
      ],
      limit: 1,
    })
  ).results;

  if (runawayTokens.length > 0) {
    // notify about the runaway token
    const runawayToken = runawayTokens[0];

    const mediaUrl = getMediaUrl('offchainTradingFomoRunaway', {
      customUrl: runawayToken.profile.image,
      odds: 0.25,
    });

    sendTelegramMessage(state, api, {
      chatId: state.id,
      message: templates.offchainTradingFomoRunaway({
        args: {
          userId: state.id,
          mediaUrl,
          tokens: {
            tokenName: runawayToken.profile.name,
            tokenTicker: runawayToken.profile.ticker,
            priceChange: formatPrice(
              Math.round(runawayToken.priceChange.last1hour * 100),
            ),
          },
        },
        payload: getChatbotPayload({
          feature: 'trading',
          $subFeature: 'offchainTrading_fomo_runaway',
          mediaUrl,
          nonCuratedCreativeId: 'meme_image',
          payload: {
            dlRoute: 'TradingTokenPage',
            dlOpts: { offchainTokenId: runawayToken.id },
          },
        }),
      }),
      receiverId: state.id,
      maxDelayMs: maxNotifDelays.offchainTradingFomoRunaway,
      priority: highPriorityNotifs.offchainTradingFomoRunaway,
    });

    return true;
  }

  const popularTokens = (
    await api.sharedStates.offchainTrading.search({
      where: {
        status: {
          isAnyOf: [
            OffchainTokenStatus.Created,
            OffchainTokenStatus.Moderated,
            OffchainTokenStatus.ModeratedOS,
            OffchainTokenStatus.Reported,
          ],
        },
        availableAt: {
          // min age
          lessThanOrEqual: now - 36 * HOUR_IN_MS,
        },
        holderCount: {
          greaterThanOrEqual: 200,
        },
        priceChange: {
          last24hours: {
            greaterThanOrEqual: 0.5,
          },
        },
      },
      sort: [
        {
          field: 'distribution',
          order: 'asc',
        },
      ],
      limit: 1,
    })
  ).results;

  if (popularTokens.length > 0) {
    const popularToken = popularTokens[0];

    const mediaUrl = getMediaUrl('offchainTradingFomoPopular', {
      customUrl: popularToken.profile.image,
      odds: 0.25,
    });

    sendTelegramMessage(state, api, {
      chatId: state.id,
      message: templates.offchainTradingFomoPopular({
        args: {
          userId: state.id,
          mediaUrl,
          tokens: {
            tokenName: popularToken.profile.name,
            tokenTicker: popularToken.profile.ticker,
            priceChange: formatPrice(
              Math.round(popularToken.priceChange.last1hour * 100),
            ),
          },
        },
        payload: getChatbotPayload({
          feature: 'trading',
          $subFeature: 'offchainTrading_fomo_popular',
          mediaUrl,
          nonCuratedCreativeId: 'meme_image',
          payload: {
            dlRoute: 'TradingTokenPage',
            dlOpts: { offchainTokenId: popularToken.id },
          },
        }),
      }),
      receiverId: state.id,
      maxDelayMs: maxNotifDelays.offchainTradingFomoPopular,
      priority: highPriorityNotifs.offchainTradingFomoPopular,
    });

    return true;
  }

  return false;
};

export const sendBotNotification = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  // Order of priorities:
  // - Quest notifications
  // - FOMO token notifications
  // - Owned token notifications
  // - everything else

  const notifFeatures = Object.keys(
    state.notificationBacklog,
  ) as NotificationFeature[];
  const now = api.date.now();

  const earliestNoticationTime = getEarliestNotifiableTime(state, now);
  const leeway = MIN_IN_MS * 15;
  if (earliestNoticationTime > now + leeway) {
    // too early
    scheduleBotNotification(state, api);
    return;
  }

  // check if player should be notified about new quest
  const notifiedAboutNewQuests = notifyAboutNewQuests(state, api);
  if (notifiedAboutNewQuests) {
    state.lastNotifTime = now;
    scheduleBotNotification(state, api);
    return;
  }

  const ownedOffchainTokenIds = Object.keys(state.trading.offchainTokens);
  if (ownedOffchainTokenIds.length > 0) {
    const notifiedAboutOffchainTokens = await notifyAboutOffchainTokens(
      state,
      api,
    );
    if (notifiedAboutOffchainTokens) {
      state.lastNotifTime = now;
      scheduleBotNotification(state, api);
      return;
    }
  }

  let bucketId = state.ruleset.abTests[tests.TEST_FOMO_MSG_IMPACT]?.bucketId;
  if (!bucketId) {
    bucketId = getReceiverBucket(tests.TEST_FOMO_MSG_IMPACT, state.id);
    api.abTests.assign(tests.TEST_FOMO_MSG_IMPACT, bucketId);
  }

  if (bucketId === 'control') {
    const notifiedWithTokenFomo = await notifyWithTokenFomo(state, api);
    if (notifiedWithTokenFomo) {
      state.lastNotifTime = now;
      scheduleBotNotification(state, api);
      return;
    }
  }

  if (bucketId === '50_fomo') {
    // 50% of being notified with FOMO messages
    if (Math.random() < 0.5) {
      const notifiedWithTokenFomo = await notifyWithTokenFomo(state, api);
      if (notifiedWithTokenFomo) {
        state.lastNotifTime = now;
        scheduleBotNotification(state, api);
        return;
      }
    }
  }

  if (bucketId === 'fallback_to_fomo') {
    if (notifFeatures.length === 0) {
      const notifiedWithTokenFomo = await notifyWithTokenFomo(state, api);
      if (notifiedWithTokenFomo) {
        state.lastNotifTime = now;
        scheduleBotNotification(state, api);
        return;
      }
    }
  }

  let highestPriorityFeature: NotificationFeature | undefined = undefined;
  let highestPriority = notifFeaturePriorities.length;

  const newBacklog: BotNotifBacklog = {};

  notifFeatures.forEach((notifFeature) => {
    const feature = notifFeature as NotificationFeature;
    if (!(feature in NotificationFeature)) {
      console.error(`Not a notification feature: "${feature}"`);
      return;
    }

    const priority = notifFeaturePriorities.indexOf(
      feature as NotificationFeature,
    );
    if (priority === -1) {
      console.error(`Notification feature not available: "${feature}"`);
      return;
    }

    const notificationEntry = state.notificationBacklog[notifFeature];
    const triggerTime = notificationEntry?.triggerTime ?? 0;
    if (triggerTime > now) {
      // @note: the typing is correct but the type checker is not able to figure it out
      // @ts-ignore
      newBacklog[notifFeature] = state.notificationBacklog[notifFeature];
      return;
    }

    if (priority < highestPriority) {
      highestPriority = priority;
      highestPriorityFeature = notifFeature;
    }
  });

  if (highestPriorityFeature) {
    const reengagementIteration = sendHighPriorityMessage(
      state,
      api,
      highestPriorityFeature,
    );

    if (!newBacklog.reengagement && reengagementIteration < 4) {
      // Add reengagement message to the backlog if discarded
      const delay =
        [DAY_IN_MS, 2 * DAY_IN_MS, 4 * DAY_IN_MS][reengagementIteration] ??
        7 * DAY_IN_MS;

      newBacklog.reengagement = {
        triggerTime: now + delay,
        payload: {
          iteration: reengagementIteration + 1,
        },
      };
    }
  }

  // updating backlog
  state.notificationBacklog = newBacklog;

  scheduleBotNotification(state, api);
};
/**
 *
 * @param state
 * @param api
 * @param highestPriorityFeature
 * @returns reengagementIteration
 */
export const sendHighPriorityMessage = (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  highestPriorityFeature: NotificationFeature,
  useCheat?: 'useCheat',
) => {
  const now = api.date.now();

  let message;
  let reengagementIteration = 1;

  switch (highestPriorityFeature) {
    case NotificationFeature.friendJoined:
      {
        const notification =
          state.notificationBacklog[NotificationFeature.friendJoined];
        if (!notification) {
          throw new Error(
            `Notification content for "${NotificationFeature.friendJoined}" is missing`,
          );
        }

        const friendCount =
          notification.friendCount ?? notification.payload.length;
        if (friendCount === 1) {
          const mediaUrl = getNotificationMedia('friendJoined');
          message = templates.friendJoined({
            args: {
              userId: state.id,
              tokens: {
                friendName: notification.payload[0].friendName,
                bonus: formatPrice(notification.payload[0].bonus),
              },
              mediaUrl,
            },
            payload: getChatbotPayload({
              feature: 'friend',
              $subFeature: 'friend_joined',
              mediaUrl,
            }),
          });
        } else {
          const mediaUrl = getNotificationMedia('friendsJoined');
          message = templates.friendsJoined({
            args: {
              userId: state.id,
              tokens: {
                friendName: notification.payload[0].friendName,
                friendCount: (friendCount - 1).toString(),
                bonus: formatPrice(
                  notification.payload.reduce(
                    (totalBonus, friendJoinedData) =>
                      friendJoinedData.bonus + totalBonus,
                    0,
                  ),
                ),
              },
              mediaUrl,
            },
            payload: getChatbotPayload({
              feature: 'friend',
              $subFeature: 'friend_joined',
              mediaUrl,
            }),
          });
        }
      }
      break;
    case NotificationFeature.energyRecharged:
      {
        const notification =
          state.notificationBacklog[NotificationFeature.energyRecharged];
        if (!notification) {
          throw new Error(
            `Notification content for "${NotificationFeature.energyRecharged}" is missing`,
          );
        }

        const mediaUrl = getNotificationMedia('energyRecharged');
        message = templates.energyRecharged({
          args: { userId: state.id, mediaUrl },
          payload: getChatbotPayload({
            feature: 'energy',
            $subFeature: 'energy_recharged',
            mediaUrl,
          }),
        });
      }

      break;
    case NotificationFeature.reengagement:
      {
        const notification = useCheat
          ? { payload: { iteration: 1 } }
          : state.notificationBacklog[NotificationFeature.reengagement];
        if (!notification) {
          throw new Error(
            `Notification content for "${NotificationFeature.reengagement}" is missing`,
          );
        }

        const iteration = notification.payload.iteration;
        const templateIds = [
          'reengagement1',
          'reengagement2',
          'reengagement3',
          'reengagement4',
          'reengagement5',
          'reengagement6',
          'reengagement7',
          'reengagement8',
          'reengagement9',
        ] as const;
        let templateId = templateIds[iteration - 1] || 'reengagement1';

        if (templateId === 'reengagement1') {
          const bucketToTemplateMap: Record<
            string,
            (typeof templateIds)[number]
          > = {
            control: 'reengagement1',
            dont_miss_out: 'reengagement4',
            times_ticking: 'reengagement5',
            get_ready: 'reengagement6',
            miss_you: 'reengagement7',
            insider_tip: 'reengagement8',
            losing_ground: 'reengagement9',
          };
          const bucketId =
            state.ruleset.abTests[tests.TEST_REENGAGEMENT_VARIANTS]?.bucketId ??
            'control';
          templateId = bucketToTemplateMap[String(bucketId)];
        }

        const mediaUrl = getNotificationMedia(templateId);
        message = templates[templateId]({
          args: { userId: state.id, mediaUrl },
          payload: getChatbotPayload(
            {
              feature: 'reengagement',
              $subFeature: 'reengagement_24h',
              mediaUrl,
            },
            {
              retention_day: state.consecutive_days + 1,
            },
          ),
        });

        reengagementIteration = iteration + 1;
      }

      break;
  }

  if (message) {
    sendTelegramMessage(state, api, {
      chatId: state.id,
      message,
      receiverId: state.id,
      maxDelayMs: maxNotifDelays[highestPriorityFeature],
      priority: highPriorityNotifs[highestPriorityFeature],
    });

    state.lastNotifFeature = highestPriorityFeature;
  }

  return reengagementIteration;
};

export const scheduleBotNotification = (
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantSyncActionAPI<ReplicantServer>,
) => {
  const now = api.date.now();

  let delay = 0;

  const backlogContent = Object.values(state.notificationBacklog);
  if (backlogContent.length === 0) {
    // no reason to notify the player except about trading
    if (state.last_session_end_time + WEEK_IN_MS > now) {
      delay = DAY_IN_MS;
    } else if (state.last_session_end_time + 3 * WEEK_IN_MS > now) {
      delay = 3.5 * DAY_IN_MS;
    } else if (state.last_session_end_time + 5 * MONTH_IN_MS > now) {
      delay = MONTH_IN_MS;
    } else {
      return;
    }
  } else {
    // player will be notified about one notification in the backlog
    delay = backlogContent[0].triggerTime - now;
    for (let i = 1; i < backlogContent.length; i += 1) {
      delay = Math.min(backlogContent[i].triggerTime - now, delay);
    }
  }

  const earliestNotifTime = getEarliestNotifiableTime(state, now);

  // @note: cannot schedule an action for a time too close or multiple actions might trigger
  const minDelay = 2 * MIN_IN_MS;

  const notifDelay = Math.max(earliestNotifTime - now, delay, minDelay);

  // schedule for when is appropriate
  api.scheduledActions.schedule.sendBotNotification({
    args: {},
    notificationId: 'botNotifications',
    delayInMS: notifDelay,
  });
};

export function sendReengagementBroadcast(
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantSyncActionAPI<ReplicantServer>,
) {
  let bucketId = state.ruleset.abTests[tests.TEST_REENGAGEMENT_BROADCAST]
    ?.bucketId as string | undefined;
  if (!bucketId) {
    bucketId = getReceiverBucket(tests.TEST_REENGAGEMENT_BROADCAST, state.id);
    api.abTests.assign(tests.TEST_REENGAGEMENT_BROADCAST, bucketId);
  }

  api.sendAnalyticsEvents([
    {
      eventType: 'ReengagementBroadcast',
      eventProperties: {
        [tests.TEST_REENGAGEMENT_BROADCAST]: bucketId,
      },
    },
  ]);

  if (bucketId === 'control') {
    // no message for you!
    return;
  }

  const messageType = bucketId.includes('MissYou') ? 'MissYou' : 'Changes';
  const creativeName =
    messageType === 'MissYou' ? 'sadSloth.jpg' : 'tradingSloth2.jpg';

  let creativeTextID = bucketId[bucketId.length - 1];
  let message = '';

  if (messageType === 'MissYou') {
    if (creativeTextID === '1') {
      message = `Things aren't as fun without you around! Play Now!`;
    } else if (creativeTextID === '2') {
      message = `Fun’s been lonely without you... let’s fix that! Play Now!`;
    } else if (creativeTextID === '3') {
      message = `You’re missed, and we can't wait to see you back!`;
    } else if (creativeTextID === '4') {
      message = `We’re missing your spark—come back & play!`;
    } else if (creativeTextID === '5') {
      message = `Without you, it’s just not as fun—let’s change that!`;
    }
  } else {
    if (creativeTextID === '1') {
      message = `💎 Missed us? Big updates are on the horizon… It's the perfect time to boost your earnings!`;
    } else if (creativeTextID === '2') {
      message = `We've been busy! Huge updates are coming. Don't miss out on the increased coin gains.`;
    } else if (creativeTextID === '3') {
      message = `Epic updates are on the way! Return now to prepare and start earning even more. 💰`;
    } else if (creativeTextID === '4') {
      message = `Don't miss out! We’ve got NEW FEATURES ready for you—come back & join the FUN!`;
    } else if (creativeTextID === '5') {
      message = `BIG UPDATES are LIVE—don’t miss out, it’s time to jump back in!`;
    }
  }

  const mediaUrl = `${notificationFolderUrl}${creativeName}`;

  sendTelegramMessage(state, api, {
    chatId: state.id,
    message: templates.fullGeneric({
      args: {
        userId: state.id,
        message,
        mediaUrl,
      },
      payload: getChatbotPayload(
        {
          feature: 'reengagement',
          $subFeature: 'broadcast',
          mediaUrl,
        },
        {
          creativeTextID,
        },
      ),
    }),
    receiverId: state.id,
    maxDelayMs: 24 * HOUR_IN_MS,
    priority: 'high',
  });
}

export function sendStartCmdMessage(
  state: MutableState,
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantSyncActionAPI<ReplicantServer>,
  referralPayloadKey?: string,
) {
  let bucketId = state.ruleset.abTests[tests.TEST_START_MSG_VARIANTS]
    ?.bucketId as string | undefined;
  if (!bucketId) {
    bucketId = getReceiverBucket(tests.TEST_START_MSG_VARIANTS, state.id);
    api.abTests.assign(tests.TEST_START_MSG_VARIANTS, bucketId);
  }

  let captionTemplate = cmdReplyCaptions.startCmdPrivate.control;
  let creativeTextID = 'control';
  if (bucketId.includes('_A')) {
    captionTemplate = cmdReplyCaptions.startCmdPrivate.A;
    creativeTextID = 'A';
  } else if (bucketId.includes('_B')) {
    captionTemplate = cmdReplyCaptions.startCmdPrivate.B;
    creativeTextID = 'B';
  } else if (bucketId.includes('_C')) {
    captionTemplate = cmdReplyCaptions.startCmdPrivate.C;
    creativeTextID = 'C';
  }

  let creativeName;
  if (bucketId[bucketId.length - 1] === '1') {
    creativeName = 'start_1.png';
  } else if (bucketId[bucketId.length - 1] === '2') {
    creativeName = 'start_2.png';
  } else if (bucketId[bucketId.length - 1] === '3') {
    creativeName = 'start_3.jpg';
  }

  const caption = captionTemplate.replace('{username}', state.username);

  const mediaUrl =
    creativeName === undefined
      ? undefined
      : `${notificationFolderUrl}${creativeName}`;

  sendTelegramMessage(state, api, {
    chatId: state.id,
    message: templates.startCmdPrivate({
      args: {
        caption,
        mediaUrl,
      },
      payload: getChatbotPayload(
        {
          feature: 'bot',
          $subFeature: 'bot_start',
          mediaUrl,
          nonCuratedCreativeId: 'none',
        },
        {
          creativeTextID,
          $key: referralPayloadKey,
        },
      ),
    }),
    receiverId: state.id,
    maxDelayMs: maxNotifDelays.startCmdPrivate,
    priority: highPriorityNotifs.startCmdPrivate,
  });
}
