import { IAPConfig } from '../../../replicant/features/offchainTrading/types';
import { app, AppController, isLocal } from '../AppController';
import { newTokenFormConfig, updateTokenFormConfig } from './types';
import { Form } from '../../Form';
import { Tutorials } from '../../tutorial/types';
import { v4 as uuid } from 'uuid';
import {
  IAP_TG_STARS_TIMEOUT_MS,
  tmgRuleset,
} from '../../../replicant/features/offchainTrading/offchainTrading.ruleset';
import { ErrorCode } from '../../../replicant/response';
import { waitFor } from '../../utils';
import { MIN_IN_MS } from '../../../replicant/utils/time';
import { hasReachedMemeCreationLimit } from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { assets } from '../../../assets/assets';
import { t } from 'i18next';

// @warning-start: do not change the prefix or it can break moderation
const OFFCHAIN_TRADING_PREFIX = 'offchainTrading';
const S3_PATH_PREFIX = 'trading';
// @warning-end
const TG_START_TX_SYNC_POLL_TIME = 1 * MIN_IN_MS;
const DEFAULT_TOKEN_IMAGE = 'https://notgemz.cms.gemz.fun/media/default.png';

/**
 * This class does not need to be business or pure - it only provides an API
 * There's no events
 */
export class TokenFactory {
  public newTokenForm = new Form(newTokenFormConfig);

  public updateLinksForm = new Form(updateTokenFormConfig);

  private createTokenFormData?: typeof this.newTokenForm.data & { id: string };

  public get starsPrice() {
    return this.app.session.getIAPConfig()?.priceInStars.toString() ?? '0';
  }

  public get createNewTokenFormData() {
    return this.createTokenFormData;
  }

  private tgStarsTxSyncPool?: NodeJS.Timeout;

  constructor(private app: AppController) {}

  public createNewToken = async () => {
    if (hasReachedMemeCreationLimit(this.app.state)) {
      this.app.ui.drawer.show({
        id: 'drawerTradingWarning',
        props: {
          tradingWarning: {
            warningTitle: t('meme_limit_reached_creation_title'),
            warningMessage: t('meme_limit_reached_creation_message', {
              limit: tmgRuleset.creationLimit,
            }),
            warningCta: t('meme_limit_reached_creation_cta'),
            icon: assets.trading_transaction_success,
          },
        },
      });

      return;
    }

    this.app.track('memeoffchainToken_create_start');
    this.newTokenForm.clearForm();
    // start trading tutorial slideshow
    await this.app.tutorial.startTutorial(
      Tutorials.SlideshowTradingCreateToken,
      {
        substitutes: { starAmount: this.starsPrice },
      },
    );

    // open TradingCreatePage
    this.app.tutorial.step?.onAction &&
      this.app.tutorial.step?.onAction('trading-create');
    this.app.nav.goTo('TradingCreatePage');
  };

  public submitFormCreateToken = async (retries = 0): Promise<void> => {
    if (!this.newTokenForm.isValid) {
      console.log('Form is not valid');
      return;
    }

    const id = uuid();
    console.log('id', id);
    // Verify if Id is unique
    const existingOffchainTokens =
      await this.app.asyncGetters.getOffchainTokensFromOpenSearch({
        offchainTokenIds: [id],
      });

    if (existingOffchainTokens.length > 0) {
      retries++;
      if (retries > 3) {
        return this.app.ui.showError({
          message: `Something went wrong. Please try again`,
        });
      }
      return this.submitFormCreateToken(retries);
    }

    this.createTokenFormData = { id, ...this.newTokenForm.data };

    this.app.memes.trading.onTokenCreated();
    this.app.track('memeoffchainToken_form_submit', this.createTokenFormData);
    this.app.ui.drawer.show(
      {
        id: 'drawerTradingCreateConfirm',
        hideClose: true,
      },
      true,
    );
  };

  private get targetToken() {
    return this.app.memes.currentMeme.token;
  }

  public submitFormUpdateLinks = async (): Promise<void> => {
    if (!this.targetToken) {
      return;
    }

    await this.app.invoke.asyncEditOffchainToken({
      tokenId: this.targetToken.id,
      ...this.updateLinksForm.data,
    });

    // Update the current token
    this.app.memes.getToken(this.targetToken.id, 'fetchAndUpdate');

    // track update links analytics
    this.app.track('memecard_edit', {
      cardId: this.targetToken.id || '',
      cardName: this.targetToken.name || '',
    });

    // go back to original token page
    this.app.nav.back();
  };

  private createOffchainTokenLocally = async () => {
    const { state } = this.app.memes.trading;

    if (!state.tx || !this.createTokenFormData) {
      return;
    }

    try {
      const createOffchainTokenResponse =
        await this.app.invoke.asyncCreateOffchainToken({
          offchainToken: this.createTokenFormData,
          currencyAmount: state.tx.send.toString(),
          productId: `foo`,
          isLocal: true,
          useCredit: false,
        });

      if (createOffchainTokenResponse.error) {
        return this.app.ui.showError({
          message: createOffchainTokenResponse.error,
        });
      }

      if (createOffchainTokenResponse.data) {
        const offchainToken = await this.app.memes.getToken(
          createOffchainTokenResponse.data.offchainTokenId,
          'forceFetch',
        );
        if (offchainToken) {
          this.app.invoke.asyncTakePortfolioSnapshots();

          // navigate back to pump page and show success drawer
          this.app.ui.onCreateOffchainTokenSuccess(offchainToken);
        }
      }
    } catch (e: any) {
      this.app.ui.showError({
        message: e.message,
      });
    } finally {
      this.createTokenFormData = undefined;
    }
  };

  private buyWithTelegramStars = async (
    cfg: IAPConfig,
    useCredit: boolean,
  ): Promise<'success' | 'cancel'> => {
    if (useCredit) {
      return 'success';
    }

    return new Promise((resolve, reject) => {
      let didTimeout = false;
      const iapTgStarsTimeout = setTimeout(() => {
        didTimeout = true;
        reject(new Error(ErrorCode.IAP_TG_STARS_TIMEOUT));
      }, IAP_TG_STARS_TIMEOUT_MS);

      this.app.asyncGetters
        .getInvoiceLink({
          ...cfg,
          analytics: {
            feature: 'memeoffchainToken',
            subFeature: 'memeoffchainToken_creation',
          },
        })
        .then((iapLink) => {
          if (didTimeout) {
            return;
          }
          return this.buyIAP(iapLink);
        })
        .then((iapResponse) => {
          if (!didTimeout && iapResponse) {
            resolve(iapResponse);
          }
        })
        .catch((e) => {
          if (!didTimeout) {
            reject(e);
          }
        })
        .finally(() => {
          clearTimeout(iapTgStarsTimeout);
        });
    });
  };

  // @TODO: Probably best somewhere else more 'generic'
  private buyIAP = (iapLink: string): Promise<'success' | 'cancel'> => {
    return new Promise((resolve, reject) => {
      // Seems like the type is wrong, first parameter of callback is status
      Telegram.WebApp.openInvoice(iapLink, async (status) => {
        switch (status) {
          case 'paid':
            if (this.tgStarsTxSyncPool) {
              await this.app.invoke.asyncSyncTgStarsTxAndCredits();
              this.stopTgStarsSyncPoll();
            }
            return resolve('success');
          case 'cancelled':
            this.stopTgStarsSyncPoll();
            return resolve('cancel');
          case 'failed':
            this.stopTgStarsSyncPoll();
            return reject();
        }
      });
    });
  };

  private waitForPurchaseConfirmation = async (
    productId: string,
    retries = 0,
  ): Promise<void> => {
    if (retries > 3) {
      throw new Error(`Purchase could not be confirmed. Try again later`);
    }
    const confirmed = await this.app.asyncGetters.getPurchaseConfirmed({
      productId,
    });
    if (confirmed) {
      return;
    }
    // How long to wait for? :thinking:
    await waitFor(1000);
    return this.waitForPurchaseConfirmation(productId, ++retries);
  };

  createOffchainToken = async () => {
    const { state } = this.app.memes.trading;

    if (!state.tx || !this.createTokenFormData) {
      return;
    }

    const existingOffchainToken =
      await this.app.asyncGetters.getOffchainTokensFromOpenSearch({
        offchainTokenIds: [this.createTokenFormData.id],
      });

    if (existingOffchainToken.length > 0) {
      return this.app.ui.showError({
        message: `'Ticker' must be unique. Please try again`,
      });
    }

    this.app.ui.showSpinner();

    if (isLocal) {
      return this.createOffchainTokenLocally();
    }

    this.stopTgStarsSyncPoll();

    this.app.track('memeoffchainToken_init_creation_purchase', {
      memeoffchainToken_name: this.createTokenFormData.name,
      creator_invested_amount: state.tx.send.toString(),
    });

    try {
      const iapConfig = this.app.session.getIAPConfig();

      if (!iapConfig) {
        throw new Error(
          `User trying to createOffchainToken without 'iapConfig'.`,
        );
      }

      const useCredit = this.app.state.tokenCreationCredits > 0;
      const iapResponse = await this.buyWithTelegramStars(iapConfig, useCredit);
      // If the user approves the transaction in telegram ui
      if (iapResponse === 'success') {
        this.stopTgStarsSyncPoll();
        if (!useCredit) {
          await this.waitForPurchaseConfirmation(iapConfig.productId);
        }

        // get all properties except image
        const { image: _, ...rest } = this.createTokenFormData;

        const createOffchainTokenResponse =
          await this.app.invoke.asyncCreateOffchainToken({
            offchainToken: { image: DEFAULT_TOKEN_IMAGE, ...rest },
            currencyAmount: state.tx.send.toString(),
            productId: iapConfig.productId,
            isLocal: false,
            useCredit,
          });

        if (createOffchainTokenResponse.error) {
          return this.app.ui.showError({
            message: createOffchainTokenResponse.error,
          });
        }

        await this.app.invoke.flushMessages();

        await this.app.replicant.uploadUserAsset(
          this.createTokenFormData.image,
          `${S3_PATH_PREFIX}/${OFFCHAIN_TRADING_PREFIX}-${this.createTokenFormData.id}/`,
        );

        // const tokenImageUrl = this.app.replicant.getUserAssetUrl(image);
        const offchainTokenId =
          createOffchainTokenResponse.data.offchainTokenId;

        if (offchainTokenId) {
          this.app.track('memecard_created', {
            memecard_name: this.createTokenFormData.name,
            cardID: offchainTokenId,
            owner_amount_invested: state.tx.send.toString(),
          });

          const offchainToken = await this.app.memes.getToken(
            offchainTokenId,
            'forceFetch',
          );

          // @TODO: send event instead of this
          this.app.memes.onTokenCreated(offchainTokenId);
          this.app.ui.onCreateOffchainTokenSuccess(offchainToken);

          this.createTokenFormData = undefined;
          this.newTokenForm.clearForm();
        } else {
          throw new Error(`Failed to create offchainToken`);
        }
      }
    } catch (e: any) {
      const isTimeout = e.message === ErrorCode.IAP_TG_STARS_TIMEOUT;

      const showErrorProps: Parameters<typeof this.app.ui.showError>[0] = {
        message: e.message,
      };

      if (isTimeout) {
        this.startTgStarsSyncPoll();
        this.app.track('Stars_dialog_fail', {
          memecard_name: this.createTokenFormData?.name ?? 'unknown',
          owner_amount_invested: state.tx.send.toString(),
        });

        showErrorProps.title = 'Telegram Timeout';
        showErrorProps.message = `Telegram is taking too long to respond.\nPlease try again later.`;
        showErrorProps.cta = 'Ok';
        showErrorProps.onClick = () => this.app.nav.goToTiktokFeed();
      }

      this.app.ui.showError(showErrorProps);
    } finally {
      this.app.ui.hideSpinner();
    }
  };

  /*
    The reason for this polling is because we handle TG stars timeout (sometimes UI freezes)
    But once we send the request to TG to 'openInvoice' we have no control over it. So there's
    a chance where we timeout but the user still see the stars IAP modal and makes a payment.
    This pooling is only set if the timeout happens and will stop on starting a new tx or if
    the BE responds as 'synced'.
  */
  private startTgStarsSyncPoll = () => {
    this.stopTgStarsSyncPoll();
    this.tgStarsTxSyncPool = setTimeout(async () => {
      const hasSynced = await this.app.invoke.asyncSyncTgStarsTxAndCredits();
      if (!hasSynced) {
        this.startTgStarsSyncPoll();
      }
    }, TG_START_TX_SYNC_POLL_TIME);
  };

  private stopTgStarsSyncPoll = () => {
    clearInterval(this.tgStarsTxSyncPool);
  };
}
