import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { MutableState } from '../../schema';

import { ReplicantServer } from '../../config';
import { getGiftPowerUps } from '../powerups/getters';
import { PowerUpCardType } from '../powerups/types';
import airtableQuests from '../powerups/airtable/earnQuests';
import { tests } from '../../ruleset';
import { League, SEASON, SEASON_LEAGUE_REWARDS } from './ruleset/league';
import { getLeague } from './game.getters';
import {
  getFriendsSubStateId,
  hasFriendSubState,
} from '../friends/friends.getters';
import { createFriendsSubState } from '../friends/friends.modifiers';

export async function migratePowerUps(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  if (state.powerUps.migrated) {
    return;
  }
  state.powerUps.owned = Object.keys(state.power_ups).reduce((res, cur) => {
    const pu = state.power_ups[cur];
    return {
      ...res,
      [cur]: {
        level: pu.level,
      },
    };
  }, {});

  state.powerUps.last_claimed = state.last_session_time;
  state.powerUps.daily = state.daily_powerups;
  state.powerUps.migrated = true;
}

export async function migratePowerUps1(state: MutableState) {
  if (state.powerUps.migrationVersion !== 0) {
    // not a valid state to migrate
    return;
  }

  if (
    state.powerUps.conditions.gift_daily_with_discount[
      'pu_specials_airdrop'
    ] === undefined
  ) {
    state.powerUps.conditions.gift_daily_with_discount['pu_specials_airdrop'] =
      {
        dailyGifts: [],
        discountList: [],
      };
  }

  state.powerUps.conditions.gift_daily_with_discount[
    'pu_specials_airdrop'
  ].dailyGifts = state.powerUps.conditions.gift_02_airdrop.dailyGifts;
  state.powerUps.conditions.gift_daily_with_discount[
    'pu_specials_airdrop'
  ].discountList = state.powerUps.conditions.gift_02_airdrop.discountList;
  state.powerUps.migrationVersion = 1;
}

export async function migrateToNextSeason(state: MutableState) {
  // @note: comment this block to test saving season 1 score
  if (state.first_session) {
    // go straight to new season
    state.season = SEASON;
    return;
  }

  const previousSeason = SEASON - 1;
  const previousSeasonIdx = previousSeason - 1;
  if (state.seasonLeagues[previousSeasonIdx] !== undefined) {
    return;
  }

  // score might have already been saved in previous snapshot
  state.seasonScores[previousSeasonIdx] =
    (state.seasonScores[previousSeasonIdx] ?? 0) + state.score;
  state.score = 0;

  // save current league
  const league = getLeague(state, previousSeason);
  state.seasonLeagues[previousSeasonIdx] = league;

  // provide reward as starting balance
  state.balance = SEASON_LEAGUE_REWARDS[previousSeason][league];

  // reset taps and league
  state.taps = 0;
  state.league = League.league1;

  // Clamp mine card level to 50
  state.powerUps.owned = Object.keys(state.powerUps.owned).reduce(
    (res, cur) => {
      return {
        ...res,
        [cur]: {
          ...state.powerUps.owned[cur],
          level: Math.min(state.powerUps.owned[cur].level, 50),
        },
      };
    },
    {},
  );
}

export function roundBalanceScoreAndTaps(state: MutableState) {
  state.balance = Math.round(state.balance);
  state.score = Math.round(state.score);
  state.taps = Math.round(state.taps);

  if (state.migration) {
    state.migration.balance = Math.round(state.migration.balance);
    state.migration.score = Math.round(state.migration.score);
  }
}

export const initGiftCards = (state: MutableState, now: number) => {
  const powerUps = getGiftPowerUps();
  if (!powerUps) return;
  powerUps.forEach((pu) => {
    if (pu.type === PowerUpCardType.GIFT_DAILY_WITH_DISCOUNT) {
      if (!state.powerUps.conditions.gift_daily_with_discount[pu.id]) {
        state.powerUps.conditions.gift_daily_with_discount[pu.id] = {
          dailyGifts: [],
          discountList: [],
        };
      }
    } else if (pu.type === PowerUpCardType.GIFT_ONLY) {
      if (!state.powerUps.conditions.gift_only[pu.id]) {
        state.powerUps.conditions.gift_only[pu.id] = {
          dailyGifts: [],
        };
      }
    }
  });
};

/**
 * Migrates state from strict schema to SB.map; we are moving the quests
 * to the CMS
 */
export const migrateQuests = (state: MutableState) => {
  // if we have any quest then it has been migrated
  if (Object.keys(state.quests).length > 0) {
    return;
  }
  // Initialise quests state keeping previous state value
  airtableQuests.forEach((quest) => {
    const isComplete = Boolean(
      state.earnings[quest.migrationKey as keyof typeof state.earnings],
    );

    state.quests[quest.id] = {
      state: isComplete ? 'complete' : 'default',
    };
  });
};

export const fixFriendSubState = async (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  if (state.friendsSubStateId) {
    return;
  }

  if (state.first_session) {
    createFriendsSubState(state, api);
    return;
  }

  // fix potentially missing friendsSubStateId
  const friendsSubStateId = getFriendsSubStateId(state);
  const friendsSubState = await api.sharedStates.friends.fetch(
    friendsSubStateId,
  );

  if (friendsSubState) {
    state.friendsSubStateId = friendsSubStateId;
    state.friendCount = friendsSubState.global.friendIds.length;

    delete state.friendIds;
  } else {
    createFriendsSubState(state, api, true);
  }
};
