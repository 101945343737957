import './DrawerPowerUpEarn.scss';
import { assets } from '../../../assets/assets';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';

export const DrawerPowerUpEarn = () => {
  const { t } = useTranslation(); //

  const close = () => {
    app.track('power_up_return_earning', {
      earnings: app.clicker.powerUpBonus,
    });
    app.ui.drawer.close();
  };

  return (
    <div className="drawer-details">
      <div className="drawer-layout powerup-earn">
        <div className="drawer-header powerup-earn">
          <div className="drawer-header-title powerup-earn">
            {t('pup_your_powerups')}
          </div>

          <div className="drawer-header-subtitle powerup-earn">
            <div className="text">{t('pup_working_for_you')}</div>
            <div className="score">
              <div className="icon">
                <img src={assets.coin} />
              </div>
              <div className="text">
                {largeIntegerToLetter(app.clicker.powerUpBonus)}
              </div>
            </div>
            <div className="text">{t('pup_collect_your_earnings')}</div>
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className="btn btn-normal" onClick={close}>
          {t('collect_earnings_button')}
        </div>
      </div>
    </div>
  );
};
