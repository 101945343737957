import { TutorialModule } from './TutorialModule';

/**
 * This step just call state update via id and moves to the next
 */
export class Grant1000State extends TutorialModule {
  stateUpdateId = '1000FreeGift';

  execute = async () => {
    setTimeout(() => {
      this.onComplete();
    });
    return super.execute();
  };
}
