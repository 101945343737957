import './TiktokSearchPage.scss';
import { Page } from '../Page';
import { useState, useEffect } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { MemesEvents } from '../../../data/Controllers/Memes/MemesController';
import { TradingSearchBar } from '../TradingPage/TradingSearchBar/TradingSearchBar';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { TradingTokenList } from '../TradingPage/TradingTabs/TradingTokenList';
import { useTranslation } from 'react-i18next';
import { MemeFilters } from '../../../data/Controllers/Memes/types';

// note:
// This code is entirey a duplication of TradingPage without the header
// However, search bar and tabs have been fixed, and scroll has been added to each tab list

export const TiktokSearchPage = () => {
  const { t } = useTranslation();

  const { visible, hide } = app.views.TiktokSearchPage;

  useAppUpdates({
    id: 'TiktokSearchPage',
    listener: app.views.TiktokSearchPage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TiktokSearchPage/UIEvents.OnBalanceUpdate',
    listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
    dep: visible,
  });
  useAppUpdates({
    id: 'TiktokSearchPage/TradingEvents.OnListingUpdate',
    listener: app.memes.attachEventListener(MemesEvents.OnListingUpdate),
    dep: visible,
  });
  useAppUpdates({
    id: 'TiktokSearchPage/UIEvents.TradingUpdate',
    listener: app.ui.attachEventListener(UIEvents.TradingUpdate),
    dep: visible,
  });

  // On visibility change
  useEffect(() => {
    if (visible) {
      app.track('memeoffchainToken_page_view', {
        source:
          app.nav.prevRoute === 'MinePage'
            ? 'mine_page'
            : app.nav.prevRoute === 'Clicker'
            ? 'nav_menu'
            : 'other',
      });
    }
  }, [visible]);

  // ===========================================================

  const [currentTab, setCurrentTab] = useState(app.memes.currentFilter);

  const [searchTerm, setSearchTerm] = useState('');

  const onTabClick = (filter: MemeFilters, _prev: MemeFilters) => {
    app.memes.setCurrent({ filter });
    setCurrentTab(filter);

    app.track('memeoffchainToken_tab_view', {
      tab_name: filter,
    });
    app.tutorial.step?.onAction?.();

    setSearchTerm('');
  };

  const isEndOfList = app.memes.currentList.isEndOfList();
  const placeholderCount = isEndOfList ? 0 : 10;

  // ===========================================================

  return (
    <Page id="tiktokSearch" visible={visible}>
      <TradingSearchBar
        initialSearchTerm={searchTerm}
        onInputChange={(term: string) => setSearchTerm(term)}
      />

      <div className="trading-tabs-container">
        <Tabs<MemeFilters>
          tabStyle={TabStyle.Underline}
          initial={app.memes.currentFilter}
          onTabClick={onTabClick}
          autoSelected={app.memes.currentFilter}
          tabs={[
            {
              id: 'Hot',
              name: t('trading_tab_hot'),
              badge: { visible: false, amount: 3, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.currentList.listing}
                  track="hot"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            {
              id: 'Top',
              name: t('trading_tab_top'),
              badge: { visible: false, amount: 2, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.currentList.listing}
                  track="top"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            {
              id: 'New',
              name: t('trading_tab_new'),
              badge: { visible: false, amount: 3, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.currentList.listing}
                  track="new"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            // What to do?
            // {
            //   id: 'MyCards',
            //   name: t('trading_tab_my_cards'),
            //   badge: { visible: false, amount: 1, checked: false },
            //   component: (
            //     <>
            //       {/* roi - summary */}
            //       <RoiSummary />

            //       <TradingSortingHeader
            //         totalLabel={''}
            //         totalValue={app.trading.getOffchainTokenHoldCount()}
            //         list={app.memes.currentList.listing}
            //       />

            //       <TradingGemzCoinItem balance={app.state.balance} />

            //       {/* list */}
            //       <TradingTokenList
            //         items={app.memes.currentList.listing}
            //         track="myCards"
            //         placeholderCount={0}
            //       />
            //     </>
            //   ),
            // },
          ]}
        />
      </div>
    </Page>
  );
};
