import './MinePage.scss';
import { Page } from '../Page';
import { useEffect, useState } from 'react';
import {
  AppEvents,
  app,
  useAppUpdates,
} from '../../../data/Controllers/AppController';
import { PowerUp } from '../../../replicant/features/powerups/types';
import OverlayPowerUp from '../../modals/overlays/OverlayPowerUp';

import {
  ElementUIState,
  UIEvents,
} from '../../../data/Controllers/UIController/UITypes';
import { MineHeader } from './MineHeader/MineHeader';
import { MineDailyCombo } from './MineDailyCombo/MineDailyCombo';
import { MineTabs } from './MineTabs/MineTabs';
import { TradingBanner } from '../TradingPage/TradingBanner/TradingBanner';
import { useManyAppUpdates } from '../../../data/hooks';
import { ClickerEvents } from '../../../data/Controllers/ClickerController';

export const MinePage = () => {
  const { data, visible, fetch } = app.views.MinePage;
  const { state } = app;

  useManyAppUpdates({
    id: 'MinePage',
    events: [
      { listener: app.views.MinePage.attachEventListener() },
      {
        listener: app.clicker.attachEventListener(
          ClickerEvents.onGameStateUpdate,
        ),
        dep: visible,
      },
      {
        listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
        dep: visible,
      },
      {
        listener: app.ui.attachEventListener(UIEvents.MineUpdate),
        dep: visible,
      },
    ],
  });

  // ---------------------------
  // initialize

  useEffect(() => {
    if (visible) {
      app.ui.drawer.close();
      fetch();
      // trying to grab a very specific issue that shouldn't be happening
      if (
        data &&
        state.friendCount >= 1 &&
        data.find((pu) => pu.id === 'pu_gear_pickaxe')?.locked
      ) {
        app.track('power_up_friend_unlock_mystery', {
          friendCount: state.friendCount,
        });
      }
    } else {
      setShowDailyBonusOverlay(undefined);
    }
  }, [visible]);

  // ---------------------------
  // buy card drawer

  const onTapCard = (powerup: PowerUp, hideClose?: boolean) => {
    app.track('Card_detail_view', {
      cardCategory: powerup.category,
      cardID: powerup.id,
      cardLevel: powerup.level,
    });
    app.tutorial.step?.onAction?.('mineCardTap');
    app.ui.drawer.show({
      id: 'mineCardDetails',
      hideClose,
      props: {
        mine: {
          powerup,
          onDailyFound: onDailyCardFound,
        },
      },
    });
  };

  // ---------------------------
  // daily card found

  const onDailyCardFound = (powerUp: PowerUp) => {
    const index = state.powerUps.daily.power_ups.length;
    if (index === 2) {
      setIsLastDailyBonus(true);
      app.track('Daily_bonus', {
        date: new Date(state.powerUps.daily.timestamp).toUTCString(),
      });
      app.ui.setClickerUIState({ btnDailyCombo: ElementUIState.Complete });
    }
    setShowDailyBonusOverlay(powerUp);
    setDailyBonusOverlayIndex(state.powerUps.daily.power_ups.length);
  };

  // ---------------------------
  // daily bonus item overlay

  const [showDailyBonusOverlay, setShowDailyBonusOverlay] = useState<
    PowerUp | undefined
  >(undefined);

  const [isLastDailyBonus, setIsLastDailyBonus] = useState(false);

  const [dailyBonusOverlayIndex, setDailyBonusOverlayIndex] =
    useState<number>(0);

  const onTapDailyBonusSlot = (index: number, powerup: PowerUp) => {
    setShowDailyBonusOverlay(powerup);
    setDailyBonusOverlayIndex(index);
  };

  // ---------------------------
  // render

  return (
    <Page id="modal-mine" visible={visible}>
      <MineHeader />
      <TradingBanner />

      <MineDailyCombo onClick={onTapDailyBonusSlot} />

      <MineTabs onTapItem={onTapCard} />

      {/* daily item overlay - when tapping on a header slot or finding when buying a card */}
      <OverlayPowerUp
        index={dailyBonusOverlayIndex}
        powerup={showDailyBonusOverlay}
        visible={showDailyBonusOverlay !== undefined}
        onClose={() => {
          setIsLastDailyBonus(false);
          setShowDailyBonusOverlay(undefined);
        }}
        lastFind={isLastDailyBonus}
      />

      {/* tutorial overlay - display single highlighted tutorial card */}
      {/* <OverlayTutorialCard
        index={overlayIndex}
        powerup={specials.available[0] as PowerUp}
        visible={app.ui.state.mine.tutorialOverlay === ElementUIState.Highlight}
        // onClose={() => {}} // avoid closing tutorial overlay
        onClick={async (powerup) => {
          app.ui.setMineUIState({ tutorialOverlay: ElementUIState.Normal });
          onTapItem(powerup, true); // avoid showing close button in drawer
        }}
      /> */}
    </Page>
  );
};
