import './MineDailyCombo.scss';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { formatPrice } from '../../../../replicant/utils/numbers';
import { getTotalDailyBonusReward } from '../../../../replicant/features/powerups/getters';
import { getTimeUntilNextDay } from '../../../../replicant/utils/time';
import { t } from 'i18next';
import { msToFullTime } from '../../../../data/utils';
import { PU_DAILY_BONUS_REWARD } from '../../../../replicant/features/powerups/ruleset';
import { PowerUp } from '../../../../replicant/features/powerups/types';
import { DailyComboSlot } from './DailyComboSlot';

interface Props {
  onClick: (index: number, powerup: PowerUp) => void;
}

export const MineDailyCombo = ({ onClick }: Props) => {
  const { state, now, replicant } = app;
  // has hidden meta properties required by some getters
  const replicantState = replicant?.state;

  const { balance } = app.ui;

  const dailyBonus = getTotalDailyBonusReward(replicantState);
  const timeLeft = getTimeUntilNextDay(now());

  const onTapInfo = () => {
    app.ui.drawer.show({ id: 'mineBonusInfo' });
    app.tutorial.step?.onAction?.();
  };

  return (
    <>
      <div className="daily-combo-header">
        <div className="left">
          <img src={assets.daily_combo} />
          <div className="title-label">
            {t('pup_daily_combo')}
            {dailyBonus === 0 ? (
              <div className="title-timer">{msToFullTime(timeLeft)}</div>
            ) : (
              <div className="title-timer">{t('Claimed')}</div>
            )}
          </div>
        </div>

        <div className="right">
          <div className="top">
            <img
              className="daily-info"
              src={assets.powerup_daily_info}
              onClick={() => onTapInfo()}
            />

            {/* {dailyBonus >= 0 && <img src={assets.green_tick} />} */}

            {state.powerUps.daily.power_ups[0] && (
              <img src={assets.green_tick} />
            )}
            {state.powerUps.daily.power_ups[1] && (
              <img src={assets.green_tick} />
            )}
            {state.powerUps.daily.power_ups[2] && (
              <img src={assets.green_tick} />
            )}
          </div>

          <div className="bottom">
            <img src={assets.coin} />
            <div className="right-reward-label">
              {formatPrice(PU_DAILY_BONUS_REWARD)}
            </div>
          </div>
        </div>
      </div>

      <div className="daily-combo-slots">
        <DailyComboSlot index={0} onClick={(powerup) => onClick(0, powerup)} />
        <DailyComboSlot index={1} onClick={(powerup) => onClick(1, powerup)} />
        <DailyComboSlot index={2} onClick={(powerup) => onClick(2, powerup)} />
      </div>
    </>
  );
};
