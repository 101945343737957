import { League } from './league';

export interface Friend {
  id: string;
  username: string;
  contribution: number;
  picture: string;
  team?: string;
  score: number;
  league: League;
}

export interface FriendList {
  friendCount: number;
  friends: Friend[];
}

export const MAX_FRIEND_COUNT = 30_000;
